import React from 'react';
import {makeClassName} from '../util';

import {
	CCard,
	CCardHeader,
	CCardContent,
	CCardToolbar,
	CCardToolbarAction,
	ICCardProps,
	CCardTitle,
	CCardNameTitle,
	CCardDescribe,
	CCardIntro,
	CCardName,
	CCardAvatar,
	CCardSection,
	CCardSubtleLabel,
	CCardItemIcon,
	CCardRow,
	CCardItemText,
	CCardEmailAddressRow,
} from './ccard';
import {
	ChipSet,
	IChipSetProps,
} from './chipset';

export interface IUserCardProps extends ICCardProps {
	emailAddresses: Array<IEmailAddress>;
	isAdmin: boolean;
	notes: Array<INote>;
	onToolbarAction: (axn: CCardToolbarAction) => any;
	onReceiveNotificationsClick: (index: number) => any;
	phoneNumbers: Array<IPhoneNumber>;
	serviceAreas: Array<IServiceArea>;
	subjectName: string;
	subjectTitle: string;
	userClassName: string;
}

export function UserCard(props: Partial<IUserCardProps>) {
	const {
		emailAddresses,
		isAdmin,
		subjectName,
		notes,
		onToolbarAction,
		onReceiveNotificationsClick,
		phoneNumbers,
		serviceAreas,
		subjectTitle,
		userClassName,
		...rest
	} = props;
	const avName = isAdmin
		? 'admin_panel_settings'
		: 'account_circle';
	const addrs = (emailAddresses === undefined)
		? []
		: emailAddresses;
	const nums = (phoneNumbers === undefined)
		? []
		: phoneNumbers;
	return (
		<CCard {...rest}>
			<CCardHeader>
				<CCardIntro>
					<CCardAvatar>{avName}</CCardAvatar>
					<CCardDescribe>
						<CCardNameTitle>
							<CCardName>{subjectName}</CCardName>
							<CCardTitle>{subjectTitle}</CCardTitle>
						</CCardNameTitle>
						{
							userClassName
								? <UserCardHeaderChipSet text={userClassName}/>
								: null
						}
					</CCardDescribe>
				</CCardIntro>
				<CCardToolbar
					onAction={onToolbarAction}
				/>
			</CCardHeader>
			<CCardContent>
				<CCardSection headerText="Contact details" {...rest}>
					{
						addrs.map((obj, idx) => {
							return (
								<CCardEmailAddressRow
									key={idx}
									obj={obj}
									onToolButtonClick={() => onReceiveNotificationsClick && onReceiveNotificationsClick(idx)}
									renderRowIcon={idx === 0}
								/>
							);
						})
					}
					{
						nums.map((obj, idx) => {
							return (
								<CCardRow key={idx}>
									<CCardItemIcon>
										{
											idx === 0
												? 'phone'
												: null
										}
									</CCardItemIcon>
									<CCardItemText>
										<a href={`tel:${obj.number}`}>{obj.number}</a>
										{
											obj.label
												? <CCardSubtleLabel>
													&nbsp;&nbsp;•&nbsp;{obj.label}
												</CCardSubtleLabel>
												: null
										}
									</CCardItemText>
								</CCardRow>
							);
						})
					}
				</CCardSection>
				{
					(notes && (notes.length > 0))
						? (
							<CCardSection headerText="Notes" {...rest}>
								{
									notes.map((obj, idx) => {
										return (
											<CCardRow key={idx}>
												<CCardItemIcon>
													{
														idx === 0
															? 'notes'
															: null
													}
												</CCardItemIcon>
												<CCardItemText>
													<span>{obj.text}</span>
													{
														obj.label
															? <CCardSubtleLabel>
																&nbsp;&nbsp;•&nbsp;{obj.label}
															</CCardSubtleLabel>
															: null
													}
												</CCardItemText>
											</CCardRow>
										);
									})
								}
							</CCardSection>
						)
						: null
				}
				{
					((serviceAreas === undefined) || (serviceAreas.length === 0))
						? null
						: (
							<CCardSection headerText="Service areas" {...rest}>
								<div className="pb-service-area-list-container">
									{
										serviceAreas.map(x => x.name).map((nm, idx) => {
											return (
												<CCardRow key={idx}>
													<CCardItemIcon>
														{
															idx === 0
																? 'pin_drop'
																: null
														}
													</CCardItemIcon>
													<CCardItemText>
														<span>{nm}</span>
													</CCardItemText>
												</CCardRow>
											);
										})
									}
								</div>
							</CCardSection>
						)
				}
			</CCardContent>
		</CCard>
	);
}

export interface IUserCardHeaderChipSetProps extends IChipSetProps {
	text: string;
}

export function UserCardHeaderChipSet(props: Partial<IUserCardHeaderChipSetProps>) {
	const {
		className,
		text,
		...rest
	} = props;
	const chips = [
		{
			text,
			isSelectable: false,
			isSelected: false,
			isDeletable: false,
			isDisabled: true,
			className: 'pb-cc-chip pb-cc-detail-header__chip',
		},
	];
	const clsName = makeClassName(
		'pb-cc-chip-set pb-cc-detail-header__chip-set',
		className,
	);
	return (
		<ChipSet
			chips={chips}
			className={clsName}
			{...rest}
		/>
	);
}
