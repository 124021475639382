import React from 'react';

import {Button} from './button';
import {
	ButtonRole,
	DialogCode,
	StandardButton,
} from '../constants';
import {
	bind,
	buttonRole,
	buttonText,
	FocusTrap,
	makeClassName,
} from '../util';

interface INodeRef {
	node: React.ReactNode;
	ref: React.RefObject<Button>;
}

export enum Modality {
	Modal,
	Modeless,
}

const ButtonRoleOrder: Array<ButtonRole> = [
	ButtonRole.RejectRole,
	ButtonRole.NoRole,
	ButtonRole.DestructiveRole,
	ButtonRole.ResetRole,
	ButtonRole.HelpRole,
	ButtonRole.ActionRole,
	ButtonRole.ApplyRole,
	ButtonRole.AcceptRole,
	ButtonRole.YesRole,
];

export interface IDialogProps extends React.HTMLAttributes<any> {
	buttons?: StandardButton;
	defaultButton?: StandardButton;
	escapeButton?: StandardButton;
	header?: string;
	isOpen?: boolean;
	modality?: Modality;
	onAccept?: () => any;
	onFinished?: (result: number) => any;
	onReject?: () => any;
}

export interface IDialogState {
	buttonRoles: Array<Array<INodeRef>>;
	buttons: Array<INodeRef>;
	closing: boolean;
	contentIsScrollable: boolean;
	hasFooterDivider: boolean;
	hasHeaderDivider: boolean;
	isOpen: boolean;
	opening: boolean;
	stdBtnMap: Map<INodeRef, StandardButton>;
}

export class Dialog extends React.Component<IDialogProps, IDialogState> {
	static Modality: typeof Modality = Modality;

	private readonly contentRef: React.RefObject<HTMLDivElement>;
	private readonly mql: MediaQueryList;
	private readonly rootRef: React.RefObject<HTMLDivElement>;
	private trap: FocusTrap | null;

	constructor(props: IDialogProps) {
		super(props);
		this.contentRef = React.createRef();
		this.mql = window.matchMedia('(max-width: 704px)');
		this.rootRef = React.createRef();
		this.trap = null;
		const buttonRoles: Array<Array<INodeRef>> = [];
		for (let i = 0; i < ButtonRole.NRoles; ++i) {
			buttonRoles.splice(i, 0, []);
		}
		this.state = {
			buttonRoles,
			buttons: [],
			closing: false,
			contentIsScrollable: false,
			hasFooterDivider: false,
			hasHeaderDivider: false,
			isOpen: false,
			opening: false,
			stdBtnMap: new Map(),
		};
	}

	@bind
	private accept() {
		this.done(DialogCode.Accepted);
	}

	@bind
	private accepted() {
		if (this.props.onAccept) {
			this.props.onAccept();
		}
	}

	@bind
	private button(stdBtn: StandardButton): React.ReactNode {
		for (const [btn, sBtn] of this.state.stdBtnMap.entries()) {
			if (sBtn === stdBtn) {
				return btn.node;
			}
		}
		return null;
	}

	@bind
	private buttonClicked(sBtn: StandardButton) {
		const role = this.buttonRole(
			this.button(sBtn),
		);
		let resCode: number = sBtn;
		switch (role) {
			case ButtonRole.AcceptRole:
			case ButtonRole.YesRole: {
				resCode = DialogCode.Accepted;
				break;
			}
			case ButtonRole.RejectRole:
			case ButtonRole.NoRole: {
				resCode = DialogCode.Rejected;
				break;
			}
		}
		this.done(resCode);
	}

	@bind
	private buttonKeyPressed(event: React.KeyboardEvent) {
		if (event.key === 'Enter') {
			// Avoid document listener from being fired
			// (triggering "click" twice)
			event.stopPropagation();
		}
	}

	private buttonRole(btn: React.ReactNode): ButtonRole {
		const {buttonRoles} = this.state;
		for (let i = 0; i < ButtonRole.NRoles; ++i) {
			const nodes = buttonRoles[i];
			for (let k = 0; k < nodes.length; ++k) {
				if (nodes[k].node === btn) {
					return i;
				}
			}
		}
		return ButtonRole.InvalidRole;
	}

	@bind
	private close() {
		this.setState(
			{
				closing: true,
			},
			() => {
				window.removeEventListener(
					'resize',
					this.setScrollable,
				);
				window.removeEventListener(
					'orientationchange',
					this.setScrollable,
				);
				document.removeEventListener(
					'keydown',
					this.documentKeyPressed,
				);
				document.body.classList.remove(
					'pbr-dialog-scroll-lock',
				);
				setTimeout(
					() => {
						if (this.trap) {
							this.trap.releaseFocus();
							this.trap = null;
						}
						this.setState({
							closing: false,
							isOpen: false,
						});
					},
					75,
				);
			},
		);
	}

	componentDidMount() {
		this.setButtons(
			this.props.buttons,
			() => {
				if (this.props.isOpen) {
					this.open();
				}
			},
		);
	}

	componentDidUpdate(prevProps: Readonly<IDialogProps>, prevState: Readonly<IDialogState>) {
		const {
			buttons,
			isOpen,
		} = this.props;
		const func = () => {
			if (prevProps.isOpen !== isOpen) {
				if (isOpen) {
					this.open();
				} else {
					this.close();
				}
			}
		};
		if (prevProps.buttons === buttons) {
			func();
		} else {
			this.setButtons(buttons, func);
		}
	}

	componentWillUnmount() {
		document.body.classList.remove(
			'pbr-dialog-scroll-lock',
		);
		window.removeEventListener(
			'resize',
			this.setScrollable,
		);
		window.removeEventListener(
			'orientationchange',
			this.setScrollable,
		);
		document.removeEventListener(
			'keydown',
			this.documentKeyPressed,
		);
	}

	@bind
	private contentScrolled() {
		requestAnimationFrame(() => {
			this.toggleScrollDividerHeader();
			this.toggleScrollDividerFooter();
		});
	}

	@bind
	private createButton(sBtn: StandardButton): INodeRef {
		const {defaultButton} = this.props;
		const isDefault = sBtn === defaultButton;
		const ref = React.createRef<Button>();
		const outlined = !isDefault;
		const node = (
			<Button autoFocus={isDefault} className="pbr-dialog__button" onClick={this.buttonClicked.bind(this, sBtn)} onKeyDown={this.buttonKeyPressed} outlined={outlined} raisedFilled={isDefault} ref={ref}>
				{buttonText(sBtn)}
			</Button>
		);
		return {
			node,
			ref,
		};
	}

	@bind
	private documentKeyPressed(event: KeyboardEvent) {
		let sBtn: StandardButton | undefined = undefined;
		switch (event.key) {
			case 'Enter': {
				if (this.props.defaultButton !== undefined) {
					for (const stdBtn of this.state.stdBtnMap.values()) {
						if (stdBtn === this.props.defaultButton) {
							sBtn = this.props.defaultButton;
							break;
						}
					}
				}
				break;
			}
			case 'Escape': {
				if (this.props.escapeButton) {
					for (const stdBtn of this.state.stdBtnMap.values()) {
						if (stdBtn === this.props.escapeButton) {
							sBtn = this.props.escapeButton;
							break;
						}
					}
				}
				if (sBtn === undefined) {
					this.reject();
				}
				break;
			}
		}
		if (sBtn !== undefined) {
			this.buttonClicked(sBtn);
		}
	}

	@bind
	private done(res: number) {
		this.finalize(res);
	}

	@bind
	private finalize(res: number) {
		if (res === DialogCode.Accepted) {
			this.accepted();
		} else if (res === DialogCode.Rejected) {
			this.rejected();
		}
		this.finished(res);
	}

	@bind
	private finished(res: number) {
		const {onFinished} = this.props;
		if (onFinished) {
			onFinished(res);
		}
	}

	@bind
	private isFullScreen(): boolean {
		return this.mql.matches;
	}

	@bind
	private layoutButtons(cb?: () => any) {
		const {buttonRoles} = this.state;
		const buttons: Array<INodeRef> = [];
		const acceptRoleList = buttonRoles[ButtonRole.AcceptRole];
		let currentLayout: number = 0;
		const EOL = ButtonRoleOrder.length;
		while (currentLayout !== EOL) {
			const role = ButtonRoleOrder[currentLayout];
			switch (role) {
				case ButtonRole.AcceptRole: {
					if (acceptRoleList.length === 0) {
						break;
					}
					// Only the first one
					const button = acceptRoleList[0];
					buttons.push(button);
					break;
				}
				case ButtonRole.RejectRole:
				case ButtonRole.NoRole:
				case ButtonRole.DestructiveRole:
				case ButtonRole.ResetRole:
				case ButtonRole.HelpRole:
				case ButtonRole.ActionRole:
				case ButtonRole.ApplyRole:
				case ButtonRole.YesRole: {
					buttons.push(
						...this._layoutButtons(
							buttonRoles[role],
							false,
						),
					);
					break;
				}
			}
			++currentLayout;
		}
		this.setState({buttons}, cb);
	}

	@bind
	private _layoutButtons(buttonList: Array<INodeRef>, reverse: boolean) {
		const start = reverse
			? (buttonList.length - 1)
			: 0;
		const end = reverse
			? -1
			: buttonList.length;
		const step = reverse
			? -1
			: 1;
		const buttons: Array<INodeRef> = [];
		for (let i = start; i !== end; i += step) {
			buttons.push(buttonList[i]);
		}
		return buttons;
	}

	private open() {
		window.addEventListener(
			'resize',
			this.setScrollable,
		);
		window.addEventListener(
			'orientationchange',
			this.setScrollable,
		);
		document.addEventListener(
			'keydown',
			this.documentKeyPressed,
		);
		document.body.classList.add(
			'pbr-dialog-scroll-lock',
		);
		this.setState(
			{
				isOpen: true,
				opening: true,
			},
			() => {
				setTimeout(
					() => {
						this.setState({opening: false});
						const curr = this.rootRef.current;
						if (curr) {
							let initFocusEl: HTMLButtonElement | null = null;
							if (this.props.defaultButton !== undefined) {
								for (const [nodeRef, stdBtn] of this.state.stdBtnMap.entries()) {
									if (stdBtn === this.props.defaultButton) {
										initFocusEl = (
											nodeRef.ref.current
											&& nodeRef.ref.current.rootButtonRef
											&& nodeRef.ref.current.rootButtonRef.current
										);
									}
								}
							}
							this.trap = new FocusTrap(
								curr,
								{initialFocusEl: initFocusEl},
							);
							this.trap.trapFocus();
						}
					},
					150,
				);
			},
		);
	}

	@bind
	private reject() {
		this.done(DialogCode.Rejected);
	}

	@bind
	private rejected() {
		if (this.props.onReject) {
			this.props.onReject();
		}
	}

	render() {
		const {
			buttons: sBtns,
			children,
			className,
			defaultButton,
			header,
			isOpen: io,
			modality,
			onAccept,
			onFinished,
			onReject,
			...rest
		} = this.props;
		const {
			buttons,
			closing,
			isOpen,
			opening,
			hasFooterDivider,
			contentIsScrollable,
			hasHeaderDivider,
		} = this.state;
		const isFullScreen = this.isFullScreen();
		const clsName = makeClassName(
			'pbr-dialog',
			'pbr-new-hotness-dialog',
			opening
				? 'pbr-dialog--opening'
				: undefined,
			closing
				? 'pbr-dialog--closing'
				: undefined,
			isOpen
				? 'pbr-dialog--open'
				: undefined,
			contentIsScrollable
				? 'pbr-dialog--scrollable'
				: undefined,
			isFullScreen
				? 'pbr-new-hotness-dialog--full-screen'
				: undefined,
			hasHeaderDivider
				? 'pbr-dialog-scroll-divider-header'
				: undefined,
			hasFooterDivider
				? 'pbr-dialog-scroll-divider-footer'
				: undefined,
			className,
		);
		const contentScrollListener = (isOpen && isFullScreen)
			? this.contentScrolled
			: undefined;
		const hasChildren = React.Children.toArray(children).length > 0;
		const tabIndex = ((buttons.length > 0) || (modality === Modality.Modeless))
			? undefined
			: 0;  // If no other focusable elements exist, make surface focusable to focustrap doesn't moan and complain.
		const sty = tabIndex === undefined
			? undefined
			: {outline: 'none'};
		return (
			<div className={clsName} ref={this.rootRef} {...rest}>
				<div className="pbr-dialog__container">
					<div className="pbr-dialog__surface" style={sty} tabIndex={tabIndex}>
						{
							header
								? (
									<div className="pbr-dialog__header">
										<h2 className="pbr-dialog__title">
											{header}
										</h2>
									</div>
								)
								: null
						}
						{
							hasChildren
								? (
									<div className="pbr-dialog__content" onScroll={contentScrollListener} ref={this.contentRef}>
										{children}
									</div>
								)
								: null
						}
						{
							(buttons.length > 0)
								? (
									<div className="pbr-dialog__actions">
										{
											...buttons.map(x => x.node)
										}
									</div>
								)
								: null
						}
					</div>
				</div>
				<div
					className="pbr-dialog__scrim"
					onClick={this.scrimClicked}
				/>
			</div>
		);
	}

	@bind
	private scrimClicked() {
		this.reject();
	}

	private setButtons(sBtns?: StandardButton | undefined, cb?: () => any) {
		if (sBtns === undefined) {
			sBtns = StandardButton.NoButton;
		}
		const buttons: Array<INodeRef> = [];
		const buttonRoles: Array<Array<INodeRef>> = [];
		const stdBtnMap = new Map<INodeRef, StandardButton>();
		for (let i = 0; i < ButtonRole.NRoles; ++i) {
			buttonRoles.splice(i, 0, []);
		}
		let i = StandardButton.FirstButton;
		while (i <= StandardButton.LastButton) {
			if (i & sBtns) {
				const btn = this.createButton(i);
				const role = buttonRole(i);
				if (role === ButtonRole.InvalidRole) {
					console.log('setButtons: Invalid ButtonRole, button not added.');
				} else {
					buttonRoles[role].push(btn);
					stdBtnMap.set(btn, i);
					buttons.push(btn);
				}
			}
			i = i << 1;
		}
		this.setState(
			{
				buttonRoles,
				buttons,
				stdBtnMap,
			},
			() => this.layoutButtons(cb),
		);
	}

	@bind
	private setScrollable() {
		this.setState(
			{
				contentIsScrollable: false,
			},
			() => {
				const el = this.contentRef.current;
				if (isScrollable(el)) {
					this.setState(
						{
							contentIsScrollable: true,
						},
						() => {
							if (this.isFullScreen()) {
								this.toggleScrollDividerHeader();
								this.toggleScrollDividerFooter();
							}
						},
					);
				}
			},
		);
	}

	@bind
	private toggleScrollDividerFooter() {
		const el = this.contentRef.current;
		const atBottom = scrollAtBottom(el);
		if (!atBottom) {
			this.setState({
				hasFooterDivider: true,
			});
		} else if (this.state.hasFooterDivider) {
			this.setState({
				hasFooterDivider: false,
			});
		}
	}

	@bind
	private toggleScrollDividerHeader() {
		const el = this.contentRef.current;
		const atTop = scrollAtTop(el);
		if (!atTop) {
			this.setState({
				hasHeaderDivider: true,
			});
		} else if (this.state.hasHeaderDivider) {
			this.setState({
				hasHeaderDivider: false,
			});
		}
	}
}

function scrollAtBottom(el: HTMLElement | null) {
	if (el === null) {
		return false;
	}
	return Math.ceil(el.scrollHeight - el.scrollTop) === el.clientHeight;
}

function scrollAtTop(el: HTMLElement | null) {
	if (el === null) {
		return false;
	}
	return el.scrollTop === 0;
}

function isScrollable(el: HTMLElement | null): boolean {
	return el
		? el.scrollHeight > el.offsetHeight
		: false;
}
