import React from 'react';

import {bind} from '../../../util';
import {Pricing} from './pricing';
import {Size} from './size';
import {ProductFormBase} from './base';
import {Children} from './children';
import {ColorInput} from './colorinput';
import {Duration} from './duration';
import {IconInput} from './iconinput';
import {
	MaxOptions,
	Options,
} from './options';
import {
	CheckState,
	ProductUserRole,
} from '../../../constants';
import {
	IProductUserAssignmentOption,
	ProductUserAssignmentTable,
} from '../../user/form';
import {
	Button,
	CCardHeader,
	CCardToolbar,
	CCardToolbarAction,
	CtaMenu,
	IListProps,
	IMenuOptionProps,
	ISwitchProps,
	List,
	ListItem,
	TextInput,
	Switch,
} from '../../../components';

export interface IProductFormProps {
	addons?: Array<IProduct>;
	childPrices?: (childPk: number) => Array<IPriceGroupProductPrice>;
	children?: Array<IProduct>;
	isCompact?: boolean;
	obj: IProduct;
	onAddChild?: () => any;
	onAddonClick?: (index: number) => any;
	onAddOption?: () => any;
	onCancel: () => any;
	onChange: <K extends keyof IProduct>(name: K, value: IProduct[K]) => any;
	onChildClick?: (index: number) => any;
	onDeleteOption?: (index: number) => any;
	onOptionChange?: <K extends keyof IProductOption>(index: number, name: K, value: IProductOption[K]) => any;
	onPriceChange: <K extends keyof IPriceGroupProductPrice>(index: number, name: K, value: IPriceGroupProductPrice[K]) => any;
	onPriceDelete: (index: number) => any;
	onPriceGroupSelect: (index: number) => any;
	onQuickBooksItemClick?: (index: number) => any;
	onSave: () => any;
	onToolbarAction?: (axn: CCardToolbarAction, coords: PlainCoords) => any;
	onUserAssignmentClick?: (userId: string, role: ProductUserRole, state: CheckState) => any;
	onUserClassClick?: (index: number) => any;
	options?: Array<IProductOption>;
	priceGroups: Array<IPriceGroup>;
	prices: Array<IPriceGroupProductPrice>;
	productUsers?: Array<IProductUser>;
	quickBooksItems?: Array<IQuickBooksItem>;
	toolbarActions?: Array<CCardToolbarAction>;
	userClasses?: Array<IUserClass>;
	users?: Array<IUser>;
}

interface IProductFormState {
	isPriceGroupMenuOpen: boolean;
}

export class ProductForm extends React.Component<IProductFormProps, IProductFormState> {
	constructor(props: IProductFormProps) {
		super(props);
		this.state = {
			isPriceGroupMenuOpen: false,
		};
	}

	@bind
	private addOnProductClicked(index: number): void {
		const {onAddonClick} = this.props;
		if (onAddonClick) {
			onAddonClick(index);
		}
	}

	@bind
	private assignableUserClicked(value: string, role: ProductUserRole, state: CheckState): void {
		const {onUserAssignmentClick} = this.props;
		if (onUserAssignmentClick !== undefined) {
			onUserAssignmentClick(value, role, state);
		}
	}

	@bind
	private changed<K extends keyof IProduct>(name: K, value: IProduct[K]): void {
		this.props.onChange(name, value);
	}

	@bind
	private closePriceGroupMenu(): void {
		const {isPriceGroupMenuOpen} = this.state;
		if (isPriceGroupMenuOpen) {
			this.setState({
				isPriceGroupMenuOpen: false,
			});
		}
	}

	@bind
	private exclusiveProductClicked(index: number): void {
		const {onUserClassClick} = this.props;
		if (onUserClassClick) {
			onUserClassClick(index);
		}
	}

	@bind
	private openPriceGroupMenu(): void {
		const {isPriceGroupMenuOpen} = this.state;
		if (!isPriceGroupMenuOpen) {
			this.setState({
				isPriceGroupMenuOpen: true,
			});
		}
	}

	@bind
	private optionIsDefaultChanged(index: number, state: CheckState): void {
		const {onOptionChange} = this.props;
		if (onOptionChange) {
			onOptionChange(
				index,
				'isDefault',
				state === CheckState.Checked,
			);
		}
	}

	@bind
	private optionNameChanged(index: number, value: string): void {
		const {onOptionChange} = this.props;
		if (onOptionChange) {
			onOptionChange(
				index,
				'name',
				value,
			);
		}
	}

	private priceGroupMenuOptions(): Array<IMenuOptionProps> {
		const {
			priceGroups,
		} = this.props;
		return priceGroups.map(obj => {
			return {
				isSelected: false,
				label: obj.name,
				value: String(obj.id),
			};
		});
	}

	@bind
	private priceGroupMenuOptionSelected(index: number): void {
		this.props.onPriceGroupSelect(index);
	}

	@bind
	private priceGroupName(pk: number): string {
		const {priceGroups} = this.props;
		for (const obj of priceGroups) {
			if (obj.id === pk) {
				return obj.name;
			}
		}
		return '';
	}

	private productUserOptions(): Array<IProductUserAssignmentOption> {
		const {
			obj,
			productUsers,
			users,
		} = this.props;
		if ((users === undefined) || (productUsers === undefined)) {
			return [];
		}
		const rv: Array<IProductUserAssignmentOption> = [];
		for (const user of users) {
			const edIdx = productUsers.findIndex(x => (x.roleId === ProductUserRole.Editor && x.userId === user.email && x.productId === obj.id));
			const shIdx = productUsers.findIndex(x => (x.roleId === ProductUserRole.Shooter && x.userId === user.email && x.productId === obj.id));
			rv.push({
				isEditor: edIdx >= 0,
				isShooter: shIdx >= 0,
				value: user.email,
				label: user.displayName,
			});
		}
		return rv;
	}

	@bind
	private quickBooksItemClicked(index: number) {
		const {onQuickBooksItemClick} = this.props;
		if (onQuickBooksItemClick) {
			onQuickBooksItemClick(index);
		}
	}

	render() {
		const {
			addons,
			childPrices,
			children,
			isCompact,
			obj,
			onAddChild,
			onAddOption,
			onCancel,
			onChange,
			onChildClick,
			onDeleteOption,
			onPriceChange,
			onPriceDelete,
			onSave,
			onToolbarAction,
			options,
			prices,
			quickBooksItems,
			toolbarActions,
			userClasses,
			users,
		} = this.props;
		const {isPriceGroupMenuOpen} = this.state;
		const qbItems = quickBooksItems || [];
		const objAddonPks = new Set(obj.addOns);
		const dur = (obj.duration === null)
			? 0
			: obj.duration;
		const sz = (obj.size === null)
			? 0
			: obj.size;
		const ctaDivCls = (prices.length > 0)
			? 'pb-product-section'
			: 'pb-empty-product-section';
		const avComp = isCompact
			? null
			: (
				<LabeledSwitch isSelected={obj.isActive} onClick={this.toggleIsActive}>
					Available
				</LabeledSwitch>
			);
		const mutexComp = isCompact
			? null
			: (
				<LabeledSwitch isSelected={obj.isMutuallyExclusive} onClick={this.toggleIsMutuallyExclusive}>
					Mutually exclusive
				</LabeledSwitch>
			);
		const exclComp = (isCompact || (userClasses === undefined))
			? null
			: (
				<ExclusiveUserClassList
					currentlySelectedUserClassIds={obj.exclusiveUserClasses}
					objs={userClasses}
					onCheckboxChange={this.exclusiveProductClicked}
				/>
			);
		const nmComp = (
			<TextInput
				isMinimal
				className="width--100-percent"
				label="Name"
				value={obj.name}
				onChange={val => this.changed('name', val)}
			/>
		);
		const smComp = (
			<TextInput
				isMinimal
				className="width--100-percent"
				label="Summary"
				value={obj.summary}
				onChange={val => this.changed('summary', val)}
			/>
		);
		const descComp = (
			<TextInput
				isMinimal
				isTextArea
				className="width--100-percent"
				label="Description"
				value={obj.description}
				rows={5}
				onChange={val => this.changed('description', val)}
			/>
		);
		const durComp = (
			<Duration duration={dur} onChange={val => onChange('duration', val)}>
				Production duration
			</Duration>
		);
		const postDurComp = (
			<Duration>
				Post-production duration
			</Duration>
		);
		const szComp = (
			<Size
				size={sz}
				onChange={this.sizeInput}
			/>
		);
		const icComp = isCompact
			? null
			: (
				<IconInput
					onChange={val => onChange('icon', val)}
					value={obj.icon}
				/>
			);
		const clComp = isCompact
			? null
			: (
				<ColorInput
					onChange={val => onChange('color', val)}
					value={obj.color}
				/>
			);
		const prComp = (
			<>
				<h4 className="pb-catalog-item-form__subsection-subtitle">Pricing</h4>
				<Pricing
					onDelete={onPriceDelete}
					onPriceChange={onPriceChange}
					objs={prices}
					priceGroupName={this.priceGroupName}
				/>
				<div className={ctaDivCls}>
					<CtaMenu onSelection={this.priceGroupMenuOptionSelected} onCloseRequest={this.closePriceGroupMenu} isOpen={isPriceGroupMenuOpen} menuOptions={this.priceGroupMenuOptions()}>
						<Button onClick={this.openPriceGroupMenu}>Add Price</Button>
					</CtaMenu>
				</div>
			</>
		);
		const vrComp = isCompact
			? null
			: (
				<>
					<h4 className="pb-catalog-item-form__subsection-subtitle">Variants</h4>
					<Children
						prices={childPrices}
						objects={children}
						onAdd={onAddChild}
						onClick={onChildClick}
					/>
				</>
			);
		const optComp = (isCompact || (options === undefined))
			? null
			: (
				<>
					<h4 className="pb-catalog-item-form__subsection-subtitle">Options</h4>
					<Options
						options={options}
						onAddClick={onAddOption}
						onDefaultCheck={this.optionIsDefaultChanged}
						onDeleteClick={onDeleteOption}
						onNameChange={this.optionNameChanged}
					/>
					{
						(options.length > 0)
							? (
								<MaxOptions
									maxOptions={obj.maxOptions}
									onInputChange={val => this.changed('maxOptions', val)}
								/>
							)
							: null
					}
				</>
			);
		const addComp = (isCompact || (addons === undefined))
			? null
			: (
				<>
					<h4 className="pb-catalog-item-form__subsection-subtitle">Add-ons</h4>
					<List className="pb-product-detail-obj-list" isCompact>
						{
							addons.map((ob, idx) => {
								return (
									<ListItem onCheckboxChange={this.addOnProductClicked.bind(this, idx)} isSelected={objAddonPks.has(ob.id)} key={idx} withCheckbox>
										{ob.name}
									</ListItem>
								);
							})
						}
					</List>
				</>
			);
		const assComp = (isCompact || (users === undefined))
			? null
			: (
				<>
					<h4 className="pb-catalog-item-form__subsection-subtitle">Assignable team members</h4>
					<ProductUserAssignmentTable
						onOptionCheckStateChange={this.assignableUserClicked}
						options={this.productUserOptions()}
					/>
				</>
			);
		const qbComp = (isCompact || (qbItems.length === 0))
			? null
			: (
				<>
					<h4 className="pb-catalog-item-form__subsection-subtitle">Related QuickBooks item</h4>
					<List className="pb-product-detail-obj-list" isCompact>
						{
							qbItems.map((ob, idx) => {
								return (
									<ListItem isClickable isSelected={ob.id === obj.quickbooksItemId} key={idx} onClick={this.quickBooksItemClicked.bind(this, idx)}>
										{ob.name}
									</ListItem>
								);
							})
						}
					</List>
				</>
			);
		const tbComp = (toolbarActions === undefined)
			? null
			: (
				<CCardHeader className="display--flex flex-direction--row justify-content--space-between align-items--center">
					<div>
						{obj.name}
					</div>
					<CCardToolbar
						actions={toolbarActions}
						onAction={onToolbarAction}
					/>
				</CCardHeader>
			);
		return (
			<ProductFormBase
				addOnComp={addComp}
				assignableUserComp={assComp}
				availableComp={avComp}
				colorComp={clComp}
				descriptionComp={descComp}
				durationComp={durComp}
				exclusiveToComp={exclComp}
				iconComp={icComp}
				mutexComp={mutexComp}
				nameComp={nmComp}
				optionComp={optComp}
				postDurationComp={postDurComp}
				pricingComp={prComp}
				quickBooksComp={qbComp}
				sizeComp={szComp}
				summaryComp={smComp}
				toolBarComp={tbComp}
				variantComp={vrComp}>
				<div className="pb-product-form-axn-btns">
					<Button onClick={onCancel}>Cancel</Button>
					<Button onClick={onSave} raisedFilled>Save</Button>
				</div>
			</ProductFormBase>
		);
	}

	@bind
	private sizeInput(value: number | null): void {
		this.changed('size', value);
	}

	@bind
	private toggleIsActive(): void {
		const {obj} = this.props;
		this.changed(
			'isActive',
			!obj.isActive,
		);
	}

	@bind
	private toggleIsMutuallyExclusive(): void {
		const {obj} = this.props;
		this.changed(
			'isMutuallyExclusive',
			!obj.isMutuallyExclusive,
		);
	}
}

function LabeledSwitch(props: Partial<ISwitchProps>) {
	const {
		children,
		...rest
	} = props;
	return (
		<label>
			<div>{children}</div>
			<Switch {...rest}/>
		</label>
	);
}

interface IExclusiveUserClassListProps extends Partial<IListProps> {
	currentlySelectedUserClassIds: Array<number>;
	objs: Array<IUserClass>;
	onCheckboxChange: (index: number, state: CheckState) => any;
}

function ExclusiveUserClassList(props: IExclusiveUserClassListProps) {
	const {
		currentlySelectedUserClassIds,
		objs,
		onCheckboxChange,
		...rest
	} = props;
	return (
		<>
			<div>Exclusive to</div>
			<List className="pb-product-detail-obj-list" isCompact {...rest}>
				{
					objs.map((obj, idx) => {
						return (
							<ListItem onCheckboxChange={state => onCheckboxChange(idx, state)} isSelected={currentlySelectedUserClassIds.indexOf(obj.id) >= 0} key={idx} withCheckbox>
								{obj.name}
							</ListItem>
						);
					})
				}
			</List>
		</>
	);
}
