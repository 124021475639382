import React from 'react';
import {MDCChipActionAdapter, MDCChipTrailingActionFoundation, MDCChipActionAttributes, MDCChipActionEvents, MDCChipActionInteractionEventDetail, MDCChipActionInteractionTrigger, MDCChipActionNavigationEventDetail, MDCChipActionType, MDCChipPrimaryActionFoundation, MDCChipActionFoundation, MDCChipActionFocusBehavior} from '@material/chips';

import {bind, makeClassName} from '../../util';
import {Icon, IIconProps} from '../icon';

export interface IGoogleSucksAtSoftwareActionEventData {
	actionId: string;
	clickCoords: PlainCoords;
	key: string;
	source: MDCChipActionType;
	trigger: MDCChipActionInteractionTrigger;
}

export enum ActionEvent {
	Interaction,
	Navigation,
}

export interface IActionProps extends React.ButtonHTMLAttributes<any> {
	isDeletable: boolean;
	isDisabled: boolean;
	isFilter: boolean;
	isPrimary: boolean;
	isSelectable: boolean;
	isSelected: boolean;
	leadingIconName: string;
	onEvent: (axn: ActionEvent, data: Partial<IGoogleSucksAtSoftwareActionEventData>) => any;
}

interface IActionState {
	lastClickCoords: {x: number, y: number};
}

export class Action extends React.Component<Partial<IActionProps>, IActionState> {
	private ctrl: MDCChipActionFoundation;
	private readonly rootRef: React.RefObject<HTMLButtonElement>;

	constructor(props: Partial<IActionProps>) {
		super(props);
		this.ctrl = new MDCChipPrimaryActionFoundation();
		this.rootRef = React.createRef();
		this.state = {
			lastClickCoords: {
				x: 0,
				y: 0,
			},
		};
	}

	actionType(): MDCChipActionType {
		const {isPrimary} = this.props;
		if (isPrimary) {
			return MDCChipActionType.PRIMARY;
		}
		return MDCChipActionType.TRAILING;
	}

	@bind
	private clicked(event: React.MouseEvent): void {
		this.setState(
			{
				lastClickCoords: {
					x: event.clientX,
					y: event.clientY,
				}
			},
			() => this.ctrl.handleClick()
		);
	}

	componentDidMount() {
		this.resetCtrl();
	}

	componentDidUpdate(prevProps: Readonly<Partial<IActionProps>>, prevState: Readonly<{}>) {
		const {isPrimary} = this.props;
		if (prevProps.isPrimary !== isPrimary) {
			this.resetCtrl();
		}
	}

	componentWillUnmount() {
		this.ctrl.destroy();
	}

	isFocusable(): boolean {
		return this.ctrl.isFocusable();
	}

	isSelectable(): boolean {
		return this.ctrl.isSelectable();
	}

	@bind
	private keyPressed(event: React.KeyboardEvent): void {
		this.ctrl.handleKeydown(event.nativeEvent);
	}

	private mdcAdapter(): MDCChipActionAdapter {
		return {
			emitEvent: <D extends object>(name: MDCChipActionEvents, detail: D) => {
				const {onEvent} = this.props;
				if (!onEvent) {
					return;
				}
				const axn = (name === MDCChipActionEvents.INTERACTION)
					? ActionEvent.Interaction
					: ActionEvent.Navigation;
				const data: Partial<IGoogleSucksAtSoftwareActionEventData> = {};
				if (objIsIntAction(detail)) {
					data.actionId = detail.actionID;
					data.source = detail.source;
					data.trigger = detail.trigger;
				} else if (objIsNavAction(detail)) {
					data.key = detail.key;
					data.source = detail.source;
				} else {
					console.log('PrimaryAction::emitEvent: Got invalid detail object:', detail);
					return;
				}
				const {lastClickCoords} = this.state;
				data.clickCoords = {
					...lastClickCoords,
				};
				onEvent(axn, data);
			},
			focus: () => {
				this.setFocused(true);
			},
			getAttribute: (attr: MDCChipActionAttributes) => {
				const root = this.rootRef.current;
				if (root) {
					return root.getAttribute(attr);
				}
				return null;
			},
			getElementID: () => {
				const root = this.rootRef.current;
				if (root) {
					return root.id;
				}
				return '';
			},
			removeAttribute: (attr: MDCChipActionAttributes) => {
				const root = this.rootRef.current;
				if (root) {
					root.removeAttribute(attr);
				}
			},
			setAttribute: (attr: MDCChipActionAttributes, value: string) => {
				this.setAttribute(attr, value);
			},
		};
	}

	render() {
		const {
			children,
			isDeletable,
			isDisabled,
			isFilter,
			isPrimary,
			isSelectable,
			isSelected,
			leadingIconName,
			onEvent,
			tabIndex,
			type,
			...rest
		} = this.props;
		const clsName = makeClassName(
			'mdc-evolution-chip__action',
			isPrimary
				? 'mdc-evolution-chip__action--primary'
				: 'mdc-evolution-chip__action--trailing',
		);
		const tabIdx = (tabIndex === undefined)
			? isDisabled
				? -1
				: isPrimary
					? 0
					: -1
			: tabIndex;
		const typ = (type === undefined)
			? 'button'
			: type;
		const isDel = isDeletable
			? 'true'
			: undefined;
		const role = isSelectable
			? 'option'
			: undefined;
		const ariaSel = isSelectable
			? String(isSelected === true) as 'true' | 'false'
			: undefined;
		return (
			<button aria-selected={ariaSel} className={clsName} data-mdc-deletable={isDel} disabled={isDisabled} ref={this.rootRef} onClick={this.clicked} onKeyDown={this.keyPressed} role={role} tabIndex={tabIdx} type={typ} {...rest}>
				<Rip isPrimary={isPrimary}/>
				{
					isPrimary && (leadingIconName || isSelectable || isFilter)
						? (
							<Graphic>
								{
									leadingIconName
										? (
											<LeadingIcon>{leadingIconName}</LeadingIcon>
										)
										: null
								}
								{
									(isFilter || isSelectable)
										? (
											<Check/>
										)
										: null
								}
							</Graphic>
						)
						: null
				}
				{
					isPrimary
						? (
							<span className="mdc-evolution-chip__text-label">{children}</span>
						)
						: (
							<TrailingIcon>close</TrailingIcon>
						)
				}
			</button>
		);
	}

	private resetCtrl(): void {
		const {isPrimary} = this.props;
		this.ctrl.destroy();
		if (isPrimary || (isPrimary === undefined)) {
			this.ctrl = new MDCChipPrimaryActionFoundation(
				this.mdcAdapter(),
			);
		} else {
			this.ctrl = new MDCChipTrailingActionFoundation(
				this.mdcAdapter(),
			);
		}
		this.ctrl.init();
	}

	setAttribute(name: string, value: string): void {
		const root = this.rootRef.current;
		if (root) {
			root.setAttribute(name, value);
		}
	}

	setFocus(stuff: MDCChipActionFocusBehavior): void {
		this.ctrl.setFocus(stuff);
	}

	setFocused(isFocused: boolean): void {
		if (isFocused) {
			const root = this.rootRef.current;
			if (root) {
				root.focus();
			}
		}
	}
}

interface IRipProps extends React.HTMLAttributes<any> {
	isPrimary: boolean;
}

function Rip(props: Partial<IRipProps>) {
	const {
		className,
		isPrimary,
		...rest
	} = props;
	const clsName = makeClassName(
		'mdc-evolution-chip__ripple',
		isPrimary
			? 'mdc-evolution-chip__ripple--primary'
			: 'mdc-evolution-chip__ripple--trailing',
		className,
	);
	return (
		<span className={clsName} {...rest}/>
	);
}

interface IGraphicProps extends React.HTMLAttributes<any> {
}

function Graphic(props: Partial<IGraphicProps>) {
	const {
		children,
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		'mdc-evolution-chip__graphic',
		className,
	);
	return (
		<span className={clsName} {...rest}>
			{children}
		</span>
	);
}

interface ILeadingIconProps extends IIconProps {
}

function LeadingIcon(props: Partial<ILeadingIconProps>) {
	const {
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		'mdc-evolution-chip__icon',
		'mdc-evolution-chip__icon--primary',
		className,
	);
	return (
		<Icon className={clsName} {...rest}/>
	);
}

interface ICheckProps extends React.HTMLAttributes<any> {
}

function Check(props: Partial<ICheckProps>) {
	const {
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		'mdc-evolution-chip__checkmark',
		className,
	);
	return (
		<span className={clsName} {...rest}>
			<svg className="mdc-evolution-chip__checkmark-svg" viewBox="-2 -3 30 30">
				<path
					className="mdc-evolution-chip__checkmark-path"
					fill="none"
					stroke="black"
					d="M1.73,12.91 8.1,19.28 22.79,4.59"
				/>
			</svg>
		</span>
	);
}

function objIsIntAction(obj: any): obj is MDCChipActionInteractionEventDetail {
	try {
		return 'trigger' in obj;
	} catch {
		return false;
	}
}

function objIsNavAction(obj: any): obj is MDCChipActionNavigationEventDetail {
	try {
		return 'key' in obj;
	} catch {
		return false;
	}
}

interface ITrailingIconProps extends IIconProps {
}

function TrailingIcon(props: Partial<ITrailingIconProps>) {
	const {
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		'mdc-evolution-chip__icon',
		'mdc-evolution-chip__icon--trailing',
		className,
	);
	return (
		<Icon className={clsName} {...rest}/>
	);
}
