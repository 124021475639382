import React from 'react';
import {
	UiTableColumnDataType,
	UiTableTitle,
} from '../../constants';

import {api} from '../../httpapi';
import {ListView} from '../list';
import {
	bind,
	idxOk,
	isNumber,
	numberFormat,
	staticCfg,
} from '../../util';
import {
	ComboBox,
	DataTableCellProps,
	Icon,
	IMenuOptionProps,
	TextInput,
	TubbyButton,
	WideContentContainer,
	WideContentHeader,
} from '../../components';

export * from './detail';

enum ProductColumn {
	Icon = 'Icon',
	ChoiceLimit = 'Choice Limit',
	Description = 'Description',
	Name = 'Name',
	Public = 'Public',
	Duration = 'Duration',
	Size = 'Size',
	Color = 'Color',
	Price = 'Price',
}

export interface IProductViewProps extends React.HTMLAttributes<any> {
}

interface IProductViewState {
	activePriceGroupPk: number;
	activeSize: number | null;
	cfg: ICfg;
	priceGroupObjs: Array<IPriceGroup>;
}

export class ProductView extends React.Component<Partial<IProductViewProps>, IProductViewState> {
	private readonly listViewRef: React.RefObject<ListView>;

	constructor(props: Partial<IProductViewProps>) {
		super(props);
		this.listViewRef = React.createRef();
		this.state = {
			activePriceGroupPk: 0,
			activeSize: null,
			cfg: staticCfg(),
			priceGroupObjs: [],
		};
	}

	@bind
	private cellHook(col: ICol, obj: IProduct): Partial<DataTableCellProps> {
		switch (col.label) {
			case ProductColumn.Icon: {
				return {
					children: (
						<Icon>
							{obj.icon}
						</Icon>
					),
					href: obj.absoluteUrl,
				};
			}
			case ProductColumn.ChoiceLimit: {
				return {
					children: obj.maxOptions,
					dataType: UiTableColumnDataType.Integer,
					href: obj.absoluteUrl,
				};
			}
			case ProductColumn.Description: {
				return {
					children: obj.summary,
					href: obj.absoluteUrl,
				};
			}
			case ProductColumn.Name: {
				return {
					children: obj.name,
					href: obj.absoluteUrl,
				};
			}
			case ProductColumn.Public: {
				return {
					children: obj.isActive
						? (
							<Icon>check</Icon>
						)
						: null,
					href: obj.absoluteUrl,
				};
			}
			case ProductColumn.Duration: {
				return {
					children: obj.durationDisplay,
					dataType: UiTableColumnDataType.Integer,
					href: obj.absoluteUrl,
				};
			}
			case ProductColumn.Size: {
				return {
					children: obj.size === null
						? null
						: numberFormat(obj.size),
					dataType: UiTableColumnDataType.Integer,
					href: obj.absoluteUrl,
				};
			}
			case ProductColumn.Color: {
				return {
					href: obj.absoluteUrl,
					style: (obj.color.length > 0)
						? {backgroundColor: obj.color}
						: undefined,
				};
			}
			case ProductColumn.Price: {
				return {
					children: obj.priceDisplay,
					dataType: UiTableColumnDataType.Integer,
					href: obj.absoluteUrl,
				};
			}
		}
		return {
			children: null,
		};
	}

	async componentDidMount() {
		await this.refreshCfg();
		await this.refreshPage();
		this.setState({
			priceGroupObjs: await api.priceGroupList(),
		});
	}

	@bind
	private async fetchCfg(): Promise<ICfg> {
		return await api.cfgDetail();
	}

	@bind
	private priceGroupOptions(): Array<IMenuOptionProps> {
		const {
			activePriceGroupPk,
			priceGroupObjs,
		} = this.state;
		return [
			{
				value: '',
				label: '',
				isSelected: activePriceGroupPk === 0,
			},
			...priceGroupObjs.map(x => {
				return {
					label: x.name,
					value: String(x.id),
					isSelected: x.id === activePriceGroupPk,
				};
			}),
		];
	}

	@bind
	private priceGroupSelected(index: number): void {
		const opts = this.priceGroupOptions();
		if (idxOk(index, opts.length, 'priceGroupSelected')) {
			const opt = opts[index];
			let n = 0;
			if (opt.value !== '') {
				n = Number.parseInt(opt.value);
				if (!isNumber(n)) {
					console.log('priceGroupSelected: Invalid object ident.:', opt.value);
					n = 0;
				}
			}
			this.setState(
				{
					activePriceGroupPk: n,
				},
				() => this.refreshPage({pgid: n}),
			);
		}
	}

	@bind
	async refreshCfg(cb?: () => any): Promise<void> {
		this.setState(
			{
				cfg: await this.fetchCfg(),
			},
			cb,
		);
	}

	@bind
	async refreshPage(params?: Partial<IPageParams>, cb?: () => any): Promise<void> {
		const curr = this.listViewRef.current;
		if (curr) {
			await curr.refreshPage(params, cb);
		}
	}

	render() {
		const {
			activeSize,
			activePriceGroupPk,
			cfg,
		} = this.state;
		const szInp = (activeSize === null)
			? ''
			: String(activeSize);
		const szLabel = (activeSize === null)
			? 'Size >= ?'
			: `Size >= ${activeSize}`;
		return (
			<>
				<WideContentHeader hasDivider>
					Products
				</WideContentHeader>
				<WideContentContainer>
					<div className="pb-list-view-actions">
						<div className="pb-list-view-actions__action-block">
							<ComboBox
								isOutlined
								onChange={this.priceGroupSelected}
								className="pb-list-view-actions__action"
								label="Price Group"
								options={this.priceGroupOptions()}
							/>
							<TextInput
								isOutlined
								type="number"
								className="pb-list-view-actions__action"
								label={szLabel}
								onChange={this.sizeChanged}
								value={szInp}
								step={100}
								min={0}
							/>
						</div>
						<TubbyButton isAnchor href={window.pbUrls.productCreateView}>
							Create product
						</TubbyButton>
					</div>
					{/*<ProductList*/}
					{/*	activePriceGroupPk={activePriceGroupPk}*/}
					{/*	activeSize={activeSize}*/}
					{/*	cfg={cfg}*/}
					{/*	pageGetter={api.productList}*/}
					{/*	ref={this.productListRef}*/}
					{/*/>*/}
					<ListView
						activePriceGroupPk={activePriceGroupPk}
						activeSize={activeSize}
						cellHook={this.cellHook}
						cfg={cfg}
						columnMenuEnabled
						columnsMovable
						pageGetter={api.productList}
						ref={this.listViewRef}
						uiTableTitle={UiTableTitle.ItemList}
					/>
				</WideContentContainer>
			</>
		);
	}

	@bind
	private sizeChanged(value: string): void {
		let n: number | null = null;
		if (value !== '') {
			n = Number.parseInt(value);
			if (!isNumber(n)) {
				console.log('sizeChanged: Invalid input value:', value);
				n = null;
			}
		}
		this.setState(
			{
				activeSize: n,
			},
			() => this.refreshPage(),
		);
	}
}
