import React from 'react';

import {path} from '../urls';
import {RequestHandler} from '../urls/handlers';
import {ResolverMatch} from '../urls/resolvers';
import {AppDetailView} from './app';
import {NotificationView} from './notification';
import {PaymentMethodView} from './paymentmethod/view';
import {TeamMemberListView} from './teammember';
import {PriceGroupView} from './pricegroup';
import {UserClassView} from './userclass';
import {ServiceAreaView} from './servicearea';
import {AlternateEmailView} from './alternateemail';
import {PhoneNumberView} from './phonenumber';
import {
	ClientOrganizationListView,
	ClientUserListView,
} from './client';
import {
	MessageTemplateCreateView,
	MessageTemplateUpdateView,
	SnippetCreateView,
	SnippetUpdateView,
} from './messaging';
import {
	ProductDetailView,
	ProductView,
} from './product';
import {
	// MediaObjectCollectionView,
	ProjectCreateView,
	ProjectView,
	RescheduleView,
} from './project';

export function Pb() {
	const [comp, props] = currUrl();
	return comp
		? React.createElement(comp, props, null)
		: null;
}

const urlPatterns = [
	path(`^${window.pbUrls.addEmailView.slice(1)}$`, AlternateEmailView),
	path(`^${window.pbUrls.addPhoneNumberView.slice(1)}$`, PhoneNumberView),
	path(`^${window.pbUrls.orgAppIndexView.slice(1)}([\\w-]+)/$`, AppDetailView),
	path(`^${window.pbUrls.notificationsView.slice(1)}$`, NotificationView),
	path(`^${window.pbUrls.messageListView.slice(1)}(\\d+)/$`, MessageTemplateUpdateView),
	path(`^${window.pbUrls.messageCreateView.slice(1)}$`, MessageTemplateCreateView),
	path(`^${window.pbUrls.snippetListView.slice(1)}(\\d+)/$`, SnippetUpdateView),
	path(`^${window.pbUrls.snippetCreateView.slice(1)}$`, SnippetCreateView),
	path(`^${window.pbUrls.paymentMethodListView.slice(1)}$`, PaymentMethodView),
	path(`^${window.pbUrls.organizationClientOrganizationListView.slice(1)}$`, ClientOrganizationListView),
	path(`^${window.pbUrls.organizationClientUserListView.slice(1)}$`, ClientUserListView),
	path(`^${window.pbUrls.priceGroupListView.slice(1)}$`, PriceGroupView),
	path(`^${window.pbUrls.productListView.slice(1)}$`, ProductView),
	path(`^${window.pbUrls.productListView.slice(1)}create/$`, ProductDetailView),
	path(`^${window.pbUrls.productListView.slice(1)}(\\d+)/$`, ProductDetailView),
	path(`^${window.pbUrls.productListView.slice(1)}$`, ProductView),
	path(`^${window.pbUrls.projectCreateView.slice(1)}$`, ProjectCreateView),
	path(`^${window.pbUrls.projectListView.slice(1)}([A-Z0-9]{5})?/?$`, ProjectView),
	// path(`^${window.pbUrls.projectListView.slice(1)}([A-Z0-9]{5})/media/(\\d+)/$`, MediaObjectCollectionView),
	path(`^${window.pbUrls.projectListView.slice(1)}([A-Z0-9]{5})/reschedule/$`, RescheduleView),
	path(`^${window.pbUrls.organizationServiceAreaListView.slice(1)}$`, ServiceAreaView),
	path(`^${window.pbUrls.organizationTeamMemberListView.slice(1)}$`, TeamMemberListView),
	path(`^${window.pbUrls.organizationUserClassListView.slice(1)}$`, UserClassView),
];

function currUrl(): [React.ClassType<any, any, any>, any] | [null, null] {
	const handler = new RequestHandler();
	const req = {
		pathname: window.location.pathname,
		urlPatterns,
	};
	let match: ResolverMatch | undefined = undefined;
	try {
		match = handler.resolveRequest(req);
	} catch (exc) {
		console.error(exc);
	}
	if (match) {
		const props: any = {pk: null};
		// if (match.args.length === 1) {
		if (match.args.length > 0) {
			props.pk = match.args[0];
			if (match.args.length > 1) {
				props.otherPk = match.args[1];
			}
		}
		return [
			match.func,
			props,
		];
	}
	return [
		null,
		null,
	];
}
