import React from 'react';

import {
	bind,
	clamp,
	makeClassName,
} from '../util';

export interface ISliderProps extends Omit<React.HTMLAttributes<any>, 'onChange' | 'onDragStart' | 'onDragEnd' | 'onInput'> {
	formatValueIndicator?: (value: number) => string;
	isDisabled?: boolean;
	isDiscrete?: boolean;
	max: number;
	min: number;
	name?: string;
	onChange: (value: number) => any;
	step: number;
	value: number;
}

interface ISliderState {
	activeTrackStyle: React.CSSProperties | undefined;
	dims: {left: number; width: number;};
	inputHasFocus: boolean;
	isDragging: boolean;
	mouseIsInsideThumb: boolean;
	thumbStyle: React.CSSProperties | undefined;
	thumbWidth: number;
	valIndiContWidth: number;
	valIndiText: string;
}

export class Slider extends React.Component<ISliderProps, ISliderState> {
	private readonly rootRef: React.RefObject<HTMLDivElement>;
	private readonly inputRef: React.RefObject<HTMLInputElement>;
	private readonly thumbRef: React.RefObject<HTMLDivElement>;
	private readonly valIndiContRef: React.RefObject<HTMLDivElement>;

	constructor(props: ISliderProps) {
		super(props);
		this.rootRef = React.createRef();
		this.inputRef = React.createRef();
		this.thumbRef = React.createRef();
		this.valIndiContRef = React.createRef();
		this.state = {
			activeTrackStyle: undefined,
			dims: {
				left: 0,
				width: 0,
			},
			inputHasFocus: false,
			isDragging: false,
			mouseIsInsideThumb: false,
			thumbStyle: undefined,
			thumbWidth: 0,
			valIndiContWidth: 0,
			valIndiText: '',
		};
	}

	componentDidMount() {
		this.setDims(this.updateThumbTrack);
	}

	componentDidUpdate(prevProps: Readonly<ISliderProps>, prevState: Readonly<ISliderState>) {
		if (prevProps.value !== this.props.value) {
			this.updateThumbTrack();
		}
	}

	componentWillUnmount() {
		document.body.addEventListener(
			'mousemove',
			this.mouseMoved,
		);
		document.body.addEventListener(
			'mouseup',
			this.mouseReleased,
		);
	}

	@bind
	private inputGotFocus() {
		this.setState({
			inputHasFocus: true,
		});
	}

	@bind
	private inputLostFocus() {
		this.setState({
			inputHasFocus: false,
		});
	}

	@bind
	private mouseEnteredThumb() {
		if (this.props.isDiscrete) {
			this.setState({
				mouseIsInsideThumb: true,
			});
		}
	}

	@bind
	private mouseLeftThumb() {
		if (this.state.inputHasFocus) {
			return;
		}
		if (this.state.mouseIsInsideThumb) {
			this.setState({
				mouseIsInsideThumb: false,
			});
		}
	}

	@bind
	private mouseMoved(event: MouseEvent) {
		if (this.props.isDisabled || !this.state.isDragging) {
			return;
		}
		event.preventDefault();
		this.props.onChange(
			clamp(
				this.xOnSliderScale(event.clientX),
				this.props.min,
				this.props.max,
			),
		);
	}

	@bind
	private mousePressed(event: React.MouseEvent) {
		this.startDragging(() => {
			this.props.onChange(
				clamp(
					this.xOnSliderScale(event.clientX),
					this.props.min,
					this.props.max,
				),
			);
		});
		event.preventDefault();
	}

	@bind
	private mouseReleased() {
		this.stopDragging();
	}

	render() {
		const {
			children,
			className,
			isDisabled,
			isDiscrete,
			max,
			min,
			name,
			formatValueIndicator,
			onChange,
			step,
			value,
			...rest
		} = this.props;
		const {
			activeTrackStyle,
			inputHasFocus,
			mouseIsInsideThumb,
			thumbStyle,
		} = this.state;
		const clsName = makeClassName(
			'mdc-slider',
			isDisabled
				? 'mdc-slider--disabled'
				: undefined,
			isDiscrete
				? 'mdc-slider--discrete'
				: undefined,
			className,
		);
		const thumbClsName = makeClassName(
			'mdc-slider__thumb',
			isDiscrete && (inputHasFocus || mouseIsInsideThumb)
				? 'mdc-slider__thumb--with-indicator'
				: undefined,
			inputHasFocus
				? 'mdc-slider__thumb--focused'
				: undefined,
		);
		const val = formatValueIndicator
			? formatValueIndicator(value)
			: value;
		return (
			<div className={clsName} {...rest} onMouseDown={this.mousePressed} ref={this.rootRef}>
				<input
					className="mdc-slider__input"
					disabled={isDisabled}
					max={max}
					min={min}
					name={name}
					onBlur={this.inputLostFocus}
					step={step}
					onFocus={this.inputGotFocus}
					onChange={() => undefined}
					ref={this.inputRef}
					type="range"
					style={{pointerEvents: 'auto'}}
					value={value}
				/>
				<div className="mdc-slider__track">
					<div className="mdc-slider__track--inactive"/>
					<div className="mdc-slider__track--active">
						<div className="mdc-slider__track--active_fill" style={activeTrackStyle}/>
					</div>
				</div>
				<div className={thumbClsName} onMouseEnter={this.mouseEnteredThumb} onMouseLeave={this.mouseLeftThumb} ref={this.thumbRef} style={thumbStyle}>
					{
						isDiscrete
							? (
								<div className="mdc-slider__value-indicator-container" ref={this.valIndiContRef}>
									<div className="mdc-slider__value-indicator">
										<span className="mdc-slider__value-indicator-text">{val}</span>
									</div>
								</div>
							)
							: null
					}
					<div className="mdc-slider__thumb-knob"/>
				</div>
			</div>
		);
	}

	private setDims(cb?: () => any) {
		let dims: {left: number; width: number;} = this.state.dims;
		let curr = this.rootRef.current;
		if (curr) {
			const rect = curr.getBoundingClientRect();
			dims = {
				left: rect.left,
				width: rect.width,
			};
		}
		let thumbWidth = this.state.thumbWidth;
		curr = this.thumbRef.current;
		if (curr) {
			const rect = curr.getBoundingClientRect();
			thumbWidth = rect.width;
		}
		let valIndiContWidth = this.state.valIndiContWidth;
		curr = this.valIndiContRef.current;
		if (curr) {
			const rect = curr.getBoundingClientRect();
			valIndiContWidth = rect.width;
		}
		this.setState(
			{
				dims,
				thumbWidth,
				valIndiContWidth,
			},
			cb,
		);
	}

	private startDragging(cb?: () => any) {
		if (this.props.isDisabled) {
			return;
		}
		const curr = this.inputRef.current;
		if (curr) {
			curr.focus();
		}
		this.setDims(() => {
			this.setState(
				{
					isDragging: true,
				},
				() => {
					document.body.addEventListener(
						'mousemove',
						this.mouseMoved,
					);
					document.body.addEventListener(
						'mouseup',
						this.mouseReleased,
					);
					if (cb) {
						cb();
					}
				},
			);
		});
	}

	private stopDragging() {
		document.body.removeEventListener(
			'mousemove',
			this.mouseMoved,
		);
		document.body.removeEventListener(
			'mouseup',
			this.mouseReleased,
		);
		this.setState({isDragging: false});
	}

	@bind
	private updateThumbTrack() {
		const pctComplete = (this.props.value - this.props.min) / (this.props.max - this.props.min);
		const thumbStartPos = pctComplete * this.state.dims.width;
		this.setState({
			activeTrackStyle: {
				...this.state.activeTrackStyle,
				transform: `scaleX(${pctComplete})`,
			},
			thumbStyle: {
				...this.state.thumbStyle,
				...this.valueIndicatorAlignmentStyle(thumbStartPos),
				transform: `translateX(${thumbStartPos}px)`,
			},
		});
	}

	private valueIndicatorAlignmentStyle(thumbPos: number): React.CSSProperties {
		const thumbHalfWidth = this.state.thumbWidth / 2;
		const containerWidth = this.state.valIndiContWidth;
		const sliderWidth = this.state.dims.width;
		if ((containerWidth / 2) > (thumbPos + thumbHalfWidth)) {
			return {
				['--slider-value-indicator-caret-left' as any]: `${thumbHalfWidth}px`,
				['--slider-value-indicator-caret-right' as any]: 'auto',
				['--slider-value-indicator-caret-transform' as any]: 'translateX(-50%)',
				['--slider-value-indicator-container-left' as any]: '0',
				['--slider-value-indicator-container-right' as any]: 'auto',
				['--slider-value-indicator-container-transform' as any]: 'none',
			};
		}
		if (containerWidth / 2 > sliderWidth - thumbPos + thumbHalfWidth) {
			return {
				['--slider-value-indicator-caret-left' as any]: 'auto',
				['--slider-value-indicator-caret-right' as any]: `${thumbHalfWidth}px`,
				['--slider-value-indicator-caret-transform' as any]: 'translateX(50%)',
				['--slider-value-indicator-container-left' as any]: 'auto',
				['--slider-value-indicator-container-right' as any]: '0',
				['--slider-value-indicator-container-transform' as any]: 'none',
			};
		}
		return {
			['--slider-value-indicator-caret-left' as any]: '50%',
			['--slider-value-indicator-caret-right' as any]: 'auto',
			['--slider-value-indicator-caret-transform' as any]: 'translateX(-50%)',
			['--slider-value-indicator-container-left' as any]: '50%',
			['--slider-value-indicator-container-right' as any]: 'auto',
			['--slider-value-indicator-container-transform' as any]: 'translateX(-50%)',
		};
	}

	private xOnSliderScale(clientX: number): number {
		const xPos = clientX - this.state.dims.left;
		let pctComplete = xPos / this.state.dims.width;
		// Fit the percentage complete between the range [min,max]
		// by remapping from [0, 1] to [min, min+(max-min)].
		const value = this.props.min + pctComplete * (this.props.max - this.props.min);
		if (value === this.props.max || value === this.props.min) {
			return value;
		}
		return quantize(value, this.props.min, this.props.step);
	}
}

function quantize(value: number, min: number, step: number): number {
	const numSteps = Math.round((value - min) / step);
	return min + numSteps * step;
}
