import React, {PropsWithChildren} from 'react';

import {makeClassName} from '../../util';
import {CssClassName} from './constants';

export interface IListGroupProps extends React.HTMLAttributes<any> {
}

export function ListGroup(props: PropsWithChildren<IListGroupProps>) {
	const {
		children,
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		CssClassName.ListGroup,
		className,
	);
	return (
		<div className={clsName} {...rest}>
			{children}
		</div>
	);
}

export interface IListGroupHeaderProps extends React.HTMLAttributes<any> {
}

export function ListGroupHeader(props: PropsWithChildren<IListGroupHeaderProps>) {
	const {
		children,
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		CssClassName.ListGroupSubheader,
		className,
	);
	return (
		<div className={clsName} {...rest}>
			{children}
		</div>
	);
}
