import React from 'react';

import {
	Button,
	Checkbox,
	DataTable,
	DataTableCellProps,
	Dialog,
	Icon,
	IconButton,
	IListProps,
	IMenuOptionProps,
	List,
	ListItem,
	Menu,
	Prompt,
	TextInput,
} from '../../../../components';
import {
	CheckState,
	DialogCode,
	InvoiceLineState,
	StandardButton,
	UiTableColumnDataType,
} from '../../../../constants';
import {
	bind,
	makeClassName,
	staticInvoiceLine,
	staticMediaObject,
} from '../../../../util';

enum Opt {
	NoOpt,
	EditLine,
	DeleteLine,
	DeleteMediaObject,
	LineDetail,
	SetLineStateNotReady,
	SetLineStateReady,
	SetLineStateReleased,
	AddMediaObject,
	EditMediaObject,
}

interface IDialogProps {
	iconName: string;
	headerText: string;
	bodyText?: string;
}

export interface IInvoiceViewProps extends React.HTMLAttributes<any> {
	lines: Array<IInvoiceLine>;
	total?: number | string;
	balance?: number | string;
	onLineSave?: (data: IInvoiceLine) => any;
	onLineDelete?: (index: number) => any;
	onMediaObjectSave?: (invoiceLinePk: number, data: IMediaObject) => any;
	onMediaObjectDelete?: (invoiceLinePk: number, index: number) => any;
}

interface IInvoiceViewState {
	currentLineEdit: IInvoiceLine;
	currentMediaObjEdit: IMediaObject;
	currentLineIdx: number;
	currentMediaObjIdx: number;
	currentOpt: Opt;
	dialogIsOpen: boolean;
	menuCoords: PlainCoords;
	menuIsOpen: boolean;
}

export class InvoiceView extends React.Component<IInvoiceViewProps, IInvoiceViewState> {
	constructor(props: IInvoiceViewProps) {
		super(props);
		this.state = {
			currentLineEdit: staticInvoiceLine(),
			currentMediaObjEdit: staticMediaObject(),
			currentLineIdx: -1,
			currentMediaObjIdx: -1,
			currentOpt: Opt.NoOpt,
			dialogIsOpen: false,
			menuCoords: {
				x: 0,
				y: 0,
			},
			menuIsOpen: false,
		};
	}

	@bind
	private closeDialog() {
		if (this.state.dialogIsOpen) {
			this.setState({
				dialogIsOpen: false,
			});
		}
	}

	private currentMediaObject(): IMediaObject | null {
		const {currentMediaObjIdx} = this.state;
		if (currentMediaObjIdx >= 0) {
			const line = this.currentLine();
			if (line && currentMediaObjIdx < line.mediaObjects.length) {
				return line.mediaObjects[currentMediaObjIdx];
			}
		}
		return null;
	}

	@bind
	private closeMenu() {
		const {menuIsOpen} = this.state;
		if (menuIsOpen) {
			this.setState({
				menuIsOpen: false,
			});
		}
	}

	private currentLine(): IInvoiceLine | null {
		const {lines} = this.props;
		const {currentLineIdx} = this.state;
		if ((currentLineIdx >= 0) && (currentLineIdx < lines.length)) {
			return lines[currentLineIdx];
		}
		return null;
	}

	@bind
	private dialogProps(): IDialogProps {
		let iconName: string = '';
		let headerText: string = '';
		let bodyText: string | undefined = undefined;
		const {currentOpt} = this.state;
		switch (currentOpt) {
			case Opt.DeleteLine: {
				iconName = 'delete_outline';
				let suffix: string = 'this line item';
				const currLine = this.currentLine();
				if (currLine && (currLine.summary.trim().length > 0)) {
					suffix = currLine.summary.trim();
				}
				headerText = `Delete ${suffix}?`;
				break;
			}
			case Opt.DeleteMediaObject: {
				iconName = 'delete_outline';
				let suffix: string = 'this item';
				const currMediaObj = this.currentMediaObject();
				if (currMediaObj && (currMediaObj.name.trim().length > 0)) {
					suffix = currMediaObj.name.trim();
				}
				headerText = `Delete ${suffix}?`;
				break;
			}
			default: {
				console.log('Invalid option (%s [%s]) for dialog context', currentOpt, Opt[currentOpt]);
			}
		}
		return {
			iconName,
			headerText,
			bodyText,
		};
	}

	@bind
	private dialogResult(result: DialogCode) {
		const {
			onLineDelete,
			onMediaObjectDelete,
		} = this.props;
		const {
			currentOpt,
			currentLineIdx,
			currentLineEdit,
			currentMediaObjEdit,
		} = this.state;
		switch (currentOpt) {
			case Opt.DeleteLine: {
				if ((result === DialogCode.Accepted) && (currentLineIdx >= 0) && onLineDelete) {
					onLineDelete(currentLineIdx);
				}
				break;
			}
			case Opt.DeleteMediaObject: {
				if ((result === DialogCode.Accepted) && (currentLineIdx >= 0) && onMediaObjectDelete) {
					onMediaObjectDelete(currentLineEdit.id, currentMediaObjEdit.id);
				}
				break;
			}
			default: {
				console.log('dialogResult: Unhandled current option: %s', currentOpt);
				break;
			}
		}
		this.closeDialog();
	}

	@bind
	private lineAmountChanged(value: string) {
		this.setState({
			currentLineEdit: {
				...this.state.currentLineEdit,
				unitAmount: value,
			},
		});
	}

	@bind
	private lineDescriptionChanged(value: string) {
		this.setState({
			currentLineEdit: {
				...this.state.currentLineEdit,
				summary: value,
			},
		});
	}

	@bind
	private lineTaxableChanged(state: CheckState) {
		this.setState({
			currentLineEdit: {
				...this.state.currentLineEdit,
				taxable: state === CheckState.Checked,
			},
		});
	}

	@bind
	private lineEditCanceled() {
		this.setState(
			{
				currentLineIdx: -1,
				currentOpt: Opt.NoOpt,
			},
			this.stopEditingCurrentLine,
		);
	}

	@bind
	private lineEditSaved() {
		const {onLineSave} = this.props;
		if (onLineSave) {
			onLineSave({
				...this.currentLine(),
				...this.state.currentLineEdit,
			});
		}
		this.setState(
			{
				currentLineIdx: -1,
				currentOpt: Opt.NoOpt,
			},
			this.stopEditingCurrentLine,
		);
	}

	@bind
	private lineOptionButtonClicked(lineIndex: number, event: React.MouseEvent) {
		this.setState({
			currentLineIdx: lineIndex,
			currentOpt: Opt.LineDetail,
			menuCoords: {
				x: event.clientX,
				y: event.clientY,
			},
			menuIsOpen: true,
		});
	}

	@bind
	private mediaObjectEditCanceled() {
		this.setState(
			{
				currentOpt: Opt.NoOpt,
				currentMediaObjEdit: staticMediaObject(),
			},
		);
	}

	@bind
	private mediaObjectEditSaved() {
		const {onMediaObjectSave} = this.props;
		const line = this.currentLine();
		if (onMediaObjectSave && line) {
			const obj = this.state.currentMediaObjEdit;
			onMediaObjectSave(
				line.id,
				obj,
			);
			this.setState(
				{
					currentOpt: Opt.NoOpt,
					currentMediaObjEdit: staticMediaObject(),
				},
			);
		}
	}

	@bind
	private mediaObjectIsCollectionChanged(value: CheckState) {
		this.setState({
			currentMediaObjEdit: {
				...this.state.currentMediaObjEdit,
				isCollection: value === CheckState.Checked,
			},
		});
	}

	@bind
	private mediaObjectNameChanged(value: string) {
		this.setState({
			currentMediaObjEdit: {
				...this.state.currentMediaObjEdit,
				name: value,
			},
		});
	}

	@bind
	private mediaObjectUrlChanged(value: string) {
		this.setState({
			currentMediaObjEdit: {
				...this.state.currentMediaObjEdit,
				url: value,
			},
		});
	}

	@bind
	private menuItemSelected(itemIndex: number) {
		const opts = this.menuOptions();
		if ((itemIndex >= 0) && (itemIndex < opts.length)) {
			const line = this.currentLine();
			const opt = opts[itemIndex];
			switch (opt.value) {
				case String(Opt.EditLine): {
					this.setState({
						currentLineEdit: {
							...staticInvoiceLine(),
							...this.currentLine(),
						},
						currentOpt: Opt.EditLine,
					});
					break;
				}
				case String(Opt.SetLineStateNotReady):
				case String(Opt.SetLineStateReady):
				case String(Opt.SetLineStateReleased): {
					const {onLineSave} = this.props;
					if (line && onLineSave) {
						let newState: string;
						switch (opt.value) {
							case String(Opt.SetLineStateNotReady): {
								newState = InvoiceLineState.Draft;
								break;
							}
							case String(Opt.SetLineStateReady): {
								newState = InvoiceLineState.ReadyForRelease;
								break;
							}
							case String(Opt.SetLineStateReleased): {
								newState = InvoiceLineState.Released;
								break;
							}
							default: {
								console.log('Invalid option for line state: %s', opt.value);
								return;
							}
						}
						onLineSave({
							...line,
							state: newState,
						});
					}
					break;
				}
				case String(Opt.DeleteLine): {
					break;
				}
				case String(Opt.AddMediaObject): {
					this.setState({
						currentOpt: Opt.AddMediaObject,
					});
				}
			}
		} else {
			console.log('menuItemSelected(%s): Invalid index', itemIndex);
		}
	}

	@bind
	private mediaObjectClicked(invoiceLineIdx: number, mediaObjectIndex: number) {
		this.setState(
			{
				currentLineIdx: invoiceLineIdx,
			},
			() => {
				let nextCurrMediaObjIdx = -1;
				if (mediaObjectIndex >= 0) {
					const line = this.currentLine();
					if (line && (mediaObjectIndex < line.mediaObjects.length)) {
						nextCurrMediaObjIdx = mediaObjectIndex;
					}
				}
				this.setState(
					{
						currentMediaObjIdx: nextCurrMediaObjIdx,
					},
					() => {
						if (this.state.currentMediaObjIdx >= 0) {
							const line = this.currentLine();
							if (line) {
								const obj = line.mediaObjects[this.state.currentMediaObjIdx];
								this.setState({
									currentMediaObjEdit: {
										...obj,
									},
									currentOpt: Opt.EditMediaObject,
								});
							}
						}
					},
				);
			},
		);
	}

	@bind
	private menuClosed() {
		this.closeMenu();
	}

	private menuOptions(): Array<IMenuOptionProps> {
		const {currentOpt} = this.state;
		const currLine = this.currentLine();
		const rv: Array<IMenuOptionProps> = [];
		switch (currentOpt) {
			case Opt.LineDetail: {
				const indiState = currLine
					? currLine.state
					: undefined;
				rv.push(
					{
						label: 'Edit',
						value: String(Opt.EditLine),
					},
					{
						label: 'Add media',
						value: String(Opt.AddMediaObject),
					},
					{
						label: '-----',
						value: '',
						isItemDivider: true,
					},
					{
						label: 'Not ready for release',
						value: String(Opt.SetLineStateNotReady),
						listItemProps: {
							graphic: (
								<StateIndicator state={indiState === InvoiceLineState.Draft
									? indiState
									: undefined
								}/>
							),
						},
					},
					{
						label: 'Ready for release',
						value: String(Opt.SetLineStateReady),
						listItemProps: {
							graphic: (
								<StateIndicator state={indiState === InvoiceLineState.ReadyForRelease
									? indiState
									: undefined
								}/>
							),
						},
					},
					{
						label: 'Released',
						value: String(Opt.SetLineStateReleased),
						listItemProps: {
							graphic: (
								<StateIndicator state={indiState === InvoiceLineState.Released
									? indiState
									: undefined
								}/>
							),
						},
					},
					{
						label: '-----',
						value: '',
						isItemDivider: true,
					},
					{
						label: 'Delete',
						value: String(Opt.DeleteLine),
					},
				);
				break;
			}
			default: {
				console.log('menuOptions: Unhandled option %s (%s)', currentOpt, Opt[currentOpt]);
			}
		}
		return rv;
	}

	@bind
	private openDialog() {
		if (!this.state.dialogIsOpen) {
			this.setState({
				dialogIsOpen: true,
			});
		}
	}

	render() {
		const {
			balance,
			lines,
			total,
		} = this.props;
		const {
			dialogIsOpen,
			menuCoords,
			menuIsOpen,
			currentOpt,
			currentLineEdit,
			currentMediaObjEdit,
		} = this.state;
		let comp: React.ReactNode;
		switch (currentOpt) {
			case Opt.EditLine: {
				comp = (
					<InvoiceLineView
						description={currentLineEdit.summary}
						amount={currentLineEdit.unitAmount}
						taxable={currentLineEdit.taxable}
						onCancel={this.lineEditCanceled}
						onSave={this.lineEditSaved}
						onDescChange={this.lineDescriptionChanged}
						onAmtChange={this.lineAmountChanged}
						onTaxableChange={this.lineTaxableChanged}
					/>
				);
				break;
			}
			case Opt.EditMediaObject:
			case Opt.AddMediaObject: {
				comp = (
					<MediaObjectUpdateView
						onCancel={this.mediaObjectEditCanceled}
						onSave={this.mediaObjectEditSaved}
						onUrlChange={this.mediaObjectUrlChanged}
						onNameChange={this.mediaObjectNameChanged}
						onIsCollectionChange={this.mediaObjectIsCollectionChanged}
						name={currentMediaObjEdit.name}
						url={currentMediaObjEdit.url}
						isCollection={currentMediaObjEdit.isCollection}
					/>
				);
				break;
			}
			default: {
				const rows: Array<Array<DataTableCellProps>> = [];
				for (let i = 0; i < lines.length; ++i) {
					rows.push(
						[
							{
								children: (
									<StateIndicator state={lines[i].state}/>
								),
							},
							{
								children: (
									<>
										{lines[i].summary}
										<MediaObjectListView
											objs={lines[i].mediaObjects}
											onItemClick={this.mediaObjectClicked.bind(this, i)}
										/>
									</>
								),
							},
							{
								children: lines[i].unitAmount,
								dataType: UiTableColumnDataType.Integer,
							},
							{
								children: <IconButton onClick={this.lineOptionButtonClicked.bind(this, i)}>more_vert</IconButton>,
								style: {
									width: '24px',
								},
							},
						],
					);
				}
				if (total !== undefined) {
					rows.push(
						[
							{},
							{},
							{
								children: '--------',
								dataType: UiTableColumnDataType.Integer,
							},
							{},
						],
						[
							{},
							{
								children: 'Total',
							},
							{
								children: total,
								dataType: UiTableColumnDataType.Integer,
							},
							{},
						],
					);
				}
				if (balance !== undefined) {
					rows.push(
						[
							{},
							{},
							{
								children: '--------',
								dataType: UiTableColumnDataType.Integer,
							},
							{},
						],
						[
							{},
							{
								children: 'Balance',
							},
							{
								children: balance,
								dataType: UiTableColumnDataType.Integer,
							},
							{},
						],
					);
				}
				comp = (
					<DataTable
						className="invoice-view-table"
						columns={[]}
						rows={rows}
					/>
				);
				break;
			}
		}
		const diaProps: IDialogProps = dialogIsOpen
			? this.dialogProps()
			: {
				iconName: '',
				headerText: '',
			};
		const menuOpts = this.menuOptions();
		const menuOpen = menuIsOpen && (menuOpts.length > 0);
		return (
			<>
				{comp}
				<Menu
					isOpen={menuOpen}
					absolutePosition={menuCoords}
					anchorToBody
					onSelection={this.menuItemSelected}
					options={menuOpts}
					onClose={this.menuClosed}
				/>
				<Dialog buttons={StandardButton.Yes | StandardButton.No} isOpen={dialogIsOpen} onFinished={this.dialogResult}>
					<Prompt
						iconName={diaProps.iconName}
						header={diaProps.headerText}
					/>
				</Dialog>
			</>
		);
	}

	@bind
	private stopEditingCurrentLine() {
		this.setState({
			currentLineEdit: staticInvoiceLine(),
		});
	}
}

interface IStateIndicatorProps {
	state?: string;
}

function StateIndicator(props: IStateIndicatorProps) {
	const {state} = props;
	let clsName: string;
	switch (state) {
		case InvoiceLineState.Draft: {
			clsName = 'semi-transparent';
			break;
		}
		case InvoiceLineState.ReadyForRelease: {
			clsName = 'blue';
			break;
		}
		case InvoiceLineState.Released: {
			clsName = 'green';
			break;
		}
		default: {
			clsName = 'transparent';
			break;
		}
	}
	const iconClsName = makeClassName(
		'state-indicator',
		clsName,
	);
	return (
		<Icon className={iconClsName}>circle</Icon>
	);
}

export interface IInvoiceLineViewProps extends React.HTMLAttributes<any> {
	description?: string;
	amount?: number | string;
	taxable?: boolean;
	onCancel?: () => any;
	onSave?: () => any;
	onDescChange?: (value: string) => any;
	onAmtChange?: (value: string) => any;
	onTaxableChange?: (value: CheckState) => any;
}

function InvoiceLineView(props: IInvoiceLineViewProps) {
	const {
		description,
		amount,
		taxable,
		onCancel,
		onSave,
		onDescChange,
		onAmtChange,
		onTaxableChange,
	} = props;
	const desc = (description === undefined)
		? ''
		: description;
	const amt = (amount === undefined)
		? ''
		: amount;
	const tax = (taxable === undefined)
		? false
		: taxable;
	return (
		<div>
			<TextInput
				isDense
				isMinimal
				onChange={onDescChange}
				placeholder="Description"
				value={desc}
			/>
			<TextInput
				isDense
				isMinimal
				onChange={onAmtChange}
				placeholder="Amount"
				value={amt}
			/>
			<label>
				Taxable
				<Checkbox
					isChecked={tax}
					onChange={onTaxableChange}
				/>
			</label>
			<Button onClick={onCancel}>
				Cancel
			</Button>
			<Button onClick={onSave} raisedFilled>
				Save
			</Button>
		</div>
	);
}

interface IMediaObjectListViewProps extends Partial<IListProps> {
	objs: Array<IMediaObject>;
	onItemClick: (itemIndex: number) => any;
}

function MediaObjectListView(props: IMediaObjectListViewProps) {
	const {
		objs,
		onItemClick,
		...rest
	} = props;
	if (objs.length > 0) {
		return (
			<List {...rest}>
				{
					objs.map((obj, i) => {
						return (
							<ListItem key={i} isClickable onClick={() => onItemClick(i)}>
								{obj.name}
							</ListItem>
						);
					})
				}
			</List>
		);
	}
	return null;
}

interface IMediaObjectUpdateViewProps {
	onNameChange: (value: string) => any;
	onUrlChange: (value: string) => any;
	onIsCollectionChange: (value: CheckState) => any;
	onCancel: () => any;
	onSave: () => any;
	name: string;
	url: string;
	isCollection: boolean;
}

function MediaObjectUpdateView(props: IMediaObjectUpdateViewProps) {
	const {
		onNameChange,
		onUrlChange,
		onSave,
		onCancel,
		isCollection,
		onIsCollectionChange,
		name,
		url,
	} = props;
	return (
		<div>
			<label>
				Is this a gallery?
				<Checkbox
					onChange={onIsCollectionChange}
					isChecked={isCollection}
				/>
			</label>
			<TextInput
				isDense
				isMinimal
				onChange={onNameChange}
				placeholder="Name"
				value={name}
			/>
			{
				isCollection
					? null
					: (
						<TextInput
							isDense
							isMinimal
							onChange={onUrlChange}
							placeholder="URL"
							value={url}
						/>
					)
			}
			<Button onClick={onCancel}>
				Cancel
			</Button>
			<Button onClick={onSave} raisedFilled>
				Save
			</Button>
		</div>
	);
}
