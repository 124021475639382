import React from 'react';
import {makeClassName} from '../util';

export interface ISubheadingProps extends React.HTMLAttributes<any> {
	align: 'start' | 'center' | 'end';
}

export function SubHeader(props: Partial<ISubheadingProps>) {
	let {
		align,
		children,
		className,
		...rest
	} = props;
	align = (align === undefined)
		? 'center'
		: align;
	const clsName = makeClassName(
		'display--flex',
		'color--grayish',
		(align === 'center')
			? 'justify-content--center'
			: undefined,
		(align === 'start')
			? 'justify-content--flex-start'
			: undefined,
		(align === 'end')
			? 'justify-content--flex-end'
			: undefined,
		className,
	);
	return (
		<div className={clsName} {...rest}>
			{children}
		</div>
	);
}
