import React from 'react';
import {
	WideContentContainer,
	WideContentHeader,
} from '../../components';
import {UiTableTitle} from '../../constants';
import {api} from '../../httpapi';
import {UserView} from '../user';

export interface IClientUserListViewProps extends React.HTMLAttributes<any> {
}

export interface IClientUserListViewState {
}

export class ClientUserListView extends React.Component<IClientUserListViewProps, IClientUserListViewState> {
	constructor(props: IClientUserListViewProps) {
		super(props);
	}

	render() {
		return (
			<>
				<WideContentHeader backNavUri={window.pbUrls.organizationClientIndexView} backNavTitle="Back to clients" hasDivider>
					Client users
				</WideContentHeader>
				<WideContentContainer>
					<UserView
						objUpdater={api.updateClientUser}
						objCreator={api.createClientUser}
						pageGetter={
							async (p?: Partial<IPageParams>) => await api.clientUserList({tbl: UiTableTitle.ClientUserList, ...p})
						}
						objDetailGetter={api.clientUserDetail}
						orgListGetter={api.clientOrganizationList}
						uiTable={UiTableTitle.ClientUserList}
						userClassListGetter={api.allUserClasses}
					/>
				</WideContentContainer>
			</>
		);
	}
}
