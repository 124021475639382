import React from 'react';

import {bind} from '../../util';
import {api} from '../../httpapi';
import {CrudOp} from '../../constants';
import {PhoneNumberCreate} from './create';
import {PhoneNumberRead} from './read';
import {PhoneNumberUpdate} from './update';
import {PhoneNumberDelete} from './delete';
import {PhoneNumberList} from './list';
import {
	ContentCard,
	ContentContainer,
	ContentHeader,
	CCardToolbarAction,
	Dialog,
	IMenuOptionProps,
	Menu,
} from '../../components';

export interface IPhoneNumberViewProps extends React.HTMLAttributes<any> {
}

interface IPhoneNumberViewState {
	activePk: number;
	dialogViewState: CrudOp;
	isDirty: boolean;
	moreMenuCoords: PlainCoords | null;
	moreMenuIsOpen: boolean;
}

export class PhoneNumberView extends React.Component<IPhoneNumberViewProps, IPhoneNumberViewState> {
	constructor(props: IPhoneNumberViewProps) {
		super(props);
		this.state = {
			activePk: 0,
			moreMenuCoords: null,
			moreMenuIsOpen: false,
			dialogViewState: CrudOp.NoOp,
			isDirty: false,
		};
	}

	@bind
	private cancelPressed(): void {
		const {
			activePk,
			dialogViewState,
		} = this.state;
		switch (dialogViewState) {
			case CrudOp.Create:
			case CrudOp.Read: {
				this.closeDialog();
				break;
			}
			case CrudOp.Update:
			case CrudOp.Delete: {
				this.openReadDialog(activePk);
				break;
			}
		}
	}

	@bind
	private closeDialog(): void {
		this.setState({
			activePk: 0,
			dialogViewState: CrudOp.NoOp,
		});
	}

	@bind
	private closeMoreMenu(): void {
		this.setState({
			moreMenuIsOpen: false,
			moreMenuCoords: null,
		});
	}

	@bind
	private listRefreshed(): void {
		this.setState({
			isDirty: false,
		});
	}

	@bind
	private moreMenuClosed(): void {
		this.closeMoreMenu();
	}

	@bind
	private moreMenuOptionSelected(index: number): void {
		const {activePk} = this.state;
		const opts = this.moreMenuOptions();
		if ((index >= 0) && (index < opts.length)) {
			this.openDeleteDialog(activePk);
		} else {
			console.log('moreMenuOptionSelected: Invalid option index:', index);
		}
	}

	private moreMenuOptions(): Array<IMenuOptionProps> {
		return [
			{
				isSelected: false,
				label: 'Delete',
				value: 'DELETE',
			},
		];
	}

	@bind
	private objClicked(pk: number): void {
		this.openReadDialog(pk);
	}

	@bind
	private objCreated(): void {
		this.setState(
			{
				isDirty: true,
			},
			() => this.closeDialog(),
		);
	}

	@bind
	private async objDeleted() {
		this.setState(
			{
				isDirty: true,
			},
			() => this.closeDialog(),
		);
	}

	@bind
	private objUpdated(): void {
		const {activePk} = this.state;
		this.setState(
			{
				isDirty: true,
			},
			() => this.openReadDialog(activePk),
		);
	}

	@bind
	private openCreateDialog(): void {
		this.setState({
			dialogViewState: CrudOp.Create,
		});
	}

	@bind
	private openDeleteDialog(activePk: number) {
		this.closeMoreMenu();
		this.setState({
			activePk,
			dialogViewState: CrudOp.Delete,
		});
	}

	private openMoreMenu(x?: number, y?: number): void {
		if ((x === undefined) || (y === undefined)) {
			console.log('openMoreMenu: Coordinates are undefined');
			return;
		}
		this.setState({
			moreMenuIsOpen: true,
			moreMenuCoords: {
				x,
				y,
			},
		});
	}

	@bind
	private openReadDialog(pk: number): void {
		this.setState({
			activePk: pk,
			dialogViewState: CrudOp.Read,
		});
	}

	render() {
		const {
			activePk,
			dialogViewState,
			moreMenuIsOpen,
			moreMenuCoords,
			isDirty,
		} = this.state;
		let comp: React.ReactNode = null;
		switch (dialogViewState) {
			case CrudOp.Create: {
				comp = (
					<PhoneNumberCreate
						onCancel={this.cancelPressed}
						objCreator={api.createPhoneNumber}
						onDone={this.objCreated}
					/>
				);
				break;
			}
			case CrudOp.Read: {
				comp = (
					<PhoneNumberRead
						objGetter={api.phoneNumberDetail}
						onToolbarAction={this.toolbarAction}
						pk={activePk}
					/>
				);
				break;
			}
			case CrudOp.Update: {
				comp = (
					<PhoneNumberUpdate
						onCancel={this.cancelPressed}
						pk={activePk}
						onDone={this.objUpdated}
						objGetter={api.phoneNumberDetail}
						objUpdater={api.updatePhoneNumber}
					/>
				);
				break;
			}
			case CrudOp.Delete: {
				comp = (
					<PhoneNumberDelete
						onCancel={this.cancelPressed}
						objGetter={api.phoneNumberDetail}
						objDeleter={api.deletePhoneNumber}
						onDone={this.objDeleted}
						pk={activePk}
					/>
				);
				break;
			}
		}
		const open = dialogViewState !== CrudOp.NoOp;
		return (
			<>
				<ContentHeader hasDivider>Phone numbers</ContentHeader>
				<ContentContainer>
					<ContentCard>
						<PhoneNumberList
							onObjectClick={this.objClicked}
							objGetter={api.phoneNumberList}
							onCreateBtnClick={this.openCreateDialog}
							pendingRefresh={isDirty}
							onRefresh={this.listRefreshed}
						/>
					</ContentCard>
				</ContentContainer>
				<Dialog isOpen={open} onFinished={this.closeDialog}>
					{comp}
				</Dialog>
				<Menu
					anchorToBody
					isCompact
					isOpen={moreMenuIsOpen}
					onClose={this.moreMenuClosed}
					onSelection={this.moreMenuOptionSelected}
					options={this.moreMenuOptions()}
					absolutePosition={moreMenuCoords || undefined}
				/>
			</>
		);
	}

	@bind
	private toolbarAction(axn: CCardToolbarAction, clientX?: number, clientY?: number): void {
		switch (axn) {
			case CCardToolbarAction.Close: {
				this.closeDialog();
				break;
			}
			case CCardToolbarAction.Edit: {
				this.setState({
					dialogViewState: CrudOp.Update,
				});
				break;
			}
			case CCardToolbarAction.More: {
				this.openMoreMenu(clientX, clientY);
				break;
			}
		}
	}
}
