import React from 'react';

import {AppWidgetName} from '../../../constants';
import {
	AppWidgThing,
	bind,
	capitalize,
	idxOk,
	isNumber,
} from '../../../util';
import {
	CardHeader,
	IProjectCreateViewState,
	SubtleLabel,
} from './misc';
import {
	ComboBox,
	GridLayout,
	GridLayoutCell,
	GridLayoutDivider,
	IMenuOptionProps,
	TextInput,
} from '../../../components';

export interface IProjectAccessOccupancyViewProps extends Omit<React.HTMLAttributes<any>, 'onChange'> {
	accessNotes?: string;
	onChange?: <K extends keyof IProjectCreateViewState>(name: K, value: IProjectCreateViewState[K]) => any;
	appWidgThings: Array<AppWidgThing>;
	selectedAccessOptionValue: number | null;
	selectedOccupancyOptionValue: number | null;
}

interface IProjectAccessOccupancyViewState {
}

export class ProjectAccessOccupancyView extends React.Component<IProjectAccessOccupancyViewProps, IProjectAccessOccupancyViewState> {
	@bind
	private accessNotesChanged(value: string): void {
		const {onChange} = this.props;
		if (onChange) {
			onChange('accessNotes', value);
		}
	}

	@bind
	private accessOptionChanged(index: number): void {
		const {onChange} = this.props;
		const opts = this.accessOptions();
		if (onChange && idxOk(index, opts.length, 'accessOptionChanged')) {
			let num: number | null;
			if (opts[index].value.length > 0) {
				num = Number.parseInt(opts[index].value);
				if (!isNumber(num)) {
					console.log('accessOptionChanged: Invalid option value: %s', opts[index].value);
					num = null;
				}
			} else {
				num = null;
			}
			onChange('accessOrgAppWidgetOptionId', num);
		}
	}

	private accessOptions(): Array<IMenuOptionProps> {
		const {selectedAccessOptionValue} = this.props;
		const obj = this.appWidgThing(AppWidgetName.Access);
		return obj.optionMenuOptions(
			widg => selectedAccessOptionValue === widg.id,
		);
	}

	private appWidgThing(name: string): AppWidgThing {
		const {appWidgThings} = this.props;
		for (const obj of (appWidgThings || [])) {
			if (obj.name === name) {
				return obj;
			}
		}
		return new AppWidgThing();
	}

	@bind
	private occupancyOptionChanged(index: number): void {
		const {onChange} = this.props;
		const opts = this.occupancyOptions();
		if (onChange && idxOk(index, opts.length, 'occupancyOptionChanged')) {
			let num: number | null;
			if (opts[index].value.length > 0) {
				num = Number.parseInt(opts[index].value);
				if (!isNumber(num)) {
					console.log('occupancyOptionChanged: Invalid option value: %s', opts[index].value);
					num = null;
				}
			} else {
				num = null;
			}
			onChange('occupancyOrgAppWidgetOptionId', num);
		}
	}

	private occupancyOptions(): Array<IMenuOptionProps> {
		const {selectedOccupancyOptionValue} = this.props;
		const obj = this.appWidgThing(AppWidgetName.Occupancy);
		return obj.optionMenuOptions(
			widg => selectedOccupancyOptionValue === widg.id,
		);
	}

	render() {
		const {
			accessNotes,
			children,
		} = this.props;
		const accessAppWidg = this.appWidgThing(AppWidgetName.Access);
		const accessNotesAppWidg = this.appWidgThing(AppWidgetName.AccessNotes);
		const occupancyAppWidg = this.appWidgThing(AppWidgetName.Occupancy);
		if (accessAppWidg.isDisabled && accessNotesAppWidg.isDisabled && occupancyAppWidg.isDisabled) {
			return null;
		}
		const haveAccess = accessAppWidg.isEnabled || accessNotesAppWidg.isEnabled;
		const haveOcc = occupancyAppWidg.isEnabled;
		return (
			<>
				<CardHeader stepNumber={3}>
					{
						(haveAccess && haveOcc)
							? 'Access \u0026 Occupancy'
							: haveAccess
								? 'Access'
								: haveOcc
									? 'Occupancy'
									: null
					}
				</CardHeader>
				{
					haveAccess
						? (
							<GridLayout>
								{
									(accessAppWidg.label.length > 0)
										? (
											<GridLayoutCell span={4}>
												<SubtleLabel>
													{capitalize(accessAppWidg.label)}
												</SubtleLabel>
											</GridLayoutCell>
										)
										: null
								}
								{
									accessAppWidg.isEnabled
										? (
											<GridLayoutCell span={12}>
												<ComboBox
													className="width--100-percent"
													isRequired={accessAppWidg.isRequired}
													label={accessAppWidg.placeholder || undefined}
													onChange={this.accessOptionChanged}
													options={this.accessOptions()}
												/>
											</GridLayoutCell>
										)
										: null
								}
								{
									accessNotesAppWidg.isEnabled
										? (
											<GridLayoutCell span={12}>
												<TextInput
													className="width--100-percent"
													isRequired={accessNotesAppWidg.isRequired}
													isTextArea
													placeholder={accessNotesAppWidg.placeholder || undefined}
													onChange={this.accessNotesChanged}
													value={accessNotes}
												/>
											</GridLayoutCell>
										)
										: null
								}
							</GridLayout>
						)
						: null
				}
				{
					(haveAccess && haveOcc)
						? (
							<GridLayout noPad>
								<GridLayoutDivider span={12}/>
							</GridLayout>
						)
						: null
				}
				{
					haveOcc
						? (
							<GridLayout>
								{
									(occupancyAppWidg.label.length > 0)
										? (
											<GridLayoutCell span={4}>
												<SubtleLabel>
													{capitalize(occupancyAppWidg.label)}
												</SubtleLabel>
											</GridLayoutCell>
										)
										: null
								}
								<GridLayoutCell span={12}>
									<ComboBox
										className="width--100-percent"
										isRequired={occupancyAppWidg.isRequired}
										label={occupancyAppWidg.placeholder || undefined}
										onChange={this.occupancyOptionChanged}
										options={this.occupancyOptions()}
									/>
								</GridLayoutCell>
								<GridLayoutCell span={12}>
									{children}
								</GridLayoutCell>
							</GridLayout>
						)
						: null
				}
			</>
		);
	}
}
