import React from 'react';

import {bind} from '../../util';
import {
	CCardContent,
	CCard,
	CCardControls,
	CCardBottomButtons,
	EditCardHeader,
	EditCardSection,
	EditCardSectionRow,
	CCardTextInputControl,
	ICCardProps,
} from '../../components';

export interface IEmailAddressFormProps extends Omit<ICCardProps, 'onChange'> {
	obj: IAlternateEmailAddress;
	headerText: string;
	onCancel: () => any;
	onChange: <K extends keyof IAlternateEmailAddress>(name: K, value: IAlternateEmailAddress[K]) => any;
	onSave: () => any;
}

interface IEmailAddressFormState {
}

export class AlternateEmailForm extends React.Component<IEmailAddressFormProps, IEmailAddressFormState> {
	private readonly formRef: React.RefObject<HTMLFormElement>;

	constructor(props: IEmailAddressFormProps) {
		super(props);
		this.formRef = React.createRef();
	}

	@bind
	private addressChanged(value: string) {
		this.changed('address', value);
	}

	private changed<K extends keyof IAlternateEmailAddress>(name: K, value: IAlternateEmailAddress[K]): void {
		const {onChange} = this.props;
		if (onChange) {
			onChange(name, value);
		}
	}

	private isValid(): boolean {
		const curr = this.formRef.current;
		if (curr) {
			return curr.checkValidity();
		}
		return true;
	}

	@bind
	private labelChanged(value: string) {
		this.changed('label', value);
	}

	render() {
		const {
			headerText,
			obj,
			onCancel,
			onSave,
			onChange,
			...rest
		} = this.props;
		return (
			<CCard {...rest}>
				<EditCardHeader text={headerText}/>
				<form action="" onSubmit={this.submitted} ref={this.formRef}>
					<CCardContent>
						<EditCardSection>
							<EditCardSectionRow itemIconName="mail_outline" {...rest}>
								<CCardControls>
									<CCardTextInputControl
										isRequired
										autoFocus
										value={obj.address}
										label="Email"
										onChange={this.addressChanged}
									/>
								</CCardControls>
							</EditCardSectionRow>
							<EditCardSectionRow itemIconIsOutlined itemIconName="label">
								<CCardControls>
									<CCardTextInputControl
										label="Label"
										onChange={this.labelChanged}
										value={obj.label}
									/>
								</CCardControls>
							</EditCardSectionRow>
						</EditCardSection>
					</CCardContent>
					<CCardBottomButtons
						onCancel={onCancel}
						saveIsSubmit
					/>
				</form>
			</CCard>
		);
	}

	@bind
	private save() {
		if (this.isValid()) {
			this.props.onSave();
		}
	}

	@bind
	private submitted(event: React.FormEvent) {
		event.preventDefault();
		this.save();
	}
}
