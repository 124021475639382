import React from 'react';

import {makeClassName} from '../../util';

export enum CircularProgressSize {
	Small = 0,
	Medium = 1,
	Large = 2,
}

export interface ICircularProgressProps extends React.HTMLAttributes<HTMLDivElement> {
	isDeterminate?: boolean;
	isOpen?: boolean;
	progress?: number;
	size: 0 | 1 | 2;
}

interface ICircularProgressState {
}

export class CircularProgress extends React.Component<ICircularProgressProps, ICircularProgressState> {
	render() {
		const {
			className,
			isDeterminate,
			isOpen,
			progress,
			size,
			...rest
		} = this.props;
		const s = sizes[size];
		const sty = {
			height: s.height,
			width: s.width,
		};
		const clsName = makeClassName(
				'mdc-circular-progress',
				isDeterminate
					? undefined
					: 'mdc-circular-progress--indeterminate',
				isOpen
					? undefined
					: 'mdc-circular-progress--closed',
				className,
			)
		;
		return (
			<div className={clsName} style={sty} role="progressbar" {...rest}>
				<div className="mdc-circular-progress__determinate-container">
					<svg className="mdc-circular-progress__determinate-circle-graphic" viewBox={s.graphic1ViewBox} xmlns="http://www.w3.org/2000/svg">
						<circle className="mdc-circular-progress__determinate-track" cx={s.trackCx} cy={s.trackCy} r={s.trackR} strokeWidth={s.trackStrokeWidth}/>
						<circle className="mdc-circular-progress__determinate-circle" cx={s.circleCx} cy={s.circleCy} r={s.circleR} strokeDasharray={s.circleStrokeDasharray} strokeDashoffset={this.unfilledArcLength()} strokeWidth={s.circleStrokeWidth}/>
					</svg>
				</div>
				<div className="mdc-circular-progress__indeterminate-container">
					<div className="mdc-circular-progress__spinner-layer">
						<div className="mdc-circular-progress__circle-clipper mdc-circular-progress__circle-left">
							<svg className="mdc-circular-progress__indeterminate-circle-graphic" viewBox={s.graphic2ViewBox} xmlns="http://www.w3.org/2000/svg">
								<circle cx={s.graphic2CircleCx} cy={s.graphic2CircleCy} r={s.graphic2CircleR} strokeDasharray={s.graphic2StrokeDasharray} strokeDashoffset={s.graphic2StrokeDashoffset} strokeWidth={s.graphic2StrokeWidth}/>
							</svg>
						</div>
						<div className="mdc-circular-progress__gap-patch">
							<svg className="mdc-circular-progress__indeterminate-circle-graphic" viewBox={s.graphic3ViewBox} xmlns="http://www.w3.org/2000/svg">
								<circle cx={s.graphic3CircleCx} cy={s.graphic3CircleCy} r={s.graphic3CircleR} strokeDasharray={s.graphic3StrokeDasharray} strokeDashoffset={s.graphic3StrokeDashoffset} strokeWidth={s.graphic3StrokeWidth}/>
							</svg>
						</div>
						<div className="mdc-circular-progress__circle-clipper mdc-circular-progress__circle-right">
							<svg className="mdc-circular-progress__indeterminate-circle-graphic" viewBox={s.graphic4ViewBox} xmlns="http://www.w3.org/2000/svg">
								<circle cx={s.graphic4CircleCx} cy={s.graphic4CircleCy} r={s.graphic4CircleR} strokeDasharray={s.graphic4StrokeDasharray} strokeDashoffset={s.graphic4StrokeDashoffset} strokeWidth={s.graphic4StrokeWidth}/>
							</svg>
						</div>
					</div>
				</div>
			</div>
		);
	}

	private unfilledArcLength(): number {
		const {
			isDeterminate,
			progress,
			size,
		} = this.props;
		if (isDeterminate) {
			const prog = (progress === undefined)
				? 0
				: progress;
			return (1 - prog) * (2 * Math.PI * sizes[size].circleR);
		}
		return sizes[size].circleStrokeDashoffset;
	}
}

interface IAttrMap {
	circleCx: number;
	circleCy: number;
	circleR: number;
	circleStrokeDasharray: number;
	circleStrokeDashoffset: number;
	circleStrokeWidth: number;
	graphic1ViewBox: string;
	graphic2CircleCx: number;
	graphic2CircleCy: number;
	graphic2CircleR: number;
	graphic2StrokeDasharray: number;
	graphic2StrokeDashoffset: number;
	graphic2StrokeWidth: number;
	graphic2ViewBox: string;
	graphic3CircleCx: number;
	graphic3CircleCy: number;
	graphic3CircleR: number;
	graphic3StrokeDasharray: number;
	graphic3StrokeDashoffset: number;
	graphic3StrokeWidth: number;
	graphic3ViewBox: string;
	graphic4CircleCx: number;
	graphic4CircleCy: number;
	graphic4CircleR: number;
	graphic4StrokeDasharray: number;
	graphic4StrokeDashoffset: number;
	graphic4StrokeWidth: number;
	graphic4ViewBox: string;
	height: number;
	trackCx: number;
	trackCy: number;
	trackR: number;
	trackStrokeWidth: number;
	width: number;
}

const sizes: [IAttrMap, IAttrMap, IAttrMap] = [
	{
		width: 24,
		height: 24,
		graphic1ViewBox: '0 0 24 24',
		trackCx: 12,
		trackCy: 12,
		trackR: 8.75,
		trackStrokeWidth: 2.5,
		circleCx: 12,
		circleCy: 12,
		circleR: 8.75,
		circleStrokeDasharray: 54.978,
		circleStrokeDashoffset: 54.978,
		circleStrokeWidth: 2.5,
		graphic2ViewBox: '0 0 24 24',
		graphic2CircleCx: 12,
		graphic2CircleCy: 12,
		graphic2CircleR: 8.75,
		graphic2StrokeDasharray: 54.978,
		graphic2StrokeDashoffset: 27.489,
		graphic2StrokeWidth: 2.5,
		graphic3ViewBox: '0 0 24 24',
		graphic3CircleCx: 12,
		graphic3CircleCy: 12,
		graphic3CircleR: 8.75,
		graphic3StrokeDasharray: 54.978,
		graphic3StrokeDashoffset: 27.489,
		graphic3StrokeWidth: 2,
		graphic4ViewBox: '0 0 24 24',
		graphic4CircleCx: 12,
		graphic4CircleCy: 12,
		graphic4CircleR: 8.75,
		graphic4StrokeDasharray: 54.978,
		graphic4StrokeDashoffset: 27.489,
		graphic4StrokeWidth: 2.5,
	},
	{
		width: 36,
		height: 36,
		graphic1ViewBox: '0 0 32 32',
		trackCx: 16,
		trackCy: 16,
		trackR: 12.5,
		trackStrokeWidth: 3,
		circleCx: 16,
		circleCy: 16,
		circleR: 12.5,
		circleStrokeDasharray: 78.54,
		circleStrokeDashoffset: 78.54,
		circleStrokeWidth: 3,
		graphic2ViewBox: '0 0 32 32',
		graphic2CircleCx: 16,
		graphic2CircleCy: 16,
		graphic2CircleR: 12.5,
		graphic2StrokeDasharray: 78.54,
		graphic2StrokeDashoffset: 39.27,
		graphic2StrokeWidth: 3,
		graphic3ViewBox: '0 0 32 32',
		graphic3CircleCx: 16,
		graphic3CircleCy: 16,
		graphic3CircleR: 12.5,
		graphic3StrokeDasharray: 78.54,
		graphic3StrokeDashoffset: 39.27,
		graphic3StrokeWidth: 2.4,
		graphic4ViewBox: '0 0 32 32',
		graphic4CircleCx: 16,
		graphic4CircleCy: 16,
		graphic4CircleR: 12.5,
		graphic4StrokeDasharray: 78.54,
		graphic4StrokeDashoffset: 39.27,
		graphic4StrokeWidth: 3,
	},
	{
		width: 48,
		height: 48,
		graphic1ViewBox: '0 0 48 48',
		trackCx: 24,
		trackCy: 24,
		trackR: 18,
		trackStrokeWidth: 4,
		circleCx: 24,
		circleCy: 24,
		circleR: 18,
		circleStrokeDasharray: 113.097,
		circleStrokeDashoffset: 113.097,
		circleStrokeWidth: 4,
		graphic2ViewBox: '0 0 48 48',
		graphic2CircleCx: 24,
		graphic2CircleCy: 24,
		graphic2CircleR: 18,
		graphic2StrokeDasharray: 113.097,
		graphic2StrokeDashoffset: 56.549,
		graphic2StrokeWidth: 4,
		graphic3ViewBox: '0 0 48 48',
		graphic3CircleCx: 24,
		graphic3CircleCy: 24,
		graphic3CircleR: 18,
		graphic3StrokeDasharray: 113.097,
		graphic3StrokeDashoffset: 56.549,
		graphic3StrokeWidth: 3.2,
		graphic4ViewBox: '0 0 48 48',
		graphic4CircleCx: 24,
		graphic4CircleCy: 24,
		graphic4CircleR: 18,
		graphic4StrokeDasharray: 113.097,
		graphic4StrokeDashoffset: 56.549,
		graphic4StrokeWidth: 4,
	},
];
