import React from 'react';

import {api} from '../../httpapi';
import {
	bind,
	staticPriceGroup,
} from '../../util';
import {
	Button,
	CCard,
	CCardAvatar,
	CCardDescribe,
	CCardSection,
	CCardHeader,
	CCardIntro,
	CCardName,
	CCardNameTitle,
	CCardContent,
	CCardBottom,
	ICCardProps,
} from '../../components';

export interface IPriceGroupDeleteViewProps extends Omit<ICCardProps, 'onChange'> {
	onCancel?: () => any;
	onDone?: () => any;
	pk: number;
}

interface IPriceGroupDeleteViewState {
	obj: IPriceGroup;
}

export class PriceGroupDeleteView extends React.Component<IPriceGroupDeleteViewProps, IPriceGroupDeleteViewState> {
	constructor(props: IPriceGroupDeleteViewProps) {
		super(props);
		this.state = {
			obj: staticPriceGroup(),
		};
	}

	@bind
	private canceled(): void {
		const {onCancel} = this.props;
		if (onCancel) {
			onCancel();
		}
	}

	async componentDidMount() {
		this.setState({
			obj: await api.priceGroupDetail(this.props.pk),
		});
	}

	@bind
	private async deleteObj(pk: number | string): Promise<void> {
		return await api.deletePriceGroup(pk);
	}

	render() {
		const {
			onCancel,
			onDone,
			...rest
		} = this.props;
		const {obj} = this.state;
		const av = (obj.icon.length > 0)
			? obj.icon
			: 'attach_money';
		return (
			<CCard {...rest}>
				<CCardHeader>
					<CCardIntro>
						<CCardAvatar>
							{av}
						</CCardAvatar>
						<CCardDescribe>
							<CCardNameTitle>
								<CCardName>
									{obj.name}
								</CCardName>
							</CCardNameTitle>
						</CCardDescribe>
					</CCardIntro>
				</CCardHeader>
				<CCardContent>
					<CCardSection>
						Delete this price group?
					</CCardSection>
				</CCardContent>
				<CCardBottom {...rest}>
					<Button value="cancel" raisedFilled onClick={this.canceled}>Cancel</Button>
					<Button value="delete" onClick={this.submitted}>Delete</Button>
				</CCardBottom>
			</CCard>
		);
	}

	@bind
	private async submitted() {
		const {onDone} = this.props;
		const {obj} = this.state;
		await this.deleteObj(obj.id);
		this.setState(
			{
				obj: staticPriceGroup(),
			},
			() => {
				if (onDone) {
					onDone();
				}
			},
		);
	}
}

