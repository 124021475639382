import {stringRepeat} from './util';

export function closestMatchingElement<E extends Element = Element>(element: Element | null, selector: string): E | null {
	if (!element) {
		return null;
	}
	if (element.closest) {
		return element.closest(selector);
	}
	let curr: Element | null = element;
	while (curr) {
		if (elementMatchesSelector(curr, selector)) {
			return <E>curr;
		}
		curr = curr.parentElement;
	}
	return null;
}

export function extractCookie(name: string): string | undefined {
	if (!document.cookie) {
		return undefined;
	}
	const cookies = document.cookie.split(';');
	const def = `${name}=`;
	for (let i = 0; i < cookies.length; ++i) {
		const cookie = cookies[i].trim();
		if (cookie.slice(0, name.length + 1) === def) {
			return decodeURIComponent(cookie.slice(name.length + 1));
		}
	}
	return undefined;
}

export function elementMatchesSelector(element: Element, selector: string): boolean {
	const nativeMatches = element.matches
		|| element.webkitMatchesSelector
		// @ts-ignore
		|| element.msMatchesSelector;
	return nativeMatches.call(element, selector);
}

export function elementString(elem: Element): string {
	return `<${nodeStrings(elem).join(' ')}/>`;
}

export function isBackspace(event: KeyboardEvent): boolean {
	return (event.key === 'Backspace') || (event.keyCode === 8);
}

export function isDelete(event: KeyboardEvent): boolean {
	return (event.key === 'Delete') || (event.keyCode === 46);
}

export function isEnter(event: KeyboardEvent): boolean {
	return (event.key === 'Enter') || (event.keyCode === 13);
}

export function isEscape(event: KeyboardEvent): boolean {
	return (event.key === 'Escape') || (event.keyCode === 27);
}

export function makeClassName(...classNames: Array<string | undefined>): string {
	const parts: Array<string> = [];
	for (const obj of classNames) {
		if (obj) {
			parts.push(obj);
		}
	}
	return parts.join(' ');
}

function nodeStrings(node: Node): Array<string> {
	const rv: Array<string> = [];
	if (node.nodeType === Node.ELEMENT_NODE) {
		const elem = <Element>node;
		rv.push(node.nodeName);
		if (elem.id) {
			rv.push(`id="${elem.id}"`);
		}
		if (elem.className) {
			rv.push(`class="${elem.className}"`);
		}
	}
	return rv;
}

export function pixelString(value: number, zeroIsEmptyString: boolean = false): string {
	return (value === 0)
		? zeroIsEmptyString
			? ''
			: '0'
		: `${value}px`;
}

export function printTree(node: Node): void {
	console.log(treeParts(node).join('\n'));
}

function treeParts(node: Node, level: number = 0): Array<string> {
	const parts: Array<string> = [];
	const name = node.nodeName;
	const pad = stringRepeat(' ', level / 2 * 8);
	const children = node.childNodes;
	if (node.nodeType === Node.TEXT_NODE) {
		const t = (node.textContent || '').trim();
		if (t) {
			parts.push(`${pad}${t}`);
		}
	} else {
		const s = nodeStrings(node).join(' ');
		if (children.length > 0) {
			parts.push(`${pad}<${s}>`);
			for (let i = 0; i < children.length; ++i) {
				const child = children[i];
				parts.push(...treeParts(child, level + 1));
			}
			parts.push(`${pad}</${name}>`);
		} else {
			parts.push(`${pad}<${s}></${name}>`);
		}
	}
	return parts;
}
