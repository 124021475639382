import React from 'react';

import {bind} from '../../util';
import {
	CCardContent,
	CCard,
	CCardControls,
	CCardBottomButtons,
	EditCardHeader,
	EditCardSection,
	EditCardSectionRow,
	CCardTextInputControl,
	ICCardProps,
} from '../../components';

export interface IUserClassFormProps extends Omit<Partial<ICCardProps>, 'onChange'> {
	obj: IUserClass;
	onCancel: () => any;
	onChange: <K extends keyof IUserClass>(name: K, value: IUserClass[K]) => any;
	onSave: () => any;
	title: string;
}

export class UserClassForm extends React.Component<IUserClassFormProps, {}> {
	@bind
	private changed<K extends keyof IUserClass>(name: K, value: IUserClass[K]): void {
		this.props.onChange(name, value);
	}

	@bind
	private nameChanged(value: string): void {
		this.changed('name', value);
	}

	render() {
		const {
			obj,
			onCancel,
			onSave,
			onChange,
			title,
			...rest
		} = this.props;
		return (
			<CCard {...rest}>
				<EditCardHeader text={title}/>
				<CCardContent>
					<EditCardSection>
						<EditCardSectionRow>
							<CCardControls>
								<CCardTextInputControl
									value={obj.name}
									label="Name"
									onChange={this.nameChanged}
								/>
							</CCardControls>
						</EditCardSectionRow>
					</EditCardSection>
				</CCardContent>
				<CCardBottomButtons
					onCancel={onCancel}
					onSave={onSave}
				/>
			</CCard>
		);
	}
}

