import React from 'react';

import {
	CheckState,
	ProductUserRole,
} from '../../constants';
import {
	bind,
	idxOk,
	isNumber,
	makeClassName,
} from '../../util';
import {
	CCard,
	CCardBottomButtons,
	CCardComboBoxControl,
	CCardContent,
	CCardControls,
	CCardRowActionSet,
	CCardTextInputControl,
	Checkbox,
	ChipSet,
	ChipSetEventType,
	EditCardHeader,
	EditCardSection,
	EditCardSectionRow,
	FloatingLabel,
	ICCardProps,
	IChipSetChip,
	IChipSetProps,
	IEditCardSectionRowProps,
	IFloatingLabelProps,
	IGoogleSucksAtSoftwareChipSetEventData,
	IMenuOptionProps,
	List,
	ListItem,
	Menu,
} from '../../components';

export interface IUserFormProps extends Omit<Partial<ICCardProps>, 'onChange'> {
	accountEmailAddrIsReadOnly?: boolean;
	obj: INewUser;
	onCancel: () => any;
	onChange: <K extends keyof INewUser>(value: INewUser[K], name: K, cb?: () => any) => any;
	onProductUserCheckChange?: (productPk: number, role: ProductUserRole, isChecked: boolean) => any;
	onSave: () => any;
	organizations: Array<IOrganization>;
	products?: Array<IProduct>;
	productUsers?: Array<IProductUser>;
	pwFields?: React.ReactNode;
	renderChips?: boolean;
	serviceAreas?: Array<IServiceArea>;
	title: string;
	userClasses: Array<IUserClass>;
}

type Coords = PlainCoords;

interface IUserFormState {
	menuCoords: Coords;
	menuIsOpen: boolean;
}

export class UserForm extends React.Component<IUserFormProps, IUserFormState> {
	private readonly menuRef: React.RefObject<Menu>;

	constructor(props: IUserFormProps) {
		super(props);
		this.menuRef = React.createRef();
		this.state = {
			menuCoords: {
				x: 0,
				y: 0,
			},
			menuIsOpen: false,
		};
	}

	@bind
	private addEmailAddress(): void {
		const {obj} = this.props;
		const objs = [
			...obj.alternateEmailAddresses,
		];
		objs.push(
			staticEmailAddress(),
		);
		this.changed(
			objs,
			'alternateEmailAddresses',
		);
	}

	@bind
	private addNote(): void {
		const {obj} = this.props;
		const objs = [
			...obj.notes,
		];
		objs.push(
			staticNote(),
		);
		this.changed(
			objs,
			'notes',
		);
	}

	@bind
	private addPhoneNumber(): void {
		const {obj} = this.props;
		const objs = [
			...obj.phoneNumbers,
		];
		objs.push(
			staticPhoneNumber(),
		);
		this.changed(
			objs,
			'phoneNumbers',
		);
	}

	@bind
	private alternateEmailChanged(index: number, isLabel: boolean, value: string): void {
		const {obj} = this.props;
		if ((index >= 0) && (index < obj.alternateEmailAddresses.length)) {
			const objs = [
				...obj.alternateEmailAddresses,
			];
			const o = objs[index];
			if (isLabel) {
				o.label = value;
			} else {
				o.address = value;
			}
			this.changed(
				objs,
				'alternateEmailAddresses',
			);
		} else {
			console.log('alternateEmailChanged: Invalid index:', index);
		}
	}

	@bind
	private changed<K extends keyof INewUser>(value: INewUser[K], name: K, cb?: () => any): void {
		const {onChange} = this.props;
		onChange(value, name, cb);
	}

	private chips(): Array<Partial<IChipSetChip>> {
		const {
			obj,
			userClasses,
		} = this.props;
		const rv: Array<Partial<IChipSetChip>> = [
			{
				text: obj.isAdmin
					? 'Admin Privileges'
					: 'Regular Privileges',
				isSelectable: false,
				isSelected: false,
				isDeletable: false,
				className: 'pb-cc-chip pb-cc-edit-header__chip',
				leadingIconName: obj.isAdmin
					? 'admin_panel_settings'
					: 'account_circle',
				id: 'pb-edit-cc-chip-00',
			},
		];
		if ((obj.userClassId !== null) || (userClasses.length > 0)) {
			rv.push(
				{
					text: this.userClassName(obj.userClassId),
					isSelectable: false,
					isSelected: false,
					isDeletable: false,
					className: 'pb-cc-chip pb-cc-edit-header__chip',
					id: 'pb-edit-cc-chip-01',
				},
			);
		}
		return rv;
	}

	@bind
	private clearName(): void {
		this.changed(
			'',
			'firstName',
			() => this.changed('', 'lastName'),
		);
	}

	@bind
	private closeMenu(): void {
		const {menuIsOpen} = this.state;
		if (menuIsOpen) {
			this.setState({
				menuIsOpen: false,
			});
		}
	}

	componentDidMount() {
		this.forceUpdate();
	}

	@bind
	private deleteEmailAddress(index: number): void {
		const {obj} = this.props;
		if (idxOk(index, obj.alternateEmailAddresses.length, 'deleteEmailAddress')) {
			const objs = [
				...obj.alternateEmailAddresses,
			];
			objs.splice(index, 1);
			this.changed(
				objs,
				'alternateEmailAddresses',
			);
		}
	}

	@bind
	private deleteNote(index: number): void {
		const {obj} = this.props;
		if (idxOk(index, obj.notes.length, 'deleteNote')) {
			const objs = [
				...obj.notes,
			];
			objs.splice(index, 1);
			this.changed(
				objs,
				'notes',
			);
		}
	}

	@bind
	private deletePhoneNumber(index: number): void {
		const {obj} = this.props;
		if (idxOk(index, obj.phoneNumbers.length, 'deletePhoneNumber')) {
			const objs = [
				...obj.phoneNumbers,
			];
			objs.splice(index, 1);
			this.changed(
				objs,
				'phoneNumbers',
			);
		}
	}

	@bind
	private focusSelectedMenuItem(): void {
		const obj = this.menuRef.current;
		if (obj) {
			obj.focusListItemAtIndex(
				Math.max(
					0,
					this.selectedMenuOptionIndex(),
				),
			);
		}
	}

	@bind
	private menuItemSelected(index: number, isNavigation: boolean): void {
		const opt = this.menuOption(index);
		if (opt === null) {
			console.log('menuItemSelected: Invalid index:', index);
			return;
		}
		const id = opt.value === ''
			? null
			: Number.parseInt(opt.value);
		if (id === null || isNumber(id)) {
			this.changed(id, 'userClassId');
			if (!isNavigation) {
				this.closeMenu();
			}
		} else {
			console.log('menuItemSelected: Invalid option value:', opt.value);
		}
	}

	@bind
	private menuKeyPressed(event: React.KeyboardEvent): void {
		const {menuIsOpen} = this.state;
		if ((event.key === 'Escape') && menuIsOpen) {
			event.stopPropagation();
			this.closeMenu();
		}
	}

	private menuOption(index: number): IMenuOptionProps | null {
		const opts = this.menuOptions();
		if ((index >= 0) && (index < opts.length)) {
			return opts[index];
		}
		return null;
	}

	private menuOptions(): Array<IMenuOptionProps> {
		const {
			obj,
			userClasses,
		} = this.props;
		const rv: Array<IMenuOptionProps> = [
			{
				isSelected: false,
				label: '---',
				value: '',
			},
		];
		if (userClasses === undefined) {
			return rv;
		}
		for (const o of userClasses) {
			rv.push({
				isSelected: o.id === obj.userClassId,
				label: o.name,
				value: String(o.id),
			});
		}
		if (obj.userClassId === null) {
			rv[0].isSelected = true;
		}
		return rv;
	}

	@bind
	private noteChanged(index: number, isLabel: boolean, value: string): void {
		const {obj} = this.props;
		if ((index >= 0) && (index < obj.notes.length)) {
			const objs = obj.notes;
			const o = objs[index];
			if (isLabel) {
				o.label = value;
			} else {
				o.text = value;
			}
			this.changed(
				objs,
				'notes',
			);
		} else {
			console.log('noteChanged: Invalid index:', index);
		}
	}

	private objHasProductUserForRole(role: ProductUserRole, productPk: number): boolean {
		const prodUsers = this.objProductUsers();
		return prodUsers.findIndex(
			x => ((x.roleId === role) && (x.productId === productPk)),
		) >= 0;
	}

	private objProductUsers(): Array<IProductUser> {
		const {obj} = this.props;
		return this.productUsers(
		).filter(
			x => (x.userId === obj.email),
		);
	}

	@bind
	private organizationIdChanged(value: string): void {
		try {
			this.changed(
				(value === '')
					? null
					: coerceNum(value, Number.parseInt),
				'organizationId',
			);
		} catch {
			console.log('organizationIdChanged: Invalid value:', value);
		}
	}

	private orgComboOpts(): Array<IMenuOptionProps> {
		const {
			obj,
			organizations,
		} = this.props;
		const rv: Array<IMenuOptionProps> = [];
		for (const org of organizations) {
			rv.push({
				label: org.name,
				value: String(org.id),
				isSelected: org.id === obj.organizationId,
			});
		}
		return rv;
	}

	@bind
	private orgComboOptSelected(index: number): void {
		const opts = this.orgComboOpts();
		if ((index >= 0) && (index < opts.length)) {
			this.organizationIdChanged(opts[index].value);
		} else {
			console.log('orgComboOptSelected: Invalid index:', index);
		}
	}

	@bind
	private phoneNumberChanged(index: number, isLabel: boolean, value: string): void {
		const {obj} = this.props;
		if ((index >= 0) && (index < obj.phoneNumbers.length)) {
			const objs = [
				...obj.phoneNumbers,
			];
			const o = objs[index];
			if (isLabel) {
				o.label = value;
			} else {
				o.number = value;
			}
			this.changed(
				objs,
				'phoneNumbers',
			);
		} else {
			console.log('phoneNumberChanged: Invalid index:', index);
		}
	}

	@bind
	private productUserAssignmentOptions(): Array<IProductUserAssignmentOption> {
		const prods = this.products();
		return prods.map(prod => {
			return {
				isEditor: this.objHasProductUserForRole(ProductUserRole.Editor, prod.id),
				isShooter: this.objHasProductUserForRole(ProductUserRole.Shooter, prod.id),
				label: prod.name,
				value: String(prod.id),
			};
		});
	}

	@bind
	private productUserCheckStateChanged(productPk: string, role: ProductUserRole, state: CheckState) {
		const {onProductUserCheckChange} = this.props;
		if (onProductUserCheckChange !== undefined) {
			const n = Number.parseInt(productPk);
			if (isNumber(n)) {
				onProductUserCheckChange(
					n,
					role,
					state === CheckState.Checked,
				);
			}
		}
	}

	private products(): Array<IProduct> {
		const {products} = this.props;
		return (products === undefined)
			? []
			: products;
	}

	private productUsers(): Array<IProductUser> {
		const {productUsers} = this.props;
		return (productUsers === undefined)
			? []
			: productUsers;
	}

	render() {
		const {
			accountEmailAddrIsReadOnly,
			obj,
			onCancel,
			onChange,
			onProductUserCheckChange,
			onSave,
			organizations,
			productUsers,
			products,
			pwFields,
			renderChips,
			serviceAreas,
			title,
			userClasses,
			...rest
		} = this.props;
		const {
			menuCoords,
			menuIsOpen,
		} = this.state;
		const renderOrgs = organizations.length > 0;
		const prods = this.products();
		const renderProds = prods.length > 0;
		const sa = this.serviceAreas();
		const osa = new Set(obj.serviceAreas);
		const renderSvcAreas = sa.length > 0;
		return (
			<CCard {...rest}>
				<EditCardHeader text={title}>
					{
						renderChips
							? (
								<UserFormHeaderChipSet chips={this.chips()} obj={obj} onPrivilegesClick={isAdmin => this.changed(isAdmin, 'isAdmin')} onUserClassClick={this.userClassClicked} userClassName={this.userClassName(obj.userClassId)}>
									<Menu
										absolutePosition={menuCoords}
										anchorToBody
										isCompact
										isOpen={menuIsOpen}
										onSelection={this.menuItemSelected}
										options={this.menuOptions()}
										onClose={this.closeMenu}
										onKeyDown={this.menuKeyPressed}
										ref={this.menuRef}
										onOpen={this.focusSelectedMenuItem}
									/>
								</UserFormHeaderChipSet>
							)
							: null
					}
				</EditCardHeader>
				<CCardContent>
					<EditCardSection>
						<NameSectionRow
							firstName={obj.firstName}
							lastName={obj.lastName}
							onChange={this.changed}
							onClear={this.clearName}
						/>
						<CardLabeledValueSectionRow renderAddAction={obj.alternateEmailAddresses.length === 0} itemIconName="mail_outline" onAdd={this.addEmailAddress}>
							<CCardTextInputControl
								isDisabled={accountEmailAddrIsReadOnly}
								value={obj.email}
								label="Email"
								onChange={val => this.changed(val, 'email')}
							/>
							<CCardTextInputControl
								isDisabled
								label="Label"
								value="Account"
							/>
						</CardLabeledValueSectionRow>
						{
							obj.alternateEmailAddresses.map((ob, idx) => {
								const lastObj = idx === (obj.alternateEmailAddresses.length - 1);
								const renderAxn = lastObj && (ob.address.trim().length > 0);
								return (
									<CardLabeledValueSectionRow renderAddAction={renderAxn} itemIconName="mail_outline" key={idx} onAdd={this.addEmailAddress} onDelete={this.deleteEmailAddress.bind(this, idx)}>
										<CCardTextInputControl
											value={ob.address}
											label="Email"
											onChange={this.alternateEmailChanged.bind(this, idx, false)}
										/>
										<CCardTextInputControl
											value={ob.label}
											label="Label"
											onChange={this.alternateEmailChanged.bind(this, idx, true)}
										/>
									</CardLabeledValueSectionRow>
								);
							})
						}
						{
							obj.phoneNumbers.map((ob, idx) => {
								const lastObj = idx === (obj.phoneNumbers.length - 1);
								const renderAxn = lastObj && (ob.number.trim().length > 0);
								return (
									<CardLabeledValueSectionRow renderAddAction={renderAxn} itemIconName="phone" key={idx} onAdd={this.addPhoneNumber} onDelete={this.deletePhoneNumber.bind(this, idx)}>
										<CCardTextInputControl
											value={ob.number}
											label="Number"
											onChange={this.phoneNumberChanged.bind(this, idx, false)}
										/>
										<CCardTextInputControl
											value={ob.label}
											label="Label"
											onChange={this.phoneNumberChanged.bind(this, idx, true)}
										/>
									</CardLabeledValueSectionRow>
								);
							})
						}
						{
							obj.notes.map((ob, idx) => {
								const lastObj = idx === (obj.notes.length - 1);
								const renderAxn = lastObj && (ob.text.trim().length > 0);
								return (
									<CardLabeledValueSectionRow renderAddAction={renderAxn} itemIconName="notes" key={idx} onAdd={this.addNote} onDelete={this.deleteNote.bind(this, idx)}>
										<CCardTextInputControl
											value={ob.text}
											label="Text"
											onChange={this.noteChanged.bind(this, idx, false)}
										/>
										<CCardTextInputControl
											value={ob.label}
											label="Label"
											onChange={this.noteChanged.bind(this, idx, true)}
										/>
									</CardLabeledValueSectionRow>
								);
							})
						}
						{
							renderOrgs
								? (
									<EditCardSectionRow itemIconName="groups">
										<CCardControls>
											<CCardComboBoxControl
												// anchorMenuToBody
												label="Organization"
												options={this.orgComboOpts()}
												onChange={this.orgComboOptSelected}
											/>
										</CCardControls>
									</EditCardSectionRow>
								)
								: null
						}
						{
							renderSvcAreas
								? (
									<EditCardSectionRow itemIconName="pin_drop">
										<EditCardLabeledSection labelText="Service areas">
											<EditCardSectionRowScrollList>
												<List isCompact>
													{
														sa.map((obj, idx) => {
															return (
																<ListItem isClickable isSelected={osa.has(obj.id)} key={idx} onClick={this.serviceAreaItemClicked.bind(this, idx)}>
																	{obj.name}
																</ListItem>
															);
														})
													}
												</List>
											</EditCardSectionRowScrollList>
										</EditCardLabeledSection>
									</EditCardSectionRow>
								)
								: null
						}
						{
							renderProds
								? (
									<EditCardSectionRow itemIconName="sell">
										<EditCardLabeledSection labelText="Products">
											<EditCardSectionRowScrollList>
												<ProductUserAssignmentTable
													onOptionCheckStateChange={this.productUserCheckStateChanged}
													options={this.productUserAssignmentOptions()}
												/>
											</EditCardSectionRowScrollList>
										</EditCardLabeledSection>
									</EditCardSectionRow>
								)
								: null
						}
						{pwFields}
					</EditCardSection>
				</CCardContent>
				<CCardBottomButtons
					onCancel={onCancel}
					onSave={onSave}
				/>
			</CCard>
		);
	}

	@bind
	private selectedMenuOptionIndex(): number {
		const opts = this.menuOptions();
		for (let i = 0; i < opts.length; ++i) {
			const opt = opts[i];
			if (opt.isSelected) {
				return i;
			}
		}
		return -1;
	}

	@bind
	private serviceAreaCheckboxClicked(index: number, state: CheckState): void {
		const {obj} = this.props;
		const svcs = this.serviceAreas();
		if ((index >= 0) && (index < svcs.length)) {
			const svc = svcs[index];
			if (state === CheckState.Checked) {
				this.changed(
					[
						...obj.serviceAreas,
						svc.id,
					],
					'serviceAreas',
				);
			} else {
				const idx = obj.serviceAreas.findIndex(x => (x === svc.id));
				if (idx === -1) {
					console.log('serviceAreaCheckboxClicked: Invalid idx:', idx);
				} else {
					const objs = [...obj.serviceAreas];
					objs.splice(idx, 1);
					this.changed(objs, 'serviceAreas');
				}
			}
		} else {
			console.log('serviceAreaCheckboxClicked: Invalid index:', index);
		}
	}

	@bind
	private serviceAreaItemClicked(index: number) {
		const {obj} = this.props;
		const svcs = this.serviceAreas();
		if (idxOk(index, svcs.length, 'serviceAreaItemClicked')) {
			const svc = svcs[index];
			const objSvcPkIdx = obj.serviceAreas.findIndex(
				x => (x === svc.id),
			);
			const currSelected = objSvcPkIdx >= 0;
			if (currSelected) {
				const objs = [...obj.serviceAreas];
				objs.splice(objSvcPkIdx, 1);
				this.changed(objs, 'serviceAreas');
			} else {
				this.changed(
					[
						...obj.serviceAreas,
						svc.id,
					],
					'serviceAreas',
				);
			}
		}
	}

	private serviceAreas(): Array<IServiceArea> {
		const {serviceAreas} = this.props;
		return (serviceAreas === undefined)
			? []
			: serviceAreas;
	}

	private userClass(pk: number): IUserClass | null {
		const {userClasses} = this.props;
		for (const obj of userClasses) {
			if (obj.id === pk) {
				return obj;
			}
		}
		return null;
	}

	@bind
	private userClassClicked(coords?: Coords): void {
		const {menuIsOpen} = this.state;
		if (!menuIsOpen && coords) {
			this.setState(
				{
					menuCoords: {...coords},
				},
				() => this.setState({menuIsOpen: true}),
			);
		}
	}

	private userClassName(pk: number | null): string {
		if (pk === null) {
			return '---';
		}
		const obj = this.userClass(pk);
		return obj
			? obj.name
			: '---';
	}
}

interface IUserFormHeaderChipSetProps extends Partial<IChipSetProps> {
	obj: IUser;
	onPrivilegesClick?: (isAdmin: boolean) => any;
	onUserClassClick?: (coords?: PlainCoords) => any;
	userClassName: string;
}

class UserFormHeaderChipSet extends React.Component<IUserFormHeaderChipSetProps, {}> {
	@bind
	private chipEvent(typ: ChipSetEventType, data: Partial<IGoogleSucksAtSoftwareChipSetEventData>): void {
		const {
			obj,
			onPrivilegesClick,
			onUserClassClick,
		} = this.props;
		if ((onPrivilegesClick === undefined) && (onUserClassClick === undefined)) {
			return;
		}
		if (typ === ChipSetEventType.Interaction) {
			if (onPrivilegesClick && (data.chipIndex === 0)) {
				onPrivilegesClick(!obj.isAdmin);
			} else if (onUserClassClick && (data.chipIndex === 1)) {
				onUserClassClick(data.clickCoords);
			}
		}
	}

	render() {
		const {
			className,
			obj,
			onPrivilegesClick,
			onUserClassClick,
			userClassName,
			...rest
		} = this.props;
		const clsName = makeClassName(
			'pb-cc-chip-set',
			'pb-cc-edit-header__chip-set',
			className,
		);
		return (
			<ChipSet
				className={clsName}
				onEvent={this.chipEvent}
				{...rest}
			/>
		);
	}
}

interface INameSectionRowProps extends Omit<IEditCardSectionRowProps, 'onChange'> {
	firstName: string;
	lastName: string;
	onChange: <K extends keyof INewUser>(value: INewUser[K], name: K) => any;
	onClear: () => any;
}

function NameSectionRow(props: Partial<INameSectionRowProps>) {
	const {
		firstName,
		lastName,
		onChange,
		onClear,
		...rest
	} = props;
	return (
		<EditCardSectionRow itemIconName="account_circle" {...rest}>
			<CCardControls>
				<CCardTextInputControl
					value={firstName}
					label="First name"
					onChange={(value: string) => onChange && onChange(value, 'firstName')}
				/>
				<CCardTextInputControl
					value={lastName}
					label="Last name"
					onChange={(value: string) => onChange && onChange(value, 'lastName')}
				/>
			</CCardControls>
			<CCardRowActionSet
				onDeleteClick={() => onClear && onClear()}
			/>
		</EditCardSectionRow>
	);
}

function staticEmailAddress(): IEmailAddress {
	return {
		address: '',
		label: '',
		id: -1,
		receiveNotifications: false,
	};
}

function staticNote(): INote {
	return {
		text: '',
		label: '',
		id: -1,
	};
}

function staticPhoneNumber(): IPhoneNumber {
	return {
		number: '',
		label: '',
		id: -1,
	};
}

function coerceNum(val: string, func: (val: string) => number): number | null {
	if (val === '') {
		return null;
	}
	const num = func(val);
	if (isNumber(num)) {
		return num;
	}
	throw new Error;
}

export interface ICardLabeledValueSectionRowProps extends IEditCardSectionRowProps {
	onAdd: () => any;
	onDelete: () => any;
}

export function CardLabeledValueSectionRow(props: Partial<ICardLabeledValueSectionRowProps>) {
	const {
		children,
		isDisabled,
		onAdd,
		onDelete,
		...rest
	} = props;
	return (
		<EditCardSectionRow isDisabled={isDisabled} {...rest}>
			<CCardControls>
				{children}
			</CCardControls>
			<CCardRowActionSet
				onAddClick={onAdd}
				onDeleteClick={onDelete}
			/>
		</EditCardSectionRow>
	);
}

export interface IEditCardLabeledSectionProps extends React.HTMLAttributes<any> {
	labelText: string;
}

export function EditCardLabeledSection(props: IEditCardLabeledSectionProps) {
	const {
		children,
		className,
		labelText,
		...rest
	} = props;
	const clsName = makeClassName(
		'pb-cc-edit-section__row-labeled-section',
		className,
	);
	return (
		<div className={clsName} {...rest}>
			<EditCardLabeledSectionLabel>
				{labelText}
			</EditCardLabeledSectionLabel>
			{children}
		</div>
	);
}

export function EditCardLabeledSectionLabel(props: IFloatingLabelProps) {
	const {
		children,
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		'pb-cc-edit-section__row-label',
		className,
	);
	return (
		<FloatingLabel className={clsName} {...rest}>
			{children}
		</FloatingLabel>
	);
}

export function EditCardSectionRowScrollList(props: React.HTMLAttributes<any>) {
	const {
		children,
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		'pb-cc-edit-section__row-scroll-list',
		className,
	);
	return (
		<div className={clsName} {...rest}>
			{children}
		</div>
	);
}

export interface IProductUserAssignmentOption {
	isEditor: boolean;
	isShooter: boolean;
	value: string;
	label: string;
}

export interface IProductUserAssignmentTableProps extends React.TableHTMLAttributes<any> {
	onOptionCheckStateChange: (value: string, role: ProductUserRole, state: CheckState) => any;
	options: Array<IProductUserAssignmentOption>;
}

export function ProductUserAssignmentTable(props: IProductUserAssignmentTableProps) {
	const {
		className,
		onOptionCheckStateChange,
		options,
		...rest
	} = props;
	const clsName = makeClassName(
		'pb-mini-data-table',
		className,
	);
	return (
		<table className={clsName} {...rest}>
			<thead>
				<tr>
					<th>Shooter</th>
					<th>Editor</th>
					<th/>
				</tr>
			</thead>
			<tbody>
				{
					options.map((opt, idx) => {
						return (
							<tr key={idx}>
								<td>
									<Checkbox
										isChecked={opt.isShooter}
										onChange={state => onOptionCheckStateChange(opt.value, ProductUserRole.Shooter, state)}
									/>
								</td>
								<td>
									<Checkbox
										isChecked={opt.isEditor}
										onChange={state => onOptionCheckStateChange(opt.value, ProductUserRole.Editor, state)}
									/>
								</td>
								<td>
									{opt.label}
								</td>
							</tr>
						);
					})
				}
			</tbody>
		</table>
	);
}
