import React from 'react';
import ReactDOM from 'react-dom';
import {
	getCorrectPropertyName,
} from '@material/animation/util';
import {
	Corner,
	MDCMenuDistance,
	MDCMenuSurfaceAdapter,
	MDCMenuSurfaceFoundation,
} from '@material/menu-surface';

export {Corner};
import {CssClassName} from '../list';
import {
	bind,
	makeClassName,
	pixelString,
} from '../../util';

export interface IMenuSurfaceProps extends React.HTMLAttributes<any> {
	absolutePosition: PlainCoords;
	// NB: Use Menu.Corner until we separate it out.
	anchorMargin: Partial<{top: number; right: number; bottom: number; left: number;}>;
	anchorToBody: boolean;
	fullWidth: boolean;
	hasFixedPosition: boolean;
	isHoisted: boolean;
	isOpen: boolean;
	onClose: () => any;
	onClosing: () => any;
	onOpen: () => any;
	onOpening: () => any;
	width: number;
}

interface IMenuSurfaceState {
	classNames: Set<string>;
	prevFocusedEl: HTMLElement | null;
}

export class MenuSurface extends React.Component<Partial<IMenuSurfaceProps>, IMenuSurfaceState> {
	private ctrl: MDCMenuSurfaceFoundation;
	private readonly rootRef: React.RefObject<HTMLDivElement>;

	constructor(props: Partial<IMenuSurfaceProps>) {
		super(props);
		this.ctrl = new MDCMenuSurfaceFoundation();
		this.rootRef = React.createRef();
		this.state = {
			classNames: new Set(['mdc-menu-surface']),
			prevFocusedEl: null,
		};
	}

	anchorElement(): Element | null {
		const root = this.rootRef.current;
		if (root) {
			const parentEl = root.parentElement;
			if (parentEl && parentEl.classList.contains('mdc-menu-surface--anchor')) {
				return parentEl;
			}
		}
		return null;
	}

	componentDidMount() {
		this.ctrl.destroy();
		this.ctrl = new MDCMenuSurfaceFoundation(
			this.mdcAdapter(),
		);
		this.ctrl.init();
		if (this.props.hasFixedPosition) {
			this.state.classNames.add('mdc-menu-surface--fixed');
			this.setState(
				{
					classNames: new Set(this.state.classNames),
				},
				() => this.ctrl.setFixedPosition(true),
			);
		}
		if (this.props.absolutePosition) {
			this.ctrl.setAbsolutePosition(
				this.props.absolutePosition.x,
				this.props.absolutePosition.y,
			);
			this.ctrl.setIsHoisted(true);
		}
		if (this.props.isHoisted) {
			this.ctrl.setIsHoisted(true);
		}
	}

	componentDidUpdate(prevProps: Readonly<Partial<IMenuSurfaceProps>>, prevState: Readonly<IMenuSurfaceState>) {
		if (prevProps.hasFixedPosition !== this.props.hasFixedPosition) {
			const isFixed = Boolean(this.props.hasFixedPosition);
			if (isFixed) {
				this.state.classNames.add('mdc-menu-surface--fixed');
			} else {
				this.state.classNames.delete('mdc-menu-surface--fixed');
			}
			this.setState(
				{
					classNames: new Set(this.state.classNames),
				},
				() => this.ctrl.setFixedPosition(isFixed),
			);
		}
		if ((prevProps.absolutePosition === undefined) || ((this.props.absolutePosition !== undefined) && ((prevProps.absolutePosition.x !== this.props.absolutePosition.x) || (prevProps.absolutePosition.y !== this.props.absolutePosition.y)))) {
			if (this.props.absolutePosition !== undefined) {
				this.ctrl.setAbsolutePosition(
					this.props.absolutePosition.x,
					this.props.absolutePosition.y,
				);
				this.ctrl.setIsHoisted(true);
			}
		}
		if (prevProps.isHoisted !== this.props.isHoisted) {
			const isHoisted = Boolean(this.props.isHoisted);
			this.ctrl.setIsHoisted(isHoisted);
		}
		if ((prevProps.anchorMargin === undefined) || ((this.props.anchorMargin !== undefined) && ((prevProps.anchorMargin.top !== this.props.anchorMargin.top) || (prevProps.anchorMargin.right !== this.props.anchorMargin.right) || (prevProps.anchorMargin.bottom !== this.props.anchorMargin.bottom) || (prevProps.anchorMargin.left !== this.props.anchorMargin.left)))) {
			if (this.props.anchorMargin !== undefined) {
				this.ctrl.setAnchorMargin(this.props.anchorMargin);
			}
		}
		if (prevProps.isOpen !== this.props.isOpen) {
			const isOpen = Boolean(this.props.isOpen);
			if (isOpen) {
				// document.body.addEventListener(
				// 	'click',
				// 	this.windowClicked,
				// 	{capture: true},
				// );
				window.addEventListener(
					'click',
					this.windowClicked,
					{capture: true},
				);
				document.body.addEventListener(
					'keydown',
					this.keyPressed,
				);
				this.ctrl.open();
			} else {
				this.ctrl.close();
				// document.body.removeEventListener(
				// 	'click',
				// 	this.windowClicked,
				// 	{capture: true},
				// );
				window.removeEventListener(
					'click',
					this.windowClicked,
					{capture: true},
				);
				document.body.removeEventListener(
					'keydown',
					this.keyPressed,
				);
			}
		}
	}

	componentWillUnmount() {
		document.body.removeEventListener(
			'click',
			this.windowClicked,
			{capture: true},
		);
		document.body.removeEventListener(
			'keydown',
			this.keyPressed,
		);
		this.ctrl.destroy();
	}

	@bind
	private keyPressed(event: KeyboardEvent) {
		this.ctrl.handleKeydown(event);
	}

	@bind
	private keyPressedReact(event: React.KeyboardEvent) {
		this.keyPressed(event.nativeEvent);
	}

	listItemElements(): Array<HTMLElement> {
		const root = this.rootRef.current;
		if (root) {
			// return Array.from(
			// 	root.querySelectorAll(`.${CssClassName.ListItem.replace(' ', '.')}`),
			// );
			return Array.from(
				root.querySelectorAll('.pb-list-item'),
			);
		}
		return [];
	}

	private mdcAdapter(): MDCMenuSurfaceAdapter {
		return {
			addClass: (className: string) => {
				this.state.classNames.add(className);
				this.setState({
					classNames: new Set(this.state.classNames),
				});
			},
			getAnchorDimensions: () => {
				const el = this.anchorElement();
				return el
					? el.getBoundingClientRect()
					: null;
			},
			getBodyDimensions: () => {
				return {
					width: document.body.clientWidth,
					height: document.body.clientHeight,
				};
			},
			getInnerDimensions: () => {
				const root = this.rootRef.current;
				if (root) {
					return {
						width: root.offsetWidth,
						height: root.offsetHeight,
					};
				}
				return {
					width: 0,
					height: 0,
				};
			},
			getOwnerDocument: () => {
				return window.document;
			},
			getWindowDimensions: () => {
				return {
					width: window.innerWidth,
					height: window.innerHeight,
				};
			},
			getWindowScroll: () => {
				return {
					x: window.scrollX,
					y: window.scrollY,
				};
			},
			hasAnchor: () => {
				return Boolean(this.anchorElement());
			},
			hasClass: (className: string) => {
				return this.state.classNames.has(className);
			},
			isElementInContainer: (el: Element) => {
				const root = this.rootRef.current;
				if (root) {
					return root.contains(el);
				}
				return false;
			},
			isFocused: () => {
				const root = this.rootRef.current;
				if (root) {
					return root === document.activeElement;
				}
				return false;
			},
			isRtl: () => {
				const root = this.rootRef.current;
				if (root) {
					return getComputedStyle(root)
						.getPropertyValue('direction') === 'rtl';
				}
				return false;
			},
			notifyClose: () => {
				if (this.props.onClose) {
					this.props.onClose();
				}
			},
			notifyClosing: () => {
				if (this.props.onClosing) {
					this.props.onClosing();
				}
			},
			notifyOpen: () => {
				if (this.props.onOpen) {
					this.props.onOpen();
				}
			},
			notifyOpening: () => {
				if (this.props.onOpening) {
					this.props.onOpening();
				}
			},
			removeClass: (className: string) => {
				if (this.state.classNames.has(className)) {
					this.state.classNames.delete(className);
					this.setState({
						classNames: new Set(this.state.classNames),
					});
				}
			},
			restoreFocus: () => {
				const root = this.rootRef.current;
				if (root && root.contains(document.activeElement)) {
					if (this.state.prevFocusedEl) {
						this.state.prevFocusedEl.focus();
					}
				}
				if (this.state.prevFocusedEl) {
					this.setState({
						prevFocusedEl: null,
					});
				}
			},
			saveFocus: () => {
				const el = document.activeElement;
				if (el instanceof HTMLElement) {
					this.setState({
						prevFocusedEl: el,
					});
				}
			},
			setMaxHeight: (height: string) => {
				const root = this.rootRef.current;
				if (root) {
					root.style.setProperty('max-height', height);
				}
			},
			setPosition: (position: Partial<MDCMenuDistance>) => {
				const root = this.rootRef.current;
				if (root) {
					const parts: ['top', 'right', 'bottom', 'left'] = [
						'top',
						'right',
						'bottom',
						'left',
					];
					for (const part of parts) {
						if (position[part] !== undefined) {
							root.style.setProperty(part, `${position[part]}px`);
						} else {
							root.style.removeProperty(part);
						}
					}
				}
			},
			setTransformOrigin: (origin: string) => {
				const root = this.rootRef.current;
				if (root) {
					const propName = getCorrectPropertyName(
						window,
						'transform',
					);
					root.style.setProperty(
						`${propName}-origin`,
						origin,
					);
				}
			},
		};
	}

	render() {
		const {
			absolutePosition,
			anchorMargin,
			anchorToBody,
			children,
			className,
			fullWidth,
			hasFixedPosition,
			isOpen,
			onClose,
			onClosing,
			onOpen,
			onOpening,
			width,
			...rest
		} = this.props;
		const {
			classNames,
		} = this.state;
		const clsName = makeClassName(
			...classNames,
			fullWidth
				? 'mdc-menu-surface--fullwidth'
				: undefined,
			className,
		);
		const sty = (width === undefined)
			? undefined
			: {width: pixelString(width)};
		const comp = (
			<div className={clsName} onKeyDown={this.keyPressedReact} ref={this.rootRef} style={sty} {...rest}>
				{children}
			</div>
		);
		if (anchorToBody) {
			return ReactDOM.createPortal(
				comp,
				document.body,
			);
		}
		return comp;
	}

	setAnchorCorner(corner: Corner): void {
		this.ctrl.setAnchorCorner(corner);
	}

	@bind
	private windowClicked(event: MouseEvent): void {
		this.ctrl.handleBodyClick(event);
	}
}
