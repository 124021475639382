import React from 'react';

import {OrganizationView} from '../organization';
import {
	WideContentContainer,
	WideContentHeader,
} from '../../components';

export interface IClientOrganizationListViewProps extends React.HTMLAttributes<any> {
}

export interface IClientOrganizationListViewState {
}

export class ClientOrganizationListView extends React.Component<IClientOrganizationListViewProps, IClientOrganizationListViewState> {
	constructor(props: IClientOrganizationListViewProps) {
		super(props);
	}

	render() {
		return (
			<>
				<WideContentHeader backNavUri={window.pbUrls.organizationClientIndexView} backNavTitle="Back to clients" hasDivider>
					Client organizations
				</WideContentHeader>
				<WideContentContainer>
					<OrganizationView/>
				</WideContentContainer>
			</>
		);
	}
}
