import React from 'react';

import {makeClassName} from '../../../util';
import {Icon} from '../../../components';
import * as datetime from '../../../datetime';

export enum Step {
	SelectProduct,
	Waiting,
	SelectDate,
	SelectTime,
	FinalReview,
}

export interface IOperatingHoursWithDateTimeTime {
	startTime: datetime.time | null;
	endTime: datetime.time | null;
}

export interface IProjectCreateViewState {
	accessNotes: string;
	accessOrgAppWidgetOptionId: number | null;
	anytime: boolean;
	app: IApp;
	busy: boolean;
	catalog: IProductCatalog;
	clientDueDate: string;
	clientDueTime: string;
	clientUserPk: string;
	clientUsers: Array<IUser>;
	date: datetime.date | null;
	dateAvailability: Map<string, Array<IAvailability>>;
	duration: number;
	messages: Array<string>;
	name: string;
	notes: string;
	occupancyOrgAppWidgetOptionId: number | null;
	orgAppWidgets: Array<IOrgAppWidget>;
	pendingTos: Array<ITermsOfService>;
	productAddonPks: Set<number>;
	productOptionPks: Set<number>;
	productPks: Set<number>;
	profile: IProfile;
	promptIsOpen: boolean;
	propertyName: string;
	serviceAreaPk: number | null;
	serviceAreas: Array<IServiceArea>;
	size: number | null;
	step: Step;
	street: string;
	summaryData: datetime.ISummaryData | null;
	time: datetime.time | null;
	visualDate: datetime.date | null;
	weekdayTimeRangeMap: Map<number, IOperatingHoursWithDateTimeTime>;
	wx: Map<number, Array<IWxDay>>;
}

export interface ICardHeaderProps extends React.HTMLAttributes<any> {
	headerText: string;
	onBackNavClick: () => any;
	stepNumber: number | string;
}

export function CardHeader(props: Partial<ICardHeaderProps>) {
	const {
		children,
		className,
		headerText,
		onBackNavClick,
		stepNumber,
		...rest
	} = props;
	const clsName = makeClassName(
		'create-view-header',
		className,
	);
	const txt = (headerText === undefined)
		? (stepNumber === undefined)
			? null
			: (
				<>
					<span className="font-weight--bolder">{`STEP ${stepNumber}`}</span> —&nbsp;
				</>
			)
		: (
			<>
				<span className="font-weight--bolder">{`${headerText}`}</span> —&nbsp;
			</>
		);
	return (
		<header className={clsName} {...rest}>
			{
				(onBackNavClick === undefined)
					? null
					: (
						<Icon onClick={onBackNavClick}>
							keyboard_arrow_left
						</Icon>
					)
			}
			<p>
				{txt}
				{children}
			</p>
		</header>
	);
}

export interface ISubtleLabelProps extends React.HTMLAttributes<any> {
}

export function SubtleLabel(props: Partial<ISubtleLabelProps>) {
	const {
		children,
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		'pb-cc-subtle-label',
		className,
	);
	return (
		<div className={clsName} {...rest}>
			{children}
		</div>
	);
}
