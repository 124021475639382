import React from 'react';

import {bind} from '../../../util';
import {TextInput} from '../../../components';

export interface IColorInputProps {
	onChange: (value: string) => any;
	value: string;
}

export class ColorInput extends React.Component<Partial<IColorInputProps>, {}> {
	@bind
	changeEvent(value: string): void {
		const {onChange} = this.props;
		onChange && onChange(value);
	}

	render() {
		const {value} = this.props;
		return (
			<TextInput
				className="width--100-percent align-items--flex-end"
				helpText="e.g.: #00299B"
				label="Color"
				onChange={this.changeEvent}
				type="color"
				value={value}
			/>
		);
	}
}
