import React from 'react';

import {makeClassName} from '../../util';
import {CssClassName} from './constants';

export interface IListItemCenterDetailProps extends React.HTMLAttributes<any> {
}

export function ListItemCenterDetail(props: IListItemCenterDetailProps) {
	const {
		children,
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		CssClassName.ListItemCenterDetail,
		className,
	);
	return (
		<span className={clsName} {...rest}>
			{children}
		</span>
	);
}

export interface IListItemGraphicProps extends React.HTMLAttributes<any> {
}

export function ListItemGraphic(props: IListItemGraphicProps) {
	const {
		children,
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		CssClassName.ListItemGraphic,
		className,
	);
	return (
		<span className={clsName} {...rest}>
			{children}
		</span>
	);
}

export interface IListItemMetaProps extends React.HTMLAttributes<any> {
}

export function ListItemMeta(props: IListItemMetaProps) {
	const {
		children,
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		CssClassName.ListItemMeta,
		className,
	);
	return (
		<span className={clsName} {...rest}>
			{children}
		</span>
	);
}

export function ListDivider() {
	return (
		<hr className={CssClassName.ListDivider}/>
	);
}

export function ListItemDivider() {
	const clsName = makeClassName(
		CssClassName.ListDivider,
		'pb-list-item',
	);
	return (
		<li role="separator" className={clsName}/>
	);
}
