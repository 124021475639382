import React from 'react';

import {api} from '../../../httpapi';
import * as datetime from '../../../datetime';
import {
	AppSlug,
	AppWidgetName,
} from '../../../constants';
import {
	Card,
	CardInner,
	GridLayout,
	GridLayoutCell,
	IMenuOptionProps,
	ITextInputProps,
	LinearProgress,
	TextInput,
} from '../../../components';
import {
	AppWidgThing,
	appWidgThings,
	bind,
	idxOk,
	isNumber,
	makeClassName,
	staticAnnotatedProject,
	staticEvent,
	staticLocation,
	staticProfile,
	staticProject,
	staticProjectEvent,
} from '../../../util';
import {
	ClientHeader,
	formattedScheduledDateTime,
	Header,
	ProjectFieldContainer,
	ProjectSectionContainer,
	ProjectSubSectionContainer,
	ScheduledDateTime,
} from '../common';

type DueData = {date: string; time: string;};

export interface IProjectUpdateViewProps extends React.HTMLAttributes<HTMLFormElement> {
	onBackClick: () => any;
	onUpdateClick: () => any;
	onSaved: () => any;
	pk: string;
}

export interface IProjectUpdateViewState {
	accessNotes: string;
	accessOptions: Array<IMenuOptionProps>;
	annotatedProject: IAnnotatedProject;
	app: IApp;
	clientDueDate: datetime.date | null;
	clientDueTime: datetime.time | null;
	description: string;
	event: IEvent;
	initializing: boolean;
	isSaving: boolean;
	location: ILocation;
	locationName: string;
	notes: string;
	numBaths: number | null;
	numBeds: number | null;
	occupancyOptions: Array<IMenuOptionProps>;
	orgAppWidgets: Array<IOrgAppWidget>;
	photosDueDate: datetime.date | null;
	photosDueTime: datetime.time | null;
	profile: IProfile;
	project: IProject;
	projectEvent: IProjectEvent;
	projectStatus: Array<IProjectStatus>;
	selectedAccessOptionIndex: number;
	selectedOccupancyOptionIndex: number;
	street: string;
	videoDueDate: datetime.date | null;
	videoDueTime: datetime.time | null;
}

enum HtmlId {
	Access = 'id_pb-detail-form-access-input',
	AccessNotes = 'id_pb-detail-form-access-notes-input',
	BedsBath = 'id_pb-detail-form-beds-bath-input',
	ClientDue = 'id_pb-detail-form-client-due-input',
	Desc = 'id_pb-detail-form-description-input',
	Name = 'id_pb-detail-form-name-input',
	Notes = 'id_pb-detail-form-notes-input',
	Occupancy = 'id_pb-detail-form-occupancy-input',
	PhotosDue = 'id_pb-detail-form-photos-due-input',
	Street = 'id_pb-detail-form-street-input',
	VideoDue = 'id_pb-detail-form-video-due-input',
}

export class ProjectUpdateView extends React.Component<IProjectUpdateViewProps, IProjectUpdateViewState> {
	constructor(props: IProjectUpdateViewProps) {
		super(props);
		this.state = {
			accessNotes: '',
			accessOptions: [],
			annotatedProject: staticAnnotatedProject(),
			app: {
				name: '',
				slug: '',
				widgets: [],
			},
			clientDueDate: null,
			clientDueTime: null,
			description: '',
			event: staticEvent(),
			initializing: true,
			location: staticLocation(),
			locationName: '',
			notes: '',
			numBaths: null,
			isSaving: false,
			numBeds: null,
			occupancyOptions: [],
			orgAppWidgets: [],
			photosDueDate: null,
			photosDueTime: null,
			profile: staticProfile(),
			project: staticProject(),
			projectEvent: staticProjectEvent(),
			projectStatus: [],
			selectedAccessOptionIndex: 0,
			selectedOccupancyOptionIndex: 0,
			street: '',
			videoDueDate: null,
			videoDueTime: null,
		};
	}

	private accessOptions(): Array<IMenuOptionProps> {
		const {selectedAccessOptionIndex} = this.state;
		const thing = this.appWidgThingByAppWidgName(
			AppWidgetName.Access,
		);
		return [
			{
				label: '',
				value: '',
				isSelected: selectedAccessOptionIndex === 0,
			},
			...thing.options.map((opt, idx) => {
				return {
					label: opt.label,
					value: String(opt.id),
					isSelected: (idx + 1) === selectedAccessOptionIndex,
				};
			}),
		];
	}

	private appWidgThingByAppWidgName(name: AppWidgetName): AppWidgThing {
		let thing: AppWidgThing | null = null;
		for (const obj of this.appWidgThings()) {
			if (obj.name === name) {
				thing = obj;
				break;
			}
		}
		return (thing === null)
			? new AppWidgThing()
			: thing;
	}

	private appWidgThings(): Array<AppWidgThing> {
		const {
			app,
			orgAppWidgets,
		} = this.state;
		return appWidgThings(app.widgets, orgAppWidgets);
	}

	@bind
	private backClicked() {
		// Check if changes
		// If changes, notify of unsaved changes... ask if would like to save changes
		// If save changes, then save changes
		// Other wise call this.props.onBackClick or whatever
		const {
			onBackClick,
		} = this.props;
		onBackClick();
	}

	@bind
	private changed<K extends keyof IProjectUpdateViewState>(key: K, value: string) {
		let val: IProjectUpdateViewState[K];
		switch (key) {
			case 'accessNotes':
			case 'accessOptions':
			case 'description':
			case 'locationName':
			case 'notes':
			case 'street': {
				val = value as IProjectUpdateViewState[K];
				break;
			}
			case 'numBaths':
			case 'numBeds': {
				const n = (value.length === 0)
					? Number.NaN
					: Number(value);
				if (Number.isNaN(n)) {
					val = null as IProjectUpdateViewState[K];
				} else {
					val = n as IProjectUpdateViewState[K];
				}
				break;
			}
			case 'clientDueDate':
			case 'photosDueDate':
			case 'videoDueDate': {
				if (value.length === 0) {
					val = null as IProjectUpdateViewState[K];
				} else {
					val = datetime.date.fromisoformat(value) as IProjectUpdateViewState[K];
				}
				break;
			}
			case 'clientDueTime':
			case 'photosDueTime':
			case 'videoDueTime': {
				if (value.length === 0) {
					val = null as IProjectUpdateViewState[K];
				} else {
					val = datetime.time.fromisoformat(value) as IProjectUpdateViewState[K];
				}
				break;
			}
			default: {
				console.log('changed: Invalid key: %s', key);
				return;
			}
		}
		this.setState({[key]: val} as Pick<IProjectUpdateViewState, K>);
	}

	async componentDidMount() {
		await this.initialize();
	}

	private dueData(date: datetime.date | null, time: datetime.time | null): DueData {
		return {
			date: date
				? date.isoformat()
				: '',
			time: time
				? time.isoformat()
				: '',
		};
	}

	private formattedScheduledDateTime() {
		return formattedScheduledDateTime(
			this.state.event,
		);
	}

	private async initialize() {
		const {pk} = this.props;
		this.setState(
			{
				app: await api.appDetail(AppSlug.ProjectCreator),
				profile: await api.profileDetail(),
				projectStatus: await api.projectStatusList(pk),
				orgAppWidgets: await api.orgAppWidgetList({app: AppSlug.ProjectCreator}),
			},
			this.refresh.bind(
				this,
				pk,
				() => this.setState({initializing: false}),
			),
		);
	}

	private occupancyOptions(): Array<IMenuOptionProps> {
		const {selectedOccupancyOptionIndex} = this.state;
		const thing = this.appWidgThingByAppWidgName(
			AppWidgetName.Occupancy,
		);
		return [
			{
				label: '',
				value: '',
				isSelected: selectedOccupancyOptionIndex === 0,
			},
			...thing.options.map((opt, idx) => {
				return {
					label: opt.label,
					value: String(opt.id),
					isSelected: (idx + 1) === selectedOccupancyOptionIndex,
				};
			}),
		];
	}

	private projectUpdateData(): Partial<IProjectUpdate> {
		const {
			accessNotes,
			clientDueDate,
			clientDueTime,
			description,
			locationName,
			notes,
			numBaths,
			numBeds,
			photosDueDate,
			photosDueTime,
			street,
			videoDueDate,
			videoDueTime,
		} = this.state;
		return {
			accessNotes,
			accessOrgAppWidgetOptionId: this.selectedAccessOptionId(),
			clientDueDate: strOrNull(clientDueDate),
			clientDueTime: strOrNull(clientDueTime),
			description,
			locationName,
			notes,
			numBaths,
			numBeds,
			occupancyOrgAppWidgetOptionId: this.selectedOccupancyOptionId(),
			photosDueDate: strOrNull(photosDueDate),
			photosDueTime: strOrNull(photosDueTime),
			street,
			videoDueDate: strOrNull(videoDueDate),
			videoDueTime: strOrNull(videoDueTime),
		};
	}

	@bind
	private async refresh(pk: string, cb?: () => any) {
		const projectEvent = await api.projectEventDetail(pk);
		this.setState(
			{
				annotatedProject: await api.projectDetailAnnotated(pk),
				event: await api.eventDetail(projectEvent.eventId),
				location: (await api.projectLocationDetail(pk)) || this.state.location,
				project: await api.projectDetail(pk),
				projectEvent,
			},
			() => {
				this.setAttrState(
					this.state.annotatedProject,
					this.state.project,
					this.state.location,
					cb,
				);
			},
		);
	}

	render() {
		const {
			accessNotes,
			annotatedProject,
			clientDueDate,
			clientDueTime,
			description,
			initializing,
			isSaving,
			locationName,
			notes,
			numBaths,
			numBeds,
			photosDueDate,
			photosDueTime,
			profile,
			project,
			projectEvent,
			street,
			videoDueDate,
			videoDueTime,
		} = this.state;
		const clientDue = this.dueData(clientDueDate, clientDueTime);
		const fmtDt = this.formattedScheduledDateTime();
		const photosDue = this.dueData(photosDueDate, photosDueTime);
		const videoDue = this.dueData(videoDueDate, videoDueTime);
		const baths = (numBaths === null)
			? ''
			: numBaths;
		const beds = (numBeds === null)
			? ''
			: numBeds;
		const axsOpts = this.accessOptions();
		let axsSelectVal: string = '';
		for (const obj of axsOpts) {
			if (obj.isSelected) {
				axsSelectVal = obj.value;
				break;
			}
		}
		const occOpts = this.occupancyOptions();
		let occSelectVal: string = '';
		for (const obj of occOpts) {
			if (obj.isSelected) {
				occSelectVal = obj.value;
				break;
			}
		}
		if (initializing) {
			return (
				<GridLayout>
					<GridLayoutCell span={12}>
						<div className="display--flex align-items--center justify-content--center">
							<LinearProgress isOpen/>
						</div>
					</GridLayoutCell>
				</GridLayout>
			);
		}
		return (
			<GridLayout>
				<GridLayoutCell span={12}>
					<Card className="project-container">
						<CardInner>
							<Header isSaveBtnDisabled={isSaving} isEditing onBackClick={this.backClicked} onUpdateClick={this.saveChanges}>
								{annotatedProject.services}
							</Header>
							{
								profile.isProducer
									? (
										<ClientHeader>
											{annotatedProject.client}
										</ClientHeader>
									)
									: null
							}
							<ProjectSectionContainer className="display--flex align-items--center justify-content--center darker-blue">
								{this.statusDisplay(project.statusId)}
							</ProjectSectionContainer>
							<form action="">
								<ProjectSectionContainer>
									<ProjectSubSectionContainer>
										<ProjectFieldContainer>
											<ProjectFieldLabel htmlFor={HtmlId.Desc}>
												Description
											</ProjectFieldLabel>
											<InputField
												id={HtmlId.Desc}
												onChange={this.changed.bind(this, 'description')}
												placeholder={'e.g. Drone, lot lines for lots 19 \u0026 20'}
												value={description}
											/>
										</ProjectFieldContainer>
									</ProjectSubSectionContainer>
									<ProjectSubSectionContainer>
										<ProjectFieldContainer>
											<ProjectFieldLabel htmlFor={HtmlId.Street}>
												Street
											</ProjectFieldLabel>
											<InputField
												id={HtmlId.Street}
												onChange={this.changed.bind(this, 'street')}
												placeholder="e.g. 123 E Main St"
												value={street}
											/>
										</ProjectFieldContainer>
									</ProjectSubSectionContainer>
									<ProjectSubSectionContainer>
										<ProjectFieldContainer>
											<ProjectFieldLabel htmlFor={HtmlId.Name}>
												Name
											</ProjectFieldLabel>
											<InputField
												id={HtmlId.Name}
												onChange={this.changed.bind(this, 'locationName')}
												placeholder="e.g. Life's a Beach"
												value={locationName}
											/>
										</ProjectFieldContainer>
									</ProjectSubSectionContainer>
								</ProjectSectionContainer>
								<ScheduledDateTime anytime={projectEvent.flexibleDatetime} duration={fmtDt.duration} date={fmtDt.date} startTime={fmtDt.startTime} endTime={fmtDt.endTime}>
									{
										(profile.isProducer && window.pbUrls.rescheduleProjectUri)
											? (
												<a href={window.pbUrls.rescheduleProjectUri} id="id_temp-project-datetime-change-link">
													{
														projectEvent.flexibleDatetime
															? 'schedule'
															: 'reschedule'
													}
												</a>
											)
											: null
									}
								</ScheduledDateTime>
								<ProjectSubSectionContainer>
									<ProjectFieldContainer>
										<ProjectFieldLabel htmlFor={HtmlId.Access}>
											Access
										</ProjectFieldLabel>
										<div className="pb-select width--100-percent">
											<select value={axsSelectVal} className="pb-select__surface project-field-value color--white" id={HtmlId.Access} name="access_org_app_widget_option_id" onChange={this.selectChanged.bind(this, 'selectedAccessOptionIndex')} title="Access Option">
												{
													axsOpts.map((obj, idx) => {
														const clsName = obj.value === ''
															? 'color--black'
															: undefined;
														return (
															<option className={clsName} key={idx} value={obj.value}>{obj.label}</option>
														);
													})
												}
											</select>
										</div>
									</ProjectFieldContainer>
								</ProjectSubSectionContainer>
								<ProjectSectionContainer>
									<ProjectFieldContainer>
										<ProjectFieldLabel htmlFor={HtmlId.AccessNotes}>
											Access Notes
										</ProjectFieldLabel>
										<TextInput
											className="pb-project-detail-text-input width--100-percent"
											inputElementId={HtmlId.AccessNotes}
											isTextArea
											onChange={this.changed.bind(this, 'accessNotes')}
											placeholder="Lock Box Code, etc..."
											rows={3}
											value={accessNotes}
										/>
									</ProjectFieldContainer>
								</ProjectSectionContainer>
								<ProjectSubSectionContainer>
									<ProjectFieldContainer>
										<ProjectFieldLabel htmlFor={HtmlId.Occupancy}>
											Occupancy
										</ProjectFieldLabel>
										<div className="pb-select width--100-percent">
											<select value={occSelectVal} className="pb-select__surface project-field-value color--white" id={HtmlId.Occupancy} name="occupancy_org_app_widget_option_id" onChange={this.selectChanged.bind(this, 'selectedOccupancyOptionIndex')} title="Location Occupancy">
												{
													occOpts.map((obj, idx) => {
														const clsName = obj.value === ''
															? 'color--black'
															: undefined;
														return (
															<option className={clsName} key={idx} value={obj.value}>{obj.label}</option>
														);
													})
												}
											</select>
										</div>
									</ProjectFieldContainer>
								</ProjectSubSectionContainer>
								<ProjectSectionContainer className="fluid">
									<ProjectFieldContainer>
										<ProjectFieldLabel htmlFor={HtmlId.Notes}>
											Notes
										</ProjectFieldLabel>
										<TextInput
											className="pb-project-detail-text-input width--100-percent"
											inputElementId={HtmlId.Notes}
											isTextArea
											onChange={this.changed.bind(this, 'notes')}
											placeholder="General notes..."
											rows={3}
											value={notes}
										/>
									</ProjectFieldContainer>
								</ProjectSectionContainer>
								{
									profile.isProducer
										? (
											<>
												<ProjectSubSectionContainer>
													<ProjectFieldContainer>
														<ProjectFieldLabel htmlFor={HtmlId.PhotosDue}>
															Photos Due
														</ProjectFieldLabel>
														<input
															id={HtmlId.PhotosDue}
															onChange={evt => this.changed('photosDueDate', evt.target.value)}
															type="date"
															value={photosDue.date}
														/>
														<label>
															<input
																onChange={evt => this.changed('photosDueTime', evt.target.value)}
																type="time"
																value={photosDue.time}
															/>
														</label>
													</ProjectFieldContainer>
												</ProjectSubSectionContainer>
												<ProjectSubSectionContainer>
													<ProjectFieldContainer>
														<ProjectFieldLabel htmlFor={HtmlId.VideoDue}>
															Video Due
														</ProjectFieldLabel>
														<input
															id={HtmlId.VideoDue}
															onChange={evt => this.changed('videoDueDate', evt.target.value)}
															type="date"
															value={videoDue.date}
														/>
														<label>
															<input
																onChange={evt => this.changed('videoDueTime', evt.target.value)}
																type="time"
																value={videoDue.time}
															/>
														</label>
													</ProjectFieldContainer>
												</ProjectSubSectionContainer>
											</>
										)
										: null
								}
								<ProjectSubSectionContainer>
									<ProjectFieldContainer>
										<ProjectFieldLabel htmlFor={HtmlId.ClientDue}>
											Client Due
										</ProjectFieldLabel>
										<input
											id={HtmlId.ClientDue}
											onChange={evt => this.changed('clientDueDate', evt.target.value)}
											type="date"
											value={clientDue.date}
										/>
										<label>
											<input
												onChange={evt => this.changed('clientDueTime', evt.target.value)}
												type="time"
												value={clientDue.time}
											/>
										</label>
									</ProjectFieldContainer>
								</ProjectSubSectionContainer>
								<ProjectSubSectionContainer>
									<ProjectFieldContainer>
										<ProjectFieldLabel htmlFor={HtmlId.BedsBath}>
											Beds/Baths
										</ProjectFieldLabel>
										<input
											id={HtmlId.BedsBath}
											min={0}
											onChange={evt => this.changed('numBeds', evt.target.value)}
											step={0.5}
											type="number"
											value={beds}
										/>
										<label>
											<input
												min={0}
												onChange={evt => this.changed('numBaths', evt.target.value)}
												step={0.5}
												type="number"
												value={baths}
											/>
										</label>
									</ProjectFieldContainer>
								</ProjectSubSectionContainer>
							</form>
						</CardInner>
					</Card>
				</GridLayoutCell>
			</GridLayout>
		);
	}

	@bind
	private async saveChanges() {
		const {
			pk,
			onSaved,
		} = this.props;
		this.setState(
			{
				isSaving: true,
			},
			async () => {
				await api.updateProject(
					pk,
					this.projectUpdateData(),
				);
				this.setState(
					{
						isSaving: false,
					},
					onSaved,
				);
			},
		);
	}

	@bind
	private selectChanged<K extends keyof Pick<IProjectUpdateViewState, 'selectedAccessOptionIndex' | 'selectedOccupancyOptionIndex'>>(key: K, event: React.ChangeEvent<HTMLSelectElement>) {
		this.setState({
			[key]: event.target.selectedIndex,
		} as Pick<IProjectUpdateViewState, K>);
	}

	private selectedAccessOptionId(): number | null {
		const {selectedAccessOptionIndex} = this.state;
		return selectedOptionId(
			selectedAccessOptionIndex,
			this.accessOptions(),
		);
	}

	private selectedOccupancyOptionId(): number | null {
		const {selectedOccupancyOptionIndex} = this.state;
		return selectedOptionId(
			selectedOccupancyOptionIndex,
			this.occupancyOptions(),
		);
	}

	setAttrState(annotatedProject: IAnnotatedProject, project: IProject, location: ILocation, cb?: () => any) {
		const axsIdx = this.accessOptions().findIndex(
			opt => (opt.value === String(annotatedProject.accessOptionId)),
		);
		const occIdx = this.occupancyOptions().findIndex(
			opt => (opt.value === String(annotatedProject.occupancyOptionId)),
		);
		this.setState(
			{
				accessNotes: annotatedProject.accessNotes,
				clientDueDate: dateOrNull(annotatedProject.clientDueDateIsoformat),
				clientDueTime: timeOrNull(annotatedProject.clientDueTimeIsoformat),
				description: project.description,
				locationName: annotatedProject.locationName,
				notes: project.notes,
				numBaths: location.numBaths,
				numBeds: location.numBeds,
				photosDueDate: dateOrNull(annotatedProject.photosDueDateIsoformat),
				photosDueTime: timeOrNull(annotatedProject.photosDueTimeIsoformat),
				selectedAccessOptionIndex: Math.max(axsIdx, 0),
				selectedOccupancyOptionIndex: Math.max(occIdx, 0),
				street: annotatedProject.locationStreet,
				videoDueDate: dateOrNull(annotatedProject.videoDueDateIsoformat),
				videoDueTime: timeOrNull(annotatedProject.videoDueTimeIsoformat),
			},
			cb,
		);
	}

	@bind
	private statusDisplay(statusId: string): string {
		const {projectStatus} = this.state;
		for (const obj of projectStatus) {
			if (obj.name === statusId) {
				return obj.summary;
			}
		}
		return '';
	}
}

interface IProjectFieldLabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
}

function ProjectFieldLabel(props: IProjectFieldLabelProps) {
	const {
		children,
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		'project-field-label',
		className,
	);
	return (
		<label className={clsName} {...rest}>
			{children}
		</label>
	);
}

function InputField(props: Partial<Omit<ITextInputProps, 'inputElementClassName' | 'noLabel'>>) {
	const {
		className,
		id,
		...rest
	} = props;
	const clsName = makeClassName(
		'pb-project-detail-input-field',
		className,
	);
	return (
		<TextInput
			className={clsName}
			inputElementClassName="project-field-value"
			inputElementId={id}
			// xxxTmpUseDivWrapperInsteadOfLabelWrapper
			{...rest}
		/>
	);
}

function dateOrNull(value: string): datetime.date | null {
	if (value.length === 0) {
		return null;
	}
	return datetime.date.fromisoformat(value);
}

function timeOrNull(value: string): datetime.time | null {
	if (value.length === 0) {
		return null;
	}
	return datetime.time.fromisoformat(value);
}

function numOrNull(value: string): number | null {
	if (value.length === 0) {
		return null;
	}
	const rv = Number(value);
	if (isNumber(rv)) {
		return rv;
	}
	return null;
}

function strOrNull(value: datetime.date | datetime.time | null): string | null {
	if (value === null) {
		return null;
	}
	return value.isoformat();
}

function selectedOptionId(index: number, opts: Array<IMenuOptionProps>): number | null {
	if (idxOk(index, opts.length, '', true)) {
		return numOrNull(opts[index].value);
	}
	return null;
}
