import React from 'react';
import {
	CCardToolbarAction,
	Dialog,
	IMenuOptionProps,
	Menu,
} from '../../components';
import {
	CrudOp,
	StandardButton,
	UiTableTitle,
} from '../../constants';
import {api} from '../../httpapi';

import {
	bind,
	staticCfg,
} from '../../util';
import {UserCreate} from './create';
import {UserDelete} from './delete';
import {UserList} from './list';
import {UserRead} from './read';
import {UserUpdate} from './update';

export interface IUserViewProps extends React.HTMLAttributes<any> {
	objCreator: (obj: INewUser) => Promise<IExpandedUser>;
	objDetailGetter: (pk: string) => Promise<IExpandedUser>;
	pageGetter: (params?: Partial<IPageParams>) => Promise<PaginatedUserPage>;
	objUpdater: (obj: IExpandedUser) => Promise<IExpandedUser>;
	orgListGetter?: () => Promise<Array<IOrganization>>;
	productListGetter?: () => Promise<Array<IProduct>>;
	productUserListGetter?: (pk: string) => Promise<Array<IProductUser>>;
	productUserListUpdater?: (objPk: string, prodUserObjs: Array<IProductUser>) => Promise<Array<IProductUser>>;
	serviceAreaListGetter?: () => Promise<Array<IServiceArea>>;
	uiTable: UiTableTitle;
	userClassListGetter?: () => Promise<Array<IUserClass>>;
}

interface IUserViewState {
	activePk: string;
	cfg: ICfg;
	dialogViewState: CrudOp;
	moreMenuCoords: PlainCoords | null;
	moreMenuIsOpen: boolean;
	organizations: Array<IOrganization>;
	products: Array<IProduct>;
	requestedServiceAreas: boolean;
	serviceAreas: Array<IServiceArea>;
	userClasses: Array<IUserClass>;
}

export class UserView extends React.Component<IUserViewProps, IUserViewState> {
	private readonly userListRef: React.RefObject<UserList>;

	constructor(props: IUserViewProps) {
		super(props);
		this.userListRef = React.createRef();
		this.state = {
			activePk: '',
			cfg: staticCfg(),
			dialogViewState: CrudOp.NoOp,
			moreMenuCoords: null,
			moreMenuIsOpen: false,
			organizations: [],
			products: [],
			requestedServiceAreas: false,
			serviceAreas: [],
			userClasses: [],
		};
	}

	@bind
	private cancelPressed(): void {
		const {
			activePk,
			dialogViewState,
		} = this.state;
		switch (dialogViewState) {
			case CrudOp.Create: {
				this.closeDialog();
				break;
			}
			case CrudOp.Read: {
				this.closeDialog();
				break;
			}
			case CrudOp.Update: {
				this.openReadDialog(activePk);
				break;
			}
			case CrudOp.Delete: {
				this.openReadDialog(activePk);
				break;
			}
		}
	}

	@bind
	private closeDialog(): void {
		this.setState({
			activePk: '',
			dialogViewState: CrudOp.NoOp,
		});
	}

	@bind
	private closeMoreMenu(): void {
		this.setState({
			moreMenuIsOpen: false,
			moreMenuCoords: null,
		});
	}

	async componentDidMount() {
		const {
			orgListGetter,
			productListGetter,
			userClassListGetter,
		} = this.props;
		await this.refreshCfg();
		const orgs = orgListGetter
			? (await orgListGetter())
			: [];
		const cls = userClassListGetter
			? (await userClassListGetter())
			: [];
		const prods = productListGetter
			? (await productListGetter())
			: [];
		this.setState({
			organizations: orgs,
			products: prods.filter(x => x.parentId === null),
			userClasses: cls,
		});
	}

	async componentDidUpdate(prevProps: Readonly<IUserViewProps>, prevState: Readonly<IUserViewState>, snapshot?: any) {
		const {
			dialogViewState,
			requestedServiceAreas,
		} = this.state;
		if ((prevState.dialogViewState !== dialogViewState) && (dialogViewState === CrudOp.Read) && !requestedServiceAreas) {
			await this.fetchAndSetServiceAreas();
		}
	}

	@bind
	private async created() {
		await this.refreshPage(
			undefined,
			() => this.closeDialog(),
		);
	}

	private async fetchAndSetServiceAreas(): Promise<void> {
		const {
			serviceAreaListGetter,
		} = this.props;
		if (serviceAreaListGetter) {
			this.setState({
				requestedServiceAreas: true,
				serviceAreas: await serviceAreaListGetter(),
			});
		}
	}

	@bind
	private async fetchCfg(): Promise<ICfg> {
		return await api.cfgDetail();
	}

	@bind
	private moreMenuClosed(): void {
		this.closeMoreMenu();
	}

	private moreMenuOptions(): Array<IMenuOptionProps> {
		return [
			{
				isSelected: false,
				label: 'Delete',
				value: 'DELETE',
			},
		];
	}

	@bind
	private moreMenuOptionSelected(index: number): void {
		const {activePk} = this.state;
		const opts = this.moreMenuOptions();
		if ((index >= 0) && (index < opts.length)) {
			this.openDeleteDialog(activePk);
		} else {
			console.log('moreMenuOptionSelected: Invalid option index:', index);
		}
	}

	@bind
	private objClicked(pk: string): void {
		this.openReadDialog(pk);
	}

	@bind
	private async objDeleted() {
		await this.refreshPage(
			undefined,
			() => this.closeDialog(),
		);
	}

	@bind
	private openCreateDialog(): void {
		this.setState({
			dialogViewState: CrudOp.Create,
		});
	}

	@bind
	private openDeleteDialog(activePk: string) {
		this.closeMoreMenu();
		this.setState({
			activePk,
			dialogViewState: CrudOp.Delete,
		});
	}

	private openMoreMenu(x?: number, y?: number): void {
		if ((x === undefined) || (y === undefined)) {
			console.log('openMoreMenu: Coordinates are undefined');
			return;
		}
		this.setState({
			moreMenuIsOpen: true,
			moreMenuCoords: {
				x,
				y,
			},
		});
	}

	@bind
	private openReadDialog(pk: string): void {
		this.setState({
			activePk: pk,
			dialogViewState: CrudOp.Read,
		});
	}

	private products(): Array<IProduct> {
		const {products} = this.state;
		return products.filter(x => x.parentId === null);
	}

	@bind
	async refreshCfg(cb?: () => any): Promise<void> {
		this.setState(
			{
				cfg: await this.fetchCfg(),
			},
			cb,
		);
	}

	async refreshPage(params?: Partial<IPageParams>, cb?: () => any): Promise<void> {
		const curr = this.userListRef.current;
		if (curr) {
			await curr.refreshPage(params, cb);
		}
	}

	render() {
		const {
			pageGetter,
			objCreator,
			objDetailGetter,
			objUpdater,
			productUserListGetter,
			productUserListUpdater,
			uiTable,
		} = this.props;
		const {
			activePk,
			cfg,
			dialogViewState,
			moreMenuCoords,
			moreMenuIsOpen,
			organizations,
			serviceAreas,
			userClasses,
		} = this.state;
		let dialogButtons: StandardButton | undefined = undefined;
		let comp: React.ReactNode = null;
		switch (dialogViewState) {
			case CrudOp.Create: {
				comp = (
					<UserCreate
						onCancel={this.cancelPressed}
						organizations={organizations}
						userClasses={userClasses}
						objCreator={objCreator}
						onDone={this.created}
					/>
				);
				break;
			}
			case CrudOp.Read: {
				comp = (
					<UserRead
						objGetter={objDetailGetter}
						objUpdater={objUpdater}
						onToolbarAction={this.toolbarAction}
						pk={activePk}
						serviceAreas={serviceAreas}
						userClasses={userClasses}
					/>
				);
				break;
			}
			case CrudOp.Update: {
				comp = (
					<UserUpdate
						onCancel={this.cancelPressed}
						organizations={organizations}
						pk={activePk}
						productUserListGetter={productUserListGetter}
						productUserListUpdater={productUserListUpdater}
						onDone={this.updated}
						userClasses={userClasses}
						serviceAreas={serviceAreas}
						products={this.products()}
						objGetter={objDetailGetter}
						objUpdater={objUpdater}
					/>
				);
				break;
			}
			case CrudOp.Delete: {
				// dialogButtons = StandardButton.No | StandardButton.Yes;
				comp = (
					<UserDelete
						pk={activePk}
						userClasses={userClasses}
						objDeleter={api.deleteClientUser}
						objGetter={api.clientUserDetail}
						onDone={this.objDeleted}
						onCancel={this.cancelPressed}
					/>
				);
				break;
			}
		}
		const open = dialogViewState !== CrudOp.NoOp;
		return (
			<>
				<UserList
					cfg={cfg}
					ref={this.userListRef}
					onObjectClick={this.objClicked}
					onCreateBtnClick={this.openCreateDialog}
					uiTableTitle={uiTable}
					pageGetter={pageGetter}
				/>
				<Dialog buttons={dialogButtons} isOpen={open} onFinished={this.closeDialog}>
					{comp}
				</Dialog>
				<Menu
					anchorToBody
					isCompact
					isOpen={moreMenuIsOpen}
					onClose={this.moreMenuClosed}
					onSelection={this.moreMenuOptionSelected}
					options={this.moreMenuOptions()}
					absolutePosition={moreMenuCoords || undefined}
				/>
			</>
		);
	}

	@bind
	private toolbarAction(axn: CCardToolbarAction, clientX?: number, clientY?: number): void {
		switch (axn) {
			case CCardToolbarAction.Close: {
				this.closeDialog();
				break;
			}
			case CCardToolbarAction.Edit: {
				this.setState({
					dialogViewState: CrudOp.Update,
				});
				break;
			}
			case CCardToolbarAction.More: {
				this.openMoreMenu(clientX, clientY);
				break;
			}
		}
	}

	@bind
	private updated(): void {
		this.setState({
			dialogViewState: CrudOp.Read,
		});
	}
}
