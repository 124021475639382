import React from 'react';

import {staticPhoneNumber} from '../../util';
import {IUserCardProps} from '../../components/usercard';
import {CCard, CCardAvatar, CCardDescribe, CCardHeader, CCardIntro, CCardName, CCardNameTitle, CCardTitle, CCardToolbar, CCardToolbarAction} from '../../components';

export interface IPhoneNumberReadProps extends Partial<IUserCardProps> {
	objGetter: (pk: number) => Promise<IPhoneNumber>;
	onToolbarAction: (axn: CCardToolbarAction) => any;
	pk: number;
}

interface IPhoneNumberReadState {
	obj: IPhoneNumber;
}

export class PhoneNumberRead extends React.Component<IPhoneNumberReadProps, IPhoneNumberReadState> {
	constructor(props: IPhoneNumberReadProps) {
		super(props);
		this.state = {
			obj: staticPhoneNumber(),
		};
	}

	async componentDidMount() {
		const {
			objGetter,
			pk,
		} = this.props;
		this.setState({
			obj: await objGetter(pk),
		});
	}

	render() {
		const {
			objGetter,
			onToolbarAction,
			serviceAreas,
			...rest
		} = this.props;
		const {obj} = this.state;
		return (
			<CCard {...rest}>
				<CCardHeader>
					<CCardIntro>
							<CCardAvatar isOutlined>
								phone
							</CCardAvatar>
						<CCardDescribe>
							<CCardNameTitle>
								<CCardName>
									{obj.number}
								</CCardName>
								<CCardTitle>
									{obj.label}
								</CCardTitle>
							</CCardNameTitle>
						</CCardDescribe>
					</CCardIntro>
					<CCardToolbar
						onAction={onToolbarAction}
					/>
				</CCardHeader>
			</CCard>
		);
	}
}
