import React from 'react';
import {
	MDCChipSetAdapter,
	MDCChipSetFoundation,
	MDCChipSetEvents,
	MDCChipSetAttributes,
	MDCChipActionType,
	MDCChipActionFocusBehavior,
	MDCChipAnimation,
	MDCChipSetInteractionEventDetail,
	MDCChipSetRemovalEventDetail,
	MDCChipSetSelectionEventDetail,
	ChipAnimationEvent,
	ChipInteractionEvent,
	ChipNavigationEvent,
} from '@material/chips';

import {bind, makeClassName} from '../../util';
import {Chip, ChipEvent, IChip, IGoogleSucksAtSoftwareChipEventData} from './chip';

export enum ChipSetEventType {
	Interaction,
	Removal,
	Selection,
}

export interface IGoogleSucksAtSoftwareChipSetEventData {
	chipId: string;
	chipIndex: number;
	clickCoords: PlainCoords;
	isComplete: boolean;
	isSelected: boolean;
}

enum ChipType {
	Action,
	Input,
	Filter,
}

export interface IChipSetChip extends IChip {
	className: string;
	id: string;
	text: string;
}

export interface IChipSetProps extends React.HTMLAttributes<any> {
	chips: Array<Partial<IChipSetChip>>;
	isMultiselectable: boolean;
	onEvent: (typ: ChipSetEventType, data: Partial<IGoogleSucksAtSoftwareChipSetEventData>) => any;
}

interface IChipSetState {
	lastClickCoords: PlainCoords;
}

export class ChipSet extends React.Component<Partial<IChipSetProps>, IChipSetState> {
	private chipRefs: Array<React.RefObject<Chip>>;
	private ctrl: MDCChipSetFoundation;
	private readonly rootRef: React.RefObject<HTMLDivElement>;

	constructor(props: Partial<IChipSetProps>) {
		super(props);
		this.chipRefs = [];
		this.ctrl = new MDCChipSetFoundation();
		this.rootRef = React.createRef();
		this.state = {
			lastClickCoords: {
				x: 0,
				y: 0,
			}
		};
	}

	private chipComp(index: number): Chip | null {
		if ((index >= 0) && (index < this.chipRefs.length)) {
			return this.chipRefs[index].current;
		}
		console.log('ChipSet::chipComp: Invalid index. Got:', index);
		return null;
	}

	@bind
	private chipEvent(evt: ChipEvent, data: Partial<IGoogleSucksAtSoftwareChipEventData>): void {
		switch (evt) {
			case ChipEvent.Animation: {
				if ((data.chipId === undefined) || (data.animation === undefined) || (data.isComplete === undefined)) {
					console.log('ChipSet::chipEvent: Invalid data for event "%s". Got:', ChipEvent[evt], data);
					return;
				}
				this.setState(
					{
						lastClickCoords: (data.clickCoords === undefined)
							? this.state.lastClickCoords
							: data.clickCoords,
					},
					() => this.ctrl.handleChipAnimation(
						{
							detail: {
								chipID: data.chipId,
								animation: data.animation,
								isComplete: data.isComplete,
								addedAnnouncement: data.addedAnnouncement,
								removedAnnouncement: data.removedAnnouncement,
							}
						} as ChipAnimationEvent
					)
				);
				break;
			}
			case ChipEvent.Interaction: {
				if ((data.actionId === undefined) || (data.chipId === undefined) || (data.source === undefined) || (data.shouldRemove === undefined) || (data.isSelectable === undefined) || (data.isSelected === undefined)) {
					console.log('ChipSet::chipEvent: Invalid data for event "%s". Got:', ChipEvent[evt], data);
					return;
				}
				this.setState(
					{
						lastClickCoords: (data.clickCoords === undefined)
							? this.state.lastClickCoords
							: data.clickCoords,
					},
					() => this.ctrl.handleChipInteraction(
						{
							detail: {
								actionID: data.actionId,
								chipID: data.chipId,
								source: data.source,
								shouldRemove: data.shouldRemove,
								isSelectable: data.isSelectable,
								isSelected: data.isSelected,
							}
						} as ChipInteractionEvent
					)
				);
				break;
			}
			case ChipEvent.Navigation: {
				if ((data.chipId === undefined) || (data.source === undefined) || (data.key === undefined) || (data.isRTL === undefined)) {
					console.log('ChipSet::chipEvent: Invalid data for event "%s". Got:', ChipEvent[evt], data);
					return;
				}
				this.setState(
					{
						lastClickCoords: (data.clickCoords === undefined)
							? this.state.lastClickCoords
							: data.clickCoords,
					},
					() => this.ctrl.handleChipNavigation(
						{
							detail: {
								chipID: data.chipId,
								source: data.source,
								key: data.key,
								isRTL: data.isRTL,
							}
						} as ChipNavigationEvent
					)
				);
				break;
			}
		}
	}

	private chipObjs(): Array<Partial<IChipSetChip>> {
		const {chips} = this.props;
		return (chips === undefined)
			? []
			: chips;
	}

	private chipsType(): ChipType {
		const {chips} = this.props;
		if (chips) {
			for (const obj of chips) {
				if (obj.isFilter) {
					return ChipType.Filter;
				}
				if (obj.isInput) {
					return ChipType.Input;
				}
			}
		}
		return ChipType.Action;
	}

	componentDidMount() {
		this.ctrl.destroy();
		this.ctrl = new MDCChipSetFoundation(
			this.mdcAdapter(),
		);
		this.ctrl.init();
	}

	componentWillUnmount() {
		this.ctrl.destroy();
	}

	private mdcAdapter(): MDCChipSetAdapter {
		return {
			announceMessage: (message: string) => {
				console.log('ChipSet::announceMessage: NOT IMPLEMENTED. Got:', message);
			},
			emitEvent: <D extends object>(eventName: MDCChipSetEvents, eventDetail: D) => {
				const {onEvent} = this.props;
				if (!onEvent) {
					return;
				}
				let typ: ChipSetEventType;
				switch (eventName) {
					case MDCChipSetEvents.INTERACTION: {
						typ = ChipSetEventType.Interaction;
						break;
					}
					case MDCChipSetEvents.REMOVAL: {
						typ = ChipSetEventType.Removal;
						break;
					}
					case MDCChipSetEvents.SELECTION: {
						typ = ChipSetEventType.Selection;
						break;
					}
				}
				const data: Partial<IGoogleSucksAtSoftwareChipSetEventData> = {};
				if (objIsRemEvtObj(eventDetail)) {
					data.chipId = eventDetail.chipID;
					data.chipIndex = eventDetail.chipIndex;
					data.isComplete = eventDetail.isComplete;
				} else if (objIsSelEvtObj(eventDetail)) {
					data.chipId = eventDetail.chipID;
					data.chipIndex = eventDetail.chipIndex;
					data.isSelected = eventDetail.isSelected;
				} else if (objIsInterEvtObj(eventDetail)) {
					data.chipId = eventDetail.chipID;
					data.chipIndex = eventDetail.chipIndex;
				} else {
					console.log('ChipSet::emitEvent: Invalid data. Got:', eventName, eventDetail);
					return;
				}
				const {lastClickCoords} = this.state;
				data.clickCoords = {
					...lastClickCoords,
				};
				onEvent(typ, data);
			},
			getAttribute: (attrName: MDCChipSetAttributes) => {
				const root = this.rootRef.current;
				if (root) {
					return root.getAttribute(attrName);
				}
				return null;
			},
			getChipActionsAtIndex: (index: number) => {
				const obj = this.chipComp(index);
				if (obj) {
					return obj.actionsTypes();
				}
				return [];
			},
			getChipCount: () => {
				return this.chipRefs.length;
			},
			getChipIdAtIndex: (index: number) => {
				const obj = this.chipComp(index);
				if (obj) {
					return obj.id();
				}
				return '';
			},
			getChipIndexById: (chipID: string) => {
				for (let i = 0; i < this.chipRefs.length; ++i) {
					const obj = this.chipRefs[i].current;
					if (obj && (obj.id() === chipID)) {
						return i;
					}
				}
				return -1;
			},
			isChipFocusableAtIndex: (index: number, actionType: MDCChipActionType) => {
				const obj = this.chipComp(index);
				if (obj) {
					return obj.isActionFocusable(actionType);
				}
				return false;
			},
			isChipSelectableAtIndex: (index: number, actionType: MDCChipActionType) => {
				const obj = this.chipComp(index);
				return obj
					? obj.isActionSelectable(actionType)
					: false;
			},
			isChipSelectedAtIndex: (index: number, actionType: MDCChipActionType) => {
				const obj = this.chipComp(index);
				return obj
					? obj.isActionSelected(actionType)
					: false;
			},
			removeChipAtIndex: (index: number) => {
				// console.log('ChipSet::removeChipAtIndex: NOT IMPLEMENTED. Got:', index);
			},
			setChipFocusAtIndex: (index: number, action: MDCChipActionType, focus: MDCChipActionFocusBehavior) => {
				const obj = this.chipComp(index);
				if (obj) {
					obj.setActionFocus(action, focus);
				}
			},
			setChipSelectedAtIndex: (index: number, actionType: MDCChipActionType, isSelected: boolean) => {
				// console.log('ChipSet::setChipSelectedAtIndex: NOT IMPLEMENTED. Got:', index, actionType, isSelected);
			},
			startChipAnimationAtIndex: (index: number, animation: MDCChipAnimation) => {
				const obj = this.chipComp(index);
				if (obj) {
					obj.startAnimation(animation);
				}
			},
		};
	}

	render() {
		this.chipRefs = [];
		const {
			children,
			chips,
			className,
			isMultiselectable,
			onEvent,
			...rest
		} = this.props;
		const objs = this.chipObjs();
		const typ = this.chipsType();
		const role = (typ == ChipType.Filter)
			? 'listbox'
			: 'grid';
		const ariaOrient = (typ === ChipType.Filter)
			? 'horizontal'
			: undefined;
		const ariaMulSel = (typ === ChipType.Filter)
			? isMultiselectable
				? 'true'
				: 'false'
			: undefined;
		const clsName = makeClassName(
			'mdc-evolution-chip-set',
			className,
		);
		const comps = [];
		for (let i = 0; i < objs.length; ++i) {
			const obj = objs[i];
			const {
				text,
				...restObj
			} = obj;
			const ref = React.createRef<Chip>();
			this.chipRefs.push(ref);
			comps.push(
				<Chip key={i} onEvent={this.chipEvent} ref={ref} {...restObj}>
					{text}
				</Chip>
			);
		}
		return (
			<div aria-orientation={ariaOrient} aria-multiselectable={ariaMulSel} className={clsName} ref={this.rootRef} role={role} {...rest}>
				<div className="mdc-evolution-chip-set__chips" role="presentation">
					{comps}
				</div>
				{children}
			</div>
		);
	}
}

function objIsInterEvtObj(obj: any): obj is MDCChipSetInteractionEventDetail {
	try {
		return ('chipID' in obj) && ('chipIndex' in obj) && !objIsRemEvtObj(obj) && !objIsSelEvtObj(obj);
	} catch {
		return false;
	}
}

function objIsRemEvtObj(obj: any): obj is MDCChipSetRemovalEventDetail {
	try {
		return 'isComplete' in obj;
	} catch {
		return false;
	}
}

function objIsSelEvtObj(obj: any): obj is MDCChipSetSelectionEventDetail {
	try {
		return 'isSelected' in obj;
	} catch {
		return false;
	}
}
