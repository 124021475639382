import React from 'react';

import {
	bind,
	cssClassName,
	isNumber,
	numberFormat,
	numberStringCmp,
	padEnd,
	secondsToDuration,
} from '../../../util';
import {DataTable} from '../../../components';

export interface IChildrenProps {
	objects: Array<IProduct>;
	onAdd: () => any;
	onClick: (index: number) => any;
	prices?: (id: number) => Array<IPriceGroupProductPrice>;
}

export class Children extends React.Component<Partial<IChildrenProps>, {}> {
	@bind
	ctaClicked(): void {
		const {onAdd} = this.props;
		onAdd && onAdd();
	}

	render(): React.ReactNode {
		const {
			prices,
			objects,
			onClick,
		} = this.props;
		if (objects && objects.length > 0) {
			return (
				<>
					<ChildTable
						objects={objects}
						onClick={onClick}
						prices={prices}
					/>
					<Cta
						className="padding-top--16"
						onClick={this.ctaClicked}
						text="Add variant"
					/>
				</>
			);
		}
		return <Cta
			className="text-align--center"
			onClick={this.ctaClicked}
			text="Add variant"
		/>;
	}
}

interface IChildTableProps {
	objects: Array<IProduct>;
	onClick?: (index: number) => any;
	prices?: (id: number) => Array<IPriceGroupProductPrice>;
}

function formatDecimal(value: string | null): string {
	if (value) {
		const parts = value.trim().split('.', 2);
		const lhs = (parts[0].length) > 0
			? parts[0]
			: '0';
		let rhs = (parts.length === 2)
			? parts[1]
			: '';
		if (rhs.length < 2) {
			rhs = padEnd(rhs, 2, '0');
		}
		return `${lhs}.${rhs}`;
	}
	return '';
}

class ChildTable extends React.Component<IChildTableProps, {}> {
	prices(obj: IProduct): Array<IPriceGroupProductPrice> | undefined {
		const {prices} = this.props;
		if (prices) {
			return prices(obj.id);
		}
		return undefined;
	}

	// render(): React.ReactNode {
	// 	const {objects} = this.props;
	// 	return (
	// 		<DataTable className="width--100-percent">
	// 			<Container>
	// 				<Table>
	// 					<Header>
	// 						<HeaderRow>
	// 							<HeaderCell>
	// 								Name
	// 							</HeaderCell>
	// 							<HeaderCell>
	// 								Description
	// 							</HeaderCell>
	// 							<HeaderCell>
	// 								Pricing
	// 							</HeaderCell>
	// 							<HeaderCell>
	// 								Production Duration
	// 							</HeaderCell>
	// 							<HeaderCell>
	// 								Post-Production Duration
	// 							</HeaderCell>
	// 							<HeaderCell isNumeric>
	// 								Size
	// 							</HeaderCell>
	// 						</HeaderRow>
	// 					</Header>
	// 					<Body>
	// 						{objects.map((obj, i) =>
	// 							<Row
	// 								key={i}
	// 								obj={obj}
	// 								onClick={this.rowClicked.bind(this, i)}
	// 								prices={this.prices(obj)}/>)}
	// 					</Body>
	// 				</Table>
	// 			</Container>
	// 		</DataTable>
	// 	);
	// }

	render(): React.ReactNode {
		const {objects} = this.props;
		const columns = [
			{children: 'Name'},
			{children: 'Description'},
			{children: 'Pricing'},
			{children: 'Production Duration'},
			{children: 'Post-Production Duration'},
			{children: 'Size'},
		];
		const rows = objects.map((obj, idx) => {
			return [
				{
					children: <Name value={obj.name}/>,
					onClick: this.rowClicked.bind(this, idx),
				},
				{
					children: <Description value={obj.description}/>,
					onClick: this.rowClicked.bind(this, idx),
				},
				{
					children: <Pricing prices={this.prices(obj)}/>,
					onClick: this.rowClicked.bind(this, idx),
				},
				{
					children: <Duration value={obj.duration}/>,
					onClick: this.rowClicked.bind(this, idx),
				},
				{
					children: <Duration value={null}/>,
					onClick: this.rowClicked.bind(this, idx),
				},
				{
					children: <Size value={obj.size}/>,
					onClick: this.rowClicked.bind(this, idx),
				},
			];
		});
		return (
			<DataTable
				columns={columns}
				rows={rows}
			/>
		);
	}

	rowClicked(index: number): void {
		const {onClick} = this.props;
		if (onClick) {
			onClick(index);
		}
	}
}

function Name({value}: {value: string}) {
	return (value.trim().length > 0)
		?
		<span>{value}</span>
		:
		<NoValue/>;
}

function Description({value}: {value: string}) {
	return (value.trim().length > 0)
		?
		<span>{value}</span>
		:
		<NoValue/>;
}

function Duration({value}: {value: number | null}) {
	if (isNumber(value)) {
		const {
			days,
			hours,
			minutes,
		} = secondsToDuration(value);
		const suffix = (num: number) => (num === 1)
			? ''
			: 's';
		const parts: string[] = [];
		if (days > 0) {
			parts.push(`${days} day${suffix(days)}`);
		}
		if (hours > 0) {
			parts.push(`${hours} hour${suffix(hours)}`);
		}
		if (minutes > 0) {
			parts.push(`${minutes} minute${suffix(minutes)}`);
		}
		if (parts.length > 0) {
			return <span>{parts.join(' ')}</span>;
		}
	}
	return <NoValue/>;
}

function itemPriceSortKey(a: IPriceGroupProductPrice, b: IPriceGroupProductPrice): number {
	return numberStringCmp(a.price, b.price);
}

function Pricing({prices}: {prices?: IPriceGroupProductPrice[];}) {
	if (prices && (prices.length > 0)) {
		if (prices.length === 1) {
			const value = prices[0].price;
			if (value) {
				return <span>{`$${numberFormat(formatDecimal(value))}`}</span>;
			}
		}
		const sortedPrices = [...prices];
		sortedPrices.sort(itemPriceSortKey);
		const lowestValue = sortedPrices[0].price;
		const highestValue = sortedPrices[sortedPrices.length - 1].price;
		if (lowestValue && highestValue) {
			return <span>{`$${numberFormat(formatDecimal(lowestValue))} ~ $${numberFormat(formatDecimal(highestValue))}`}</span>;
		}
		if (lowestValue || highestValue) {
			return <span>{`$${numberFormat(formatDecimal(lowestValue || highestValue))}`}</span>;
		}
	}
	return <NoValue/>;
}

function Size({value}: {value: number | null}) {
	return isNumber(value)
		?
		<span>{numberFormat(value)} ft<sup>2</sup></span>
		:
		<NoValue/>;
}

function NoValue() {
	return <span className="pb-muted">&mdash;</span>;
}

interface ICtaProps extends React.HTMLAttributes<any> {
	className?: string;
	text: string;
}

export class Cta extends React.Component<ICtaProps, {}> {
	render(): React.ReactNode {
		const {
			children,
			className,
			onClick,
			text,
			...rest
		} = this.props;
		return (
			<div className={cssClassName('pb-cta', className)} {...rest}>
				<button className="mdc-button" onClick={onClick} type="button">
					<span className="mdc-button__ripple"/>
					<span className="mdc-button__label">{text}</span>
				</button>
				{children}
			</div>
		);
	}
}
