import React from 'react';

import {
	Button,
	Card,
	CircularProgress,
	ComboBox,
	Dialog,
	GridLayout,
	GridLayoutCell,
	IButtonProps,
	IMenuOptionProps,
	MessageBox,
	Prompt,
	TextInput,
} from '../../../components';
import {
	AppSlug,
	AppWidgetName,
	DialogCode,
	StandardButton,
} from '../../../constants';
import * as datetime from '../../../datetime';
import {ISchedulerSelectedBlock} from '../../../datetime';
import {api} from '../../../httpapi';
import {
	AppWidgThing,
	appWidgThings,
	assert,
	bind,
	capitalize,
	idxOk,
	makeClassName,
	makeTime,
	staticProfile,
} from '../../../util';
import {ProjectAccessOccupancyView} from './accessoccupancy';
import {DateTime} from './datetime';
import {ProjectFinalReviewView} from './finalreview';
import {ProjectLocationView} from './location';
import {
	CardHeader,
	IOperatingHoursWithDateTimeTime,
	IProjectCreateViewState,
	Step,
} from './misc';
import {ProjectServiceView} from './services';

const TIME_DELTA_MINUTES = 30;
const TIME_DELTA_SECONDS = TIME_DELTA_MINUTES * 60;
const TIME_SEGMENT_STEP_MINUTES = TIME_DELTA_MINUTES;

export interface IProjectCreateViewProps extends React.HTMLAttributes<any> {
}

export class ProjectCreateView extends React.Component<Partial<IProjectCreateViewProps>, IProjectCreateViewState> {
	constructor(props: Partial<IProjectCreateViewProps>) {
		super(props);
		this.state = {
			accessNotes: '',
			accessOrgAppWidgetOptionId: null,
			anytime: false,
			app: {
				name: '',
				slug: '',
				widgets: [],
			},
			busy: false,
			catalog: {products: []},
			clientDueDate: '',
			clientDueTime: '',
			clientUserPk: '',
			clientUsers: [],
			date: null,
			dateAvailability: new Map(),
			duration: 0,
			messages: [],
			name: '',
			notes: '',
			occupancyOrgAppWidgetOptionId: null,
			orgAppWidgets: [],
			pendingTos: [],
			productAddonPks: new Set(),
			productOptionPks: new Set(),
			productPks: new Set(),
			profile: staticProfile(),
			promptIsOpen: false,
			propertyName: '',
			serviceAreaPk: null,
			serviceAreas: [],
			size: null,
			step: Step.SelectProduct,
			street: '',
			summaryData: null,
			time: null,
			visualDate: null,
			weekdayTimeRangeMap: new Map(),
			wx: new Map(),
		};
	}

	@bind
	anyTimeChanged(checked: boolean): void {
		const {
			date,
			time,
			visualDate,
		} = this.state;
		this.setState({
			anytime: checked,
			date: checked
				? visualDate
				: date,
			time: checked
				? null
				: time,
		});
	}

	private appWidgThing(appWidgName: string): AppWidgThing {
		for (const obj of this.appWidgThings()) {
			if (obj.name === appWidgName) {
				return obj;
			}
		}
		return new AppWidgThing();
	}

	private appWidgThings(): Array<AppWidgThing> {
		const {
			app,
			orgAppWidgets,
		} = this.state;
		return appWidgThings(app.widgets, orgAppWidgets);
	}

	@bind
	private backNavClicked(): void {
		const {step} = this.state;
		switch (step) {
			case Step.SelectProduct: {
				return window.location.assign('/');
			}
			case Step.SelectDate: {
				this.setState({
					anytime: false,
					date: null,
					step: Step.SelectProduct,
					time: null,
					visualDate: null,
				});
				break;
			}
			case Step.SelectTime: {
				this.setState({
					step: Step.SelectDate,
					visualDate: null,
				});
				break;
			}
			case Step.FinalReview: {
				this.setState({
					step: Step.SelectTime,
				});
				break;
			}
		}
	}

	@bind
	private changed<K extends keyof IProjectCreateViewState>(name: K, value: IProjectCreateViewState[K]): void {
		this.setState({
			[name]: value,
		} as Pick<IProjectCreateViewState, K>);
	}

	private clearDateAvailability(isoDate: string): void {
		const {dateAvailability} = this.state;
		dateAvailability.delete(isoDate);
		this.setState({
			dateAvailability: new Map(dateAvailability),
		});
	}

	private clientMenuOptions(): Array<IMenuOptionProps> {
		const {
			clientUserPk,
			clientUsers,
		} = this.state;
		return clientUsers.map(obj => {
			return {
				label: obj.displayName,
				value: obj.email,
				isSelected: obj.email === clientUserPk,
			};
		});
	}

	@bind
	private clientSelectionChanged(index: number) {
		const opts = this.clientMenuOptions();
		if ((index >= 0) && (index < opts.length)) {
			const opt = opts[index];
			const cid = opt.value;
			this.setBusy(
				true,
				async () => {
					const cata = await this.productCatalog(cid);
					this.setState({
						catalog: cata,
						clientUserPk: cid,
						productAddonPks: new Set(),
						productPks: new Set(),
						busy: false,
					});
				},
			);
		} else {
			console.log('clientSelectionChanged: Invalid index:', index);
		}
	}

	@bind
	private closePromptDialog(): void {
		if (this.state.promptIsOpen) {
			this.setState({
				promptIsOpen: false,
			});
		}
	}

	@bind
	private async closeTosPromptDialog(result: DialogCode) {
		const {pendingTos} = this.state;
		switch (result) {
			case DialogCode.Accepted: {
				assert(idxOk(0, pendingTos.length, 'closeTosPromptDialog'));
				this.tosAccepted(pendingTos[0].id);
				break;
			}
			case DialogCode.Rejected: {
				break;
			}
			default: {
				throw new Error('Invalid prompt result.');
			}
		}
		this.setState({
			promptIsOpen: false,
		});
	}

	componentDidMount() {
		this.setBusy(
			true,
			async () => {
				const profile = await api.profileDetail();
				const catalog = profile.isProducer
					? this.state.catalog
					: await this.productCatalog();
				this.setState({
					catalog,
					clientUsers: profile.isProducer
						? await api.allClientUsers()
						: this.state.clientUsers,
					profile,
					serviceAreas: await api.allServiceAreas(),
					app: await api.appDetail(AppSlug.ProjectCreator),
					orgAppWidgets: await api.orgAppWidgetList({app: AppSlug.ProjectCreator}),
					busy: false,
				});
			},
		);
	}

	async componentDidUpdate(prevProps: Readonly<Partial<IProjectCreateViewProps>>, prevState: Readonly<IProjectCreateViewState>) {
		const {
			step,
			serviceAreaPk,
		} = this.state;
		if ((prevState.step !== step) && (step === Step.SelectDate)) {
			this.setWeekdayTimeRangeMapIfNotAlready();
			this.setDuration();
			this.setServiceAreaWxDataIfNotAlready(serviceAreaPk);
		}
	}

	private createProject() {
		this.setBusy(
			true,
			async () => {
				let hasPendingTos = false;
				if (!this.state.profile.isProducer) {
					const profile = await api.profileDetail();
					hasPendingTos = profile.pendingTermsOfService.length > 0;
					this.setState({
						profile,
					});
				}
				if (hasPendingTos) {
					this.setState({
						pendingTos: await this.fetchPendingTos(),
						promptIsOpen: true,
						busy: false,
					});
					return;
				}
				const data = this.newProjectData();
				const obj = await api.createProject(data);
				window.location.assign(obj.absoluteUrl);
			},
		);
	}

	@bind
	private dateAvailability(date: datetime.date): Array<IAvailability> {
		const {dateAvailability} = this.state;
		const d = dateAvailability.get(date.isoformat());
		return d
			? d
			: [];
	}

	@bind
	dateChanged(dtDate: datetime.date | null): void {
		const {
			date,
			step,
			time,
			visualDate,
		} = this.state;
		let newVisDate: datetime.date | null = null;
		let newDate: datetime.date | null = date;
		let newStep: Step = step;
		let newTime: datetime.time | null = time;
		if (dtDate === null) {
			newDate = null;
			newTime = null;
			if (visualDate !== null) {
				this.clearDateAvailability(visualDate.isoformat());
			}
		} else {
			this.setDateAvailability(dtDate);
			if (this.dateIsAvailable(dtDate)) {
				newVisDate = dtDate;
				newStep = Step.SelectTime;
			}
		}
		this.setState({
			date: newDate,
			step: newStep,
			time: newTime,
			visualDate: newVisDate,
		});
	}

	@bind
	dateIsAvailable(date: datetime.date): boolean {
		const x = this.timeRangeForWeekday(date.isoweekday());
		return x !== null;
	}

	private dateTimeIsAvailable(dt: datetime.datetime): boolean {
		const {
			duration,
			weekdayTimeRangeMap,
		} = this.state;
		const avail = this.dateAvailability(dt.date());
		let remainingDuration = duration;
		const availStartIdx = avail.findIndex(
			a => ((datetime.datetime.fromisoformat(a.datetime).hour >= dt.hour) && (datetime.datetime.fromisoformat(a.datetime).minute >= dt.minute)),
		);
		if (availStartIdx >= 0) {
			for (let i = availStartIdx; (i < avail.length) && (remainingDuration > 0); ++i) {
				const av = avail[i];
				if (!av.available) {
					return false;
				}
				remainingDuration -= TIME_DELTA_SECONDS;
			}
		}
		const startDt = datetime.datetime.fromisoformat(dt.isoformat());
		const delta = new datetime.timedelta(undefined, duration);
		const endDt = startDt.add(delta);
		const timeRange = weekdayTimeRangeMap.get(startDt.date().isoweekday());
		if ((timeRange === undefined) || (timeRange === null) || ((timeRange.startTime === null) || (timeRange.endTime === null))) {
			return false;
		}
		const targetStart = startDt.time();
		const targetEnd = endDt.time();
		const tgtStart = targetStart.hour * 60 + targetStart.minute;
		const tgtEnd = targetEnd.hour * 60 + targetEnd.minute;
		const early = timeRange.startTime.hour * 60 + timeRange.startTime.minute;
		const late = timeRange.endTime.hour * 60 + timeRange.endTime.minute;
		return (tgtStart >= early) && (tgtEnd <= late);
	}

	private async fetchPendingTos(): Promise<Array<ITermsOfService>> {
		const {profile} = this.state;
		return await api.policyList({
			tos: profile.pendingTermsOfService,
		});
	}

	private openPromptDialog(msgs: Array<string>) {
		this.setState({
			messages: msgs,
			promptIsOpen: true,
		});
	}

	private newProjectData(): INewProject {
		const {
			accessNotes,
			accessOrgAppWidgetOptionId,
			anytime,
			clientDueDate,
			clientDueTime,
			clientUserPk,
			date,
			name,
			notes,
			occupancyOrgAppWidgetOptionId,
			productAddonPks,
			productOptionPks,
			productPks,
			propertyName,
			serviceAreaPk,
			size,
			street,
			time,
		} = this.state;
		return {
			accessNotes,
			accessOrgAppWidgetOptionId,
			anytime,
			clientDueDate: (clientDueDate === '')
				? null
				: clientDueDate,
			clientDueTime: (clientDueTime === '')
				? null
				: clientDueTime,
			clientPk: clientUserPk,
			date: (date === null)
				? null
				: date.isoformat(),
			description: name,
			locationName: propertyName,
			notes,
			occupancyOrgAppWidgetOptionId,
			productAddonPks: Array.from(productAddonPks),
			productOptionPks: Array.from(productOptionPks),
			productPks: Array.from(productPks),
			serviceAreaPk,
			size,
			street,
			time: (time === null)
				? null
				: time.isoformat(),
		};
	}

	@bind
	private primaryButtonClicked(): void {
		const {
			anytime,
			date,
			step,
			time,
		} = this.state;
		let nextViewState: Step = step;
		switch (step) {
			case Step.SelectProduct: {
				if (this.validateStep()) {
					nextViewState = Step.SelectDate;
				}
				break;
			}
			case Step.SelectDate:
			case Step.SelectTime: {
				if ((date !== null) && ((time !== null) || anytime)) {
					nextViewState = Step.FinalReview;
					this.setSummary();
				}
				break;
			}
			case Step.FinalReview: {
				this.createProject();
				break;
			}
		}
		this.setState({
			step: nextViewState,
		});
	}

	private primaryButtonIsDisabled(): boolean {
		const {
			step,
			date,
			time,
			anytime,
		} = this.state;
		switch (step) {
			case Step.SelectProduct: {
				return false;
			}
			case Step.Waiting: {
				return true;
			}
		}
		return (date === null) || ((time === null) && !anytime);
	}

	private product(pk: number): ICatalogProduct | null {
		const {catalog} = this.state;
		for (const obj of catalog.products) {
			if (obj.id === pk) {
				return obj;
			}
		}
		return null;
	}

	private async productCatalog(clientId?: string): Promise<IProductCatalog> {
		return await api.productCatalog({cid: clientId});
	}

	@bind
	private productChildren(pk: number): Array<ICatalogProduct> {
		const obj = this.product(pk);
		if (obj === null) {
			return [];
		}
		const rv: Array<ICatalogProduct> = [];
		for (const childPk of obj.children) {
			const child = this.product(childPk);
			if (child !== null) {
				rv.push(child);
			}
		}
		return rv;
	}

	render() {
		const {
			accessOrgAppWidgetOptionId,
			accessNotes,
			anytime,
			busy,
			catalog,
			clientUsers,
			messages,
			occupancyOrgAppWidgetOptionId,
			pendingTos,
			productAddonPks,
			productPks,
			promptIsOpen,
			propertyName,
			name,
			notes,
			serviceAreaPk,
			serviceAreas,
			size,
			step,
			street,
			summaryData,
			visualDate,
		} = this.state;
		const renderClientSelect = clientUsers.length > 0;
		const smry = (summaryData === null)
			? undefined
			: summaryData;
		const comps: Array<React.ReactNode> = [];
		switch (step) {
			case Step.SelectProduct: {
				comps.push(
					<>
						<CardHeader>
							Project name
						</CardHeader>
						<GridLayout>
							<GridLayoutCell span={12}>
								<TextInput
									className="width--100-percent"
									isOutlined
									label="Project name (optional)"
									style={{marginBottom: '24px'}}
									onChange={val => this.changed('name', val)}
									value={name}
								/>
							</GridLayoutCell>
						</GridLayout>
					</>,
					renderClientSelect
						? (
							<Card>
								<CardHeader stepNumber={0}>
									Select Client
								</CardHeader>
								<GridLayout>
									<GridLayoutCell span={12}>
										<ComboBox
											isRequired
											options={this.clientMenuOptions()}
											onChange={this.clientSelectionChanged}
											className="width--100-percent"
											label="Select a client"
										/>
									</GridLayoutCell>
								</GridLayout>
							</Card>
						)
						: null,
					<ProjectLocationView
						onChange={this.changed}
						propertyName={propertyName}
						serviceAreaPk={serviceAreaPk}
						serviceAreas={serviceAreas}
						street={street}
					/>,
					<ProjectServiceView
						onChange={this.changed}
						productAddonPks={productAddonPks}
						productPks={productPks}
						products={catalog.products}
						size={size}
					/>,
					<ProjectAccessOccupancyView
						accessNotes={accessNotes}
						appWidgThings={this.appWidgThings()}
						onChange={this.changed}
						selectedAccessOptionValue={accessOrgAppWidgetOptionId}
						selectedOccupancyOptionValue={occupancyOrgAppWidgetOptionId}
					/>,
				);
				break;
			}
			case Step.SelectDate:
			case Step.SelectTime: {
				comps.push(
					<DateTime
						currentDate={visualDate}
						currentDateAvailability={this.dateAvailability}
						dateIsAvailable={this.dateIsAvailable}
						onAnyTimeChange={this.anyTimeChanged}
						onBackNav={this.backNavClicked}
						onDateChange={this.dateChanged}
						onTimeChange={this.timeChanged}
						onTimeSelectRequest={this.timeSelectRequested}
						selectedEvent={this.selectedEvent()}
						timeRangeForWeekday={this.timeRangeForWeekday}
						timeSegmentStep={TIME_SEGMENT_STEP_MINUTES}
						weather={this.wx()}
					/>,
				);
				break;
			}
			case Step.FinalReview: {
				comps.push(
					<ProjectFinalReviewView
						anyTime={anytime}
						onChange={this.changed}
						onBackNav={this.backNavClicked}
						productChildren={this.productChildren}
						summaryData={smry}
						city={this.serviceAreaName()}
						street={street}
						notes={notes}
					/>,
				);
				break;
			}
		}
		return (
			<>
				<GridLayout>
					{
						comps.map((comp, idx) => {
							return (
								<GridLayoutCell key={idx} span={12}>
									<Card>
										{comp}
									</Card>
								</GridLayoutCell>
							);
						})
					}
					<GridLayoutCell span={12}>
						<PrimaryButtonBox>
							{
								busy
									? (
										<CircularProgress
											size={1}
										/>
									)
									: (
										<PrimaryButton disabled={this.primaryButtonIsDisabled()} onClick={this.primaryButtonClicked}>
											Submit
										</PrimaryButton>
									)
							}
						</PrimaryButtonBox>
					</GridLayoutCell>
				</GridLayout>
				{/*{*/}
				{/*	(pendingTos.length > 0)*/}
				{/*		? (*/}
				{/*			<Dialog buttons={StandardButton.Accept | StandardButton.Decline} isOpen={promptIsOpen} onFinished={this.closeTosPromptDialog}>*/}
				{/*				<Prompt iconName="policy" header="Terms of Service">*/}
				{/*					<p>{pendingTos[0].text}</p>*/}
				{/*				</Prompt>*/}
				{/*			</Dialog>*/}
				{/*		)*/}
				{/*		: (*/}
				{/*			<Dialog buttons={StandardButton.Ok} isOpen={promptIsOpen} onFinished={this.closePromptDialog}>*/}
				{/*				<Prompt iconName="error" header="Something's missing..." subHeader="Please provide the following">*/}
				{/*					{*/}
				{/*						messages.map((msg, i) => {*/}
				{/*							return (*/}
				{/*								<p key={i}>{msg}</p>*/}
				{/*							);*/}
				{/*						})*/}
				{/*					}*/}
				{/*				</Prompt>*/}
				{/*			</Dialog>*/}
				{/*		)*/}
				{/*}*/}
				{
					(pendingTos.length > 0)
						? (
							<Dialog buttons={StandardButton.Accept | StandardButton.Decline} isOpen={promptIsOpen} onFinished={this.closeTosPromptDialog}>
								<Prompt iconName="policy" header="Terms of Service">
									<p>{pendingTos[0].text}</p>
								</Prompt>
							</Dialog>
						)
						: (
							<MessageBox buttons={StandardButton.Ok} isOpen={promptIsOpen} onFinished={this.closePromptDialog} severity={MessageBox.Severity.Warning} text="Something's missing..." informativeText="Please provide the following">
								{
									messages.map((msg, i) => {
										return (
											<p key={i}>{msg}</p>
										);
									})
								}
							</MessageBox>
						)
				}
			</>
		);
	}

	private selectedEvent(): ISchedulerSelectedBlock | null {
		const {
			duration,
			anytime,
			date: stateDate,
			time: stateTime,
		} = this.state;
		if (stateDate && (stateTime || anytime)) {
			let iso: string = stateDate.isoformat();
			if (stateTime) {
				iso = `${iso}T${stateTime.isoformat()}`;
			}
			const dt = datetime.datetime.fromisoformat(iso);
			return {
				start: dt,
				duration,
				anyTime: anytime,
			};
		}
		return null;
	}

	private serviceAreaName(): string {
		const {
			serviceAreas,
			serviceAreaPk,
		} = this.state;
		for (const obj of serviceAreas) {
			if (obj.id === serviceAreaPk) {
				return obj.name;
			}
		}
		return '';
	}

	private setBusy(busy: boolean, cb?: () => any) {
		this.setState(
			{
				busy,
			},
			cb,
		);
	}

	private setDateAvailability(date: datetime.date) {
		const {
			dateAvailability,
			productPks,
			serviceAreaPk,
		} = this.state;
		if (serviceAreaPk === null) {
			return;
		}
		const timeRange = this.timeRangeForWeekday(date.isoweekday());
		if (timeRange === null) {
			return;
		}
		const delta = `00:${TIME_DELTA_MINUTES}:00`;
		const startTime = timeRange.start;
		const endTime = timeRange.stop;
		const startDateTime = datetime.datetime.combine(date, startTime);
		const endDateTime = datetime.datetime.combine(date, endTime);
		this.setBusy(
			true,
			async () => {
				const data = await api.availabilityList({
					svc: serviceAreaPk,
					start: startDateTime.isoformat(),
					end: endDateTime.isoformat(),
					dx: delta,
					pid: Array.from(productPks),
				});
				dateAvailability.set(
					date.isoformat(),
					data,
				);
				this.setState({
					dateAvailability: new Map(dateAvailability),
					busy: false,
				});
			},
		);
	}

	private setDateTime(dt: datetime.datetime | null, anyTime?: boolean): void {
		let s: Pick<IProjectCreateViewState, 'anytime' | 'date' | 'time'>;
		if (dt) {
			const at = (anyTime === undefined)
				? this.state.anytime
				: anyTime;
			s = {
				anytime: at,
				date: dt.date(),
				time: dt.time(),
			};
		} else {
			s = {
				anytime: false,
				date: null,
				time: null,
			};
		}
		this.setState(s);
	}

	@bind
	private setDuration() {
		const {
			clientUserPk,
			productPks,
			productAddonPks,
			size,
		} = this.state;
		const cid = clientUserPk.length > 0
			? clientUserPk
			: undefined;
		this.setBusy(
			true,
			async () => {
				const duration = await api.durationDetail({
					cid,
					pid: Array.from(productPks),
					aid: Array.from(productAddonPks),
					sz: (size === null)
						? undefined
						: size,
				});
				this.setState({
					duration,
					busy: false,
				});
			},
		);
	}

	private setServiceAreaWxDataIfNotAlready(pk: number | null) {
		const {wx} = this.state;
		if ((pk === null) || wx.has(pk)) {
			return;
		}
		this.setBusy(
			true,
			async () => {
				wx.set(
					pk,
					await api.serviceAreaWxDayList(pk),
				);
				this.setState(
					{
						busy: false,
						wx: new Map(wx),
					},
				);
			},
		);
	}

	@bind
	private setWeekdayTimeRangeMapIfNotAlready() {
		const {weekdayTimeRangeMap} = this.state;
		if (weekdayTimeRangeMap.size > 0) {
			return;
		}
		this.setBusy(
			true,
			async () => {
				this.setState({
					weekdayTimeRangeMap: operatingHoursToWeekdayRangeMap(await api.operatingHours()),
					busy: false,
				});
			},
		);
	}

	@bind
	private setSummary() {
		const {
			anytime,
			clientUserPk,
			date,
			productPks,
			productAddonPks,
			size,
			time,
		} = this.state;
		assert(date !== null, 'No date provided');
		assert((anytime || (time !== null)), 'No time provided');
		const t = anytime
			? datetime.time.fromisoformat('00:00:00')
			: time as datetime.time;
		const dt = datetime.datetime.combine(
			date,
			t,
		);
		this.setBusy(
			true,
			async () => {
				const summaryData = await api.summaryDetail({
					cid: clientUserPk,
					pid: Array.from(productPks),
					aid: Array.from(productAddonPks),
					dt: dt.isoformat(),
					sz: (size === null)
						? undefined
						: size,
				});
				this.setState({
					busy: false,
					summaryData,
				});
			},
		);
	}

	@bind
	private timeChanged(time: datetime.time | null): void {
		const {date} = this.state;
		let newDate: datetime.date | null = date;
		let newTime: datetime.time | null = null;
		if (time !== null) {
			const {visualDate} = this.state;
			if (visualDate !== null) {
				newDate = visualDate;
				newTime = time;
			}
		}
		this.setState({
			anytime: false,
			date: newDate,
			time: newTime,
		});
	}

	@bind
	timeRangeForWeekday(weekday: number): datetime.ITimeRange | null {
		const {weekdayTimeRangeMap} = this.state;
		const rv = weekdayTimeRangeMap.get(weekday);
		if ((rv === undefined) || ((rv.startTime === null) || (rv.endTime === null))) {
			return null;
		}
		return {
			start: rv.startTime,
			stop: rv.endTime,
		};
	}

	@bind
	private timeSelectRequested(dt: datetime.datetime): boolean {
		const dt1 = datetime.datetime.fromisoformat(dt.isoformat());
		const minute = (dt.minute > 0)
			? 0
			: 30;
		const dt2 = datetime.datetime.combine(
			dt.date(),
			makeTime(dt.hour, minute, dt.second),
		);
		if (this.dateTimeIsAvailable(dt1)) {
			if (this.dateTimeIsAvailable(dt2)) {
				return true;
			}
			this.setDateTime(dt1, false);
		} else if (this.dateTimeIsAvailable(dt2)) {
			this.setDateTime(dt2, false);
		}
		return false;
	}

	@bind
	private tosAccepted(pk: number) {
		const {pendingTos} = this.state;
		assert((pendingTos.length > 0) && (pendingTos[0].id === pk));
		this.setBusy(
			true,
			async () => {
				this.setState(
					{
						busy: false,
						pendingTos: await api.tosAgreement(pk),
					},
					() => this.createProject(),
				);
			},
		);
	}

	private validateStep(): boolean {
		const {
			accessNotes,
			accessOrgAppWidgetOptionId,
			clientUserPk,
			occupancyOrgAppWidgetOptionId,
			productPks,
			profile,
			serviceAreaPk,
			serviceAreas,
			size,
			step,
			street,
		} = this.state;
		const msgs: Array<string> = [];
		if (productPks.size === 0) {
			msgs.push('- At least 1 service');
		}
		switch (step) {
			case Step.SelectProduct: {
				if (profile.isProducer && (clientUserPk.length === 0)) {
					msgs.push('- Client');
				}
				if (street.length === 0) {
					msgs.push('- Street address');
				}
				if ((serviceAreaPk === null) && (serviceAreas.length > 0)) {
					msgs.push('- City');
				}
				let selectedProdHasSize = false;
				for (const prodPk of productPks) {
					const prod = this.product(prodPk);
					if ((prod !== null) && ((prod.size !== null) || (prod.sizeMax !== null))) {
						selectedProdHasSize = true;
						break;
					}
				}
				if (selectedProdHasSize && (size === null)) {
					msgs.push('- Estimated Size (slide to select)');
				}
				if (accessOrgAppWidgetOptionId === null) {
					const appWidg = this.appWidgThing(AppWidgetName.Access);
					if (appWidg.isEnabled && appWidg.isRequired) {
						msgs.push(`- ${capitalize(appWidg.label)}`);
					}
				}
				if (accessNotes.trim().length === 0) {
					const appWidg = this.appWidgThing(AppWidgetName.AccessNotes);
					if (appWidg.isEnabled && appWidg.isRequired) {
						msgs.push(`- ${capitalize(appWidg.label)}`);
					}
				}
				if (occupancyOrgAppWidgetOptionId === null) {
					const appWidg = this.appWidgThing(AppWidgetName.Occupancy);
					if (appWidg.isEnabled && appWidg.isRequired) {
						msgs.push(`- ${capitalize(appWidg.label)}`);
					}
				}
				break;
			}
		}
		if (msgs.length > 0) {
			this.openPromptDialog(msgs);
			return false;
		}
		return true;
	}

	private wx(): Array<IWxDay> {
		const {
			serviceAreaPk,
			wx,
		} = this.state;
		if (serviceAreaPk === null) {
			return [];
		}
		const rv = wx.get(serviceAreaPk);
		return (rv === undefined)
			? []
			: rv;
	}
}

function PrimaryButton(props: Partial<IButtonProps>) {
	const {
		children,
		raisedFilled,
		trailingIconName,
		...rest
	} = props;
	const raised = (raisedFilled === undefined)
		? true
		: raisedFilled;
	const iconName = (trailingIconName === undefined)
		? 'chevron_right'
		: trailingIconName;
	return (
		<Button raisedFilled={raised} id="create-form-button" trailingIconName={iconName} {...rest}>
			{children}
		</Button>
	);
}

interface IPrimaryButtonBoxProps extends React.HTMLAttributes<any> {
}

function PrimaryButtonBox(props: Partial<IPrimaryButtonBoxProps>) {
	const {
		children,
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		'primary-button-box',
		className,
	);
	return (
		<div className={clsName} {...rest}>
			{props.children}
		</div>
	);
}

export function operatingHoursToWeekdayRangeMap(hours: Array<IOperatingHours>): Map<number, IOperatingHoursWithDateTimeTime> {
	const rv = new Map<number, IOperatingHoursWithDateTimeTime>();
	for (const obj of hours) {
		const startTime = obj.startTime === null
			? null
			: datetime.time.fromisoformat(obj.startTime);
		const endTime = obj.endTime === null
			? null
			: datetime.time.fromisoformat(obj.endTime);
		rv.set(
			obj.iso8601,
			{
				startTime,
				endTime,
			},
		);
	}
	return rv;
}
