import React from 'react';

import {makeClassName} from '../util';
import {Icon} from './icon';
import {
	IconButton,
	IIconButtonProps,
} from './iconbutton';

export interface IToolButtonProps extends IIconButtonProps {
}

interface IToolButtonState {
}

export class ToolButton extends React.Component<Partial<IToolButtonProps>, IToolButtonState> {
	render() {
		const {
			children,
			className,
			...rest
		} = this.props;
		const clsName = makeClassName(
			'material-icons',
			'mdc-icon-button',
			'pb-tool-button',
			className,
		);
		return (
			<button className={clsName} {...rest}>
				<span className="mdc-icon-button__ripple"/>
				<span className="mdc-icon-button__focus-ring"/>
				<Icon className="pb-tool-button__icon">{children}</Icon>
			</button>
		);
	}
}
