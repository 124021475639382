import React from 'react';

import * as datetime from '../../datetime';
import {
	IMiniFloatingActionButtonProps,
	MiniFloatingActionButton,
} from '../../components';
import {
	makeClassName,
	padStart,
	secondsToDuration,
	to12Hour,
	toPeriod,
} from '../../util';

export interface IAssignmentState {
	isSelected: boolean;
	roleName: string;
	userEmail: string;
}

interface IClientHeaderProps extends IProjectSubSectionContainerProps {
	href?: string;
}

export function ClientHeader(props: IClientHeaderProps) {
	const {
		children,
		className,
		href,
		...rest
	} = props;
	const clsName = makeClassName(
		'darker-blue',
		'client-header',
		className,
	);
	const h = (href === undefined)
		? '#'
		: href;
	return (
		<ProjectSubSectionContainer className={clsName} {...rest}>
			<a className="client-header-text" href={h} rel="noopener" target="_blank">{children}</a>
		</ProjectSubSectionContainer>
	);
}

interface IHeaderProps extends IProjectSectionContainerProps {
	isEditing?: boolean;
	isSaveBtnDisabled?: boolean;
	onBackClick?: () => any;
	onUpdateClick?: () => any;
}

export function Header(props: IHeaderProps) {
	const {
		children,
		className,
		isEditing,
		isSaveBtnDisabled,
		onBackClick,
		onUpdateClick,
		...rest
	} = props;
	const clsName = makeClassName(
		'height--auto',
		'padding-bottom--12',
		className,
	);
	const hdrBtnClsName = isEditing
		? 'updating'
		: undefined;
	return (
		<ProjectSectionContainer className={clsName} {...rest}>
			<div className="project-header">
				<div className="header-row">
					<div className="header-button-container">
						<HeaderButton onClick={onBackClick}>
							arrow_back
						</HeaderButton>
					</div>
					<div className="header-title">
						{children}
					</div>
					<div className="header-button-container">
						<HeaderButton disabled={isSaveBtnDisabled} className={hdrBtnClsName} onClick={onUpdateClick}>
							{
								isEditing
									? 'save'
									: 'edit'
							}
						</HeaderButton>
					</div>
				</div>
			</div>
		</ProjectSectionContainer>
	);
}

export interface IHeaderButtonProps extends IMiniFloatingActionButtonProps {
}

export function HeaderButton(props: IHeaderButtonProps) {
	const {
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		'header-button',
		className,
	);
	return (
		<MiniFloatingActionButton
			className={clsName}
			{...rest}
		/>
	);
}

export interface IProjectFieldContainerProps extends React.HTMLAttributes<any> {
}

export function ProjectFieldContainer(props: IProjectFieldContainerProps) {
	const {
		children,
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		'project-field-container',
		className,
	);
	return (
		<div className={clsName} {...rest}>
			{children}
		</div>
	);
}

export interface IProjectFieldLabelProps extends React.HTMLAttributes<any> {
}

export function ProjectFieldLabel(props: IProjectFieldLabelProps) {
	const {
		children,
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		'project-field-label',
		className,
	);
	return (
		<span className={clsName} {...rest}>
			{children}
		</span>
	);
}

export interface IProjectFieldValueProps extends React.HTMLAttributes<any> {
}

export function ProjectFieldValue(props: IProjectFieldValueProps) {
	const {
		children,
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		'project-field-value',
		className,
	);
	return (
		<span className={clsName} {...rest}>
			{children}
		</span>
	);
}

export interface IProjectSectionContainerProps extends React.HTMLAttributes<any> {
}

export function ProjectSectionContainer(props: IProjectSectionContainerProps) {
	const {
		children,
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		'project-section-container',
		className,
	);
	return (
		<section className={clsName} {...rest}>
			{children}
		</section>
	);
}

export interface IProjectSubSectionContainerProps extends React.HTMLAttributes<any> {
}

export function ProjectSubSectionContainer(props: IProjectSubSectionContainerProps) {
	const {
		children,
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		'project-sub-section-container',
		className,
	);
	return (
		<section className={clsName} {...rest}>
			{children}
		</section>
	);
}

interface IScheduledDateTimeProps extends IProjectSectionContainerProps {
	anytime: boolean;
	date: string;
	duration: string;
	endTime: string;
	startTime: string;
}

export function ScheduledDateTime(props: IScheduledDateTimeProps) {
	const {
		anytime,
		children,
		className,
		date,
		duration,
		endTime,
		startTime,
		...rest
	} = props;
	const clsName = makeClassName(
		anytime
			? 'background-color--pb-blue'
			: 'darker-blue',
		className,
	);
	const timeContainerClsName = anytime
		? 'anytime-container'
		: 'time-container';
	const div1ClsName = anytime
		? 'duration-label'
		: undefined;
	const div2ClsName = anytime
		? 'duration'
		: undefined;
	return (
		<ProjectSectionContainer className={clsName} {...rest}>
			<div className="project-datetime-container">
				<h4 className="date-container">{date}</h4>
				<div className={timeContainerClsName}>
					<div className={div1ClsName}>
						{
							anytime
								? (
									<>
										DURATION <small>est.</small>
									</>
								)
								: (
									<>
										<p className="event-datetime-label">START</p>
										<p className="event-datetime-time">{startTime}</p>
									</>
								)
						}
					</div>
					<div className={div2ClsName}>
						{
							anytime
								? duration
								: (
									<>
										<p className="event-datetime-label">
											END <small>est.</small>
										</p>
										<p className="event-datetime-time">{endTime}</p>
									</>
								)
						}
					</div>
				</div>
			</div>
			{children}
		</ProjectSectionContainer>
	);
}

interface IFormattedScheduledDateTime {
	date: string;
	duration: string;
	startTime: string;
	endTime: string;
}

export function formattedScheduledDateTime(event: IEvent): IFormattedScheduledDateTime {
	let date: string = '';
	let duration: string = '';
	let startTime: string = '';
	let endTime: string = '';
	if (event.dt) {
		if (event.dt.lower) {
			const lowerDt = datetime.datetime.fromisoformat(event.dt.lower);
			date = `${lowerDt.weekdayName()}, ${lowerDt.monthName()} ${lowerDt.day}`;
			startTime = `${to12Hour(lowerDt.hour)}:${padStart(lowerDt.minute, 2, '0')} ${toPeriod(lowerDt.hour)}`;
		}
		if (event.dt.upper) {
			const upperDt = datetime.datetime.fromisoformat(event.dt.upper);
			endTime = `${to12Hour(upperDt.hour)}:${padStart(upperDt.minute, 2, '0')} ${toPeriod(upperDt.hour)}`;
		}
		if (event.dt.lower && event.dt.upper) {
			const lowerDt = datetime.datetime.fromisoformat(event.dt.lower);
			const upperDt = datetime.datetime.fromisoformat(event.dt.upper);
			const td = upperDt.sub(lowerDt);
			const d = secondsToDuration(td.totalSeconds());
			const parts: Array<string> = [];
			if (d.days > 0) {
				const s = d.days === 1
					? 'DAY'
					: 'DAYS';
				parts.push(`${d.days} ${s}`);
			}
			if (d.hours > 0) {
				parts.push(`${d.hours} HR`);
			}
			if (d.minutes > 0) {
				parts.push(`${d.minutes} MIN`);
			}
			duration = parts.join(' ');
		}
	}
	return {
		date,
		duration,
		startTime,
		endTime,
	};
}
