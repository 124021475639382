import React from 'react';

import {Button} from '../../../components';

export interface IConfirmDeleteProps extends Omit<React.HTMLAttributes<any>, 'onClick'> {
	onClick: (res: boolean) => any;
}

export function ConfirmDelete(props: Partial<IConfirmDeleteProps>) {
	const {
		children,
		onClick,
		...rest
	} = props;
	return (
		<div className="display--flex flex-direction--column">
			<div className="align-self--center" {...rest}>
				<div className="text-align--center">
					{children}
				</div>
				<div>
					<Button onClick={() => onClick && onClick(false)}>
						Keep
					</Button>
					<Button className="pb-button--color-danger" onClick={() => onClick && onClick(true)}>
						Delete
					</Button>
				</div>
			</div>
		</div>
	);
}
