import React from 'react';

import {
	bind,
	staticNewUser,
} from '../../util';
import {UserForm} from './form';
import {
	CCardControls,
	EditCardSectionRow,
	CCardRowActionSet,
	CCardTextInputControl,
	ICCardProps,
} from '../../components';

export interface IUserCreateProps extends Partial<Omit<ICCardProps, 'onChange'>> {
	objCreator: (obj: INewUser) => Promise<IExpandedUser>;
	onCancel?: () => any;
	onDone?: () => any;
	organizations: Array<IOrganization>;
	userClasses: Array<IUserClass>;
}

interface IUserCreateState {
	obj: INewUser;
}

export class UserCreate extends React.Component<IUserCreateProps, IUserCreateState> {
	constructor(props: IUserCreateProps) {
		super(props);
		this.state = {
			obj: staticNewUser(),
		};
	}

	@bind
	private canceled(): void {
		const {onCancel} = this.props;
		this.setState(
			{
				obj: staticNewUser(),
			},
			() => {
				if (onCancel) {
					onCancel();
				}
			},
		);
	}

	@bind
	private changed<K extends keyof INewUser>(value: INewUser[K], name: K): void {
		this.setState({
			obj: {
				...this.state.obj,
				[name]: value,
			},
		});
	}

	private async createObj(obj: INewUser): Promise<IExpandedUser> {
		return await this.props.objCreator(obj);
	}

	render() {
		const {
			objCreator,
			onCancel,
			onDone,
			userClasses,
			...rest
		} = this.props;
		const {obj} = this.state;
		const uc = (userClasses === undefined)
			? []
			: userClasses;
		const pw = (
			<EditCardSectionRow itemIconName="password">
				<CCardControls>
					<CCardTextInputControl
						type="password"
						label="Password"
						value={obj.password}
						onChange={val => this.changed(val, 'password')}
					/>
					<CCardTextInputControl
						type="password"
						label="Confirm password"
						value={obj.passwordConfirm}
						onChange={val => this.changed(val, 'passwordConfirm')}
					/>
				</CCardControls>
				<CCardRowActionSet/>
			</EditCardSectionRow>
		);
		return (
			<UserForm
				obj={obj}
				onChange={this.changed}
				onSave={this.submitted}
				onCancel={this.canceled}
				pwFields={pw}
				title="Create user"
				userClasses={uc}
				{...rest}
			/>
		);
	}

	@bind
	private async submitted() {
		const {onDone} = this.props;
		const {obj} = this.state;
		const newObj = await this.createObj(obj);
		this.setState(
			{
				obj: {
					...newObj,
					password: '',
					passwordConfirm: '',
				},
			},
			() => {
				if (onDone) {
					onDone();
				}
			},
		);
	}
}

