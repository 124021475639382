import React from 'react';

import {makeClassName} from '../../util';
import {IconButton} from '../iconbutton';

const MINI_DATE_PICKER_TEXT = 'Need to schedule a bit further into the future? (weather permitting)';

interface IDateInputProps extends Omit<React.LabelHTMLAttributes<any>, 'onChange'> {
	onChange: (value: string) => any;
	value: string;
}

function DateInput(props: Partial<IDateInputProps>) {
	const {
		className,
		onChange,
		value,
		...rest
	} = props;
	const clsName = makeClassName(
		'outline--none',
		className,
	);
	return (
		<label {...rest}>
			<input
				className={clsName}
				onChange={onChange && (evt => onChange(evt.target.value))}
				type="date"
				value={value}
			/>
		</label>
	);
}

export interface IDatePickerProps extends Omit<React.HTMLAttributes<any>, 'onChange'> {
	inputValue: string;
	isSelected: boolean;
	onChange: (value: string) => any;
	onForwardClick: () => any;
}

export function DatePicker(props: Partial<IDatePickerProps>) {
	const {
		children,
		className,
		inputValue,
		isSelected,
		onChange,
		onForwardClick,
		...rest
	} = props;
	const btnDisabled = ((inputValue === undefined) || (inputValue.length === 0));
	const btnClsName = btnDisabled
		? undefined
		: 'pb-nudge-anim';
	const clsName = makeClassName(
		'display--flex',
		'flex-direction--column',
		'padding-top--16',
		className,
	);
	const inpClsName = makeClassName(
		isSelected
			? 'pb-project-create-input--selected'
			: undefined,
	);
	return (
		<div className={clsName} {...rest}>
			<small className="display--flex color--grayish">
				{MINI_DATE_PICKER_TEXT}
			</small>
			<div className="display--flex flex-direction--row padding-top--8 align-items--center">
				<DateInput
					className={inpClsName}
					onChange={onChange}
					value={inputValue}
				/>
				<IconButton className={btnClsName} disabled={btnDisabled} onClick={onForwardClick} style={{marginLeft: '4px'}}>
					forward
				</IconButton>
				{children}
			</div>
		</div>
	);
}
