import React from 'react';

import {bind} from '../../util';
import {
	IListViewProps,
	ListView,
} from '../list';

export interface IServiceAreaListProps extends Omit<IListViewProps, 'columnsDraggable' | 'columnSelectionEnabled' | 'onCellClick' | 'tableLabel'> {
	onObjectClick: (pk: number) => any;
}

interface IServiceAreaListState {
}

export class ServiceAreaList extends React.Component<IServiceAreaListProps, IServiceAreaListState> {
	private readonly listViewRef: React.RefObject<ListView>;

	constructor(props: IServiceAreaListProps) {
		super(props);
		this.listViewRef = React.createRef();
	}

	@bind
	private cellClicked(obj: IServiceArea) {
		this.props.onObjectClick(obj.id);
	}

	@bind
	async refreshPage(params?: Partial<IPageParams>, cb?: () => any): Promise<void> {
		const curr = this.listViewRef.current;
		if (curr) {
			await curr.refreshPage(params, cb);
		}
	}

	render() {
		const {
			onObjectClick,
			...rest
		} = this.props;
		return (
			<ListView
				columnMenuEnabled
				columnsMovable
				onCellClick={this.cellClicked}
				ref={this.listViewRef}
				{...rest}
			/>
		);
	}
}
