import React from 'react';

import {
	bind,
	makeClassName,
} from '../../util';
import {CssClassName} from './constants';
import {
	ListItemCenterDetail,
	ListItemGraphic,
	ListItemMeta,
} from './misc';
import {Checkbox} from '../checkbox';
import {CheckState} from '../../constants';
import {ListItemText} from './text';
import {Icon} from '../icon';

export interface IListItemBaseProps extends Omit<React.LiHTMLAttributes<any>, 'onKeyPress' | 'onKeyPressCapture'> {
	afterText: React.ReactNode;
	centerDetail?: React.ReactNode;
	graphic: React.ReactNode;
	isSelected: boolean;
	isClickable: boolean;
	leadingIconName: string;
	meta: React.ReactNode;
	noRipple: boolean;
	onCheckboxChange: (state: CheckState) => any;
	onKeyPress: (key: string) => any;
	trailingIconName: string;
	withCheckbox: boolean;
}

export class ListItemBase extends React.Component<Partial<IListItemBaseProps>, {}> {
	@bind
	private keyPressed(event: React.KeyboardEvent): void {
		const {onKeyPress} = this.props;
		if (onKeyPress) {
			onKeyPress(event.key);
		}
	}

	render() {
		const {
			afterText,
			centerDetail,
			children,
			className,
			graphic,
			isSelected,
			isClickable,
			leadingIconName,
			meta,
			noRipple,
			onCheckboxChange,
			onKeyPress,
			tabIndex,
			trailingIconName,
			withCheckbox,
			...rest
		} = this.props;
		let ariaSelected: boolean | undefined = undefined;
		let tabIdx: number | undefined = tabIndex;
		if (isSelected) {
			ariaSelected = true;
			if (tabIdx === undefined) {
				tabIdx = 0;
			}
		} else if (isSelected !== undefined) {
			ariaSelected = false;
			if (tabIdx === undefined) {
				tabIdx = -1;
			}
		}
		const clsName = makeClassName(
			CssClassName.ListItem,
			isSelected
				? CssClassName.ListItemSelected
				: undefined,
			isClickable
				? CssClassName.ListItemClickable
				: undefined,
			className,
		);
		const inner = (
			<>
				{
					noRipple
						? null
						: (
							<span className={CssClassName.ListItemRipple}/>
						)
				}
				{
					withCheckbox
						? (
							<ListItemGraphic>
								<Checkbox
									isChecked={isSelected}
									onChange={onCheckboxChange}
								/>
							</ListItemGraphic>
						)
						: null
				}
				{
					(graphic === undefined)
						? null
						: (
							<ListItemGraphic>
								{graphic}
							</ListItemGraphic>
						)
				}
				{
					(leadingIconName === undefined)
						? null
						: (
							<ListItemGraphic>
								<Icon>{leadingIconName}</Icon>
							</ListItemGraphic>
						)
				}
				{children}
				{
					(centerDetail === undefined)
						? null
						: (
							<ListItemCenterDetail>
								{centerDetail}
							</ListItemCenterDetail>
						)
				}
				{afterText}
				{
					(meta === undefined)
						? null
						: (
							<ListItemMeta>
								{meta}
							</ListItemMeta>
						)
				}
				{
					(trailingIconName === undefined)
						? null
						: (
							<ListItemMeta>
								<Icon>{trailingIconName}</Icon>
							</ListItemMeta>
						)
				}
			</>
		);
		return (
			<li aria-selected={ariaSelected} className={clsName} onKeyDown={this.keyPressed} tabIndex={tabIdx} {...rest}>
				{
					withCheckbox
						? (
							<label>
								{inner}
							</label>
						)
						: inner
				}
			</li>
		);
	}
}

export interface IListItemProps extends IListItemBaseProps {
}

interface IListItemState {
}

export class ListItem extends React.Component<Partial<IListItemProps>, IListItemState> {
	render() {
		const {
			children,
			...rest
		} = this.props;
		return (
			<ListItemBase {...rest}>
				<ListItemText>
					{children}
				</ListItemText>
			</ListItemBase>
		);
	}
}
