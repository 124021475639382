import React from 'react';

import {
	CCard,
	CCardAvatar,
	CCardContent,
	CCardDescribe,
	CCardHeader,
	CCardIntro,
	CCardName,
	CCardNameTitle,
	CCardSection,
	CCardTitle,
	CCardToolbar,
	CCardToolbarAction,
	IListItemProps,
	List,
	ListItem,
} from '../../components';

export interface IWidgetCardProps extends React.HTMLAttributes<any> {
	onToolbarAction: (axn: CCardToolbarAction, coords: PlainCoords) => any;
	widget: IAppWidget;
	orgAppWidgetOptions: Array<IOrgAppWidgetOption>;
}

export function WidgetCard(props: IWidgetCardProps) {
	const {
		onToolbarAction,
		orgAppWidgetOptions,
		widget,
	} = props;
	return (
		<CCard>
			<CCardHeader>
				<CCardIntro>
					<CCardAvatar>widgets</CCardAvatar>
					<CCardDescribe>
						<CCardNameTitle>
							<CCardName>{widget.label}</CCardName>
							<CCardTitle>{appWidgetTypeDisplay(widget)}</CCardTitle>
						</CCardNameTitle>
					</CCardDescribe>
				</CCardIntro>
				<CCardToolbar
					onAction={onToolbarAction}
				/>
			</CCardHeader>
			<CCardContent>
				{
					orgAppWidgetOptions.length > 0
						? (
							<CCardSection headerText="Options">
								<List>
									{
										orgAppWidgetOptions.map((opt, idx) => {
											return (
												<OrgAppWidgetOptionListItem
													key={idx}
													obj={opt}
												/>
											);
										})
									}
								</List>
							</CCardSection>
						)
						: null
				}
			</CCardContent>
		</CCard>
	);
}

interface IOrgAppWidgetOptionListItemProps extends Partial<IListItemProps> {
	obj: IOrgAppWidgetOption;
}

function OrgAppWidgetOptionListItem(props: IOrgAppWidgetOptionListItemProps) {
	const {
		obj,
		...rest
	} = props;
	return (
		<ListItem noRipple {...rest}>
			{obj.label}
		</ListItem>
	);
}

function appWidgetTypeDisplay(widg: IAppWidget): string {
	if (widg.widgetInputType === null) {
		return '';
	}
	return widg.widgetInputType.label;
}
