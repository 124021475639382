import React from 'react';

import {
	bind,
	staticOrganization,
} from '../../util';
import {
	Button,
	CCard,
	CCardAvatar,
	CCardDescribe,
	CCardSection,
	CCardHeader,
	CCardIntro,
	CCardName,
	CCardNameTitle,
	CCardContent,
	CCardBottom,
	ICCardProps,
} from '../../components';

export interface IOrgDeleteProps extends Omit<ICCardProps, 'onChange'> {
	objDeleter: (pk: number | string) => Promise<any>;
	objGetter: (pk: number | string) => Promise<IOrganization>;
	onCancel: () => any;
	onDone: () => any;
	pk: number;
}

interface IOrgDeleteState {
	obj: IOrganization;
}

export class OrgDelete extends React.Component<IOrgDeleteProps, IOrgDeleteState> {
	constructor(props: IOrgDeleteProps) {
		super(props);
		this.state = {
			obj: staticOrganization(),
		};
	}

	@bind
	private canceled(): void {
		const {onCancel} = this.props;
		if (onCancel) {
			onCancel();
		}
	}

	async componentDidMount() {
		const {
			objGetter,
			pk,
		} = this.props;
		this.setState({
			obj: await objGetter(pk),
		});
	}

	@bind
	private async deleteObj(pk: number | string): Promise<void> {
		const {objDeleter} = this.props;
		return await objDeleter(pk);
	}

	render() {
		const {
			objDeleter,
			objGetter,
			onCancel,
			onDone,
			pk,
			...rest
		} = this.props;
		const {
			obj,
		} = this.state;
		return (
			<CCard {...rest}>
				<CCardHeader>
					<CCardIntro>
						<CCardAvatar>
							groups
						</CCardAvatar>
						<CCardDescribe>
							<CCardNameTitle>
								<CCardName>
									{obj.name}
								</CCardName>
							</CCardNameTitle>
						</CCardDescribe>
					</CCardIntro>
				</CCardHeader>
				<CCardContent>
					<CCardSection>
						Delete this organization?
					</CCardSection>
				</CCardContent>
				<CCardBottom {...rest}>
					<Button value="cancel" raisedFilled onClick={this.canceled}>Cancel</Button>
					<Button value="delete" onClick={this.submitted}>Delete</Button>
				</CCardBottom>
			</CCard>
		);
	}

	@bind
	private async submitted() {
		const {onDone} = this.props;
		const {obj} = this.state;
		await this.deleteObj(obj.id);
		this.setState(
			{
				obj: staticOrganization(),
			},
			() => {
				if (onDone) {
					onDone();
				}
			},
		);
	}
}
