import React from 'react';
import {ProductUserRole} from '../../constants';

import {bind, staticAlternateEmail, staticExpandedUser, staticNote, staticPhoneNumber, staticProductUser} from '../../util';
import {UserForm} from './form';
import {ICCardProps} from '../../components';

interface IUserUpdatePropsBase extends Omit<ICCardProps, 'onChange'> {
	onCancel: () => any;
	onDone: () => any;
}

export interface IUserUpdateProps extends Partial<IUserUpdatePropsBase> {
	objGetter: (pk: string) => Promise<IExpandedUser>;
	objUpdater: (obj: IExpandedUser) => Promise<IExpandedUser>;
	organizations: Array<IOrganization>;
	pk: string;
	products?: Array<IProduct>;
	productUserListGetter?: (pk: string) => Promise<Array<IProductUser>>;
	productUserListUpdater?: (objPk: string, prodUserObjs: Array<IProductUser>) => Promise<Array<IProductUser>>;
	serviceAreas?: Array<IServiceArea>;
	userClasses: Array<IUserClass>;
}

interface IUserUpdateState {
	obj: IExpandedUser;
	productUsers: Array<IProductUser>;
	awaitingObj: boolean;
}

export class UserUpdate extends React.Component<IUserUpdateProps, IUserUpdateState> {
	constructor(props: IUserUpdateProps) {
		super(props);
		this.state = {
			obj: staticExpandedUser(),
			productUsers: [],
			awaitingObj: true,
		};
	}

	@bind
	private canceled(): void {
		const {onCancel} = this.props;
		this.setState(
			{
				obj: staticExpandedUser(),
			},
			() => {
				if (onCancel) {
					onCancel();
				}
			}
		);
	}

	@bind
	private changed<K extends keyof INewUser>(value: INewUser[K], name: K, cb?: () => any): void {
		switch (name) {
			case 'alternateEmailAddresses': {
				if ((value as INewUser['alternateEmailAddresses']).length === 0) {
					(value as INewUser['alternateEmailAddresses']).push(
						staticAlternateEmail(),
					);
				}
				break;
			}
			case 'phoneNumbers': {
				if ((value as INewUser['phoneNumbers']).length === 0) {
					(value as INewUser['phoneNumbers']).push(
						staticPhoneNumber(),
					);
				}
				break;
			}
			case 'notes': {
				if ((value as INewUser['notes']).length === 0) {
					(value as INewUser['notes']).push(
						staticNote(),
					);
				}
				break;
			}
		}
		const newObj = {
			...this.state.obj,
			[name]: value,
		};
		this.setState(
			{
				obj: newObj,
			},
			cb,
		);
	}

	async componentDidMount() {
		const {
			objGetter,
			productUserListGetter,
			pk,
		} = this.props;
		const productUsers = (productUserListGetter === undefined)
			? []
			: await productUserListGetter(pk);
		const obj = await objGetter(pk);
		if (obj.alternateEmailAddresses.length === 0) {
			obj.alternateEmailAddresses.push(
				staticAlternateEmail(),
			);
		}
		if (obj.notes.length === 0) {
			obj.notes.push(
				staticNote(),
			);
		}
		if (obj.phoneNumbers.length === 0) {
			obj.phoneNumbers.push(
				staticPhoneNumber(),
			);
		}
		this.setState({
			obj,
			productUsers,
			awaitingObj: false,
		});
	}

	@bind
	private productUserChanged(productPk: number, role: ProductUserRole, isChecked: boolean) {
		const {
			obj,
			productUsers,
		} = this.state;
		const idx = productUsers.findIndex(
			x => ((x.roleId === role) && (x.productId === productPk))
		);
		let newProdUsers: Array<IProductUser>;
		if (isChecked) {
			// Add ProductUser
			if (idx >= 0) {
				console.log('productUserChanged: isChecked is true when object exists.');
				return;
			}
			const newObj: IProductUser = {
				...staticProductUser(),
				productId: productPk,
				userId: obj.email,
				roleId: role,
			};
			newProdUsers = [
				...productUsers,
				newObj,
			];
		} else {
			// Remove ProductUser
			if (idx === -1) {
				console.log('productUserChanged: isChecked is false when object does not exist.');
				return;
			}
			newProdUsers = [
				...productUsers,
			];
			newProdUsers.splice(idx, 1);
		}
		this.setState({
			productUsers: newProdUsers,
		});
	}

	render() {
		const {
			onCancel,
			onDone,
			objGetter,
			objUpdater,
			organizations,
			products,
			productUserListGetter,
			productUserListUpdater,
			serviceAreas,
			userClasses,
			...rest
		} = this.props;
		const {
			obj,
			awaitingObj,
			productUsers,
		} = this.state;
		if (awaitingObj) {
			return null;
		}
		const uc = (userClasses === undefined)
			? []
			: userClasses;
		return (
			<UserForm
				accountEmailAddrIsReadOnly
				obj={obj}
				onCancel={this.canceled}
				onChange={this.changed}
				onProductUserCheckChange={this.productUserChanged}
				onSave={this.submitted}
				organizations={organizations}
				productUsers={productUsers}
				products={products}
				renderChips
				serviceAreas={serviceAreas}
				title="Update user"
				userClasses={uc}
				{...rest}
			/>
		);
	}

	@bind
	private async submitted() {
		const {
			onDone,
			productUserListUpdater,
		} = this.props;
		const {
			obj,
			productUsers,
		} = this.state;
		const newObj = await this.updateObj({
			...obj,
			alternateEmailAddresses: obj.alternateEmailAddresses.filter(keepObj),
			notes: obj.notes.filter(keepObj),
			phoneNumbers: obj.phoneNumbers.filter(keepObj),
		});
		let prodUsers = [
			...productUsers,
		];
		if (productUserListUpdater !== undefined) {
			prodUsers = await productUserListUpdater(
				newObj.email,
				productUsers,
			);
		}
		this.setState(
			{
				obj: newObj,
				productUsers: prodUsers,
			},
			() => {
				if (onDone) {
					onDone();
				}
			}
		);
	}

	private async updateObj(obj: IExpandedUser): Promise<IExpandedUser> {
		return await this.props.objUpdater(obj);
	}
}

function keepObj(obj: IEmailAddress | INote | IPhoneNumber): boolean {
	let id: number | null = obj.id;
	let val: string;
	if (isEmailAddress(obj)) {
		val = obj.address;
	} else if (isPhoneNumber(obj)) {
		val = obj.number;
	} else {
		val = obj.text;
	}
	val = val.trim();
	if ((id === null) || (id <= 0)) {
		return val.length > 0;
	}
	return true;
}

function isEmailAddress(obj: any): obj is IEmailAddress {
	try {
		return 'address' in obj;
	} catch {
		return false;
	}
}

function isPhoneNumber(obj: any): obj is IPhoneNumber {
	try {
		return 'number' in obj;
	} catch {
		return false;
	}
}
