import React from 'react';

import {Checkbox} from './checkbox';
import {ComboBox} from './combobox';
import {
	Icon,
	IIconProps,
} from './icon';
import {IconButton} from './iconbutton';
import {
	IMenuOptionProps,
	Menu,
} from './menu';
import {LinearProgress} from './progress';
import {
	CheckState,
	PageNavigation,
	SortOrder,
	UiTableColumnDataType,
} from '../constants';
import {
	bind,
	idxOk,
	makeClassName,
	pixelString,
	range,
} from '../util';

export interface IDataTablePageInfo extends IPageInfo {
	label?: string;
	perPageOptions: Array<IMenuOptionProps>;
}

export type DataTableHeaderCellProps = Omit<IDataTableHeaderCellProps, 'checkbox' | 'isDraggable'>;
export type DataTableCellProps = Omit<IDataTableBodyCellProps, 'checkbox'>;

export interface IDataTableProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onClick'> {
	cellsClickable?: boolean;
	columnMenuEnabled?: boolean;
	columnMenuOptions?: Array<IMenuOptionProps>;
	columns: Array<DataTableHeaderCellProps>;
	columnsMovable?: boolean;
	columnSortOrder?: SortOrder;
	columnsSortable?: boolean;
	headerIsSticky?: boolean;
	onClick?: (row: number, column: number) => any;
	onColumnMenuOptionClick?: (columnIndex: number) => any;
	onColumnMove?: (fromIndex: number, toIndex: number) => any;
	onDeselectAll?: () => any;
	onPaginationPageNav?: (pageNav: PageNavigation) => any;
	onPaginationPerPageOptionChange?: (optionIndex: number) => any;
	onRowSelectionChange?: (checkedRows: Array<number>) => any;
	onSelectAll?: () => any;
	onSortClick?: (columnIndex: number) => any;
	pageInfo?: IDataTablePageInfo;
	rows: Array<Array<DataTableCellProps>>;
	rowsSelectable?: boolean;
	showProgress?: boolean;
	sortedColumnIndex?: number;
}

interface IDataTableState {
	checkedRowIndices: Set<number>;
	columnMenuCoords: PlainCoords;
	columnMenuIsOpen: boolean;
	dragDropIndiCoords: PlainCoords;
	headerCellRects: Array<DOMRect>;
	headerRowIsChecked: boolean;
	isDragging: boolean;
	progressBarStyle: React.CSSProperties | undefined;
}

const rowIdxCol = new Intl.Collator(
	undefined,
	{
		numeric: true,
		usage: 'sort',
	},
);

export class DataTable extends React.Component<IDataTableProps, IDataTableState> {
	private readonly containerRef: React.RefObject<HTMLDivElement>;
	private readonly headerRowRef: React.RefObject<HTMLTableRowElement>;

	constructor(props: IDataTableProps) {
		super(props);
		this.containerRef = React.createRef();
		this.headerRowRef = React.createRef();
		this.state = {
			checkedRowIndices: new Set(),
			columnMenuCoords: {
				x: 0,
				y: 0,
			},
			columnMenuIsOpen: false,
			dragDropIndiCoords: {
				x: 0,
				y: 0,
			},
			headerCellRects: [],
			headerRowIsChecked: false,
			isDragging: false,
			progressBarStyle: undefined,
		};
	}

	@bind
	private cellClicked(row: number, column: number) {
		const {onClick} = this.props;
		if (onClick) {
			onClick(row, column);
		}
	}

	@bind
	private closeColumnMenu(): void {
		const {columnMenuIsOpen} = this.state;
		if (columnMenuIsOpen) {
			this.setState({
				columnMenuIsOpen: false,
			});
		}
	}

	@bind
	private columnMenuButtonClicked(event: React.MouseEvent) {
		this.openColumnMenu(event.clientX, event.clientY);
	}

	@bind
	private columnMenuOptionSelected(index: number): void {
		this.closeColumnMenu();
		const {
			columnMenuOptions,
			onColumnMenuOptionClick,
		} = this.props;
		if ((onColumnMenuOptionClick === undefined) || (columnMenuOptions === undefined)) {
			return;
		}
		if (idxOk(index, columnMenuOptions.length, 'columnMenuOptionSelected')) {
			onColumnMenuOptionClick(index);
		}
	}

	@bind
	private columnSortClicked(index: number) {
		const {
			onSortClick,
			rows,
		} = this.props;
		if (onSortClick && idxOk(index, rows.length, 'columnSortClicked')) {
			onSortClick(index);
		}
	}

	componentDidUpdate(prevProps: Readonly<IDataTableProps>, prevState: Readonly<IDataTableState>) {
		const {
			pageInfo,
			rows,
			showProgress,
		} = this.props;
		if (prevProps.showProgress !== showProgress) {
			let sty: React.CSSProperties | undefined;
			if (showProgress) {
				sty = this.progressBarStyle();
			} else {
				sty = undefined;
			}
			this.setState({
				progressBarStyle: sty,
			});
		}
		const {
			checkedRowIndices,
			headerRowIsChecked,
		} = this.state;
		if (headerRowIsChecked || (checkedRowIndices.size > 0)) {
			const unmatchedRowCount = prevProps.rows.length !== rows.length;
			const unmatchedPageInfo =
				((prevProps.pageInfo === undefined) && (pageInfo !== undefined))
				|| ((pageInfo === undefined) && (prevProps.pageInfo !== undefined));
			const unmatchedPageInfoNumber =
				(prevProps.pageInfo !== undefined)
				&& (pageInfo !== undefined)
				&& (prevProps.pageInfo.number !== pageInfo.number);
			if (unmatchedRowCount || unmatchedPageInfo || unmatchedPageInfoNumber) {
				this.setState(
					{
						headerRowIsChecked: false,
						checkedRowIndices: new Set(),
					},
					this.notifyRowSelectionChange,
				);
			}
		}
	}

	@bind
	private headerCellDragEnd() {
		this.setState({
			isDragging: false,
		});
	}

	@bind
	private headerCellDragOver(index: number, event: React.DragEvent) {
		event.preventDefault();
		event.dataTransfer.dropEffect = 'move';
		const rect = this.state.headerCellRects[index];
		this.setState({
			dragDropIndiCoords: dragIndiCoords(
				event.clientX,
				rect,
			),
		});
	}

	@bind
	private headerCellDragStart(index: number, event: React.DragEvent) {
		this.startDragging(index, event.clientX);
		event.dataTransfer.setData(
			'text/plain',
			String(index),
		);
		event.dataTransfer.dropEffect = 'move';
	}

	@bind
	private headerCellDrop(index: number, event: React.DragEvent) {
		const {onColumnMove} = this.props;
		const {headerCellRects} = this.state;
		event.preventDefault();
		if (onColumnMove === undefined) {
			return;
		}
		const fromIdx = Number.parseInt(event.dataTransfer.getData('text/plain'));
		let toIdx: number;
		const rect = headerCellRects[index];
		const overHalfWay = isOverHalfWay(event.clientX, rect);
		if (fromIdx === index) {
			toIdx = fromIdx;
		} else if (fromIdx < index) {
			toIdx = overHalfWay
				? index
				: index - 1;
		} else {
			toIdx = overHalfWay
				? index + 1
				: index;
		}
		onColumnMove(fromIdx, toIdx);
	}

	@bind
	private headerCheckboxStateChanged(state: CheckState) {
		const {
			onDeselectAll,
			onSelectAll,
			rows,
		} = this.props;
		const checked = state === CheckState.Checked;
		const checkedRows = new Set<number>(
			checked
				? range(rows.length)
				: [],
		);
		const cb = () => {
			const func = checked
				? onSelectAll
				: onDeselectAll;
			if (func) {
				func();
			}
			this.notifyRowSelectionChange();
		};
		this.setState(
			{
				checkedRowIndices: checkedRows,
				headerRowIsChecked: checked,
			},
			cb,
		);
	}

	@bind
	private openColumnMenu(x: number, y: number) {
		this.setState({
			columnMenuCoords: {
				x,
				y,
			},
			columnMenuIsOpen: true,
		});
	}

	private progressBarStyle(): React.CSSProperties | undefined {
		const headerRow = this.headerRowRef.current;
		if (headerRow) {
			const cont = this.containerRef.current;
			if (cont) {
				const headerHeight = headerRow.getBoundingClientRect().height;
				const contHeight = cont.getBoundingClientRect().height;
				return {
					height: pixelString(contHeight - headerHeight),
					top: pixelString(headerHeight),
				};
			}
		}
		return undefined;
	}

	@bind
	private notifyRowSelectionChange(indices?: Iterable<number>) {
		const {onRowSelectionChange} = this.props;
		if (!onRowSelectionChange) {
			return;
		}
		const selectedRows = Array.from(
			indices === undefined
				? this.state.checkedRowIndices
				: indices,
		);
		// NB: This following statement is completely legit. No idea
		//     why TS library defines a, b as only strings.
		// @ts-ignore
		selectedRows.sort(rowIdxCol.compare);
		onRowSelectionChange(selectedRows);
	}

	render() {
		const {
			cellsClickable,
			className,
			columnMenuEnabled,
			columnMenuOptions,
			columns,
			columnsMovable,
			columnSortOrder,
			columnsSortable,
			headerIsSticky,
			onClick,
			onColumnMenuOptionClick,
			onColumnMove,
			onDeselectAll,
			onPaginationPageNav,
			onPaginationPerPageOptionChange,
			onRowSelectionChange,
			onSelectAll,
			onSortClick,
			pageInfo,
			rows,
			rowsSelectable,
			showProgress,
			sortedColumnIndex,
			...rest
		} = this.props;
		const {
			checkedRowIndices,
			columnMenuCoords,
			columnMenuIsOpen,
			dragDropIndiCoords,
			headerRowIsChecked,
			isDragging,
			progressBarStyle,
		} = this.state;
		const clsName = makeClassName(
			'mdc-data-table',
			'pb-data-table',
			headerIsSticky
				? 'mdc-data-table--sticky-header'
				: undefined,
			showProgress
				? 'mdc-data-table--in-progress'
				: undefined,
			className,
		);
		const dragDropArrow = columnsMovable
			? (
				<DragDropIndicator
					isVisible={isDragging}
					x={dragDropIndiCoords.x}
					y={dragDropIndiCoords.y}
				/>
			)
			: null;
		const pag = pageInfo
			? (
				<DataTablePagination
					onNavClick={onPaginationPageNav}
					onPerPageChange={onPaginationPerPageOptionChange}
					pageInfo={pageInfo}
				/>
			)
			: null;
		return (
			<div className={clsName} {...rest}>
				{pag}
				<div className="mdc-data-table__table-container" ref={this.containerRef}>
					{
						columnMenuEnabled
							? (
								<>
									<IconButton className="pb-table-column-select-menu-btn" onClick={this.columnMenuButtonClicked}>
										settings
									</IconButton>
									<Menu
										absolutePosition={columnMenuCoords}
										anchorToBody
										isCompact
										isOpen={columnMenuIsOpen}
										onClose={this.closeColumnMenu}
										onSelection={this.columnMenuOptionSelected}
										options={columnMenuOptions}
									/>
								</>
							)
							: null
					}
					<table className="mdc-data-table__table">
						{
							columns.length > 0
								? (
									<thead>
										<tr className="mdc-data-table__header-row" ref={this.headerRowRef}>
											{
												columns.map((col, idx) => {
													const oc = columnsSortable
														? this.columnSortClicked.bind(this, idx)
														: undefined;
													const comp = (
														<DataTableHeaderCell
															isDraggable={columnsMovable}
															isSortable={columnsSortable}
															isSorted={idx === sortedColumnIndex}
															key={idx}
															onClick={oc}
															onDragEnd={this.headerCellDragEnd}
															onDragOver={this.headerCellDragOver.bind(this, idx)}
															onDragStart={this.headerCellDragStart.bind(this, idx)}
															onDrop={this.headerCellDrop.bind(this, idx)}
															sortOrder={columnSortOrder}
															{...col}
														/>
													);
													if (rowsSelectable && (idx === 0)) {
														const checkbox = (
															<Checkbox
																className="mdc-data-table__header-row-checkbox"
																onChange={this.headerCheckboxStateChanged}
																isChecked={headerRowIsChecked}
															/>
														);
														return (
															<React.Fragment key={idx}>
																<DataTableHeaderCell checkbox={checkbox}/>
																{comp}
															</React.Fragment>
														);
													}
													return comp;
												})
											}
										</tr>
									</thead>
								)
								: null
						}
						<tbody className="mdc-data-table__content">
							{
								rows.map((row, rowIdx) => {
									const rowIsChecked = checkedRowIndices.has(rowIdx);
									return (
										<DataTableBodyRow isChecked={rowIsChecked} key={rowIdx}>
											{
												row.map((cell, cellIdx) => {
													const cb = this.cellClicked.bind(this, rowIdx, cellIdx);
													const comp = (
														<DataTableBodyCell
															isClickable={cellsClickable}
															key={cellIdx}
															onClick={cb}
															{...cell}
														/>
													);
													if (rowsSelectable && (cellIdx === 0)) {
														const checkbox = (
															<Checkbox
																className="mdc-data-table__row-checkbox"
																isChecked={rowIsChecked}
																onChange={this.rowCheckboxStateChanged.bind(this, rowIdx)}
															/>
														);
														return (
															<React.Fragment key={cellIdx}>
																<DataTableBodyCell checkbox={checkbox}/>
																{comp}
															</React.Fragment>
														);
													}
													return comp;
												})
											}
										</DataTableBodyRow>
									);
								})
							}
						</tbody>
					</table>
				</div>
				{pag}
				{dragDropArrow}
				<DataTableProgressBar
					isOpen={showProgress}
					style={progressBarStyle}
				/>
			</div>
		);
	}

	@bind
	private rowCheckboxStateChanged(index: number, state: CheckState) {
		const {rows} = this.props;
		const {checkedRowIndices} = this.state;
		const checked = state === CheckState.Checked;
		let headerRowIsChecked: boolean;
		const checkedIdx = new Set(checkedRowIndices);
		if (checked) {
			checkedIdx.add(index);
			headerRowIsChecked = checkedIdx.size === rows.length;
		} else {
			checkedIdx.delete(index);
			headerRowIsChecked = false;
		}
		this.setState(
			{
				checkedRowIndices: checkedIdx,
				headerRowIsChecked,
			},
			this.notifyRowSelectionChange,
		);
	}

	private startDragging(startIndex: number, clientX: number) {
		const el = this.headerRowRef.current;
		if (el) {
			const {rowsSelectable} = this.props;
			// NB: Account for the first column being used for check boxes.
			let els = Array.from(
				el.querySelectorAll('.mdc-data-table__header-cell'),
			);
			if (rowsSelectable) {
				els = els.slice(1);
			}
			const rects = els.map(x => (x.getBoundingClientRect()));
			const rect = rects[startIndex];
			this.setState({
				headerCellRects: rects,
				isDragging: true,
				dragDropIndiCoords: dragIndiCoords(
					clientX,
					rect,
				),
			});
		}
	}
}

interface IDataTableHeaderCellProps extends React.ThHTMLAttributes<HTMLTableCellElement> {
	checkbox?: React.ReactNode;
	dataType?: string;
	isDraggable?: boolean;
	isSortable?: boolean;
	isSorted?: boolean;
	label?: string;
	sortOrder?: SortOrder;
	labelCentered?: boolean;
}

function DataTableHeaderCell(props: IDataTableHeaderCellProps) {
	const {
		checkbox,
		children,
		className,
		dataType,
		isDraggable,
		isSortable,
		isSorted,
		label,
		labelCentered,
		role,
		scope,
		sortOrder,
		...rest
	} = props;
	const clsName = makeClassName(
		'mdc-data-table__header-cell',
		dataTypeIsNumeric(dataType)
			? 'mdc-data-table__header-cell--numeric'
			: undefined,
		checkbox
			? 'mdc-data-table__header-cell--checkbox'
			: undefined,
		isSortable
			? 'mdc-data-table__header-cell--with-sort'
			: undefined,
		(isSortable && isSorted)
			? 'mdc-data-table__header-cell--sorted'
			: undefined,
		(isSortable && isSorted && (sortOrder === SortOrder.DescendingOrder))
			? 'mdc-data-table__header-cell--sorted-descending'
			: undefined,
		labelCentered
			? 'text-align--center'
			: undefined,
		className,
	);
	const rl = (role === undefined)
		? 'columnheader'
		: role;
	const scp = (scope === undefined)
		? 'col'
		: scope;
	return (
		<th className={clsName} draggable={isDraggable} role={rl} scope={scp} {...rest}>
			{checkbox}
			{
				isSortable
					? (
						<DataTableHeaderCellSortWrap>
							{label}
							{children}
						</DataTableHeaderCellSortWrap>
					)
					: (
						<>
							{label}
							{children}
						</>
					)
			}
		</th>
	);
}

interface IDataTableHeaderCellSortWrapProps extends React.HTMLAttributes<HTMLDivElement> {
}

function DataTableHeaderCellSortWrap(props: IDataTableHeaderCellSortWrapProps) {
	const {
		children,
		className,
		onClick,
		...rest
	} = props;
	const clsName = makeClassName(
		'mdc-data-table__header-cell-wrapper',
		className,
	);
	return (
		<div className={clsName} {...rest}>
			<div className="mdc-data-table__header-cell-label">
				{children}
			</div>
			<IconButton className="mdc-data-table__sort-icon-button" onClick={onClick}>
				arrow_upward
			</IconButton>
		</div>
	);
}

interface IDataTableBodyRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
	isChecked?: boolean;
}

function DataTableBodyRow(props: IDataTableBodyRowProps) {
	const {
		children,
		className,
		isChecked,
		...rest
	} = props;
	const clsName = makeClassName(
		'mdc-data-table__row',
		isChecked
			? 'mdc-data-table__row--selected'
			: undefined,
		className,
	);
	return (
		<tr className={clsName} {...rest}>
			{children}
		</tr>
	);
}

interface IDataTableBodyCellProps extends React.TdHTMLAttributes<HTMLTableCellElement> {
	checkbox?: React.ReactNode;
	dataType?: string;
	href?: string;
	isClickable?: boolean;
	noTruncate?: boolean;
}

function DataTableBodyCell(props: IDataTableBodyCellProps) {
	const {
		checkbox,
		children,
		className,
		dataType,
		href,
		isClickable,
		noTruncate,
		...rest
	} = props;
	const clsName = makeClassName(
		'mdc-data-table__cell',
		dataTypeIsNumeric(dataType)
			? 'mdc-data-table__cell--numeric'
			: undefined,
		checkbox
			? 'mdc-data-table__cell--checkbox'
			: undefined,
		href
			? 'pb-data-table__link-cell'
			: isClickable
				? 'pb-data-table__cell--clickable'
				: undefined,
		className,
	);
	return (
		<td className={clsName} {...rest}>
			{checkbox}
			{
				href
					? (
						<a className="pb-data-table__cell-anchor" href={href}>
							{
								noTruncate
									? children
									: (
										<span className="pb-data-table__cell-child-container">
											{children}
										</span>
									)
							}
						</a>
					)
					: noTruncate
						? children
						: (
							<span className="pb-data-table__cell-child-container">
								{children}
							</span>
						)
			}
		</td>
	);
}

function dataTypeIsNumeric(dataType: string | undefined): boolean {
	switch (dataType) {
		case UiTableColumnDataType.Date:
		case UiTableColumnDataType.Time:
		case UiTableColumnDataType.Datetime:
		case UiTableColumnDataType.Decimal:
		case UiTableColumnDataType.Float:
		case UiTableColumnDataType.Integer: {
			return true;
		}
		default: {
			return false;
		}
	}
}

interface IDataTablePaginationProps extends React.HTMLAttributes<HTMLDivElement> {
	pageInfo: IDataTablePageInfo;
	onNavClick?: (pageNav: PageNavigation) => any;
	onPerPageChange?: (optionIndex: number) => any;
}

function DataTablePagination(props: IDataTablePaginationProps) {
	const {
		className,
		onNavClick,
		onPerPageChange,
		pageInfo,
		...rest
	} = props;
	const clsName = makeClassName(
		'mdc-data-table__pagination',
		className,
	);
	const lbl = (pageInfo.label === undefined)
		? 'Rows per page'
		: pageInfo.label;
	const firstPgCb = onNavClick
		? () => onNavClick(PageNavigation.FirstPage)
		: undefined;
	const prevPgCb = onNavClick
		? () => onNavClick(PageNavigation.PrevPage)
		: undefined;
	const nextPgCb = onNavClick
		? () => onNavClick(PageNavigation.NextPage)
		: undefined;
	const lastPgCb = onNavClick
		? () => onNavClick(PageNavigation.LastPage)
		: undefined;
	return (
		<div className={clsName} {...rest}>
			<div className="mdc-data-table__pagination-trailing">
				<div className="mdc-data-table__pagination-rows-per-page">
					<div className="mdc-data-table__pagination-rows-per-page-label">
						{lbl}
					</div>
					<ComboBox
						className="mdc-data-table__pagination-rows-per-page-select"
						isOutlined
						noLabel
						onChange={onPerPageChange}
						options={pageInfo.perPageOptions}
					/>
				</div>
				<div className="mdc-data-table__pagination-navigation">
					<div className="mdc-data-table__pagination-total">
						{pageInfo.startIndex}-{pageInfo.endIndex} of {pageInfo.count}
					</div>
					<IconButton className="mdc-data-table__pagination-button" disabled={!pageInfo.hasPrevious} onClick={firstPgCb}>
						first_page
					</IconButton>
					<IconButton className="mdc-data-table__pagination-button" disabled={!pageInfo.hasPrevious} onClick={prevPgCb}>
						chevron_left
					</IconButton>
					<IconButton className="mdc-data-table__pagination-button" disabled={!pageInfo.hasNext} onClick={nextPgCb}>
						chevron_right
					</IconButton>
					<IconButton className="mdc-data-table__pagination-button" disabled={!pageInfo.hasNext} onClick={lastPgCb}>
						last_page
					</IconButton>
				</div>
			</div>
		</div>
	);
}

interface IDataTableProgressBarProps extends React.HTMLAttributes<HTMLDivElement> {
	isOpen?: boolean;
}

function DataTableProgressBar(props: IDataTableProgressBarProps) {
	const {
		className,
		isOpen,
		style,
		...rest
	} = props;
	const clsName = makeClassName(
		'mdc-data-table__progress-indicator',
		className,
	);
	return (
		<div className={clsName} {...rest}>
			<div className="mdc-data-table__scrim"/>
			<LinearProgress
				className="mdc-data-table__linear-progress"
				style={style}
				isOpen={isOpen}
			/>
		</div>
	);
}

interface IDragDropIndicatorProps extends Partial<IIconProps> {
	isVisible: boolean;
	x: number;
	y: number;
}

function DragDropIndicator(props: IDragDropIndicatorProps) {
	const {
		isVisible,
		style,
		x,
		y,
		...rest
	} = props;
	const clsName = makeClassName(
		'pb-data-table__column-drag-drop-indicator',
		isVisible
			? 'pb-data-table__column-drag-drop-indicator--visible'
			: undefined,
	);
	const s = {
		left: pixelString(x - 12),
		top: pixelString(y - 12),
	};
	const sty = (style === undefined)
		? s
		: {
			...s,
			...style,
		};
	return (
		<Icon className={clsName} style={sty} {...rest}>
			keyboard_arrow_down
		</Icon>
	);
}

function dragIndiCoords(clientX: number, rect: DOMRect): {x: number; y: number} {
	let x: number;
	let y: number = rect.y;
	if (isOverHalfWay(clientX, rect)) {
		x = rect.x + rect.width;
	} else {
		x = rect.x;
	}
	return {
		x,
		y,
	};
}

function isOverHalfWay(clientX: number, rect: DOMRect): boolean {
	return clientX > rect.x + rect.width / 2;
}
