import React from 'react';

import {Cta} from './children';
import {CheckState} from '../../../constants';
import {bind, isNumber, makeClassName} from '../../../util';
import {
	Checkbox,
	IListItemGraphicProps,
	IListItemProps,
	List,
	ListItem,
	ListItemBase,
	ListItemGraphic,
	ListItemText,
	TextInput,
	ToolButton,
} from '../../../components';

export interface IOptionsProps extends React.HTMLAttributes<any> {
	onAddClick: () => any;
	onDefaultCheck: (index: number, state: CheckState) => any;
	onDeleteClick: (index: number) => any;
	onNameChange: (index: number, value: string) => any;
	options: Array<IProductOption>;
}

export interface IOptionsState {
}

export class Options extends React.Component<Partial<IOptionsProps>, IOptionsState> {
	@bind
	private defaultChecked(index: number, state: CheckState): void {
		const {onDefaultCheck} = this.props;
		if (onDefaultCheck) {
			onDefaultCheck(index, state);
		}
	}

	@bind
	private deleteClicked(index: number): void {
		const {onDeleteClick} = this.props;
		if (onDeleteClick) {
			onDeleteClick(index);
		}
	}

	@bind
	private nameChanged(index: number, value: string): void {
		const {onNameChange} = this.props;
		if (onNameChange) {
			onNameChange(index, value);
		}
	}

	render() {
		const {
			onAddClick,
			options,
		} = this.props;
		const opts = (options === undefined)
			? []
			: options;
		if (!options || options.length === 0) {
			return <Cta
				className="text-align--center"
				onClick={onAddClick}
				text="Add option"
			/>;
		}
		return (
			<>
				{
					(opts.length > 0)
						? (
							<p className="pb-catalog-item-form__subsection-label text-align--right">
								Default selected
							</p>
						)
						: null
				}
				<List isCompact className="pb-product-detail-obj-list pb-product-option-list" role="group">
					{
						opts.map((obj, idx) => {
							return (
								<Option
									key={idx}
									option={obj}
									onNameChange={this.nameChanged.bind(this, idx)}
									onDeleteClick={this.deleteClicked.bind(this, idx)}
									onDefaultCheck={this.defaultChecked.bind(this, idx)}
								/>
							);
						})
					}
					<AddButton noRipple onClick={onAddClick}/>
				</List>
			</>
		);
	}
}

interface IOptionProps extends IListItemProps {
	onDefaultCheck: (state: CheckState) => any;
	onDeleteClick: () => any;
	onNameChange: (value: string) => any;
	option: IProductOption;
}

interface IOptionState {
}

class Option extends React.Component<Partial<IOptionProps>, IOptionState> {
	render() {
		const {
			option,
			tabIndex,
			onDeleteClick,
			onDefaultCheck,
			onNameChange,
			...rest
		} = this.props;
		if (!option) {
			return null;
		}
		const tabIdx = (tabIndex === undefined)
			? option.isDefault
				? 0
				: undefined
			: tabIndex;
		const graphic = (
			<DeleteButton onClick={onDeleteClick}/>
		);
		const meta = (
			<Checkbox
				isChecked={option.isDefault}
				onChange={onDefaultCheck}
				title="Is option selected by default?"
			/>
		);
		return (
			<ListItemBase noRipple className="pb-list-item--with-form-control" graphic={graphic} tabIndex={tabIdx} meta={meta} {...rest}>
				<ListItemText className="width--100-percent">
					<TextInput
						className="width--100-percent"
						isMinimal
						value={option.name}
						label="Name"
						onChange={onNameChange}
					/>
				</ListItemText>
			</ListItemBase>
		);
	}
}

interface IDeleteButtonProps extends Omit<IListItemGraphicProps, 'onClick'> {
	onClick: () => any;
}

function DeleteButton(props: Partial<IDeleteButtonProps>) {
	const {
		onClick,
		...rest
	} = props;
	return (
		<ListItemGraphic {...rest}>
			<ToolButton className="pb-product-option-icon-btn pb-product-option-icon-btn--delete-btn" onClick={onClick} title="Delete this option">
				remove_circle_outline
			</ToolButton>
		</ListItemGraphic>
	);
}

interface IAddButtonProps extends Omit<IListItemProps, 'onClick'> {
	onClick: () => any;
}

function AddButton(props: Partial<IAddButtonProps>) {
	const {
		className,
		children,
		onClick,
		...rest
	} = props;
	const clsName = makeClassName(
		'pb-product-option-add-btn',
		className,
	);
	const graphic = (
		<ToolButton className="pb-product-option-icon-btn pb-product-option-icon-btn--active" onClick={onClick}>
			add_circle_outline
		</ToolButton>
	);
	return (
		<ListItem className={clsName} graphic={graphic} {...rest}>
			{children}
		</ListItem>
	);
}

export interface IMaxOptionsProps extends React.HTMLAttributes<any> {
	maxOptions: number | null;
	onInputChange: (value: number | null) => any;
}

export function MaxOptions(props: Partial<IMaxOptionsProps>) {
	const {
		maxOptions,
		onInputChange,
		...rest
	} = props;

	const hasMaxOpts = isNumber(maxOptions);
	const max = isNumber(maxOptions)
		? maxOptions
		: undefined;

	function inp(value?: string) {
		if (!onInputChange) {
			return;
		}
		if (value === undefined) {
			if (max === undefined) {
				onInputChange(0);
			} else {
				onInputChange(null);
			}
			return;
		}
		const n = Number.parseInt(value);
		if (isNumber(n)) {
			onInputChange(n);
		} else {
			onInputChange(null);
		}
	}

	function tog() {
		inp();
	}

	const lblClsName = makeClassName(
		'pb-product-options-max-options-label',
		hasMaxOpts
			? undefined
			: 'pb-product-options-max-options-label--no-max',
	);
	return (
		<div className="pb-product-options-max-options" {...rest}>
			<div className="display--flex flex-direction--row align-items--center">
				<LimitOptionCountToggle isOn={hasMaxOpts} onClick={tog}/>
				<label className={lblClsName} htmlFor="id_maxOptions">
					Limit # of selected options
				</label>
			</div>
			<div className="pb-product-options-max-options-input-container">
				<TextInput
					isDense
					isTransparent
					className="pb-product-options-max-options-input"
					id="id_maxOptions"
					name="maxOptions"
					type="number"
					value={String(max)}
					isDisabled={!hasMaxOpts}
					min={0}
					onChange={inp}
					step={1}
				/>
			</div>
		</div>
	);
}

interface ILimitOptionCountToggleProps extends React.HTMLAttributes<any> {
	isOn: boolean;
}

function LimitOptionCountToggle(props: Partial<ILimitOptionCountToggleProps>) {
	const {
		isOn,
		onClick,
		...rest
	} = props;
	const iconName = isOn
		? 'toggle_on'
		: 'toggle_off';
	const btnClsName = makeClassName(
		'pb-product-option-icon-btn',
		isOn
			? 'pb-product-option-icon-btn--active'
			: 'pb-product-option-icon-btn--toggled-off',
	);
	return (
		<div className="padding-right--32" {...rest}>
			<ToolButton className={btnClsName} onClick={onClick}>
				{iconName}
			</ToolButton>
		</div>
	);
}
