import {MDCDrawer} from '@material/drawer';
import {MDCTabBar} from '@material/tab-bar';
import {MDCTextField} from '@material/textfield';
import {MDCTopAppBar} from '@material/top-app-bar';

let mdcDrawer: MDCDrawer | null = null;

function drawerButtonClicked() {
	if (mdcDrawer) {
		mdcDrawer.open = !mdcDrawer.open;
	}
}

const topBar = document.getElementById('pb-primary-header');
if (topBar) {
	new MDCTopAppBar(topBar);
}

const drawerEl = document.getElementById('primary-drawer');
if (drawerEl) {
	mdcDrawer = new MDCDrawer(drawerEl);
}
const drawerBtnEl = document.getElementById('primary-drawer-open-close-button');
if (drawerBtnEl) {
	drawerBtnEl.addEventListener('click', drawerButtonClicked);
}

for (const el of document.querySelectorAll('.mdc-tab-bar')) {
	new MDCTabBar(el);
}

switch (document.location.pathname) {
	case '/signin/':
	case '/account/password/':
	case '/account/name/': {
		for (const el of document.querySelectorAll('.mdc-text-field')) {
			new MDCTextField(el);
		}
		break;
	}
}

function setPolicyUsrClsSelRootDisplay(display: boolean) {
	const el = document.getElementById('id_client-user-type-select-root');
	if (el) {
		if (display) {
			el.classList.remove('display--none');
		} else {
			el.classList.add('display--none');
		}
	}
}

function policyAppliesToSelChanged(event: Event) {
	const tgt = event.target;
	if (tgt instanceof HTMLSelectElement && (tgt.selectedOptions.length === 1)) {
		const optEl = tgt.selectedOptions.item(0);
		if (optEl) {
			setPolicyUsrClsSelRootDisplay(optEl.value !== 'everyone');
		}
	}
}

const policyAppliesToEl = <HTMLSelectElement | null>document.getElementById('id_applies-to');
if (policyAppliesToEl) {
	policyAppliesToEl.addEventListener('change', policyAppliesToSelChanged);
}

