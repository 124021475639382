export enum CssClassName {
	List = 'mdc-deprecated-list pb-list',
	ListCompact = 'mdc-deprecated-list--dense',
	ListDivider = 'mdc-deprecated-list-divider',
	ListGroup = 'mdc-deprecated-list-group',
	ListGroupSubheader = 'mdc-deprecated-list-group__subheader',
	ListItem = 'mdc-deprecated-list-item pb-list-item',
	ListItemCenterDetail = 'mdc-deprecated-list-item__text pb-list-item--center-detail',
	ListItemGraphic = 'mdc-deprecated-list-item__graphic pb-list-item-leading-graphic',
	ListItemMeta = 'mdc-deprecated-list-item__meta pb-list-item--end-detail',
	ListItemPrimaryText = 'mdc-deprecated-list-item__primary-text pb-list-item--primary-text',
	ListItemRipple = 'mdc-deprecated-list-item__ripple',
	ListItemSecondaryText = 'mdc-deprecated-list-item__secondary-text',
	ListItemSelected = 'mdc-deprecated-list-item--selected',
	ListItemClickable = 'pb-list-item--clickable',
	ListItemText = 'mdc-deprecated-list-item__text pb-list-item__text',
	ListTwoLine = 'mdc-deprecated-list--two-line',
}
