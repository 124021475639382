import React from 'react';
import {
	CCardToolbarAction,
	IMenuOptionProps,
	Menu,
	MessageBox,
	Modality,
	Severity,
} from '../../components';
import {
	CrudOp,
	StandardButton,
} from '../../constants';

import {api} from '../../httpapi';
import {
	bind,
	staticOrganization,
} from '../../util';
import {OrgDelete} from './delete';
import {OrgForm} from './form';
import {OrgList} from './list';

export interface IOrganizationViewProps extends React.HTMLAttributes<any> {
}

interface IOrganizationViewState {
	activeObj: IOrganization;
	activePk: number;
	dialogViewState: CrudOp;
	moreMenuCoords: PlainCoords | null;
	moreMenuIsOpen: boolean;
}

export class OrganizationView extends React.Component<IOrganizationViewProps, IOrganizationViewState> {
	private readonly orgListRef: React.RefObject<OrgList>;

	constructor(props: IOrganizationViewProps) {
		super(props);
		this.orgListRef = React.createRef();
		this.state = {
			activeObj: staticOrganization(),
			activePk: 0,
			dialogViewState: CrudOp.NoOp,
			moreMenuCoords: null,
			moreMenuIsOpen: false,
		};
	}

	@bind
	private cancelPressed(): void {
		const {
			activePk,
			dialogViewState,
		} = this.state;
		switch (dialogViewState) {
			case CrudOp.Create:
			case CrudOp.Read: {
				this.closeDialog();
				break;
			}
			case CrudOp.Update:
			case CrudOp.Delete: {
				this.openReadDialog(activePk);
				break;
			}
		}
	}

	@bind
	private closeDialog(): void {
		this.setState({
			activePk: -1,
			dialogViewState: CrudOp.NoOp,
		});
	}

	@bind
	private closeMoreMenu(): void {
		this.setState({
			moreMenuIsOpen: false,
			moreMenuCoords: null,
		});
	}

	async componentDidUpdate(prevProps: Readonly<IOrganizationViewProps>, prevState: Readonly<IOrganizationViewState>) {
		const {
			activePk,
			dialogViewState,
		} = this.state;
		if (prevState.dialogViewState !== dialogViewState) {
			switch (dialogViewState) {
				case CrudOp.Read:
				case CrudOp.Update: {
					this.setState({
						activeObj: await api.clientOrganizationDetail(activePk),
					});
					break;
				}
			}
		}
	}

	@bind
	private dialogResult(res: number) {
		this.closeDialog();
		switch (res) {
			case StandardButton.Save: {
				console.log('save');
				break;
			}
			case StandardButton.Cancel: {
				console.log('cancel');
				break;
			}
			default: {
				console.log('dialogResult: Invalid result code: %s', res);
				break;
			}
		}
	}

	@bind
	private moreMenuClosed(): void {
		this.closeMoreMenu();
	}

	private moreMenuOptions(): Array<IMenuOptionProps> {
		return [
			{
				isSelected: false,
				label: 'Delete',
				value: 'DELETE',
			},
		];
	}

	@bind
	private moreMenuOptionSelected(index: number): void {
		const {activePk} = this.state;
		const opts = this.moreMenuOptions();
		if ((index >= 0) && (index < opts.length)) {
			this.openDeleteDialog(activePk);
		} else {
			console.log('moreMenuOptionSelected: Invalid option index:', index);
		}
	}

	private objChanged<K extends keyof IOrganization>(value: IOrganization[K], name: K) {
		this.setState({
			activeObj: {
				...this.state.activeObj,
				[name]: value,
			},
		});
	}

	@bind
	private async objCreated() {
		await this.refreshPage(
			undefined,
			() => this.closeDialog(),
		);
	}

	@bind
	private async objDeleted() {
		await this.refreshPage(
			undefined,
			() => this.closeDialog(),
		);
	}

	@bind
	private async objUpdated() {
		await this.refreshPage(
			undefined,
			() => this.closeDialog(),
		);
	}

	@bind
	private openCreateDialog(): void {
		this.setState({
			dialogViewState: CrudOp.Create,
		});
	}

	@bind
	private openDeleteDialog(activePk: number) {
		this.closeMoreMenu();
		this.setState({
			activePk,
			dialogViewState: CrudOp.Delete,
		});
	}

	private openMoreMenu(x?: number, y?: number): void {
		if ((x === undefined) || (y === undefined)) {
			console.log('openMoreMenu: Coordinates are undefined');
			return;
		}
		this.setState({
			moreMenuIsOpen: true,
			moreMenuCoords: {
				x,
				y,
			},
		});
	}

	@bind
	private openReadDialog(pk: number): void {
		this.setState({
			activePk: pk,
			dialogViewState: CrudOp.Read,
		});
	}

	@bind
	private orgClicked(pk: number): void {
		this.setState({
			activePk: pk,
			dialogViewState: CrudOp.Read,
		});
	}

	@bind
	async refreshPage(params?: Partial<IPageParams>, cb?: () => any): Promise<void> {
		const curr = this.orgListRef.current;
		if (curr) {
			await curr.refreshPage(params, cb);
		}
	}

	render() {
		const {
			activeObj,
			activePk,
			dialogViewState,
			moreMenuIsOpen,
			moreMenuCoords,
		} = this.state;
		let infoText: string | undefined = undefined;
		let btns: StandardButton = StandardButton.Close;
		let comp: React.ReactNode;
		let text: string | undefined = undefined;
		let severity: Severity | undefined = undefined;
		let iconName: string | undefined = undefined;
		switch (dialogViewState) {
			case CrudOp.Create: {
				btns = StandardButton.Save | StandardButton.Cancel;
				text = 'Create organization';
				comp = (
					<OrgForm
						obj={activeObj}
						onChange={this.objChanged}
					/>
				);
				break;
			}
			case CrudOp.Read: {
				iconName = 'groups';
				btns = StandardButton.Close;
				text = activeObj.name;
				if (activeObj.email.length > 0) {
					infoText = activeObj.email;
				}
				break;
			}
			case CrudOp.Update: {
				btns = StandardButton.Save | StandardButton.Cancel;
				text = 'Update organization';
				comp = (
					<OrgForm
						obj={activeObj}
						onChange={this.objChanged}
					/>
				);
				break;
			}
			case CrudOp.Delete: {
				severity = Severity.Warning;
				btns = StandardButton.Yes | StandardButton.No;
				comp = (
					<OrgDelete
						pk={activePk}
						objGetter={api.clientOrganizationDetail}
						objDeleter={api.deleteOrganization}
						onDone={this.objDeleted}
						onCancel={this.cancelPressed}
					/>
				);
				break;
			}
		}
		const open = dialogViewState !== CrudOp.NoOp;
		return (
			<>
				<OrgList
					onObjectClicked={this.orgClicked}
					ref={this.orgListRef}
					onCreateBtnClick={this.openCreateDialog}
				/>
				<MessageBox modality={Modality.Modeless} informativeText={infoText} severity={severity} iconName={iconName} isOpen={open} onFinished={this.dialogResult} text={text}>
					{comp}
				</MessageBox>
				<Menu
					anchorToBody
					isCompact
					isOpen={moreMenuIsOpen}
					onClose={this.moreMenuClosed}
					onSelection={this.moreMenuOptionSelected}
					options={this.moreMenuOptions()}
					absolutePosition={moreMenuCoords || undefined}
				/>
			</>
		);
	}

	@bind
	private toolbarAction(axn: CCardToolbarAction, clientX?: number, clientY?: number): void {
		switch (axn) {
			case CCardToolbarAction.Close: {
				this.closeDialog();
				break;
			}
			case CCardToolbarAction.Edit: {
				this.setState({
					dialogViewState: CrudOp.Update,
				});
				break;
			}
			case CCardToolbarAction.More: {
				this.openMoreMenu(clientX, clientY);
				break;
			}
		}
	}
}

