import React, {PropsWithChildren} from 'react';
import {makeClassName} from '../util';

import {Icon} from './icon';

export interface ISegmentedButtonProps extends React.HTMLAttributes<any> {
	isSingleSelect: boolean;
}

export function SegmentedButton(props: PropsWithChildren<Partial<ISegmentedButtonProps>>) {
	const {
		children,
		className,
		isSingleSelect,
		...rest
	} = props;
	const clsName = makeClassName(
		'mdc-segmented-button',
		isSingleSelect
			? 'mdc-segmented-button--single-select'
			: undefined,
		className,
	);
	const role = isSingleSelect
		? 'radiogroup'
		: 'group';
	return (
		<div className={clsName} role={role} {...rest}>
			{children}
		</div>
	);
}

export interface IButtonSegmentProps extends React.ButtonHTMLAttributes<any> {
	iconName: string;
	isSelected: boolean;
}

export function ButtonSegment(props: PropsWithChildren<Partial<IButtonSegmentProps>>) {
	const {
		children,
		className,
		iconName,
		isSelected,
		...rest
	} = props;
	const clsName = makeClassName(
		'mdc-segmented-button__segment',
		'pb-segmented-button__segment',
		isSelected
			? 'mdc-segmented-button__segment--selected'
			: undefined,
		className,
	);
	const icon = iconName
		? <Icon className="mdc-segmented-button__icon">{iconName}</Icon>
		: null;
	return (
		<button className={clsName} {...rest}>
			{icon}
			{
				children
					? <div className="mdc-segmented-button__label">{children}</div>
					: null
			}
		</button>
	);
}
