import React from 'react';
import {
	bind,
	makeClassName,
} from '../util';

export interface IFloatingLabelProps extends React.HTMLAttributes<any> {
	isFloating?: boolean;
	isRequired?: boolean;
	isShaking?: boolean;
}

export interface IFloatingLabelState {
	isFloating: boolean;
	isRequired: boolean;
	isShaking: boolean;
}

export class FloatingLabel extends React.Component<IFloatingLabelProps, IFloatingLabelState> {
	private readonly rootRef: React.RefObject<HTMLSpanElement>;

	constructor(props: IFloatingLabelProps) {
		super(props);
		this.rootRef = React.createRef();
		this.state = {
			isFloating: false,
			isRequired: false,
			isShaking: false,
		};
	}

	@bind
	private animationEnded() {
		this.setShaking(false);
	}

	componentDidMount() {
		const {
			isFloating,
			isRequired,
			isShaking,
		} = this.props;
		this.setRequired(isRequired);
		this.setShaking(isShaking);
		this.setFloating(isFloating);
	}

	componentDidUpdate(prevProps: Readonly<IFloatingLabelProps>, prevState: Readonly<IFloatingLabelState>) {
		const {
			isFloating,
			isRequired,
			isShaking,
		} = this.props;
		if (prevProps.isShaking !== isShaking) {
			this.setShaking(isShaking);
		}
		if (prevProps.isRequired !== isRequired) {
			this.setShaking(isRequired);
		}
		if (prevProps.isFloating !== isFloating) {
			this.setFloating(isFloating);
		}
	}

	render() {
		const {
			children,
			className,
			isFloating: xf,
			isRequired: xr,
			isShaking: xs,
			...rest
		} = this.props;
		const {
			isFloating,
			isRequired,
			isShaking,
		} = this.state;
		const clsName = makeClassName(
			'mdc-floating-label',
			isFloating
				? 'mdc-floating-label--float-above'
				: undefined,
			isRequired
				? 'mdc-floating-label--required'
				: undefined,
			isShaking
				? 'mdc-floating-label--shake'
				: undefined,
			className,
		);
		return (
			<span className={clsName} onAnimationEnd={this.animationEnded} ref={this.rootRef} {...rest}>
				{children}
			</span>
		);
	}

	setFloating(isFloating?: boolean) {
		isFloating = Boolean(isFloating);
		if (isFloating !== this.state.isFloating) {
			this.setState({
				isFloating,
				isShaking: isFloating
					? this.state.isShaking
					: false,
			});
		}
	}

	setRequired(isRequired?: boolean) {
		isRequired = Boolean(isRequired);
		if (isRequired !== this.state.isRequired) {
			this.setState({isRequired});
		}
	}

	setShaking(isShaking?: boolean) {
		isShaking = Boolean(isShaking);
		if (isShaking !== this.state.isShaking) {
			this.setState({isShaking});
		}
	}

	width(): number {
		const curr = this.rootRef.current;
		if (curr) {
			return estimateScrollWidth(curr);
		}
		return 0;
	}
}

function estimateScrollWidth(el: HTMLElement): number {
	// If the element inherits display: none from any parent, the offsetParent
	// property will be null. See:
	// https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/offsetParent
	//
	// This check ensures we only clone the node when necessary.
	if (el.offsetParent !== null) {
		return el.scrollWidth;
	}
	const clone = el.cloneNode(true) as HTMLElement;
	clone.style.setProperty('position', 'absolute');
	clone.style.setProperty('transform', 'translate(-9999px, -9999px)');
	document.documentElement.appendChild(clone);
	const rv = clone.scrollWidth;
	document.documentElement.removeChild(clone);
	return rv;
}
