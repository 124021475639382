import React from 'react';

import {
	SECONDS_IN_DAY,
	SECONDS_IN_HOUR,
	SECONDS_IN_MINUTE,
} from '../../../constants';
import {
	GridLayout,
	GridLayoutCell,
	TextInput,
	Slider,
} from '../../../components';
import {
	bind,
	clamp,
	isNumber,
	makeClassName,
	secondsToDuration,
} from '../../../util';
import {IconButtonSubheading} from './iconbuttonsubheading';

const ITEM_DURATION_MAX_SECONDS = 86400 * 3;
const SECONDS_IN_QUARTER_HOUR = 900;
const MAX_DAYS = Math.floor(ITEM_DURATION_MAX_SECONDS / SECONDS_IN_DAY);

export interface IDurationProps extends Omit<React.HTMLAttributes<any>, 'onChange'> {
	duration?: number;
	onChange?: (value: number) => any;
}

interface IDurationState {
	renderSlider: boolean;
}

export class Duration extends React.Component<IDurationProps, IDurationState> {
	constructor(props: IDurationProps) {
		super(props);
		this.state = {
			renderSlider: true,
		};
	}

	render() {
		const {
			children,
			duration,
		} = this.props;
		const {
			renderSlider,
		} = this.state;
		const {
			days,
			hours,
			minutes,
		} = secondsToDuration(duration);
		const iconName = renderSlider
			? 'text_fields'
			: 'linear_scale';
		const title = renderSlider
			? 'Switch to text input'
			: 'Switch to slider';
		const dur = (duration === undefined)
			? 0
			: duration;
		return (
			<>
				<IconButtonSubheading iconName={iconName} onClick={this.toggleRender} title={title}>
					{children}
				</IconButtonSubheading>
				{
					renderSlider
						? (
							<>
								<Slider
									max={ITEM_DURATION_MAX_SECONDS}
									min={0}
									onChange={this.sliderChanged}
									step={SECONDS_IN_QUARTER_HOUR}
									value={dur}
								/>
								<div className="display--flex flex-direction--row">
									<SubLabel text="Day" value={days}/>
									<SubLabel text="Hour" value={hours}/>
									<SubLabel text="Minute" value={minutes}/>
								</div>
							</>
						)
						: (
							<GridLayout className="pb-layout-grid--no-margin-or-something">
								<GridLayoutCell phoneSpan={12} span={4} tabletSpan={12}>
									<TextInput
										isMinimal
										helpText="Days"
										label="Days"
										max={MAX_DAYS}
										min={0}
										name="days"
										onChange={this.textInputChanged}
										persistHelpText
										step={1}
										type="number"
										value={String(days)}
									/>
								</GridLayoutCell>
								<GridLayoutCell phoneSpan={12} span={4} tabletSpan={12}>
									<TextInput
										isMinimal
										helpText="Hours"
										label="Hours"
										name="hours"
										onChange={this.textInputChanged}
										persistHelpText
										step={1}
										type="number"
										value={String(hours)}
									/>
								</GridLayoutCell>
								<GridLayoutCell phoneSpan={12} span={4} tabletSpan={12}>
									<TextInput
										isMinimal
										helpText="Minutes"
										label="Minutes"
										name="minutes"
										onChange={this.textInputChanged}
										persistHelpText
										step={1}
										type="number"
										value={String(minutes)}
									/>
								</GridLayoutCell>
							</GridLayout>
						)
				}
			</>
		);
	}

	@bind
	private sliderChanged(value: number): void {
		const {onChange} = this.props;
		if (onChange) {
			onChange(
				clamp(
					value,
					0,
					ITEM_DURATION_MAX_SECONDS,
				),
			);
		}
	}

	@bind
	textInputChanged(value: string, inputName?: string): void {
		const {
			duration,
			onChange,
		} = this.props;
		if (!onChange) {
			return;
		}
		const num = Number.parseInt(value);
		if ((inputName === undefined) || (inputName === '')) {
			console.log('textInputChanged: Got invalid input name:', inputName);
			return;
		}
		if (!isNumber(num)) {
			console.log('textInputChanged: Got invalid input value:', value);
			return;
		}
		const dur = (duration === undefined)
			? 0
			: duration;
		const secs = calculateTotalSeconds(
			num,
			dur,
			inputName,
		);
		onChange(
			clamp(
				secs,
				0,
				ITEM_DURATION_MAX_SECONDS,
			),
		);
	}

	@bind
	private toggleRender(): void {
		const {renderSlider} = this.state;
		this.setState({
			renderSlider: !renderSlider,
		});
	}
}

interface ISubLabelProps extends React.HTMLAttributes<any> {
	pluralText?: string;
	text: string;
	value: number;
}

function SubLabel(props: ISubLabelProps) {
	const {
		className,
		pluralText,
		text,
		value,
		...rest
	} = props;
	const clsName = makeClassName(
		'pb-catalog-item-form__subsection-input-label',
		className,
	);
	const txt = (value === 1)
		? text
		: pluralText
			? text
			: `${text}s`;
	return (
		<div className={clsName} {...rest}>
			<div>
				{value}
			</div>
			<div>
				{txt}
			</div>
		</div>
	);
}

function calculateTotalSeconds(input: number, currentTotal: number, period: string): number {
	const dur = secondsToDuration(currentTotal);
	let rv: number = currentTotal;
	switch (period) {
		case 'days': {
			rv -= (dur.days * SECONDS_IN_DAY);
			rv += (input * SECONDS_IN_DAY);
			break;
		}
		case 'hours': {
			rv -= (dur.hours * SECONDS_IN_HOUR);
			rv += (input * SECONDS_IN_HOUR);
			break;
		}
		case 'minutes': {
			rv -= (dur.minutes * SECONDS_IN_MINUTE);
			rv += (input * SECONDS_IN_MINUTE);
			break;
		}
	}
	return rv;
}
