import React from 'react';

import {api} from '../../httpapi';
import {SnippetFormView} from './snippetform';
import {
	bind,
	staticSnippet,
} from '../../util';
import {
	Button,
	CCard,
	CCardAvatar,
	CCardBottom,
	CCardContent,
	CCardDescribe,
	CCardHeader,
	CCardIntro,
	CCardName,
	CCardNameTitle,
	CCardSection,
	Dialog,
	ICCardProps,
	WideContentCard,
	WideContentContainer,
	WideContentHeader,
} from '../../components';

export interface ISnippetUpdateViewProps extends React.HTMLAttributes<any> {
	pk: string;
}

export interface ISnippetUpdateViewState {
	confirmDeleteDialogIsOpen: boolean;
	initializing: boolean;
	obj: ISnippet;
}

export class SnippetUpdateView extends React.Component<ISnippetUpdateViewProps, ISnippetUpdateViewState> {
	constructor(props: ISnippetUpdateViewProps) {
		super(props);
		this.state = {
			confirmDeleteDialogIsOpen: false,
			initializing: true,
			obj: staticSnippet(),
		};
	}

	@bind
	private bodyChanged(body: string) {
		const {obj} = this.state;
		this.setState({
			obj: {
				...obj,
				body,
			},
		});
	}

	@bind
	private closeDialog() {
		this.setState({
			confirmDeleteDialogIsOpen: false,
		});
	}

	async componentDidMount() {
		const {pk} = this.props;
		this.setState(
			{
				obj: await api.snippetDetail(pk),
			},
			() => this.setState({initializing: false}),
		);
	}

	@bind
	private deleteButtonClicked() {
		this.openDialog();
	}

	@bind
	private async deleteConfirmed() {
		await this.deleteObj();
		this.closeDialog();
		window.location.assign(window.pbUrls.snippetListView);
	}

	@bind
	private async deleteObj() {
		await api.deleteSnippet(this.state.obj.id);
	}

	@bind
	private deleteRejected() {
		this.closeDialog();
	}

	@bind
	private async formSubmitted(event: React.FormEvent) {
		event.preventDefault();
		const {obj} = this.state;
		this.setState({
			obj: await api.updateSnippet(obj),
		});
	}

	@bind
	private nameChanged(name: string) {
		const {obj} = this.state;
		this.setState({
			obj: {
				...obj,
				name,
			},
		});
	}

	private openDialog() {
		this.setState({
			confirmDeleteDialogIsOpen: true,
		});
	}

	render() {
		const {
			confirmDeleteDialogIsOpen,
			initializing,
			obj,
		} = this.state;
		if (initializing) {
			return null;
		}
		const delBtn = (
			<Button className="margin-left--auto pb-button--color-danger" onClick={this.deleteButtonClicked}>
				Delete
			</Button>
		);
		const heading = obj.name.trim().length > 0
			? obj.name
			: 'Snippet';
		return (
			<>
				<WideContentHeader backNavUri={window.pbUrls.snippetListView} backNavTitle="back to snippets" hasDivider trailingNode={delBtn}>
					{heading}
				</WideContentHeader>
				<WideContentContainer>
					<WideContentCard>
						<SnippetFormView
							obj={obj}
							onNameChange={this.nameChanged}
							onBodyChange={this.bodyChanged}
							onSubmit={this.formSubmitted}
						/>
					</WideContentCard>
				</WideContentContainer>
				<Dialog isOpen={confirmDeleteDialogIsOpen}>
					<ConfirmDelete name={obj.name}>
						<CCardBottom>
							<Button value="cancel" raisedFilled onClick={this.deleteRejected}>Cancel</Button>
							<Button value="delete" onClick={this.deleteConfirmed}>Delete</Button>
						</CCardBottom>
					</ConfirmDelete>
				</Dialog>
			</>
		);
	}
}

interface IConfirmDeleteProps extends Partial<ICCardProps> {
	name: string;
}

function ConfirmDelete(props: IConfirmDeleteProps) {
	const {
		children,
		name,
		...rest
	} = props;
	return (
		<CCard {...rest}>
			<CCardHeader>
				<CCardIntro>
					<CCardAvatar>short_text</CCardAvatar>
					<CCardDescribe>
						<CCardNameTitle>
							<CCardName>{name}</CCardName>
						</CCardNameTitle>
					</CCardDescribe>
				</CCardIntro>
			</CCardHeader>
			<CCardContent>
				<CCardSection>
					Delete this snippet?
				</CCardSection>
			</CCardContent>
			{children}
		</CCard>
	);
}
