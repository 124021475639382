import React from 'react';

import {bind} from '../../util';
import {
	CCardContent,
	CCard,
	CCardControls,
	CCardBottomButtons,
	EditCardHeader,
	EditCardSection,
	EditCardSectionRow,
	CCardTextInputControl,
	ICCardProps,
	IEditCardSectionRowProps,
} from '../../components';

export interface IServiceAreaFormProps extends Omit<ICCardProps, 'onChange'> {
	obj: IServiceArea;
	headerText: string;
	onCancel: () => any;
	onChange: <K extends keyof IServiceArea>(name: K, value: IServiceArea[K]) => any;
	onSave: () => any;
}

interface IServiceAreaFormState {
}

export class ServiceAreaForm extends React.Component<IServiceAreaFormProps, IServiceAreaFormState> {
	private changed<K extends keyof IServiceArea>(name: K, value: IServiceArea[K]): void {
		const {onChange} = this.props;
		if (onChange) {
			onChange(name, value);
		}
	}

	@bind
	private latChanged(value: string): void {
		this.changed(
			'latitude',
			(value.length === 0)
				? null
				: value,
		);
	}

	@bind
	private longChanged(value: string): void {
		this.changed(
			'longitude',
			(value.length === 0)
				? null
				: value,
		);
	}

	@bind
	private nameChanged(value: string): void {
		this.changed('name', value);
	}

	render() {
		const {
			headerText,
			obj,
			onCancel,
			onSave,
			onChange,
			...rest
		} = this.props;
		const lat = (obj.latitude === null)
			? ''
			: obj.latitude;
		const long = (obj.longitude === null)
			? ''
			: obj.longitude;
		return (
			<CCard {...rest}>
				<EditCardHeader text={headerText}/>
				<CCardContent>
					<EditCardSection>
						<NameSectionRow
							name={obj.name}
							onChange={this.nameChanged}
						/>
						<EditCardSectionRow itemIconName="pin_drop">
							<CCardControls>
								<CCardTextInputControl
									label="Latitude"
									onChange={this.latChanged}
									value={lat}
								/>
								<CCardTextInputControl
									label="Longitude"
									onChange={this.longChanged}
									value={long}
								/>
							</CCardControls>
						</EditCardSectionRow>
					</EditCardSection>
				</CCardContent>
				<CCardBottomButtons
					onCancel={onCancel}
					onSave={onSave}
				/>
			</CCard>
		);
	}
}

interface INameSectionRowProps extends Omit<IEditCardSectionRowProps, 'onChange'> {
	name: string;
	onChange: (value: string) => any;
}

function NameSectionRow(props: Partial<INameSectionRowProps>) {
	const {
		name,
		onChange,
		...rest
	} = props;
	const handler = (value: string) => onChange && onChange(value);
	return (
		<EditCardSectionRow itemIconName="travel_explore" {...rest}>
			<CCardControls>
				<CCardTextInputControl
					value={name}
					label="Name"
					onChange={handler}
				/>
			</CCardControls>
		</EditCardSectionRow>
	);
}
