import React from 'react';

import {api} from '../../httpapi';
import {
	bind,
	coordStr,
	staticCfg,
} from '../../util';
import {
	DataTableCellProps,
	CCardToolbarAction,
	Dialog,
	IMenuOptionProps,
	Menu,
	TubbyButton,
	WideContentContainer,
	WideContentHeader,
} from '../../components';
import {
	CrudOp,
	SortOrder,
	UiTableTitle,
} from '../../constants';
import {ServiceAreaCreate} from './create';
import {ServiceAreaRead} from './read';
import {ServiceAreaUpdate} from './update';
import {ServiceAreaDelete} from './delete';
import {ServiceAreaList} from './list';

enum ServiceAreaColumn {
	Name = 'Name',
	Icon = 'Icon',
	LatLong = 'Lat/Long',
}

export interface IServiceAreaViewProps extends React.HTMLAttributes<any> {
}

interface IServiceAreaViewState {
	activePk: number;
	cfg: ICfg;
	dialogViewState: CrudOp;
	moreMenuCoords: PlainCoords | null;
	moreMenuIsOpen: boolean;
}

export class ServiceAreaView extends React.Component<IServiceAreaViewProps, IServiceAreaViewState> {
	private readonly serviceAreaListRef: React.RefObject<ServiceAreaList>;

	constructor(props: IServiceAreaViewProps) {
		super(props);
		this.serviceAreaListRef = React.createRef();
		this.state = {
			activePk: 0,
			cfg: staticCfg(),
			dialogViewState: CrudOp.NoOp,
			moreMenuCoords: null,
			moreMenuIsOpen: false,
		};
	}

	@bind
	private cancelPressed(): void {
		const {
			activePk,
			dialogViewState,
		} = this.state;
		switch (dialogViewState) {
			case CrudOp.Create:
			case CrudOp.Read: {
				this.closeDialog();
				break;
			}
			case CrudOp.Update:
			case CrudOp.Delete: {
				this.openReadDialog(activePk);
				break;
			}
		}
	}

	@bind
	private cellHook(col: ICol, obj: IServiceArea): Partial<DataTableCellProps> {
		let val: string = '';
		switch (col.label) {
			case ServiceAreaColumn.Name: {
				val = obj.name;
				break;
			}
			case ServiceAreaColumn.Icon: {
				val = obj.icon;
				break;
			}
			case ServiceAreaColumn.LatLong: {
				val = coordStr(
					obj.latitude,
					obj.longitude,
					4,
					' / ',
				);
				break;
			}
		}
		return {
			children: val,
			title: val,
		};
	}

	@bind
	private closeDialog(): void {
		this.setState({
			activePk: 0,
			dialogViewState: CrudOp.NoOp,
		});
	}

	@bind
	private closeMoreMenu(): void {
		this.setState({
			moreMenuIsOpen: false,
			moreMenuCoords: null,
		});
	}

	async componentDidMount() {
		await this.refreshCfg();
	}

	@bind
	private async fetchCfg(): Promise<ICfg> {
		return await api.cfgDetail();
	}

	@bind
	private moreMenuClosed(): void {
		this.closeMoreMenu();
	}

	@bind
	private moreMenuOptionSelected(index: number): void {
		const {activePk} = this.state;
		const opts = this.moreMenuOptions();
		if ((index >= 0) && (index < opts.length)) {
			this.openDeleteDialog(activePk);
		} else {
			console.log('moreMenuOptionSelected: Invalid option index:', index);
		}
	}

	private moreMenuOptions(): Array<IMenuOptionProps> {
		return [
			{
				isSelected: false,
				label: 'Delete',
				value: 'DELETE',
			},
		];
	}

	@bind
	private objClicked(pk: number): void {
		this.openReadDialog(pk);
	}

	@bind
	private async objCreated() {
		await this.refreshPage(
			undefined,
			() => this.closeDialog(),
		);
	}

	@bind
	private async objDeleted() {
		await this.refreshPage(
			undefined,
			() => this.closeDialog(),
		);
	}

	@bind
	private async objUpdated() {
		await this.refreshPage(
			undefined,
			() => {
				this.setState({
					dialogViewState: CrudOp.Read,
				});
			},
		);
	}

	@bind
	private openCreateDialog(): void {
		this.setState({
			dialogViewState: CrudOp.Create,
		});
	}

	@bind
	private openDeleteDialog(activePk: number) {
		this.closeMoreMenu();
		this.setState({
			activePk,
			dialogViewState: CrudOp.Delete,
		});
	}

	private openMoreMenu(x?: number, y?: number): void {
		if ((x === undefined) || (y === undefined)) {
			console.log('openMoreMenu: Coordinates are undefined');
			return;
		}
		this.setState({
			moreMenuIsOpen: true,
			moreMenuCoords: {
				x,
				y,
			},
		});
	}

	@bind
	private openReadDialog(pk: number): void {
		this.setState({
			activePk: pk,
			dialogViewState: CrudOp.Read,
		});
	}

	@bind
	async refreshCfg(cb?: () => any): Promise<void> {
		this.setState(
			{
				cfg: await this.fetchCfg(),
			},
			cb,
		);
	}

	@bind
	private async refreshPage(params?: Partial<IPageParams>, cb?: () => any) {
		const curr = this.serviceAreaListRef.current;
		if (curr) {
			await curr.refreshPage(params, cb);
		}
	}

	render() {
		const {
			activePk,
			cfg,
			dialogViewState,
			moreMenuIsOpen,
			moreMenuCoords,
		} = this.state;
		let comp: React.ReactNode = null;
		switch (dialogViewState) {
			case CrudOp.Create: {
				comp = (
					<ServiceAreaCreate
						onCancel={this.cancelPressed}
						objCreator={api.createServiceArea}
						onDone={this.objCreated}
					/>
				);
				break;
			}
			case CrudOp.Read: {
				comp = (
					<ServiceAreaRead
						objGetter={api.serviceAreaDetail}
						onToolbarAction={this.toolbarAction}
						pk={activePk}
					/>
				);
				break;
			}
			case CrudOp.Update: {
				comp = (
					<ServiceAreaUpdate
						onCancel={this.cancelPressed}
						pk={activePk}
						onDone={this.objUpdated}
						objGetter={api.serviceAreaDetail}
						objUpdater={api.updateServiceArea}
					/>
				);
				break;
			}
			case CrudOp.Delete: {
				comp = (
					<ServiceAreaDelete
						onCancel={this.cancelPressed}
						onDone={this.objDeleted}
						pk={activePk}
					/>
				);
				break;
			}
		}
		const open = dialogViewState !== CrudOp.NoOp;
		return (
			<>
				<WideContentHeader hasDivider>
					Service areas
				</WideContentHeader>
				<WideContentContainer>
					<div className="pb-list-view-actions">
						<span/>
						<TubbyButton onClick={this.openCreateDialog}>
							Create service area
						</TubbyButton>
					</div>
					<ServiceAreaList
						cellHook={this.cellHook}
						cfg={cfg}
						onObjectClick={this.objClicked}
						pageGetter={api.serviceAreaList}
						ref={this.serviceAreaListRef}
						uiTableTitle={UiTableTitle.ServiceAreaList}
					/>
				</WideContentContainer>
				<Dialog isOpen={open} onFinished={this.closeDialog}>
					{comp}
				</Dialog>
				<Menu
					anchorToBody
					isCompact
					isOpen={moreMenuIsOpen}
					onClose={this.moreMenuClosed}
					onSelection={this.moreMenuOptionSelected}
					options={this.moreMenuOptions()}
					absolutePosition={moreMenuCoords || undefined}
				/>
			</>
		);
	}

	@bind
	private async sortClicked(columnPk: number, nextSortOrder: SortOrder) {
		await this.refreshPage({
			so: nextSortOrder,
			col: columnPk,
		});
	}

	@bind
	private toolbarAction(axn: CCardToolbarAction, clientX?: number, clientY?: number): void {
		switch (axn) {
			case CCardToolbarAction.Close: {
				this.closeDialog();
				break;
			}
			case CCardToolbarAction.Edit: {
				this.setState({
					dialogViewState: CrudOp.Update,
				});
				break;
			}
			case CCardToolbarAction.More: {
				this.openMoreMenu(clientX, clientY);
				break;
			}
		}
	}
}
