import React from 'react';

import {makeClassName} from '../util';

export interface ISwitchProps extends React.ButtonHTMLAttributes<any> {
	isProcessing?: boolean;
	isSelected: boolean;
}

interface ISwitchState {
}

export class Switch extends React.Component<Partial<ISwitchProps>, ISwitchState> {
	render() {
		const {
			className,
			isSelected,
			isProcessing,
			...rest
		} = this.props;
		const clsName = makeClassName(
			'mdc-switch',
			isSelected
				? 'mdc-switch--selected'
				: 'mdc-switch--unselected',
			isProcessing
				? 'mdc-switch--processing'
				: undefined,
			className,
		);
		return (
			<button className={clsName} role="switch" type="button" {...rest}>
				<div className="mdc-switch__track"/>
				<div className="mdc-switch__handle-track">
					<div className="mdc-switch__handle">
						<div className="mdc-switch__shadow">
							<div className="mdc-elevation-overlay"/>
						</div>
						<div className="mdc-switch__ripple"/>
						<div className="mdc-switch__icons">
							<svg className="mdc-switch__icon mdc-switch__icon--on" viewBox="0 0 24 24">
								<path d="M19.69,5.23L8.96,15.96l-4.23-4.23L2.96,13.5l6,6L21.46,7L19.69,5.23z"/>
							</svg>
							<svg className="mdc-switch__icon mdc-switch__icon--off" viewBox="0 0 24 24">
								<path d="M20 13H4v-2h16v2z"/>
							</svg>
						</div>
					</div>
				</div>
				<span className="mdc-switch__focus-ring-wrapper">
					<div className="mdc-switch__focus-ring"/>
				</span>
			</button>
		);
	}
}
