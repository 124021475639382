import React from 'react';

import {makeClassName} from '../../../util';
import {ToolButton} from '../../../components';

export interface IIconButtonSubheadingProps extends Omit<React.HTMLAttributes<HTMLHeadingElement>, 'onClick'> {
	iconName: string;
	onClick: () => any;
}

export function IconButtonSubheading(props: Partial<IIconButtonSubheadingProps>) {
	const {
		children,
		className,
		iconName,
		onClick,
		title,
		...rest
	} = props;
	const clsName = makeClassName(
		'pb-catalog-item-form__subsection-subtitle',
		'pb-catalog-item-form__toggleable-view-label',
		className,
	);
	return (
		<h4 className={clsName} {...rest}>
			{children}
			<ToolButton onClick={onClick} title={title}>
				{iconName}
			</ToolButton>
		</h4>
	);
}
