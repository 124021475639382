import React from 'react';
import {
	MDCMenuAdapter,
	MDCMenuFoundation,
	MDCMenuItemEventDetail,
} from '@material/menu';
import {
	Corner,
} from '@material/menu-surface';

import {
	bind,
	closestMatchingElement,
	makeClassName,
} from '../../util';
import {
	IListItemProps,
	List,
	ListItem,
	ListItemDivider,
} from '../list';
import {
	IMenuSurfaceProps,
	MenuSurface,
} from './surface';

export interface IMenuOptionProps {
	isSelected?: boolean;
	isItemDivider?: boolean;
	label?: string;
	value: string;
	listItemProps?: Partial<IListItemProps>;
}

export interface IMenuProps extends IMenuSurfaceProps {
	isCompact: boolean;
	onSelection: (index: number, isNavigation: boolean) => any;
	options: Array<IMenuOptionProps>;
}

interface IMenuState {
	classNames: Set<string>;
}

export class Menu extends React.Component<Partial<IMenuProps>, IMenuState> {
	private ctrl: MDCMenuFoundation;
	private readonly listRef: React.RefObject<List>;
	private readonly surfaceRef: React.RefObject<MenuSurface>;

	constructor(props: Partial<IMenuProps>) {
		super(props);
		this.ctrl = new MDCMenuFoundation();
		this.state = {
			classNames: new Set(),
		};
		this.listRef = React.createRef();
		this.surfaceRef = React.createRef();
	}

	componentDidMount() {
		this.ctrl.destroy();
		this.ctrl = new MDCMenuFoundation(
			this.mdcAdapter(),
		);
		this.ctrl.init();
	}

	componentDidUpdate(prevProps: Readonly<Partial<IMenuProps>>, prevState: Readonly<IMenuState>) {
		const prevIdx = this.selectedOptionIndex(prevProps.options);
		const currIdx = this.selectedOptionIndex(this.props.options);
		if ((prevIdx !== currIdx) && (currIdx >= 0)) {
			const obj = this.listRef.current;
			if (obj) {
				obj.focusListItem(currIdx);
			}
		}
	}

	componentWillUnmount() {
		this.ctrl.destroy();
	}

	focusListItemAtIndex(index: number): void {
		const el = this.listItemElements()[index];
		if (el) {
			el.focus();
		}
	}

	@bind
	private listItemClicked(index: number): void {
		const obj = this.listRef.current;
		if (obj) {
			const el = this.listItemElements()[index];
			if (el) {
				this.ctrl.handleItemAction(el);
			}
		}
	}

	listItemElements(): Array<HTMLElement> {
		const obj = this.surfaceRef.current;
		if (obj) {
			return obj.listItemElements();
		}
		return [];
	}

	@bind
	private listItemKeyPressed(index: number, key: string): void {
		if (index === -1) {
			console.log('Menu::listItemKeyPressed: Invalid index. Got: (%s, %s)', index, key);
			return;
		}
		const {
			onSelection,
			options,
		} = this.props;
		if (!onSelection || !options || (index >= options.length)) {
			if (options && (index >= options.length)) {
				console.log('Menu::listItemKeyPressed: Invalid index. Got: (%s, %s)', index, key);
			}
			return;
		}
		switch (key) {
			case 'Enter': {
				onSelection(index, false);
				break;
			}
			case 'ArrowUp':
			case 'Up': {
				const obj = this.listRef.current;
				if (obj) {
					obj.focusListItem(Math.max(0, index - 1));
				}
				break;
			}
			case 'ArrowDown':
			case 'Down': {
				const obj = this.listRef.current;
				if (obj) {
					obj.focusListItem(Math.min(options.length - 1, index + 1));
				}
				break;
			}
		}
	}

	private mdcAdapter(): MDCMenuAdapter {
		return {
			addAttributeToElementAtIndex: (index: number, attr: string, value: string) => {
				const el = this.listItemElements()[index];
				if (el) {
					el.setAttribute(attr, value);
				}
			},
			addClassToElementAtIndex: (index: number, className: string) => {
				// FIXME: How can we not do this through direct DOM
				//        manipulation? using ReactJS context? Something else?
				const el = this.listItemElements()[index];
				if (el) {
					el.classList.add(className);
				}
			},
			closeSurface: (/* skipRestoreFocus?: boolean */) => {
				// TODO: Should this be implemented? See notes in MenuSurface
				//       close, closing, open, opening notification routines.
			},
			elementContainsClass: (element: Element, className: string) => {
				return element.classList.contains(className);
			},
			focusItemAtIndex: (index: number) => {
				this.focusListItemAtIndex(index);
			},
			focusListRoot: () => {
				const obj = this.listRef.current;
				if (obj) {
					obj.focus();
				}
			},
			getAttributeFromElementAtIndex: (index: number, attr: string) => {
				const el = this.listItemElements()[index];
				if (el) {
					return el.getAttribute(attr);
				}
				return null;
			},
			getElementIndex: (element: Element) => {
				return (element instanceof HTMLElement)
					? this.listItemElements().indexOf(element)
					: -1;
			},
			getMenuItemCount: () => {
				return this.listItemElements().length;
			},
			getSelectedSiblingOfItemAtIndex: (index: number) => {
				const listItemEls = this.listItemElements();
				const el = listItemEls[index];
				if (el) {
					const groupEl = closestMatchingElement(
						el,
						'.mdc-menu__selection-group',
					);
					if (groupEl) {
						const selectedEl = groupEl.querySelector('.mdc-menu-item--selected');
						if (selectedEl && (selectedEl instanceof HTMLElement)) {
							return listItemEls.indexOf(selectedEl);
						}
					}
				}
				return -1;
			},
			isSelectableItemAtIndex: (index: number) => {
				const el = this.listItemElements()[index];
				if (el) {
					return Boolean(
						closestMatchingElement(
							el,
							'.mdc-menu__selection-group',
						),
					);
				}
				return false;
			},
			notifySelected: (evtData: MDCMenuItemEventDetail) => {
				if (this.props.onSelection) {
					this.props.onSelection(evtData.index, false);
				}
			},
			removeAttributeFromElementAtIndex: (index: number, attr: string) => {
				const el = this.listItemElements()[index];
				if (el) {
					el.removeAttribute(attr);
				}
			},
			removeClassFromElementAtIndex: (index: number, className: string) => {
				// FIXME: How can we not do this through direct DOM
				//        manipulation? using ReactJS context? Something else?
				const el = this.listItemElements()[index];
				if (el) {
					el.classList.remove(className);
				}
			},
		};
	}

	render() {
		const {
			children,
			className,
			isCompact,
			onSelection,
			options,
			isOpen,
			...rest
		} = this.props;
		const {
			classNames,
		} = this.state;
		const clsName = makeClassName(
			'mdc-menu',
			...classNames,
			className,
		);
		return (
			<MenuSurface isOpen={isOpen} className={clsName} ref={this.surfaceRef} {...rest}>
				<List isCompact={isCompact} ref={this.listRef}>
					{
						options
							? options.map((opt, idx) => {
								if (opt.isItemDivider) {
									return (
										<ListItemDivider key={idx}/>
									);
								}
								return (
									<ListItem isClickable data-value={opt.value} isSelected={opt.isSelected} key={idx} onClick={this.listItemClicked.bind(this, idx)} onKeyPress={this.listItemKeyPressed.bind(this, idx)} {...opt.listItemProps}>
										{
											opt.label === undefined
												? opt.value
												: opt.label
										}
									</ListItem>
								);
							})
							: null
					}
				</List>
			</MenuSurface>
		);
	}

	private selectedOptionIndex(opts: Array<IMenuOptionProps> | undefined): number {
		if (opts === undefined) {
			return -1;
		}
		for (let i = 0; i < opts.length; ++i) {
			if (opts[i].isSelected) {
				return i;
			}
		}
		return -1;
	}

	setAnchorCorner(corner: Corner): void {
		const obj = this.surfaceRef.current;
		if (obj) {
			obj.setAnchorCorner(corner);
		}
	}
}
