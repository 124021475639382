import React from 'react';

import {makeClassName} from '../util';

export interface IIconProps extends React.HTMLAttributes<any> {
	isOutlined: boolean;
	middleAlign: boolean;
}

export function Icon(props: Partial<IIconProps>) {
	const {
		children,
		className,
		isOutlined,
		middleAlign,
		...rest
	} = props;
	const clsName = makeClassName(
		isOutlined
			? 'material-icons-outlined'
			: 'material-icons',
		'pb-icon',
		middleAlign
			? 'vertical-align--middle'
			: undefined,
		className,
	);
	return (
		<i aria-hidden="true" className={clsName} {...rest}>
			{children}
		</i>
	);
}
