import React from 'react';

import {UiTableTitle} from '../../constants';
import {bind} from '../../util';
import {
	DataTableCellProps,
	TubbyButton,
} from '../../components';
import {
	IListViewProps,
	ListView,
} from '../list';

enum ClientUserColumn {
	Type = 'Type',
	Group = 'Group',
	Email = 'Email',
	Name = 'Name',
}

enum TeamMemberUserColumn {
	Name = 'Name',
	Email = 'Email',
	PhoneNumber = 'Phone number',
	Availability = 'Availability',
}

export interface IUserListProps extends Omit<IListViewProps, 'columnsDraggable' | 'columnSelectionEnabled' | 'cellHook' | 'onCellClick'> {
	pageGetter: (params?: Partial<IPageParams>) => Promise<PaginatedUserPage>;
	onCreateBtnClick: () => any;
	onObjectClick: (pk: string) => any;
}

interface IUserListState {
}

export class UserList extends React.Component<IUserListProps, IUserListState> {
	private readonly listViewRef: React.RefObject<ListView>;

	constructor(props: IUserListProps) {
		super(props);
		this.listViewRef = React.createRef();
	}

	@bind
	private cellClicked(obj: IUser) {
		this.props.onObjectClick(obj.email);
	}

	@bind
	private cellHook(col: ICol, obj: IUser): Partial<DataTableCellProps> {
		const {uiTableTitle} = this.props;
		let val: string = '';
		switch (col.label) {
			case ClientUserColumn.Name:
			case TeamMemberUserColumn.Name: {
				val = obj.displayName;
				break;
			}
			case ClientUserColumn.Email:
			case TeamMemberUserColumn.Email: {
				val = obj.email;
				break;
			}
			case ClientUserColumn.Group: {
				if (uiTableTitle === UiTableTitle.ClientUserList) {
					val = obj.organizationName;
				}
				break;
			}
			case ClientUserColumn.Type: {
				if (uiTableTitle === UiTableTitle.ClientUserList) {
					val = obj.userClassDisplay;
				}
				break;
			}
			case TeamMemberUserColumn.PhoneNumber: {
				if (uiTableTitle === UiTableTitle.TeamMemberList) {
					// TODO: THIS!!!
				}
				break;
			}
			case TeamMemberUserColumn.Availability: {
				if (uiTableTitle === UiTableTitle.TeamMemberList) {
					// TODO: THIS!!!
				}
				break;
			}
		}
		return {
			children: val,
			title: val,
		};
	}

	@bind
	async refreshPage(params?: Partial<IPageParams>, cb?: () => any): Promise<void> {
		const curr = this.listViewRef.current;
		if (curr) {
			await curr.refreshPage(params, cb);
		}
	}

	render() {
		const {
			onCreateBtnClick,
			onObjectClick,
			...rest
		} = this.props;
		return (
			<>
				<div className="pb-list-view-actions">
					<span/>
					<TubbyButton onClick={onCreateBtnClick}>
						Create user
					</TubbyButton>
				</div>
				<ListView
					cellHook={this.cellHook}
					columnMenuEnabled
					columnsMovable
					onCellClick={this.cellClicked}
					ref={this.listViewRef}
					{...rest}
				/>
			</>
		);
	}
}

