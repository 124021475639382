import React from 'react';
import {
	CheckState,
	StandardButton,
} from '../../constants';

import {WxView} from '../wx';
import {api} from '../../httpapi';
import {ProjectList} from './list';
import {ProjectDetailView} from './detail';
import {ProjectUpdateView} from './update';
import {PaymentFormView} from './paymentform';
import {
	bind,
	idxOk,
	isNumber,
	staticPaymentInfo,
	staticPaginationPage,
	staticProfile,
} from '../../util';
import {
	Button,
	Card,
	CardHeader,
	CardInner,
	ComboBox,
	Dialog,
	GridLayout,
	GridLayoutCell,
	IMenuOptionProps,
	Prompt,
} from '../../components';

export interface IProjectViewProps extends React.HTMLAttributes<any> {
	pk?: string | undefined;
}

interface IPromptInfo {
	body?: string;
	header: string;
	iconName: string;
	subHeader?: string;
}

interface IProjectViewState {
	isUpdating: boolean;
	page: PaginatedProjectPage;
	paginationPerPageOptionIndex: number;
	paymentInfo: IPaymentInfo;
	paymentMethods: Array<IPaymentMethod>;
	profile: IProfile;
	promptDialogIsOpen: boolean;
	selectedServiceAreaPk: number | null;
	serviceAreas: Array<IServiceArea>;
	serviceAreaWx: Array<IWxDay>;
	showPaymentSuccess: boolean;
}

export class ProjectView extends React.Component<Partial<IProjectViewProps>, IProjectViewState> {
	constructor(props: Partial<IProjectViewProps>) {
		super(props);
		this.state = {
			isUpdating: false,
			page: staticPaginationPage(),
			paginationPerPageOptionIndex: 0,
			paymentInfo: staticPaymentInfo(),
			promptDialogIsOpen: false,
			profile: staticProfile(),
			selectedServiceAreaPk: null,
			serviceAreas: [],
			serviceAreaWx: [],
			paymentMethods: [],
			showPaymentSuccess: false,
		};
	}

	@bind
	private closePromptDialog() {
		this.setState({
			promptDialogIsOpen: false,
		});
	}

	async componentDidMount() {
		setTimeout(async () => {
			this.setState({
				paymentMethods: await api.paymentMethodList(),
				profile: await api.profileDetail(),
			});
		}, 1);
	}

	async componentDidUpdate(prevProps: Readonly<Partial<IProjectViewProps>>, prevState: Readonly<IProjectViewState>) {
		const {selectedServiceAreaPk} = this.state;
		if (selectedServiceAreaPk !== prevState.selectedServiceAreaPk) {
			await this.setServiceAreaWx(selectedServiceAreaPk);
		}
	}

	@bind
	private doneProcessingPayment(nfo: IPaymentInfo) {
		this.setState(
			{
				paymentInfo: nfo,
			},
			async () => {
				if (nfo.status !== 'succeeded') {
					this.openPromptDialog();
				}
				await this.paymentProcessingComplete(nfo.status === 'succeeded');
			},
		);
	}

	private async fetchServiceAreaWx(serviceAreaPk: number): Promise<Array<IWxDay>> {
		return await api.serviceAreaWxDayList(serviceAreaPk);
	}

	@bind
	private headerBackButtonPressed() {
		if (this.state.isUpdating) {
			this.stopUpdating();
		} else {
			window.location.assign('/');
		}
	}

	@bind
	private headerUpdateButtonPressed() {
		if (this.state.isUpdating) {
			// UpdateView takes care of this, right?
			// We're probably not even getting called while isUpdating.
			// Hmmm
		} else {
			this.startUpdating();
		}
	}

	@bind
	private hidePaymentSuccess() {
		this.setState({
			showPaymentSuccess: false,
		});
	}

	@bind
	private openPromptDialog() {
		this.setState({
			promptDialogIsOpen: true,
		});
	}

	@bind
	private async paymentProcessingComplete(success: boolean) {
		this.setState(
			{
				showPaymentSuccess: success,
			},
		);
	}

	@bind
	private async projectRowSelectionChanged(checkedIndices: Array<number>) {
		const {
			paymentInfo,
			page,
		} = this.state;
		const selectedProjects: Array<IAnnotatedProject> = [];
		for (const idx of checkedIndices) {
			if (idxOk(idx, page.objects.length, 'projectRowSelectionChanged')) {
				selectedProjects.push(page.objects[idx]);
			}
		}
		this.setState(
			{
				paymentInfo: await api.batchPaymentInfo(
					{
						ids: selectedProjects.map(x => x.id),
						transactionId: paymentInfo.transactionId,
					},
				),
			},
		);
	}

	@bind
	private projectUpdated() {
		this.setState({
			isUpdating: false,
		});
	}

	@bind
	private promptInfo(): IPromptInfo {
		const {paymentInfo} = this.state;
		let body: string;
		let header: string;
		let iconName: string;
		let subHeader: string | undefined = undefined;
		if (paymentInfo.status === 'succeeded') {
			iconName = 'done';
			header = 'All set!';
			body = 'Thank you for your payment!';
		} else {
			iconName = 'error';
			header = 'An error occurred';
			subHeader = 'There was a problem processing your transaction.';
			body = paymentInfo.errorMessage;
		}
		return {
			iconName,
			header,
			subHeader,
			body,
		};
	}

	@bind
	async refreshPage(params?: Partial<IPageParams>): Promise<PaginatedProjectPage> {
		const page = await api.projectList(params);
		this.setState({
			page,
		});
		return page;
	}

	render() {
		const {pk} = this.props;
		const {
			paymentInfo,
			isUpdating,
			profile,
			serviceAreas,
			paymentMethods,
			serviceAreaWx,
			showPaymentSuccess,
			promptDialogIsOpen,
		} = this.state;
		if (pk !== undefined) {
			if (isUpdating) {
				return (
					<ProjectUpdateView
						onSaved={this.projectUpdated}
						onBackClick={this.headerBackButtonPressed}
						onUpdateClick={this.headerUpdateButtonPressed}
						pk={pk}
					/>
				);
			}
			return (
				<ProjectDetailView
					onBackClick={this.headerBackButtonPressed}
					onUpdateClick={this.headerUpdateButtonPressed}
					pk={pk}
				/>
			);
		}
		const nfo = this.promptInfo();
		const showPayment = showPaymentSuccess || ((paymentInfo.amount !== null) && (paymentInfo.status === 'pending'));
		return (
			<>
				<ProjectList
					onRowSelectionChange={this.projectRowSelectionChanged}
					pageGetter={this.refreshPage}
					rowsSelectable={!profile.isProducer}
				/>
				{
					showPayment
						? (
							<Card>
								<CardHeader primaryText="Payment"/>
								<CardInner>
									{
										showPaymentSuccess
											? (
												<div className="display--flex flex-direction--column justify-content--center align-items--center">
													<h4 className="mdc-typography mdc-typography--headline5 margin-bottom--24">Thank you for your payment!</h4>
													<Button onClick={this.hidePaymentSuccess}>
														Dismiss
													</Button>
												</div>
											)
											: (
												<PaymentFormView
													amount={paymentInfo.amount || ''}
													onDoneProcessing={this.doneProcessingPayment}
													onSavePaymentInfoCheckStateChange={this.savePaymentInfoChanged}
													paymentInfo={paymentInfo}
													paymentMethods={paymentMethods}
													processPayment={api.createBatchPayment}
													updatePaymentInfo={api.updateBatchPayment}
												/>
											)
									}
								</CardInner>
							</Card>
						)
						: null
				}
				{
					(serviceAreas.length > 0)
						? (
							<Card>
								<CardHeader>
									<ComboBox
										label="Service area"
										options={this.serviceAreaOptions()}
										onChange={this.serviceAreaOptionSelected}
										renderMinimal
									/>
								</CardHeader>
								<GridLayout>
									<GridLayoutCell span={12}>
										<WxView wxData={serviceAreaWx}/>
									</GridLayoutCell>
								</GridLayout>
							</Card>
						)
						: null
				}
				<Dialog buttons={StandardButton.Close} isOpen={promptDialogIsOpen} onFinished={this.closePromptDialog}>
					<Prompt iconName={nfo.iconName} header={nfo.header} subHeader={nfo.subHeader}>
						{nfo.body}
					</Prompt>
				</Dialog>
			</>
		);
	}

	@bind
	private savePaymentInfoChanged(state: CheckState) {
		this.setState({
			paymentInfo: {
				...this.state.paymentInfo,
				savePaymentMethod: state === CheckState.Checked,
			},
		});
	}

	private serviceAreaOptions(): Array<IMenuOptionProps> {
		const {
			selectedServiceAreaPk,
			serviceAreas,
		} = this.state;
		return serviceAreas.map(svc => {
			return {
				value: String(svc.id),
				isSelected: selectedServiceAreaPk === svc.id,
				label: svc.name,
			};
		});
	}

	@bind
	private serviceAreaOptionSelected(index: number): void {
		const opts = this.serviceAreaOptions();
		if (idxOk(index, opts.length, 'serviceAreaOptionSelected')) {
			const opt = opts[index];
			let n: number | null = null;
			if (opt.value.length > 0) {
				const num = Number.parseInt(opt.value);
				if (isNumber(num)) {
					n = num;
				}
			}
			if (this.state.selectedServiceAreaPk !== n) {
				this.setState({
					selectedServiceAreaPk: n,
				});
			}
		}
	}

	private async setServiceAreaWx(serviceAreaPk: number | null) {
		let data: Array<IWxDay> = [];
		if (serviceAreaPk !== null) {
			data = await this.fetchServiceAreaWx(serviceAreaPk);
		}
		this.setState({
			serviceAreaWx: data,
		});
	}

	@bind
	private startUpdating() {
		this.setState({
			isUpdating: true,
		});
	}

	@bind
	private stopUpdating() {
		this.setState({
			isUpdating: false,
		});
	}
}
