import React from 'react';

import {makeClassName} from '../../util';
import {Button} from '../button';
import {
	ComboBox,
	IComboBoxProps,
} from '../combobox';
import {
	ISwitchProps,
	Switch,
} from '../switch';
import {
	ITextInputProps,
	TextInput,
} from '../textinput';

export interface ICCardProps extends React.HTMLAttributes<any> {
}

export function CCard(props: ICCardProps) {
	const {
		children,
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		'pb-cc-root',
		className,
	);
	return (

		<div className={clsName} {...rest}>
			{children}
		</div>
	);
}

export function CCardBottom(props: React.HTMLAttributes<any>) {
	const {
		children,
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		'pb-cc-edit-bottom-section',
		className,
	);
	return (
		<div className={clsName} {...rest}>
			<div className="pb-cc-edit-bottom-section__buttons">
				{children}
			</div>
		</div>
	);
}

export interface ICCardBottomButtonsProps extends React.HTMLAttributes<any> {
	onCancel: () => any;
	onSave: () => any;
	saveIsSubmit?: boolean;
}

export function CCardBottomButtons(props: Partial<ICCardBottomButtonsProps>) {
	const {
		onCancel,
		onSave,
		saveIsSubmit,
		...rest
	} = props;
	const btnType = saveIsSubmit
		? 'submit'
		: 'button';
	return (
		<CCardBottom {...rest}>
			<Button onClick={onCancel}>Cancel</Button>
			<Button onClick={onSave} type={btnType}>Save</Button>
		</CCardBottom>
	);
}

export function CCardComboBoxControl(props: IComboBoxProps) {
	const {
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		'pb-cc-edit-section__control',
		className,
	);
	return (
		<ComboBox
			className={clsName}
			renderMinimal
			{...rest}
		/>
	);
}

export function CCardContent(props: React.HTMLAttributes<any>) {
	const {
		children,
		className,
	} = props;
	const clsName = makeClassName(
		'pb-cc-scrollable-container',
		className,
	);
	return (
		<div className={clsName}>
			<div className="pb-cc-scrollable-container__inner">
				{children}
			</div>
		</div>
	);
}

export function CCardControls(props: React.HTMLAttributes<any>) {
	const {
		children,
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		'pb-cc-edit-section__controls-container',
		className,
	);
	return (
		<div className={clsName} {...rest}>
			{children}
		</div>
	);
}

export function CCardSwitchControl(props: Partial<ISwitchProps>) {
	const {
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		'pb-cc-edit-section__control',
		className,
	);
	return (
		<Switch
			className={clsName}
			{...rest}
		/>
	);
}

export interface ICCardTextInputControlProps extends ITextInputProps {
	renderNormalSize: boolean;
}

export function CCardTextInputControl(props: Partial<ICCardTextInputControlProps>) {
	const {
		className,
		renderNormalSize,
		...rest
	} = props;
	const clsName = makeClassName(
		'pb-cc-edit-section__control',
		className,
	);
	const renderMin = (renderNormalSize === undefined)
		? true
		: !renderNormalSize;
	return renderMin
		? (
			<TextInput
				className={clsName}
				isMinimal
				{...rest}
			/>
		)
		: (
			<TextInput
				className={clsName}
				{...rest}
			/>
		);
}
