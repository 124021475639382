import React from 'react';

import {
	IListViewProps,
	ListView,
} from '../list';
import {api} from '../../httpapi';
import {ProjectSearch} from './search';
import {
	ProjectStatus,
	UiTableColumnDataType,
	UiTableTitle,
} from '../../constants';
import {
	Card,
	DataTableCellProps,
	IChipSetChip,
} from '../../components';
import {
	bind,
	capitalize,
	debounce,
	idxOk,
	staticCfg,
} from '../../util';

enum ProjectColumn {
	Client = 'Client',
	ClientDueDateTime = 'Client Due',
	Created = 'Created',
	CreatedByUser = 'Created By',
	Description = 'Description',
	Editor = 'Editor',
	Files = 'Files',
	InvoiceTotal = 'Invoice Total',
	Location = 'Location',
	LocationName = 'Location Name',
	Media = 'Media',
	PhotosDueDateTime = 'Photos Due',
	QuickBooks = 'QB',
	QuickBooksInvoiceId = 'QB Invoice #',
	Scheduled = 'Scheduled',
	Services = 'Services',
	Shooter = 'Shooter',
	Status = 'Status',
	Task = 'Task',
	TeamMember = 'Team Member',
	VideoDueDateTime = 'Video Due',
}

export interface IProjectListProps extends Omit<IListViewProps, 'cellHook' | 'cfg' | 'columnMenuEnabled' | 'columnsMovable' | 'searchFilters' | 'selectedStatus' | 'uiTableTitle'> {
}

interface IProjectListState {
	cfg: ICfg;
	searchInput: string;
	selectedStatus: Set<string>;
}

export class ProjectList extends React.Component<IProjectListProps, IProjectListState> {
	private readonly debouncedSearchInputChanged: (value: string) => any;
	private readonly listViewRef: React.RefObject<ListView>;

	constructor(props: IProjectListProps) {
		super(props);
		this.debouncedSearchInputChanged = debounce(
			this._searchInputChanged,
			350,
		);
		this.listViewRef = React.createRef();
		this.state = {
			cfg: staticCfg(),
			searchInput: '',
			selectedStatus: new Set(),
		};
	}

	@bind
	private cellHook(col: ICol, obj: IAnnotatedProject): Partial<DataTableCellProps> {
		let isNumeric: boolean = false;
		let val: string = '';
		switch (col.label) {
			case ProjectColumn.Client: {
				val = obj.client;
				break;
			}
			case ProjectColumn.ClientDueDateTime: {
				isNumeric = true;
				val = obj.clientDue;
				break;
			}
			case ProjectColumn.Created: {
				isNumeric = true;
				val = obj.created;
				break;
			}
			case ProjectColumn.CreatedByUser: {
				val = obj.createdBy;
				break;
			}
			case ProjectColumn.Description: {
				val = obj.description;
				break;
			}
			case ProjectColumn.Editor: {
				val = obj.editor;
				break;
			}
			case ProjectColumn.Files: {
				break;
			}
			case ProjectColumn.InvoiceTotal: {
				isNumeric = true;
				val = obj.invoiceTotal;
				break;
			}
			case ProjectColumn.Location: {
				val = obj.location;
				break;
			}
			case ProjectColumn.LocationName: {
				val = obj.locationName;
				break;
			}
			case ProjectColumn.Media: {
				console.log('projectlist.cellHook: case ProjectColumn.Media. NOT IMPLEMENTED')
				// val = String(obj.hasReleasedUrls);
				break;
			}
			case ProjectColumn.PhotosDueDateTime: {
				isNumeric = true;
				val = obj.photosDue;
				break;
			}
			case ProjectColumn.QuickBooks: {
				break;
			}
			case ProjectColumn.QuickBooksInvoiceId: {
				isNumeric = true;
				break;
			}
			case ProjectColumn.Scheduled: {
				isNumeric = true;
				val = obj.scheduled;
				break;
			}
			case ProjectColumn.Services: {
				val = obj.services;
				break;
			}
			case ProjectColumn.Shooter: {
				val = obj.shooter;
				break;
			}
			case ProjectColumn.Status: {
				val = capitalize(obj.status);
				break;
			}
			case ProjectColumn.Task: {
				break;
			}
			case ProjectColumn.TeamMember: {
				val = obj.teamMember;
				break;
			}
			case ProjectColumn.VideoDueDateTime: {
				isNumeric = true;
				val = obj.videoDue;
				break;
			}
		}
		return {
			href: obj.absoluteUrl,
			children: val,
			dataType: isNumeric
				? UiTableColumnDataType.Integer
				: undefined,
			style: {maxWidth: '300px'},
			title: val,
		};
	}

	@bind
	private cfgFetched(cfg: ICfg): void {
		const fils = cfg.filters.filter(
			x => (x.attribute === 'status'),
		);
		const selectedStatus = new Set<string>();
		for (const fil of fils) {
			if (fil.isEnabled) {
				selectedStatus.add(fil.value);
			}
		}
		this.setState({
			selectedStatus,
		});
	}

	async componentDidMount() {
		await this.refreshCfg();
	}

	@bind
	private async createCfgFilter(fil: ICfgFilter): Promise<ICfgFilter> {
		return await api.createCfgTableFilter(fil);
	}

	@bind
	private async fetchCfg(): Promise<ICfg> {
		const rv = await api.cfgDetail();
		this.cfgFetched(rv);
		return rv;
	}

	@bind
	private projectStatusFilters(): Array<{label: string; isSelected: boolean;}> {
		const {selectedStatus} = this.state;
		return projectFilters.map(obj => {
			return {
				label: obj.label,
				isSelected: selectedStatus.has(obj.value),
			};
		});
	}

	@bind
	async refreshCfg(cb?: () => any): Promise<void> {
		this.setState(
			{
				cfg: await this.fetchCfg(),
			},
			cb,
		);
	}

	@bind
	async refreshPage(params?: Partial<IPageParams>, cb?: () => any): Promise<void> {
		const curr = this.listViewRef.current;
		if (curr) {
			await curr.refreshPage(params, cb);
		}
	}

	render() {
		const {...rest} = this.props;
		const {
			cfg,
			searchInput,
			selectedStatus,
		} = this.state;
		return (
			<>
				<Card style={{marginTop: '24px'}}>
					<ProjectSearch
						onProjectFilterClick={this.statusClicked}
						onSearchFilterClick={this.searchFilterClicked}
						onSearchFilterDelete={this.searchFilterDeleted}
						onSearchInputChange={this.searchInputChanged}
						onSearchInputTrailingIconClick={this.searchSubmitted}
						projectFilters={this.projectStatusFilters()}
						searchFilters={this.searchFilterChipProps()}
						searchInput={searchInput}
						onSearchInputKeyPress={this.searchInputKeyPressed}
					/>
				</Card>
				<Card>
					<ListView
						cellHook={this.cellHook}
						cfg={cfg}
						columnMenuEnabled
						columnsMovable
						ref={this.listViewRef}
						searchFilters={this.searchFilters()}
						selectedStatus={selectedStatus}
						uiTableTitle={UiTableTitle.ProjectList}
						{...rest}
					/>
				</Card>
			</>
		);
	}

	@bind
	private searchFilterChipProps(): Array<Partial<IChipSetChip>> {
		const rv: Array<Partial<IChipSetChip>> = [];
		for (const fil of this.searchFilters()) {
			rv.push({
				text: fil.value,
				isSelected: fil.isEnabled,
				leadingIconName: fil.isEnabled
					? undefined
					: 'search',
			});
		}
		return rv;
	}

	@bind
	private async searchFilterClicked(index: number) {
		const {searchInput} = this.state;
		const fils = this.searchFilters();
		if (idxOk(index, fils.length, 'searchFilterClicked')) {
			const fil = {...fils[index]};
			fil.isEnabled = !fil.isEnabled;
			await this.updateCfgFilter(fil);
			await this.refreshCfg(
				async () => await this.refreshPage(
					{
						q: searchInput.trim().length > 0
							? [searchInput]
							: undefined,
					},
				),
			);
		}
	}

	@bind
	private async searchFilterDeleted(index: number) {
		const {searchInput} = this.state;
		const fils = this.searchFilters();
		if (idxOk(index, fils.length, 'searchFilterDeleted')) {
			const fil = fils[index];
			await api.deleteCfgTableFilter(fil.tableId, fil.id);
			await this.refreshCfg(
				async () => this.refreshPage(
					{
						q: searchInput.trim().length > 0
							? [searchInput]
							: undefined,
					},
				),
			);
		}
	}

	@bind
	searchFilters(): Array<ICfgFilter> {
		const {cfg} = this.state;
		return cfg.filters.filter(
			x => (x.attribute !== 'status'),
		);
	}

	@bind
	private searchInputChanged(value: string) {
		const {searchInput} = this.state;
		const before = searchInput;
		this.setState({searchInput: value});
		if (value.trim() !== before.trim()) {
			this.debouncedSearchInputChanged(value);
		}
	}

	@bind
	private async _searchInputChanged(value: string): Promise<void> {
		await this.refreshPage({q: [value]});
	}

	@bind
	private async searchInputKeyPressed(key: string) {
		switch (key) {
			case 'Enter': {
				await this.searchSubmitted();
				break;
			}
		}
	}

	@bind
	private async searchSubmitted() {
		const {searchInput} = this.state;
		if (searchInput.trim().length > 0) {
			await this.createCfgFilter({
				value: searchInput,
				isEnabled: true,
				id: 0,
				tableId: UiTableTitle.ProjectList,
				attribute: '',
			});
			this.setState({searchInput: ''});
			await this.refreshCfg(
				async () => await this.refreshPage(),
			);
		}
	}

	@bind
	private async statusClicked(index: number) {
		const {selectedStatus} = this.state;
		if (idxOk(index, projectFilters.length, 'statusClicked')) {
			const obj = projectFilters[index];
			if (selectedStatus.has(obj.value)) {
				selectedStatus.delete(obj.value);
			} else {
				selectedStatus.add(obj.value);
			}
			this.setState(
				{
					selectedStatus: new Set(selectedStatus),
				},
				async () => await this.refreshPage(
					undefined,
					async () => await this.refreshCfg(),
				),
			);
		}
	}

	@bind
	private async updateCfgFilter(fil: ICfgFilter) {
		await api.updateCfgTableFilter(fil);
	}
}

const projectFilters = [
	{
		isSelected: false,
		label: 'PENDING',
		value: ProjectStatus.Pending,
	},
	{
		isSelected: false,
		label: 'ON HOLD',
		value: ProjectStatus.OnHold,
	},
	{
		isSelected: false,
		label: 'CONFIRMED',
		value: ProjectStatus.InProgress,
	},
	{
		isSelected: false,
		label: 'COMPLETE',
		value: ProjectStatus.Complete,
	},
	{
		isSelected: false,
		label: 'CANCELED',
		value: ProjectStatus.Canceled,
	},
];
