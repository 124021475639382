import React from 'react';

import * as datetime from '../../datetime';
import {SubHeader} from '../subheader';
import {
	bind,
	isNumber,
	makeClassName,
	makeTime,
	padStart,
	range,
	secondsToDuration,
	to12Hour,
	toPeriod,
} from '../../util';

// Keep in-sync with scss/_timeline.scss $temp-hour-height
const CSS_HOUR_HEIGHT_PX = 48;
export const DEFAULT_SEGMENT_STEP = 30;

interface ITimePickerProps extends Omit<React.HTMLAttributes<any>, 'onClick'> {
	anyTime: boolean;
	dateString: string;
	events: Array<datetime.ISchedulerBlock>;
	onClick: (value: string) => any;
	onAnyTimeClick: (checked: boolean) => any;
	onSegmentClick: (data: datetime.time) => any;
	segmentStep: number;
	times: Array<datetime.time>;
	timeSelectAtIsoTime: string;
}

export class TimePicker extends React.Component<Partial<ITimePickerProps>, {}> {
	earliestHour(): number | null {
		const times = this.times();
		return (times.length > 0)
			? times[0].hour
			: null;
	}

	eventHeight(duration: number): number {
		const dur = secondsToDuration(duration);
		const hourFraction = dur.minutes / 60;
		const numHourBlocks = dur.hours;
		return ((CSS_HOUR_HEIGHT_PX * numHourBlocks) + (CSS_HOUR_HEIGHT_PX * hourFraction));
	}

	eventYOffset(eventTime: datetime.time): number {
		const earlyHour = this.earliestHour();
		if (earlyHour || (typeof earlyHour === 'number')) {
			const evtHour = eventTime.hour;
			const hourFraction = eventTime.minute / 60;
			return this.yOffset(evtHour - earlyHour, hourFraction);
		}
		return 0;
	}

	render() {
		const {
			anyTime,
			className,
			dateString,
			events,
			onAnyTimeClick,
			onClick,
			onSegmentClick,
			segmentStep,
			timeSelectAtIsoTime,
			...rest
		} = this.props;
		const clsName = makeClassName(
			'pb-timeline-scroll-container',
			'margin-top--16',
			className,
		);
		const times = this.times();
		const noTime = (times.length === 0);
		const showNoTime = noTime && !anyTime;
		const showTimes = !(anyTime || noTime);
		const tt = (
			<div className={clsName} {...rest}>
				<div className="pb-timeline-container">
					{
						times.map((time, idx) =>
							<Hour
								key={idx}
								onClick={this.timeClicked.bind(this, idx)}/>)
					}
					<div className="pb-timeline-left-rail"/>
					{
						(events || []).map((evt, idx) => {
							return (
								<Event
									color={evt.color}
									height={this.eventHeight(evt.duration)}
									interactive={evt.interactive}
									key={idx}
									yOffset={this.eventYOffset(evt.start.time())}
									zIndex={evt.zIndex}
								/>
							);
						})
					}
					<LeftRail times={times}/>
					<Dividers count={times.length}/>
					{
						this.showSegmentSelect()
							? (
								<SegmentSelect
									onClick={this.segmentSelect}
									segments={this.selectSegments()}
									yOffset={this.segmentSelectYOffset()}
								/>
							)
							: null
					}
				</div>
			</div>
		);
		return showNoTime
			? <TimesNoTime/>
			: (
				<>
					<SubHeader className="padding-bottom--16">
						{dateString}
					</SubHeader>
					<TimesAnytime
						isChecked={anyTime}
						onClick={onAnyTimeClick
							? () => onAnyTimeClick(!anyTime)
							: undefined}
					/>
					{
						showTimes
							? tt
							: null
					}
				</>
			);
	}

	@bind
	segmentSelect(index: number): void {
		const {onSegmentClick} = this.props;
		if (onSegmentClick === undefined) {
			return;
		}
		const segments = this.selectSegments();
		const segment = segments[index];
		if (segment) {
			onSegmentClick(segment);
			return;
		}
		console.log('Times::segmentSelect index of out of range');
	}

	segmentSelectYOffset(): number {
		const {timeSelectAtIsoTime} = this.props;
		if (timeSelectAtIsoTime) {
			const idx = this.timeIndexByISOFormat(timeSelectAtIsoTime);
			return this.yOffset(Math.max(0, idx));
		}
		return 0;
	}

	selectSegments(): Array<datetime.time> {
		const {
			segmentStep,
			timeSelectAtIsoTime,
		} = this.props;
		const step = (segmentStep === undefined)
			? DEFAULT_SEGMENT_STEP
			: segmentStep;
		const time = (timeSelectAtIsoTime === undefined)
			? null
			: this.timeByISOFormat(timeSelectAtIsoTime);
		if (time) {
			const segments: Array<datetime.time> = [];
			const hour = time.hour;
			let minute = 0;
			while (minute < 60) {
				segments.push(makeTime(hour, minute));
				minute += step;
			}
			return segments;
		}
		return [];
	}

	showSegmentSelect(): boolean {
		const {timeSelectAtIsoTime} = this.props;
		return (timeSelectAtIsoTime === undefined)
			? false
			: (timeSelectAtIsoTime.length > 0);
	}

	timeByISOFormat(fmat: string): datetime.time | null {
		const times = this.times();
		const idx = times.findIndex(t => (t.isoformat() === fmat));
		if ((idx >= 0) && (idx < times.length)) {
			return times[idx];
		}
		return null;
	}

	timeClicked(index: number): void {
		const {onClick} = this.props;
		if (onClick === undefined) {
			return;
		}
		const times = this.times();
		if ((index >= 0) && (index < times.length)) {
			onClick(times[index].isoformat());
		}
	}

	timeIndexByISOFormat(fmat: string): number {
		return this.times().findIndex(t => (t.isoformat() === fmat));
	}

	private times(): Array<datetime.time> {
		const {times} = this.props;
		return (times === undefined)
			? []
			: times;
	}

	yOffset(wholeHours: number, hourFraction: number = 0): number {
		return (wholeHours * CSS_HOUR_HEIGHT_PX) + (CSS_HOUR_HEIGHT_PX * hourFraction);
	}
}

interface IDividersProps extends React.HTMLAttributes<any> {
	count: number;
}

function Dividers(props: Partial<IDividersProps>) {
	const {
		children,
		className,
		count,
		...rest
	} = props;
	const clsName = makeClassName(
		'pb-timeline-dividers',
		className,
	);
	const c = (count === undefined)
		? 0
		: count;
	return (
		<div className={clsName} {...rest}>
			<div className="pb-timeline-v-divider"/>
			{
				range(c).map(i =>
					<div className="pb-timeline-h-divider" key={i}/>)
			}
		</div>
	);
}

interface IEventProps extends React.HTMLAttributes<any> {
	color: string;
	height: number;
	interactive: boolean;
	yOffset: number;
	zIndex: number;
}

function Event(props: Partial<IEventProps>) {
	const {
		children,
		className,
		color,
		height,
		interactive,
		yOffset,
		zIndex,
		...rest
	} = props;
	const style = {
		backgroundColor: color || '#DBE1EA',
		height: `${height}px`,
		top: `${yOffset}px`,
		zIndex: isNumber(zIndex)
			? zIndex
			: undefined,
	};
	const clsName = makeClassName(
		'pb-timeline-event',
		interactive
			? 'pointer-events--none'
			: undefined,
		className,
	);
	return (
		<div className={clsName} style={style} {...rest}>
			{children}
		</div>
	);
}

interface IHourProps extends React.HTMLAttributes<any> {
	isDisabled: boolean;
}

function Hour(props: Partial<IHourProps>) {
	const {
		children,
		className,
		isDisabled,
		...rest
	} = props;
	const clsName = makeClassName(
		'pb-timeline-hour',
		isDisabled
			? 'pb-timeline-hour--disabled'
			: undefined,
		className,
	);
	return <div className={clsName} {...rest}/>;
}

interface ILeftRailProps extends React.HTMLAttributes<any> {
	times: Array<datetime.time>;
}

function LeftRail(props: Partial<ILeftRailProps>) {
	const {
		className,
		times,
		...rest
	} = props;
	const clsName = makeClassName(
		'pb-timeline-left-rail-items',
		className,
	);
	const t = (times === undefined)
		? []
		: times;
	return (
		<div className={clsName} {...rest}>
			{
				t.map((time, idx) =>
					<div className="pb-timeline-left-rail-item" key={idx}>
						<div>{to12Hour(time.hour)}</div>
						<div className="font-weight--300">{toPeriod(time.hour)}</div>
					</div>)
			}
		</div>
	);
}

interface ITimesAnytimeProps extends React.HTMLAttributes<any> {
	isChecked: boolean;
}

function TimesAnytime(props: Partial<ITimesAnytimeProps>) {
	const {
		isChecked,
		onClick,
		...rest
	} = props;
	const icon = isChecked
		? 'check_circle'
		: 'radio_button_unchecked';
	const bgClassName = isChecked
		? 'pb-project-create-service-list-item--selected'
		: 'background-color--pb-dark-blue';
	const liClsName = makeClassName(
		`mdc-list-item`,
		'pb-project-create-service-list-item',
		bgClassName,
		'pb-color--white',
	);
	const iClsName = makeClassName(
		'material-icons',
		'mdc-list-item__graphic',
		'pb-color--white',
		'pb-margin-right--8',
	);
	return (
		<div {...rest}>
			<ul className="mdc-list padding--0-0-0-0">
				<li className={liClsName} onClick={onClick} style={{
					alignItems: 'center',
					height: '48px',
				}}>
					<i aria-hidden="true" className={iClsName}>{icon}</i>
					Anytime
				</li>
			</ul>
		</div>
	);
}

interface ITimesNoTimeProps extends React.HTMLAttributes<any> {
}

function TimesNoTime(props: Partial<ITimesNoTimeProps>) {
	const {
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		'display--flex',
		'flex-direction--column',
		'align-items--center',
		'justify-content--center',
		'color--grayish',
		'padding-top--32',
		'padding-bottom--8',
	);
	return (
		<div className={clsName} {...rest}>
			<i aria-hidden="true" className="material-icons font-size--32px">event_busy</i>
			<span className="padding-top--4">No times available on this day</span>
		</div>
	);
}

interface ISegmentSelectProps extends Omit<React.HTMLAttributes<any>, 'onClick'> {
	onClick: (index: number) => any;
	segments: Array<datetime.time>;
	yOffset: number;
}

class SegmentSelect extends React.Component<Partial<ISegmentSelectProps>, {}> {
	primaryText(seg: datetime.time): string {
		return `${to12Hour(seg.hour)}:${padStart(seg.minute, 2, '0')}`;
	}

	render() {
		const {
			className,
			onClick,
			segments,
			yOffset,
			...rest
		} = this.props;
		const style = {top: this.topPx()};
		const clsName = makeClassName(
			'pb-timeline-segment-select',
			className,
		);
		const segs = (segments === undefined)
			? []
			: segments;
		return (
			<div className={clsName} style={style} {...rest}>
				{segs.map((seg, idx) =>
					<Segment
						key={idx}
						secondaryText={this.secondaryText(seg)}
						primaryText={this.primaryText(seg)}
						onClick={this.segmentClicked.bind(this, idx)}/>)}
			</div>
		);
	}

	secondaryText(seg: datetime.time): string {
		return toPeriod(seg.hour);
	}

	segmentClicked(index: number): void {
		const {onClick} = this.props;
		if (onClick) {
			onClick(index);
		}
	}

	topPx(): string {
		return `${this.props.yOffset}px`;
	}
}

interface ISegmentProps extends React.ButtonHTMLAttributes<any> {
	primaryText: string;
	secondaryText: string;
}

function Segment(props: ISegmentProps) {
	const {
		primaryText,
		secondaryText,
		...rest
	} = props;
	return (
		<button className="mdc-button pb-timeline-segment display--block" type="button" {...rest}>
			<span className="mdc-button__ripple"/>
			{
				primaryText
					? <span className="mdc-button__label">{primaryText}</span>
					: null
			}
			{
				secondaryText
					? <span className="padding-left--4 font-weight--300">{secondaryText}</span>
					: null
			}
		</button>
	);
}
