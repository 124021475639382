type RegexPatternMatch = [string, Array<string>];
export type URLPatterns = Array<URLPattern | URLResolver>;
export type Callable = ((...args: Array<any>) => any) | (new (...args: Array<any>) => any);

export function getResolver(urlPatterns?: URLPatterns) {
	return new URLResolver(new RegexPattern(/^\//), urlPatterns);
}

export class RegexPattern {
	isEndpoint: boolean;
	name: string | null;
	regex: RegExp;

	constructor(regex: RegExp, name: string | null = null, isEndpoint: boolean = false) {
		this.isEndpoint = isEndpoint;
		this.name = name;
		this.regex = regex;
	}

	get pattern(): string {
		return this.regex.source;
	}

	match(path: string): RegexPatternMatch | null {
		const match = this.regex.exec(path);
		if (!match) {
			return null;
		}
		const substr = match[0];
		const args = match.slice(1);
		return [path.slice(substr.length), args];
	}

	toString(): string {
		return this.regex.toString();
	}
}

export class ResolverMatch {
	args: Array<string>;
	func: Callable;
	route: string | null;
	urlName: string | null;

	constructor(func: Callable, args: Array<string>, urlName: string | null = null, route: string | null = null) {
		this.args = args;
		this.func = func;
		this.route = route;
		this.urlName = urlName;
	}
}

export class URLPattern {
	callback: Callable;
	name: string | null;
	pattern: RegexPattern;

	constructor(pattern: RegexPattern, callback: Callable, name: string | null = null) {
		this.callback = callback;
		this.name = name;
		this.pattern = pattern;
	}

	resolve(path: string): ResolverMatch | null {
		const match = this.pattern.match(path);
		if (!match) {
			return null;
		}
		return new ResolverMatch(
			this.callback,
			match[1],
			this.pattern.name,
			this.pattern.toString());
	}
}

type RevDictList = Array<[string, string]>;
type RevDict<T> = Map<T, RevDictList>;
type RevLookupDict = {byView: RevDict<Callable>; byName: RevDict<string>};

export class URLResolver {
	static joinRoute(route1: string, route2: string): string {
		if (!route1) {
			return route2;
		}
		if (route2.startsWith('^')) {
			route2 = route2.slice(1);
		}
		return route1 + route2;
	}

	pattern: RegexPattern;
	urlPatterns: URLPatterns;
	private _populated: boolean;
	private _populating: boolean;
	private _reverseDict: RevLookupDict;

	constructor(pattern: RegexPattern, urlPatterns?: URLPatterns) {
		this.pattern = pattern;
		this._reverseDict = {byName: new Map(), byView: new Map()};
		this.urlPatterns = urlPatterns || [];
		this._populated = false;
		this._populating = false;
	}

	resolve(path: string): ResolverMatch {
		const match = this.pattern.match(path);
		const tried: Array<string> = [];
		if (match) {
			const [newPath, args] = match;
			for (let i = 0; i < this.urlPatterns.length; ++i) {
				const pattern = this.urlPatterns[i];
				try {
					const subMatch = pattern.resolve(newPath);
					if (subMatch) {
						const subMatchArgs = args.concat(subMatch.args);
						const currentRoute = (pattern instanceof URLPattern) ?
							'' :
							pattern.pattern.toString();
						return new ResolverMatch(
							subMatch.func,
							subMatchArgs,
							subMatch.urlName,
							URLResolver.joinRoute(currentRoute, subMatch.route || ''));
					}
				} catch (exc) {
					tried.push((<object>exc).toString());
				}
			}
		}
		let msg = `Unresolved path "${path}"`;
		const triedStr = tried.join('\n');
		if (triedStr) {
			msg = `${msg}. Tried:\n${triedStr}`;
		}
		throw new Error(msg);
	}

	reverseDict(): RevLookupDict {
		if (!this._populated) {
			this._populate();
		}
		return this._reverseDict;
	}

	private _populate(): void {
		if (this._populating) {
			return;
		}
		try {
			this._populating = true;
			const lookupsByView: RevDict<Callable> = new Map();
			const lookupsByName: RevDict<string> = new Map();
			const urlPatterns = [...this.urlPatterns];
			urlPatterns.reverse();
			for (let i = 0; i < urlPatterns.length; ++i) {
				const urlPattern = urlPatterns[i];
				let pPattern = urlPattern.pattern.pattern;
				if (pPattern.startsWith('^')) {
					pPattern = pPattern.slice(1);
				}
				if (urlPattern instanceof URLPattern) {
					const view = urlPattern.callback;
					let vLookups = lookupsByView.get(view);
					if (!vLookups) {
						vLookups = [];
						lookupsByView.set(view, vLookups);
					}
					vLookups.push([urlPattern.pattern.pattern, pPattern]);
					const name = urlPattern.name;
					if (name) {
						let nLookups = lookupsByName.get(name);
						if (!nLookups) {
							nLookups = [];
							lookupsByName.set(name, nLookups);
						}
						nLookups.push([urlPattern.pattern.pattern, pPattern]);
					}
				} else {
					urlPattern._populate();
					const pReverseDict = urlPattern.reverseDict();
					const pReverseDictByName = pReverseDict.byName;
					const pReverseDictByView = pReverseDict.byView;
					for (const [pName, pList] of pReverseDictByName) {
						for (const patts of pList) {
							let nLookups = lookupsByName.get(pName);
							if (!nLookups) {
								nLookups = [];
								lookupsByName.set(pName, nLookups);
							}
							const newMatches = pPattern + patts[1];
							nLookups.push([newMatches, newMatches]);
						}
					}
					for (const [pName, pList] of pReverseDictByView) {
						for (const patts of pList) {
							let vLookups = lookupsByView.get(pName);
							if (!vLookups) {
								vLookups = [];
								lookupsByView.set(pName, vLookups);
							}
							const newMatches = pPattern + patts[1];
							vLookups.push([newMatches, newMatches]);
						}
					}
				}
			}
			this._reverseDict = {byName: lookupsByName, byView: lookupsByView};
			this._populated = true;
		} finally {
			this._populating = false;
		}
	}
}
