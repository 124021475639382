import React from 'react';

import {bind} from '../../util';
import {api} from '../../httpapi';
import {UiTableTitle} from '../../constants';
import {DataTableCellProps} from '../../components';
import {
	IListViewProps,
	ListView,
} from '../list';

enum UserClassColumn {
	Name = 'Name',
}

export interface IUserClassListProps extends Omit<IListViewProps, 'cellHook' | 'pageGetter' | 'uiTableTitle' | 'columnSelectionEnabled' | 'columnsDraggable'> {
	onObjectClick?: (pk: number) => any;
}

interface IUserClassListState {
}

export class UserClassList extends React.Component<IUserClassListProps, IUserClassListState> {
	private readonly listViewRef: React.RefObject<ListView>;

	constructor(props: IUserClassListProps) {
		super(props);
		this.listViewRef = React.createRef();
	}

	@bind
	private cellClicked(obj: IUserClass): void {
		const {onObjectClick} = this.props;
		if (onObjectClick !== undefined) {
			onObjectClick(
				obj.id,
			);
		}
	}

	@bind
	private cellHook(col: ICol, obj: IServiceArea): Partial<DataTableCellProps> {
		let val: string = '';
		switch (col.label) {
			case UserClassColumn.Name: {
				val = obj.name;
				break;
			}
		}
		return {
			children: val,
			title: val,
		};
	}

	@bind
	async refreshPage(params?: Partial<IPageParams>, cb?: () => any): Promise<void> {
		const curr = this.listViewRef.current;
		if (curr) {
			await curr.refreshPage(params, cb);
		}
	}

	render() {
		const {
			onObjectClick,
			...rest
		} = this.props;
		return (
			<ListView
				cellHook={this.cellHook}
				onCellClick={this.cellClicked}
				pageGetter={api.userClassList}
				uiTableTitle={UiTableTitle.UserClassList}
				columnMenuEnabled
				columnsMovable
				ref={this.listViewRef}
				{...rest}
			/>
		);
	}
}
