import React from 'react';

import {makeClassName} from '../util';

export interface IContentContainerProps extends React.HTMLAttributes<any> {
}

export function ContentContainer(props: IContentContainerProps) {
	const {
		children,
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		'pb-content-container',
		className,
	);
	return (
		<div className={clsName} {...rest}>
			{children}
		</div>
	);
}

export function WideContentContainer(props: IContentContainerProps) {
	const {
		children,
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		'pb-content-container--wide',
		className,
	);
	return (
		<ContentContainer className={clsName} {...rest}>
			{children}
		</ContentContainer>
	);
}
