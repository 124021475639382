import React from 'react';

import {LineRipple} from './lineripple';
import {FloatingLabel} from './floatinglabel';
import {NotchedOutline} from './notchedoutline';
import {
	IMenuOptionProps,
	Menu,
	Corner,
} from './menu';
import {
	bind,
	debounce,
	makeClassName,
} from '../util';

export interface IComboBoxProps extends Omit<React.HTMLAttributes<any>, 'onChange'> {
	isDisabled?: boolean;
	isOutlined?: boolean;
	isRequired?: boolean;
	label?: string;
	noLabel?: boolean;
	renderMinimal?: boolean;
	onChange?: (selectedIndex: number) => any;
	options: Array<IMenuOptionProps>;
}

interface IComboBoxState {
	hasFocus: boolean;
	menuIsOpen: boolean;
}

export class ComboBox extends React.Component<IComboBoxProps, IComboBoxState> {
	private readonly anchorRef: React.RefObject<HTMLDivElement>;
	private readonly floatingLabelRef: React.RefObject<FloatingLabel>;
	private readonly lineRippleRef: React.RefObject<LineRipple>;
	private readonly menuRef: React.RefObject<Menu>;
	private readonly notchedOutlineRef: React.RefObject<NotchedOutline>;
	private readonly rootRef: React.RefObject<HTMLDivElement>;
	private readonly debouncedAnchorClicked: (event: React.MouseEvent) => any;

	constructor(props: IComboBoxProps) {
		super(props);
		this.anchorRef = React.createRef();
		this.debouncedAnchorClicked = debounce(this.anchorClicked, 330, {leading: true});
		this.floatingLabelRef = React.createRef();
		this.lineRippleRef = React.createRef();
		this.menuRef = React.createRef();
		this.notchedOutlineRef = React.createRef();
		this.rootRef = React.createRef();
		this.state = {
			hasFocus: false,
			menuIsOpen: false,
		};
	}

	@bind
	private anchorClicked(event: React.MouseEvent) {
		const {
			isDisabled,
		} = this.props;
		const {
			menuIsOpen,
		} = this.state;
		this.focusAnchor();
		if (isDisabled) {
			return;
		}
		if (menuIsOpen) {
			this.closeMenu();
			return;
		}
		this.setLineRippleCenter(event.clientX);
		this.openMenu();
	}

	@bind
	private anchorGotFocus() {
		this.setState({
			hasFocus: true,
		});
		this.setLineRippleActive(true);
	}

	private anchorHasFocus(): boolean {
		const curr = this.anchorRef.current;
		if (curr) {
			return curr === document.activeElement;
		}
		return false;
	}

	@bind
	private anchorKeyPressed(event: React.KeyboardEvent) {
		const {
			hasFocus,
			menuIsOpen,
		} = this.state;
		if (menuIsOpen || !hasFocus) {
			return;
		}
		const isEnter = event.key === 'Enter';
		const isSpace = event.key === 'Spacebar';
		const arrowUp = event.key === 'ArrowUp';
		const arrowDown = event.key === 'ArrowDown';
		if (isEnter || isSpace || arrowUp || arrowDown) {
			this.openMenu();
			event.preventDefault();
		}
	}

	@bind
	private anchorLostFocus() {
		if (this.state.menuIsOpen) {
			return;
		}
		this.blur();
	}

	private blur() {
		const curr = this.rootRef.current;
		if (curr) {
			curr.blur();
		}
		this.setState({
			hasFocus: false,
		});
		this.setLineRippleActive(false);
	}

	@bind
	private closeMenu() {
		if (this.state.menuIsOpen) {
			this.setState({
				menuIsOpen: false,
			});
		}
	}

	componentDidMount() {
		this.setMenuAnchorCorner(Corner.BOTTOM_START);
		this.fixLabel();
	}

	componentDidUpdate(prevProps: Readonly<IComboBoxProps>, prevState: Readonly<IComboBoxState>) {
		const {isDisabled} = this.props;
		if ((prevProps.isDisabled !== isDisabled) && isDisabled) {
			this.closeMenu();
		}
		const diffVal = this._selectedValue(prevProps.options) !== this._selectedValue(this.props.options);
		if (diffVal) {
			this.fixLabel();
		}
	}

	private fixLabel() {
		const label = this.floatingLabelRef.current;
		if (label) {
			const floatNotch = this.selectedValue().trim().length > 0 || this.state.hasFocus;
			this.setNotchedOutlineNotched(floatNotch);
			label.setFloating(floatNotch);
			label.setRequired(Boolean(this.props.isRequired));
		}
	}

	private floatingLabelWidth(): number {
		const curr = this.floatingLabelRef.current;
		if (curr) {
			return curr.width();
		}
		return 0;
	}

	private focusAnchor() {
		const curr = this.anchorRef.current;
		if (curr) {
			curr.focus();
		}
	}

	private focusMenuItem(index: number) {
		const curr = this.menuRef.current;
		if (curr) {
			curr.focusListItemAtIndex(index);
		}
	}

	isValid(): boolean {
		const {
			isDisabled,
			isRequired,
		} = this.props;
		if (isRequired && !isDisabled) {
			const selIdx = this.selectedIndex();
			return (selIdx !== -1) && ((selIdx !== 0) || (Boolean(this.selectedValue())));
		}
		return true;
	}

	@bind
	private menuClosed() {
		if (!this.anchorHasFocus()) {
			this.blur();
		}
		this.closeMenu();
	}

	@bind
	private menuOpened() {
		const selIdx = this.selectedIndex();
		const focusIdx = (selIdx >= 0)
			? selIdx
			: 0;
		this.focusMenuItem(focusIdx);
	}

	@bind
	private menuOptionSelected(index: number) {
		this.closeMenu();
		const {onChange} = this.props;
		if (onChange) {
			onChange(index);
		}
	}

	@bind
	private openMenu() {
		if (!this.state.menuIsOpen) {
			this.setState({
				menuIsOpen: true,
			});
		}
	}

	render() {
		const {
			className,
			isDisabled,
			isOutlined,
			isRequired,
			label,
			noLabel,
			onChange,
			options,
			renderMinimal,
			...rest
		} = this.props;
		const {
			hasFocus,
			menuIsOpen,
		} = this.state;
		const isValid = this.isValid();
		const clsName = makeClassName(
			'mdc-select',
			menuIsOpen
				? 'mdc-select--activated'
				: undefined,
			hasFocus
				? 'mdc-select--focused'
				: undefined,
			isValid
				? undefined
				: 'mdc-select--invalid',
			isOutlined
				? 'mdc-select--outlined'
				: 'mdc-select--filled',
			noLabel
				? 'mdc-select--no-label'
				: undefined,
			renderMinimal
				? 'pb-combo-box--minimal'
				: undefined,
			isDisabled
				? 'mdc-select--disabled'
				: undefined,
			isRequired
				? 'mdc-select--required'
				: undefined,
			className,
		);
		const lbl = noLabel
			? null
			: (
				<FloatingLabel ref={this.floatingLabelRef}>
					{label}
				</FloatingLabel>
			);
		const anchorTabIdx = isDisabled
			? undefined
			: 0;
		const menuClsName = makeClassName(
			'mdc-select__menu',
			isValid
				? undefined
				: 'mdc-select__menu--invalid',
		);
		return (
			<div className={clsName} ref={this.rootRef} {...rest}>
				<div className="mdc-select__anchor" onBlur={this.anchorLostFocus} onClick={this.debouncedAnchorClicked} onFocus={this.anchorGotFocus} onKeyDown={this.anchorKeyPressed} ref={this.anchorRef} role="button" tabIndex={anchorTabIdx}>
					{
						isOutlined
							? (
								<NotchedOutline ref={this.notchedOutlineRef}>
									{lbl}
								</NotchedOutline>
							)
							: (
								<>
									<div className="mdc-select__ripple"/>
									{lbl}
								</>
							)
					}
					<div className="mdc-select__selected-text-container">
						<div className="mdc-select__selected-text">{this.selectedLabel()}</div>
					</div>
					<div className="mdc-select__dropdown-icon">
						<svg className="mdc-select__dropdown-icon-graphic" focusable={false} viewBox="7 10 10 5">
							<polygon className="mdc-select__dropdown-icon-inactive" fillRule="evenodd" points="7 10 12 15 17 10" stroke="none"/>
							<polygon className="mdc-select__dropdown-icon-active" fillRule="evenodd" points="7 15 12 10 17 15" stroke="none"/>
						</svg>
					</div>
					{
						isOutlined
							? null
							: <LineRipple ref={this.lineRippleRef}/>
					}
				</div>
				<Menu
					className={menuClsName}
					fullWidth
					onClose={this.menuClosed}
					isOpen={menuIsOpen}
					onOpen={this.menuOpened}
					onSelection={this.menuOptionSelected}
					options={options}
					ref={this.menuRef}
				/>
			</div>
		);
	}

	selectedIndex(): number {
		const {options} = this.props;
		for (let i = 0; i < options.length; ++i) {
			if (options[i].isSelected) {
				return i;
			}
		}
		return -1;
	}

	private selectedLabel(): string {
		const {options} = this.props;
		for (const obj of options) {
			if (obj.isSelected) {
				return (obj.label === undefined)
					? obj.value
					: obj.label;
			}
		}
		return '';
	}

	private setMenuAnchorCorner(corner: Corner) {
		const curr = this.menuRef.current;
		if (curr) {
			curr.setAnchorCorner(corner);
		}
	}

	selectedValue(): string {
		return this._selectedValue(this.props.options);
	}

	private _selectedValue(opts: Array<IMenuOptionProps>): string {
		for (const obj of opts) {
			if (obj.isSelected) {
				return obj.value;
			}
		}
		return '';
	}

	private setLineRippleActive(active: boolean) {
		const curr = this.lineRippleRef.current;
		if (curr) {
			curr.setActive(active);
		}
	}

	private setLineRippleCenter(center: number) {
		const curr = this.lineRippleRef.current;
		if (curr) {
			curr.setRippleCenter(center);
		}
	}

	private setNotchedOutlineNotched(notched: boolean) {
		const curr = this.notchedOutlineRef.current;
		if (curr) {
			if (notched) {
				curr.open(this.floatingLabelWidth() * 0.75);
			} else if (!this.state.hasFocus) {
				curr.close();
			}
		}
	}
}
