import React from 'react';

import {api} from '../../httpapi';
import {
	ChildProductFormView,
	ProductFormView,
} from './form';
import {
	CheckState,
	DialogCode,
	ProductUserRole,
	StandardButton,
} from '../../constants';
import {
	Button,
	Dialog,
	IconButton,
	IMenuOptionProps,
	Menu,
	Prompt,
	WideContentContainer,
	WideContentHeader,
} from '../../components';
import {
	bind,
	deepCopy,
	idxOk,
	staticPriceGroupProductPrice,
	staticProduct,
	staticProductOption,
	staticProductUser,
} from '../../util';

export interface IProductDetailViewProps extends React.HTMLAttributes<any> {
	pk?: number | string | null;
}

interface IChildObj extends IProduct {
	priceObjs: Array<IPriceGroupProductPrice>;
}

interface IProductDetailViewState {
	activeChild: IChildObj | null;
	addOns: Array<IProduct>;
	childDialogIsOpen: boolean;
	children: Array<IChildObj>;
	moreMenuCoords: PlainCoords;
	moreMenuIsOpen: boolean;
	obj: IProduct;
	options: Array<IProductOption>;
	priceGroups: Array<IPriceGroup>;
	prices: Array<IPriceGroupProductPrice>;
	productUsers: Array<IProductUser>;
	promptDialogIsOpen: boolean;
	quickBooksItems: Array<IQuickBooksItem>;
	userClasses: Array<IUserClass>;
	users: Array<IUser>;
}

export class ProductDetailView extends React.Component<IProductDetailViewProps, IProductDetailViewState> {
	constructor(props: IProductDetailViewProps) {
		super(props);
		this.state = {
			activeChild: null,
			addOns: [],
			childDialogIsOpen: false,
			children: [],
			moreMenuCoords: {
				x: 0,
				y: 0,
			},
			moreMenuIsOpen: false,
			obj: staticProduct(),
			options: [],
			priceGroups: [],
			prices: [],
			productUsers: [],
			promptDialogIsOpen: false,
			quickBooksItems: [],
			userClasses: [],
			users: [],
		};
	}

	@bind
	private addChild() {
		const {
			children,
			obj,
		} = this.state;
		const childObj = {
			...staticProduct(),
			parentId: obj.id,
			priceObjs: [],
			delPricePks: [],
		};
		this.setState(
			{
				activeChild: deepCopy(childObj),
				children: [
					...children,
					childObj,
				],
			},
			this.openChildDialog,
		);
	}

	@bind
	private addOption() {
		const {
			obj,
			options,
		} = this.state;
		this.setState({
			options: [
				...options,
				{
					...staticProductOption(),
					productId: obj.id,
				},
			],
		});
	}

	@bind
	private addOnClicked(index: number) {
		const {addOns} = this.state;
		const {obj} = this.state;
		if (idxOk(index, addOns.length, 'addOnClicked')) {
			const addonObj = addOns[index];
			const addonIdIdx = obj.addOns.indexOf(addonObj.id);
			const newAddonPks: Array<number> = [
				...obj.addOns,
			];
			if (addonIdIdx >= 0) {
				// Remove
				newAddonPks.splice(addonIdIdx, 1);
			} else {
				// Add
				newAddonPks.push(addonObj.id);
			}
			this.setState({
				obj: {
					...obj,
					addOns: newAddonPks,
				},
			});
		}
	}

	@bind
	private assignedUsersChanged(userId: string, roleId: ProductUserRole, state: CheckState) {
		const {
			obj,
			productUsers,
			users,
		} = this.state;
		const userIdx = users.findIndex(x => x.email === userId);
		if (userIdx === -1) {
			console.log('assignableUsersChanged: Invalid object ID: %s', userId);
			return;
		}
		const isChecked = state === CheckState.Checked;
		const existing = productUsers.findIndex(x => ((x.roleId === roleId) && (x.userId === userId)));
		const exists = existing >= 0;
		if ((exists && isChecked) || (!exists && !isChecked)) {
			console.log('assignableUsersChanged: Invalid check state: %s (%s)', state, CheckState[state]);
			return;
		}
		const newProdUsers = [
			...productUsers,
		];
		if (exists) {
			newProdUsers.splice(existing, 1);
		} else {
			newProdUsers.push(
				{
					...staticProductUser(),
					productId: obj.id,
					roleId,
					userId,
				},
			);
		}
		this.setState({
			productUsers: newProdUsers,
		});
	}

	@bind
	private canceled() {
		console.log('canceled...');
	}

	@bind
	private childCanceled(): void {
		this.deactivateChild();
	}

	@bind
	private childChanged<K extends keyof IProduct>(name: K, value: IProduct[K]): void {
		const {activeChild} = this.state;
		if (activeChild === null) {
			console.log('childChanged: Null object.');
			return;
		}
		this.setState({
			activeChild: {
				...activeChild,
				[name]: value,
			},
		});
	}

	@bind
	private childClicked(index: number) {
		const {
			children,
		} = this.state;
		if (idxOk(index, children.length, 'childClicked')) {
			this.setState({
				activeChild: deepCopy(children[index]),
			});
		}
	}

	@bind
	private childDialogResult(result: DialogCode) {
		console.log('childDialogResult:', result);
		this.closeChildDialog();
	}

	@bind
	private childPriceChanged<K extends keyof IPriceGroupProductPrice>(index: number, name: K, value: IPriceGroupProductPrice[K]) {
		const {activeChild} = this.state;
		if (activeChild === null) {
			console.log('childPriceChanged: Null object');
			return;
		}
		if (idxOk(index, activeChild.priceObjs.length, 'childPriceChanged')) {
			activeChild.priceObjs[index][name] = value;
			this.setState({
				activeChild: {
					...activeChild,
				},
			});
		}
	}

	@bind
	private childPriceGroupSelected(index: number): void {
		const {priceGroups} = this.state;
		const {activeChild} = this.state;
		if (activeChild === null) {
			console.log('childPriceGroupSelected: Null object.');
			return;
		}
		if (idxOk(index, priceGroups.length, 'childPriceGroupSelected')) {
			const pgObj = priceGroups[index];
			const newPriceObjs = [
				...activeChild.priceObjs,
			];
			newPriceObjs.push(
				{
					...staticPriceGroupProductPrice(),
					priceGroupId: pgObj.id,
					productId: activeChild.id,
				},
			);
			this.setState({
				activeChild: {
					...activeChild,
					priceObjs: newPriceObjs,
				},
			});
		}
	}

	@bind
	private childSaved(): void {
		const {
			activeChild,
			children,
		} = this.state;
		if (activeChild === null) {
			console.log('childSaved: Null object.');
			return;
		}
		const newChildObjs = [
			...children,
		];
		const idx = newChildObjs.findIndex(x => (x.id === activeChild.id));
		if (idx === -1) {
			console.log('childSaved: Active object not found in object collection.');
			return;
		}
		newChildObjs.splice(idx, 1);
		newChildObjs.splice(idx, 0, deepCopy(activeChild));
		this.setState(
			{
				children: newChildObjs,
			},
			this.deactivateChild,
		);
	}

	@bind
	private closeChildDialog() {
		this.setState({childDialogIsOpen: false});
	}

	@bind
	private closePromptDialog() {
		this.setState({promptDialogIsOpen: false});
	}

	@bind
	private closeMoreMenu(): void {
		this.setState({moreMenuIsOpen: false});
	}

	async componentDidMount() {
		const {pk} = this.props;
		if (pk) {
			await this.refreshObjData(pk);
		}
		const addOns = await api.productAddOnList();
		const priceGroups = await api.priceGroupList();
		const quickBooksItems = await api.quickBooksItemList();
		const userClasses = await api.allUserClasses();
		const users = await api.allTeamMembers();
		this.setState(
			{
				addOns,
				priceGroups,
				quickBooksItems,
				userClasses,
				users,
			},
		);
	}

	async componentDidUpdate(prevProps: Readonly<IProductDetailViewProps>, prevState: Readonly<IProductDetailViewState>) {
		const {pk} = this.props;
		if (prevProps.pk !== pk) {
			if (pk) {
				await this.refreshObjData(pk);
			} else {
				this.setState({
					children: [],
					obj: staticProduct(),
					options: [],
					prices: [],
					productUsers: [],
				});
			}
		}
	}

	@bind
	private deactivateChild(): void {
		if (this.state.activeChild !== null) {
			this.setState({activeChild: null});
		}
	}

	@bind
	private deleteChildPrice(index: number) {
		const {activeChild} = this.state;
		if (activeChild === null) {
			console.log('deleteChildPrice: Null object');
			return;
		}
		if (idxOk(index, activeChild.priceObjs.length, 'deleteChildPrice')) {
			const newObjs = [
				...activeChild.priceObjs,
			];
			newObjs.splice(index, 1);
			this.setState({
				activeChild: {
					...activeChild,
					priceObjs: newObjs,
				},
			});
		}
	}

	@bind
	private deleteOption(index: number) {
		const {options} = this.state;
		if (idxOk(index, options.length, 'deleteOption')) {
			const newObjs: Array<IProductOption> = [
				...options,
			];
			newObjs.splice(index, 1);
			this.setState({options: newObjs});
		}
	}

	@bind
	private deletePrice(index: number) {
		const {prices} = this.state;
		if (idxOk(index, prices.length, 'deletePrice')) {
			const newObjs = [
				...prices,
			];
			newObjs.splice(index, 1);
			this.setState({prices: newObjs});
		}
	}

	@bind
	private async deleteProduct() {
		const {pk} = this.props;
		if (pk) {
			await api.deleteProduct(pk);
			window.location.assign(window.pbUrls.productListView);
		}
	}

	@bind
	private async promptDialogResult(result: DialogCode) {
		if (result === DialogCode.Accepted) {
			await this.deleteProduct();
		}
		this.closePromptDialog();
	}

	@bind
	private moreButtonClicked(event: React.MouseEvent) {
		this.openMoreMenu(event.clientX, event.clientY);
	}

	@bind
	private moreMenuClosed(): void {
		this.closeMoreMenu();
	}

	@bind
	private moreMenuOptions(): Array<IMenuOptionProps> {
		return [
			{
				isSelected: false,
				label: 'Delete',
				value: 'DELETE',
			},
		];
	}

	@bind
	private moreMenuOptionSelected(index: number) {
		const opts = this.moreMenuOptions();
		if (idxOk(index, opts.length, 'moreMenuOptionSelected')) {
			const opt = opts[index];
			if (opt.value === 'DELETE') {
				this.openPromptDialog();
			}
		}
	}

	@bind
	private objChanged<K extends keyof IProduct>(name: K, value: IProduct[K]): void {
		this.setState({
			obj: {
				...this.state.obj,
				[name]: value,
			},
		});
	}

	@bind
	private openChildDialog() {
		this.setState({childDialogIsOpen: true});
	}

	@bind
	private openPromptDialog() {
		this.setState({promptDialogIsOpen: true});
	}

	private openMoreMenu(x: number, y: number) {
		this.setState({
			moreMenuIsOpen: true,
			moreMenuCoords: {
				x,
				y,
			},
		});
	}

	@bind
	private optionChanged<K extends keyof IProductOption>(index: number, name: K, value: IProductOption[K]) {
		const {options} = this.state;
		if (idxOk(index, options.length, 'optionChanged')) {
			options[index][name] = value;
			this.setState({
				options: [
					...options,
				],
			});
		}
	}

	@bind
	private priceChanged<K extends keyof IPriceGroupProductPrice>(index: number, name: K, value: IPriceGroupProductPrice[K]) {
		const {prices} = this.state;
		if (idxOk(index, prices.length, 'priceChanged')) {
			prices[index][name] = value;
			this.setState({
				prices: [
					...prices,
				],
			});
		}
	}

	@bind
	private priceGroupSelected(index: number) {
		const {priceGroups} = this.state;
		if (idxOk(index, priceGroups.length, 'priceGroupSelected')) {
			const {
				obj,
				prices,
			} = this.state;
			const priceGroupObj = priceGroups[index];
			this.setState({
				prices: [
					...prices,
					{
						...staticPriceGroupProductPrice(),
						priceGroupId: priceGroupObj.id,
						productId: obj.id,
					},
				],
			});
		}
	}

	@bind
	private quickBooksItemClicked(index: number) {
		const {
			obj,
			quickBooksItems,
		} = this.state;
		if (idxOk(index, quickBooksItems.length, 'quickBooksItemClicked')) {
			const qbItem = quickBooksItems[index];
			const qbItemId = obj.quickbooksItemId === qbItem.id
				? ''
				: qbItem.id;
			this.setState({
				obj: {
					...obj,
					quickbooksItemId: qbItemId,
				},
			});
		}
	}

	@bind
	private async refreshObjData(pk: number | string) {
		const children: Array<IChildObj> = [];
		const childObjs = await api.productChildList(pk);
		for (const childObj of childObjs) {
			const childPrices = await api.productPriceGroupProductPriceList(childObj.id);
			children.push({
				...childObj,
				priceObjs: childPrices,
			});
		}
		this.setState({
			children,
			obj: await api.productDetail(pk),
			options: await api.productOptionList(pk),
			prices: await api.productPriceGroupProductPriceList(pk),
			productUsers: await api.productUserList(pk),
		});
	}

	render() {
		const {pk} = this.props;
		const {
			activeChild,
			addOns,
			childDialogIsOpen,
			children,
			moreMenuCoords,
			moreMenuIsOpen,
			obj,
			options,
			priceGroups,
			prices,
			productUsers,
			promptDialogIsOpen,
			quickBooksItems,
			userClasses,
			users,
		} = this.state;
		const heading = pk
			? obj.name
			: 'New Product';
		const promptHeaderObjName = obj.name.trim().length > 0
			? obj.name
			: 'this product';
		const trailingNode = pk
			? (
				<TopToolBar
					onMoreClick={this.moreButtonClicked}
					onSaveClick={this.save}
				/>
			)
			: null;
		return (
			<>
				<WideContentHeader backNavUri={window.pbUrls.productListView} backNavTitle="Back to product list" hasDivider trailingNode={trailingNode}>
					{heading}
				</WideContentHeader>
				<WideContentContainer>
					{
						activeChild
							? (
								<Dialog isOpen={childDialogIsOpen}>
									<ChildProductFormView
										obj={activeChild}
										onCancel={this.childCanceled}
										onChange={this.childChanged}
										onPriceChange={this.childPriceChanged}
										onPriceGroupSelect={this.childPriceGroupSelected}
										onSave={this.childSaved}
										onDeletePrice={this.deleteChildPrice}
										priceGroups={priceGroups}
										prices={activeChild.priceObjs}
									/>
								</Dialog>
							)
							: (
								<ProductFormView
									addOns={addOns}
									children={children}
									obj={obj}
									onAddChild={this.addChild}
									onAddOnClick={this.addOnClicked}
									onAddOption={this.addOption}
									onCancel={this.canceled}
									onChildClick={this.childClicked}
									onDeleteOption={this.deleteOption}
									onDeletePrice={this.deletePrice}
									onObjChange={this.objChanged}
									onOptionChange={this.optionChanged}
									onPriceChange={this.priceChanged}
									onPriceGroupSelect={this.priceGroupSelected}
									onQuickBooksItemClick={this.quickBooksItemClicked}
									onSave={this.save}
									onUserAssignmentChange={this.assignedUsersChanged}
									onUserClassClick={this.userClassClicked}
									options={options}
									priceGroups={priceGroups}
									prices={prices}
									productUsers={productUsers}
									quickBooksItems={quickBooksItems}
									userClasses={userClasses}
									users={users}
								/>
							)
					}
				</WideContentContainer>
				<Dialog buttons={StandardButton.Yes | StandardButton.No} isOpen={promptDialogIsOpen} onFinished={this.promptDialogResult}>
					<Prompt
						header={`Delete ${promptHeaderObjName}?`}
						iconName="delete_outline"
					/>
				</Dialog>
				<Menu
					absolutePosition={moreMenuCoords}
					anchorToBody
					isCompact
					isOpen={moreMenuIsOpen}
					onClose={this.moreMenuClosed}
					onSelection={this.moreMenuOptionSelected}
					options={this.moreMenuOptions()}
				/>
			</>
		);
	}

	@bind
	private async save() {
		const {
			addOns,
			children,
			obj,
			options,
			prices,
			productUsers,
		} = this.state;
		const addOnPks = new Set(obj.addOns);
		const data: IProductUpdate = {
			...obj,
			addOns: addOns.filter(x => addOnPks.has(x.id)).map(x => ({
				...x,
				addOns: [],
				children: [],
				options: [],
				prices: [],
				productUsers: [],
				quickbooksItem: null,
			})),
			children: children.map(x => ({
				...x,
				addOns: [],
				children: [],
				options: [],
				prices: x.priceObjs,
				productUsers: [],
				quickbooksItem: null,
			})),
			color: obj.color,
			description: obj.description,
			duration: obj.duration,
			exclusiveUserClasses: obj.exclusiveUserClasses,
			icon: obj.icon,
			isActive: obj.isActive,
			isDiscountable: obj.isDiscountable,
			isMutuallyExclusive: obj.isMutuallyExclusive,
			maxOptions: obj.maxOptions,
			name: obj.name,
			options: options,
			parentId: obj.parentId,
			prices,
			productUsers,
			quickbooksItem: this.selectedQuickBooksItem(),
			size: obj.size,
			sortIndex: obj.sortIndex,
			summary: obj.summary,
		};
		const newObj = this.props.pk
			? await api.updateProduct(data)
			: await api.createProduct(data);
		await this.refreshObjData(newObj.id);
	}

	private selectedQuickBooksItem(): IQuickBooksItem | null {
		const {
			obj,
			quickBooksItems,
		} = this.state;
		if (obj.quickbooksItemId === '') {
			return null;
		}
		for (const item of quickBooksItems) {
			if (item.id === obj.quickbooksItemId) {
				return item;
			}
		}
		return null;
	}

	@bind
	private userClassClicked(index: number) {
		const {userClasses} = this.state;
		if (idxOk(index, userClasses.length, 'userClassClicked')) {
			const userClassObj = userClasses[index];
			const {obj} = this.state;
			const ucPkIdx = obj.exclusiveUserClasses.indexOf(userClassObj.id);
			const newPks = [
				...obj.exclusiveUserClasses,
			];
			if (ucPkIdx >= 0) {
				// Remove
				newPks.splice(ucPkIdx, 1);
			} else {
				// Add
				newPks.push(userClassObj.id);
			}
			this.setState({
				obj: {
					...obj,
					exclusiveUserClasses: newPks,
				},
			});
		}
	}
}

interface ITopToolBarProps {
	onMoreClick: (event: React.MouseEvent) => any;
	onSaveClick: () => any;
}

function TopToolBar(props: ITopToolBarProps) {
	const {
		onMoreClick,
		onSaveClick,
	} = props;
	return (
		<div id="id_pb_product-detail-top-tool-bar">
			<Button onClick={onSaveClick} raisedFilled>Save</Button>
			<IconButton onClick={onMoreClick}>more_vert</IconButton>
		</div>
	);
}
