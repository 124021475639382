import React from 'react';
import {
	WideContentContainer,
	WideContentHeader,
} from '../../components';

import {api} from '../../httpapi';
import {UserView} from '../user';
import {UiTableTitle} from '../../constants';

export interface ITeamMemberListViewProps extends React.HTMLAttributes<any> {
}

interface ITeamMemberListViewState {
}

export class TeamMemberListView extends React.Component<Partial<ITeamMemberListViewProps>, ITeamMemberListViewState> {
	render() {
		return (
			<>
				<WideContentHeader hasDivider>Team</WideContentHeader>
				<WideContentContainer>
					<UserView
						pageGetter={api.teamMemberList}
						objDetailGetter={api.teamMemberDetail}
						objCreator={api.createTeamMember}
						objUpdater={api.updateTeamMember}
						productListGetter={api.allProducts}
						productUserListGetter={api.teamMemberProductUserList}
						productUserListUpdater={api.updateProductUsersForTeamMember}
						serviceAreaListGetter={api.allServiceAreas}
						uiTable={UiTableTitle.TeamMemberList}
					/>
				</WideContentContainer>
			</>
		);
	}
}
