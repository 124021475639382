import React from 'react';

import {api} from '../../httpapi';
import {CheckState} from '../../constants';
import {MessageTemplateFormView} from './messagetemplateform';
import {
	bind,
	idxOk,
	staticMessageTemplate,
} from '../../util';
import {
	Button,
	CCard,
	CCardAvatar,
	CCardBottom,
	CCardContent,
	CCardDescribe,
	CCardHeader,
	CCardIntro,
	CCardName,
	CCardNameTitle,
	CCardSection,
	Dialog,
	ICCardProps,
	WideContentCard,
	WideContentContainer,
	WideContentHeader,
} from '../../components';

export interface IMessageTemplateUpdateViewProps extends React.HTMLAttributes<any> {
	pk: string;
}

export interface IMessageTemplateUpdateViewState {
	confirmDeleteDialogIsOpen: boolean;
	initializing: boolean;
	media: Array<IMedium>;
	obj: IMessageTemplate;
	projectActions: Array<IProjectAction>;
	userClasses: Array<IUserClass>;
}

export class MessageTemplateUpdateView extends React.Component<IMessageTemplateUpdateViewProps, IMessageTemplateUpdateViewState> {
	constructor(props: IMessageTemplateUpdateViewProps) {
		super(props);
		this.state = {
			confirmDeleteDialogIsOpen: false,
			initializing: true,
			media: [],
			obj: staticMessageTemplate(),
			projectActions: [],
			userClasses: [],
		};
	}

	@bind
	private actionCheckStateChanged(index: number, state: CheckState) {
		const {
			obj,
			projectActions,
		} = this.state;
		if (idxOk(index, projectActions.length, 'actionCheckStateChanged')) {
			const actionIds = new Set(obj.actionIds);
			if (state === CheckState.Checked) {
				actionIds.add(projectActions[index].name);
			} else {
				actionIds.delete(projectActions[index].name);
			}
			this.setState({
				obj: {
					...obj,
					actionIds: Array.from(actionIds),
				},
			});
		}
	}

	@bind
	private bodyChanged(body: string) {
		const {obj} = this.state;
		this.setState({
			obj: {
				...obj,
				body,
			},
		});
	}

	@bind
	private closeDialog() {
		this.setState({
			confirmDeleteDialogIsOpen: false,
		});
	}

	async componentDidMount() {
		const {pk} = this.props;
		this.setState(
			{
				media: await api.mediumList(),
				obj: await api.messageTemplateDetail(pk),
				projectActions: await api.actionList(),
				userClasses: await api.allUserClasses(),
			},
			() => this.setState({initializing: false}),
		);
	}

	@bind
	private deleteButtonClicked() {
		this.openDialog();
	}

	@bind
	private async deleteConfirmed() {
		await this.deleteObj();
		this.closeDialog();
		window.location.assign(window.pbUrls.messageListView);
	}

	@bind
	private async deleteObj() {
		await api.deleteMessageTemplate(this.state.obj.id);
	}

	@bind
	private deleteRejected() {
		this.closeDialog();
	}

	@bind
	private footerChanged(footer: string) {
		const {obj} = this.state;
		this.setState({
			obj: {
				...obj,
				footer,
			},
		});
	}

	@bind
	private async formSubmitted(event: React.FormEvent) {
		event.preventDefault();
		const {obj} = this.state;
		const data: IMessageTemplateUpdate = {
			...obj,
		};
		this.setState({
			obj: await api.updateMessageTemplate(this.props.pk, data),
		});
	}

	@bind
	private isActiveChanged(isActive: boolean) {
		this.setState({
			obj: {
				...this.state.obj,
				isActive,
			},
		});
	}

	@bind
	private mediumCheckStateChanged(index: number, state: CheckState) {
		const {
			media,
			obj,
		} = this.state;
		if (idxOk(index, media.length, 'mediumCheckStateChanged')) {
			const mediumIds = new Set(obj.mediumIds);
			if (state === CheckState.Checked) {
				mediumIds.add(media[index].name);
			} else {
				mediumIds.delete(media[index].name);
			}
			this.setState({
				obj: {
					...obj,
					mediumIds: Array.from(mediumIds),
				},
			});
		}
	}

	@bind
	private nameChanged(name: string) {
		const {obj} = this.state;
		this.setState({
			obj: {
				...obj,
				name,
			},
		});
	}

	private openDialog() {
		this.setState({
			confirmDeleteDialogIsOpen: true,
		});
	}

	render() {
		const {
			confirmDeleteDialogIsOpen,
			initializing,
			media,
			obj,
			projectActions,
			userClasses,
		} = this.state;
		if (initializing) {
			return null;
		}
		const delBtn = (
			<Button className="margin-left--auto pb-button--color-danger" onClick={this.deleteButtonClicked}>
				Delete
			</Button>
		);
		const heading = obj.name.trim().length > 0
			? obj.name
			: 'Message template';
		return (
			<>
				<WideContentHeader backNavUri={window.pbUrls.messageListView} backNavTitle="back to messages" hasDivider trailingNode={delBtn}>
					{heading}
				</WideContentHeader>
				<WideContentContainer>
					<WideContentCard>
						<MessageTemplateFormView
							media={media}
							obj={obj}
							onActionCheckStateChange={this.actionCheckStateChanged}
							onBodyChange={this.bodyChanged}
							onFooterChange={this.footerChanged}
							onIsActiveChange={this.isActiveChanged}
							onMediumCheckStateChange={this.mediumCheckStateChanged}
							onNameChange={this.nameChanged}
							onSubjectChange={this.subjectChanged}
							onSubmit={this.formSubmitted}
							onUserClassCheckStateChange={this.userClassCheckStateChanged}
							projectActions={projectActions}
							userClasses={userClasses}
						/>
					</WideContentCard>
				</WideContentContainer>
				<Dialog isOpen={confirmDeleteDialogIsOpen}>
					<ConfirmDelete name={obj.name}>
						<CCardBottom>
							<Button value="cancel" raisedFilled onClick={this.deleteRejected}>Cancel</Button>
							<Button value="delete" onClick={this.deleteConfirmed}>Delete</Button>
						</CCardBottom>
					</ConfirmDelete>
				</Dialog>
			</>
		);
	}

	@bind
	private subjectChanged(subject: string) {
		const {obj} = this.state;
		this.setState({
			obj: {
				...obj,
				subject,
			},
		});
	}

	@bind
	private userClassCheckStateChanged(index: number, state: CheckState) {
		const {
			obj,
			userClasses,
		} = this.state;
		if (idxOk(index, userClasses.length, 'userClassCheckStateChanged')) {
			const userClassIds = new Set(obj.userClassIds);
			if (state === CheckState.Checked) {
				userClassIds.add(userClasses[index].id);
			} else {
				userClassIds.delete(userClasses[index].id);
			}
			this.setState({
				obj: {
					...obj,
					userClassIds: Array.from(userClassIds),
				},
			});
		}
	}
}

interface IConfirmDeleteProps extends Partial<ICCardProps> {
	name: string;
}

function ConfirmDelete(props: IConfirmDeleteProps) {
	const {
		children,
		name,
		...rest
	} = props;
	return (
		<CCard {...rest}>
			<CCardHeader>
				<CCardIntro>
					<CCardAvatar>send</CCardAvatar>
					<CCardDescribe>
						<CCardNameTitle>
							<CCardName>{name}</CCardName>
						</CCardNameTitle>
					</CCardDescribe>
				</CCardIntro>
			</CCardHeader>
			<CCardContent>
				<CCardSection>
					Delete this message template?
				</CCardSection>
			</CCardContent>
			{children}
		</CCard>
	);
}
