import React from 'react';

import {makeClassName} from '../util';

export interface ICardProps extends React.HTMLAttributes<any> {
	isOutlined?: boolean;
}

export function Card(props: ICardProps) {
	const {
		children,
		className,
		isOutlined,
		...rest
	} = props;
	const clsName = makeClassName(
		'base-card',
		isOutlined
			? 'base-card--outlined'
			: undefined,
		className,
	);
	return (
		<section className={clsName} {...rest}>
			{children}
		</section>
	);
}

export interface ICardInnerProps extends React.HTMLAttributes<any> {
}

export function CardInner(props: ICardInnerProps) {
	const {
		children,
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		'base-card-inner',
		className,
	);
	return (
		<section className={clsName} {...rest}>
			{children}
		</section>
	);
}

interface ICardHeaderProps extends React.HTMLAttributes<any> {
	primaryText?: string;
	secondaryText?: string;
}

export function CardHeader(props: ICardHeaderProps) {
	const {
		children,
		className,
		primaryText,
		secondaryText,
		...rest
	} = props;
	const clsName = makeClassName(
		'base-card-header',
		className,
	);
	const h = headings(primaryText, secondaryText);
	return (
		<header className={clsName} {...rest}>
			{(h.primary || h.secondary)
				?
				<h2 className="mdc-typography--title">
					{h.primary
						? h.primary
						: null}{h.secondary}
				</h2>
				:
				null}
			{children}
		</header>
	);
}

interface IHeadings {
	primary?: string;
	secondary?: string;
}

function headings(primary?: string, secondary?: string): IHeadings {
	const rv: IHeadings = {
		primary,
		secondary,
	};
	if (rv.primary && rv.secondary) {
		rv.secondary = ` \u2003\u2014 ${rv.secondary}`;
	}
	return rv;
}
