import React from 'react';

import {api} from '../../httpapi';
import {
	bind,
	capitalize,
	idxOk,
} from '../../util';
import {
	IListItemProps,
	List,
	ListItem,
	Switch,
	ContentCard,
	ContentHeader,
	ContentContainer,
} from '../../components';

export interface INotificationViewProps extends React.HTMLAttributes<any> {
}

interface INotificationViewState {
	emailAddrs: Array<IAlternateEmailAddress>;
	profile: IProfile | null;
	projectActions: Array<IProjectAction>;
}

export class NotificationView extends React.Component<Partial<INotificationViewProps>, INotificationViewState> {
	constructor(props: Partial<INotificationViewProps>) {
		super(props);
		this.state = {
			emailAddrs: [],
			projectActions: [],
			profile: null,
		};
	}

	async componentDidMount() {
		this.setState({
			emailAddrs: await api.alternateEmailList(),
			projectActions: await api.actionList(),
			profile: await api.profileDetail(),
		});
	}

	@bind
	private async emailItemClicked(address: string): Promise<void> {
		const {emailAddrs} = this.state;
		let i = 0;
		let newObj: IAlternateEmailAddress | null = null;
		for (; i < emailAddrs.length; ++i) {
			const obj = emailAddrs[i];
			if (obj.address === address) {
				newObj = await api.updateAlternateEmail({
					...obj,
					receiveNotifications: !obj.receiveNotifications,
				});
				break;
			}
		}
		if (newObj && (i >= 0) && (i < emailAddrs.length)) {
			emailAddrs.splice(i, 1);
			emailAddrs.splice(i, 0, newObj);
			this.setState({
				emailAddrs: [...emailAddrs],
			});
		}
	}

	private isSubscribedToProjectAction(name: string): boolean {
		const {profile} = this.state;
		return (profile === null)
			? false
			: profile.subscribedProjectActions.indexOf(name) >= 0;
	}

	@bind
	private async projectActionItemClicked(index: number) {
		const {
			projectActions,
			profile,
		} = this.state;
		if (profile === null) {
			return;
		}
		if (idxOk(index, projectActions.length, 'projectActionItemClicked')) {
			const newSubs = [
				...profile.subscribedProjectActions,
			];
			const obj = projectActions[index];
			const axnIdx = newSubs.indexOf(obj.name);
			const turningOff = axnIdx >= 0;
			if (turningOff) {
				newSubs.splice(axnIdx, 1);
			} else {
				newSubs.push(obj.name);
			}
			this.setState({
				profile: await api.updateProfile({
					...profile,
					subscribedProjectActions: newSubs,
				}),
			});
		}
	}

	render() {
		const {
			emailAddrs,
			projectActions,
			profile,
		} = this.state;
		if (!profile) {
			return null;
		}
		return (
			<>
				<ContentHeader hasDivider>
					Notifications
				</ContentHeader>
				<ContentContainer>
					<ContentCard>
						<List>
							{
								emailAddrs.map((obj, i) => {
									return (
										<Item key={i} onClick={this.emailItemClicked.bind(this, obj.address)} receiveNotifications={obj.receiveNotifications}>
											{obj.address}
										</Item>
									);
								})
							}
							{
								projectActions.map((obj, i) => {
									return (
										<Item key={i} onClick={this.projectActionItemClicked.bind(this, i)} receiveNotifications={this.isSubscribedToProjectAction(obj.name)}>
											{capitalize(obj.summary)}
										</Item>
									);
								})
							}
						</List>
					</ContentCard>
				</ContentContainer>
			</>
		);
	}
}

interface IItemProps extends IListItemProps {
	receiveNotifications: boolean;
}

function Item(props: Partial<IItemProps>) {
	const {
		children,
		receiveNotifications,
		...rest
	} = props;
	return (
		<ListItem meta={<Switch isSelected={receiveNotifications}/>} {...rest}>
			{children}
		</ListItem>
	);
}
