import {bind} from './util';

const FOCUS_SENTINEL_CLASS = 'focus-sentinel';

export interface FocusOptions {
	initialFocusEl?: HTMLElement | null;
	skipInitialFocus?: boolean;
	skipRestoreFocus?: boolean;
}

export class FocusTrap {
	private readonly options: FocusOptions;
	private prevFocusedEl: HTMLElement | null;
	private readonly root: HTMLElement;

	constructor(root: HTMLElement, options: FocusOptions = {}) {
		this.options = options;
		this.prevFocusedEl = null;
		this.root = root;
	}

	releaseFocus() {
		for (const el of this.root.querySelectorAll(`.${FOCUS_SENTINEL_CLASS}`)) {
			if (el.parentElement) {
				el.parentElement.removeChild(el);
			}
		}
		if (!this.options.skipRestoreFocus && this.prevFocusedEl) {
			this.prevFocusedEl.focus();
		}
	}

	@bind
	private sentinelEndFocusEvent() {
		const els = focusableElements(this.root);
		if (els.length > 0) {
			els[0].focus();
		}
	}

	@bind
	private sentinelStartFocusEvent() {
		const els = focusableElements(this.root);
		if (els.length > 0) {
			els[els.length - 1].focus();
		}
	}

	trapFocus() {
		const els = focusableElements(this.root);
		if (els.length === 0) {
			console.log('trapFocus: No focusable elements.');
		} else {
			this.prevFocusedEl = (document.activeElement instanceof HTMLElement)
				? document.activeElement
				: null;
			this.wrapTabFocus();
			if (!this.options.skipInitialFocus) {
				focusInitialElement(
					els,
					this.options.initialFocusEl,
				);
			}
		}
	}

	private wrapTabFocus() {
		const start = createSentinel();
		const end = createSentinel();
		start.addEventListener('focus', this.sentinelStartFocusEvent);
		end.addEventListener('focus', this.sentinelEndFocusEvent);
		const children = this.root.children;
		this.root.insertBefore(
			start,
			children.length > 0
				? children[0]
				: null,
		);
		this.root.appendChild(end);
	}
}

function createSentinel() {
	const sentinel = document.createElement('div');
	sentinel.setAttribute('tabindex', '0');
	sentinel.classList.add(FOCUS_SENTINEL_CLASS);
	return sentinel;
}

const focusableSelectors = [
	'[autofocus]',
	'[tabindex]',
	'a',
	'input',
	'textarea',
	'select',
	'button',
];
const focusableSelector = focusableSelectors.join(', ');

function focusableElements(root: HTMLElement): Array<HTMLElement> {
	const els = Array.from(
		root.querySelectorAll<HTMLElement>(
			focusableSelector,
		),
	);
	const rv: Array<HTMLElement> = [];
	for (const el of els) {
		const isTabbableAndVisible = (
			(el.getAttribute('disabled') === null)
			&& (el.getAttribute('hidden') === null)
			&& (el.tabIndex >= 0)
			&& !el.classList.contains(FOCUS_SENTINEL_CLASS)
			&& (el.getBoundingClientRect().width > 0)
		);
		let isHidden = false;
		if (isTabbableAndVisible) {
			const style = getComputedStyle(el);
			isHidden = (style.display === 'none') || (style.visibility === 'hidden');
		}
		if (isTabbableAndVisible && !isHidden) {
			rv.push(el);
		}
	}
	return rv;
}

function focusInitialElement(focusableEls: Array<HTMLElement>, initialFocusEl?: HTMLElement | null) {
	let idx = 0;
	if (initialFocusEl) {
		idx = Math.max(focusableEls.indexOf(initialFocusEl), 0);
	}
	if ((idx >= 0) && (idx < focusableEls.length)) {
		focusableEls[idx].focus();
	} else {
		console.log('focusInitialElement: Invalid element collection.');
	}
}
