export const COLOR_PB_GREEN = '#00CC75';
export const MAX_ORDINAL = 3652059;
export const MAX_YEAR = 9999;
export const MIN_YEAR = 1;
export const REQUEST_CONFIG_WELL_KNOWN_REGISTRY_KEY = '_PB_REQUEST_CONFIG_WELL_KNOWN_REGISTRY_KEY';
export const SECONDS_IN_DAY = 86400;
export const SECONDS_IN_HOUR = 3600;
export const SECONDS_IN_MINUTE = 60;

// Keep in sync w/back end apps::App
export enum AppSlug {
	ProjectCreator = 'project-creator',
}

// Keep in sync w/back end apps::AppWidget
export enum AppWidgetName {
	Access = 'access',
	AccessNotes = 'access_notes',
	Occupancy = 'occupancy',
}

export enum ButtonRole {
	InvalidRole = -1,
	AcceptRole,
	RejectRole,
	DestructiveRole,
	ActionRole,
	HelpRole,
	YesRole,
	NoRole,
	ResetRole,
	ApplyRole,
	NRoles,
}

// Keep in sync w/back end
export enum ProjectRole {
	Client = 'client',
	Editor = 'editor',
	Shooter = 'shooter',
}

export enum CaseSensitivity {
	CaseInsensitive,
	CaseSensitive,
}

export enum CheckState {
	Unchecked = 0,
	PartiallyChecked = 1,
	Checked = 2,
}

export enum CrudOp {
	NoOp,
	Create,
	Read,
	Update,
	Delete,
}

export enum DialogCode {
	Rejected,
	Accepted,
}

// Keep in sync with InvoiceLine.State
export enum InvoiceLineState {
	Draft = 'draft',
	ReadyForRelease = 'readyforrelease',
	Released = 'released',
}

// Keep in sync w/integrations::Integration.Known
export enum KnownIntegration {
	GoogleCalendar = 'google-calendar',
	QuickBooks = 'quickbooks',
	Stripe = 'stripe',
}

// Keep in sync with Invoice.State
export enum InvoiceState {
	Draft = 'draft',
	Settled = 'settled',
	Void = 'void',
}

// Keep in sync w/backend::apps::AppWidgetType
export enum AppWidgetType {
	Checkbox = 'checkbox',
	MultiSelect = 'multi_select',
	RadioSelect = 'radio_select',
	SingleSelect = 'single_select',
	TextInout = 'text_input',
	TextArea = 'textarea',
}

// Keep in sync w/backend::messaging::Longevity
export enum MessageLongevity {
	Ephemeral = 'ephemeral',
	Persistent = 'persistent',
}

// Keep in sync w/backend::messaging::MessageTemplateType
export enum MessageTemplateType {
	Snippet = 'snippet',
}

// Keep in sync w/backend::messaging::Medium
export enum MessageMedium {
	Email = 'email',
	Local = 'local',
	Sms = 'sms',
}

export enum MetaType {
	Invalid = 0,
	Boolean = 1,
	Number = 6,
	Decimal = 7,
	String = 10,
	TimeDelta = 13,
	Date = 14,
	Time = 15,
	DateTime = 16,
	Url = 17,
	Variant = 41,
	ModelIndex = 42,
	Null = 51,
}

export enum PageNavigation {
	FirstPage = 1,
	PrevPage,
	NextPage,
	LastPage,
}

export enum ProductUserRole {
	Shooter = 'shooter',
	Editor = 'editor',
}

// Keep in sync w/projects.ProjectAction
export enum ProjectAction {
	Created = 'created',
	Confirmed = 'confirmed',
	Completed = 'completed',
	MediaReleased = 'media_released',
	Updated = 'updated',
	Invoice = 'invoice',
	OnHold = 'on_hold',
}

export enum ProjectEmail {
	Created = 'created',
	Confirmed = 'confirmed',
	Complete = 'complete',
	Gallery = 'gallery',
	Invoice = 'invoice',
	OnHold = 'on hold',
}

export enum ProjectRoleName {
	Client = 'client',
	Shooter = 'shooter',
	Editor = 'editor',
}

// Keep in sync with Project.status
export enum ProjectStatus {
	Canceled = 'canceled',
	Pending = 'pending',
	InProgress = 'in_progress',
	Complete = 'complete',
	OnHold = 'on_hold',
}

export enum SortOrder {
	NoOrder = 'none',
	AscendingOrder = 'asc',
	DescendingOrder = 'desc',
}

export enum StandardButton {
	NoButton = 0x00000000,
	Accept = 0x00000100,
	Decline = 0x00000200,
	Ok = 0x00000400,
	Save = 0x00000800,
	SaveAll = 0x00001000,
	Open = 0x00002000,
	Yes = 0x00004000,
	YesToAll = 0x00008000,
	No = 0x00010000,
	NoToAll = 0x00020000,
	Abort = 0x00040000,
	Retry = 0x00080000,
	Ignore = 0x00100000,
	Close = 0x00200000,
	Cancel = 0x00400000,
	Discard = 0x00800000,
	Help = 0x01000000,
	Apply = 0x02000000,
	Reset = 0x04000000,
	RestoreDefaults = 0x08000000,
	FirstButton = Accept,         // internal
	LastButton = RestoreDefaults, // internal
}

// Keep in sync w/backend::core.models::Transaction.Status
export enum TransactionStatus {
	Succeeded = 'succeeded',
	Pending = 'pending',
	Failed = 'failed',
	Canceled = 'canceled',
}

// Keep in sync/ui::constants
export enum UiTableTitle {
	ClientOrgList = 'client_org_list',
	ClientUserList = 'client_user_list',
	ItemList = 'item_list',
	ProjectList = 'project_list',
	TeamMemberList = 'team_member_list',
	ServiceAreaList = 'service_area_list',
	UserClassList = 'user_class_list',
}

// Keep in sync w/ui::TableColumn::DataType
export enum UiTableColumnDataType {
	None = 'none',
	Boolean = 'boolean',
	Integer = 'integer',
	Float = 'float',
	Decimal = 'decimal',
	String = 'string',
	Datetime = 'datetime',
	Date = 'date',
	Time = 'time',
}
