import React, {PropsWithChildren} from 'react';
import {makeClassName} from '../util';

export interface IWxCalendarProps extends React.HTMLAttributes<any> {
}

export class WxCalendar extends React.Component<Partial<IWxCalendarProps>, {}> {
	render() {
		const {
			children,
			className,
			...rest
		} = this.props;
		return (
			<div className={makeClassName('pb-calendar', className)} {...rest}>
				{children}
			</div>
		);
	}
}

export interface IWxCalendarRowProps extends React.HTMLAttributes<any> {
}

export function WxCalendarRow(props: PropsWithChildren<Partial<IWxCalendarRowProps>>) {
	const {
		children,
		className,
		...rest
	} = props;
	return (
		<div className={makeClassName('pb-calendar__row', className)} {...rest}>
			{children}
		</div>
	);
}

export interface IWxCalendarDayProps extends React.HTMLAttributes<any> {
}

export function WxCalendarDay(props: PropsWithChildren<Partial<IWxCalendarDayProps>>) {
	const {
		children,
		className,
		...rest
	} = props;
	return (
		<div className={makeClassName('pb-calendar__day', className)} {...rest}>
			{children}
		</div>
	);
}

export interface IWxCalendarDayPeriodProps extends React.HTMLAttributes<any> {
}

export function WxCalendarDayPeriod(props: PropsWithChildren<Partial<IWxCalendarDayPeriodProps>>) {
	const {
		className,
		...rest
	} = props;
	return (
		<div className={makeClassName('pb-calendar__day-period', className)} {...rest}>
			<span>AM</span><span>NOON</span><span>PM</span>
		</div>
	);
}

export interface IWxCalendarDateProps extends React.HTMLAttributes<any> {
}

export function WxCalendarDate(props: PropsWithChildren<Partial<IWxCalendarDateProps>>) {
	const {
		children,
		className,
		...rest
	} = props;
	return (
		<div className={makeClassName('pb-calendar__date', className)} {...rest}>
			{children}
		</div>
	);
}

export interface IWxCalendarDateWeekdayProps extends React.HTMLAttributes<any> {
}

export function WxCalendarDateWeekday(props: PropsWithChildren<Partial<IWxCalendarDateWeekdayProps>>) {
	const {
		children,
		className,
		...rest
	} = props;
	return (
		<span className={makeClassName('pb-calendar__date__weekday', className)} {...rest}>
			{children}
		</span>
	);
}

export interface IWxCalendarDateDayOfMonthProps extends React.HTMLAttributes<any> {
}

export function WxCalendarDateDayOfMonth(props: PropsWithChildren<Partial<IWxCalendarDateDayOfMonthProps>>) {
	const {
		children,
		className,
		...rest
	} = props;
	return (
		<span className={makeClassName('pb-calendar__date__day-of-month', className)} {...rest}>
			{children}
		</span>
	);
}

export interface IWxCalendarWxProps extends React.HTMLAttributes<any> {
}

export function WxCalendarWx(props: PropsWithChildren<Partial<IWxCalendarWxProps>>) {
	const {
		children,
		className,
		...rest
	} = props;
	return (
		<div className={makeClassName('pb-calendar__wx', className)} {...rest}>
			{children}
		</div>
	);
}

export interface IWxCalendarSunProps extends React.HTMLAttributes<any> {
}

export function WxCalendarSun(props: PropsWithChildren<Partial<IWxCalendarSunProps>>) {
	const {
		children,
		className,
		...rest
	} = props;
	return (
		<div className={makeClassName('pb-calendar__sun', className)} {...rest}>
			{children}
		</div>
	);
}

export interface IWxCalendarWxConditionsProps extends React.HTMLAttributes<any> {
}

export function WxCalendarWxConditions(props: PropsWithChildren<Partial<IWxCalendarWxConditionsProps>>) {
	const {
		children,
		className,
		...rest
	} = props;
	return (
		<div className={makeClassName('pb-calendar__wx-conditions', className)} {...rest}>
			{children}
		</div>
	);
}
