import React from 'react';

import {UiTableColumnDataType} from '../../../constants';
import * as datetime from '../../../datetime';
import {
	CardHeader,
	IProjectCreateViewState,
} from './misc';
import {
	bind,
	makeClassName,
	numberFormat,
	padStart,
	priceHiLo,
	to12Hour,
	toPeriod,
} from '../../../util';
import {
	DataTable,
	DataTableCellProps,
	DataTableHeaderCellProps,
	GridLayout,
	GridLayoutCell,
	Icon,
	IDataTableProps,
	SubHeader,
} from '../../../components';

export interface IProjectFinalReviewViewProps extends Omit<React.HTMLAttributes<any>, 'onChange'> {
	anyTime: boolean;
	onBackNav: () => any;
	onChange: <K extends keyof IProjectCreateViewState>(name: K, value: IProjectCreateViewState[K]) => any;
	productChildren: (pk: number) => Array<ICatalogProduct>;
	summaryData: datetime.ISummaryData;
	city: string;
	notes: string;
	street: string;
}

interface IProjectFinalReviewViewState {
}

export class ProjectFinalReviewView extends React.Component<Partial<IProjectFinalReviewViewProps>, IProjectFinalReviewViewState> {
	@bind
	private clientDueDateChanged(event: React.ChangeEvent<HTMLInputElement>): void {
		this.notify('clientDueDate', event.target.value);
	}

	@bind
	private clientDueTimeChanged(event: React.ChangeEvent<HTMLInputElement>): void {
		this.notify('clientDueTime', event.target.value);
	}

	@bind
	private noteChanged(event: React.ChangeEvent<HTMLTextAreaElement>): void {
		this.notify('notes', event.target.value);
	}

	private notify<K extends keyof IProjectCreateViewState>(name: K, value: IProjectCreateViewState[K]) {
		const {onChange} = this.props;
		if (onChange) {
			onChange(name, value);
		}
	}

	render() {
		const {
			anyTime,
			children,
			city,
			notes,
			onBackNav,
			onChange,
			productChildren,
			street,
			summaryData,
			...rest
		} = this.props;
		const comp = (summaryData === undefined)
			? null
			: (
				<GridLayout>
					<GridLayoutCell span={12}>
						<Summary
							anyTime={anyTime}
							city={city}
							street={street}
							summaryData={summaryData}
						/>
					</GridLayoutCell>
					<GridLayoutCell span={12}>
						<Statement
							productChildren={productChildren}
							summaryData={summaryData}
						/>
					</GridLayoutCell>
					<GridLayoutCell span={12}>
						<SubHeader>
							Special Instructions & Notes
						</SubHeader>
					</GridLayoutCell>
					<GridLayoutCell span={12}>
									<textarea
										className="width--100-percent"
										id="notes_id"
										name="notes"
										onChange={this.noteChanged}
										placeholder="i.e. Key is hidden under front step"
										rows={5}
										value={notes}
									/>
					</GridLayoutCell>
					<GridLayoutCell span={12}>
						<SubHeader align="start">
							When is the media needed by? (optional)
						</SubHeader>
					</GridLayoutCell>
					<GridLayoutCell span={12}>
						<div className="display--inline-flex flex-direction--column">
							<label htmlFor="id_pb-project-create-client-due-date">date</label>
							<input
								type="date"
								id="id_pb-project-create-client-due-date"
								name="client_due_date"
								onChange={this.clientDueDateChanged}
							/>
						</div>
						<div className="display--inline-flex flex-direction--column">
							<label htmlFor="id_pb-project-create-client-due-time">time</label>
							<input
								type="time"
								id="id_pb-project-create-client-due-time"
								name="client_due_time"
								onChange={this.clientDueTimeChanged}
							/>
						</div>
					</GridLayoutCell>
					{children}
				</GridLayout>
			);
		return (
			<>
				<CardHeader onBackNavClick={onBackNav} headerText="FINAL STEP">
					Review &amp; Submit
				</CardHeader>
				{comp}
			</>
		);
	}
}

interface ISummaryProps extends React.HTMLAttributes<any> {
	anyTime: boolean;
	city: string;
	street: string;
	summaryData: datetime.ISummaryData;
}

class Summary extends React.Component<Partial<ISummaryProps>, {}> {
	render() {
		const {
			anyTime,
			city,
			className,
			street,
			summaryData,
			...rest
		} = this.props;
		const clsName = makeClassName(
			'display--flex',
			'align-items--center',
			'flex-direction--column',
			className,
		);
		const date = (summaryData === undefined)
			? undefined
			: summaryData.startDatetime.date();
		const start = (summaryData === undefined)
			? undefined
			: summaryData.startDatetime.time();
		const end = (summaryData === undefined)
			? undefined
			: summaryData.endDatetime.time();
		return (
			<div className={clsName} {...rest}>
				<span className="font-weight--bolder font-size--1point2rem">
					{street}, {city}
				</span>
				<Date date={date}/>
				{
					anyTime
						? <Anytime/>
						: (
							<Time
								start={start}
								end={end}
							/>
						)
				}
			</div>
		);
	}
}

function Anytime() {
	return (
		<div className="font-weight--300 font-size--0point96rem">ANYTIME</div>
	);
}

interface IDateProps extends React.HTMLAttributes<any> {
	date: datetime.date;
}

function Date(props: Partial<IDateProps>) {
	const {
		className,
		date,
		...rest
	} = props;
	if (date === undefined) {
		return null;
	}
	const clsName = makeClassName(
		'font-size--1point2rem padding-top--12',
		className,
	);
	return (
		<span className={clsName} {...rest}>
            {date.weekdayName()}, {date.monthName()} {date.day}
        </span>
	);
}

interface IStartTimeProps extends React.HTMLAttributes<any> {
	time: datetime.time;
}

function StartTime(props: Partial<IStartTimeProps>) {
	const {
		className,
		time,
		...rest
	} = props;
	if (time === undefined) {
		return null;
	}
	const clsName = makeClassName(
		'text-align--right',
		'padding-right--4',
		className,
	);
	return (
		<div className={clsName} {...rest}>
			<div className="font-weight--300 font-size--0point96rem">START</div>
			<div>{to12Hour(time.hour)}:{padStart(time.minute, 2, '0')} {toPeriod(time.hour)}</div>
		</div>
	);
}

interface IEndTimeProps extends React.HTMLAttributes<any> {
	time: datetime.time;
}

function EndTime(props: Partial<IEndTimeProps>) {
	const {
		className,
		time,
		...rest
	} = props;
	if (time === undefined) {
		return null;
	}
	const clsName = makeClassName(
		'text-align--left',
		'padding-left--4',
		className,
	);
	return (
		<div className={clsName} {...rest}>
			<div className="font-weight--300 font-size--0point96rem">END <small>est.</small></div>
			<div>{to12Hour(time.hour)}:{padStart(time.minute, 2, '0')} {toPeriod(time.hour)}</div>
		</div>
	);
}

interface ITimeProps extends React.HTMLAttributes<any> {
	start: datetime.time;
	end: datetime.time;
}

function Time(props: Partial<ITimeProps>) {
	const {
		className,
		end,
		start,
	} = props;
	const clsName = makeClassName(
		'display--flex',
		'padding-top--12',
		className,
	);
	return (
		<div className={clsName}>
			<StartTime time={start}/>
			<EndTime time={end}/>
		</div>
	);
}

interface IStatementProps extends IDataTableProps {
	productChildren: (pk: number) => Array<ICatalogProduct>;
	summaryData: datetime.ISummaryData;
}

function Statement(props: Partial<IStatementProps>) {
	const {
		className,
		productChildren,
		summaryData,
		...rest
	} = props;
	const clsName = makeClassName(
		'width--100-percent',
		className,
	);
	const products = (summaryData === undefined)
		? []
		: summaryData.products;
	const altTotal = (summaryData === undefined)
		? null
		: summaryData.altTotal;
	const total = (summaryData === undefined)
		? null
		: summaryData.total;
	const childFunc: (pk: number) => Array<ICatalogProduct> = (productChildren === undefined)
		? () => []
		: productChildren;
	const columns: Array<DataTableHeaderCellProps> = [
		{
			children: 'Services',
		},
		{
			children: 'Quantity',
			dataType: UiTableColumnDataType.Integer,
		},
		{
			children: 'Price',
			dataType: UiTableColumnDataType.Integer,
		},
	];
	const rows: Array<Array<DataTableCellProps>> = products.map(obj => {
		const children = childFunc(obj.product.id);
		const [price, altPrice] = priceHiLo(obj.product, children);
		return [
			{
				children: (
					<React.Fragment>
						<Icon
							className="color--faint-blue padding-right--16"
							middleAlign={true}>
							{obj.product.icon}
						</Icon>
						{obj.product.name}
					</React.Fragment>
				),
			},
			{
				children: obj.quantity,
				dataType: UiTableColumnDataType.Integer,
			},
			{
				children: (
					<React.Fragment>
						{
							altPrice
								? (
									<span className="text-decoration--line-through padding-right--4 font-size--0point8rem color--grayish">{altPrice}</span>
								)
								: null
						}
						{price || 'TBD'}
					</React.Fragment>
				),
				dataType: UiTableColumnDataType.Integer,
			},
		];
	});
	rows.push([
		{},
		{
			children: 'Total (excluding tax)',
		},
		{
			children: (
				<>
					{
						altTotal
							? (
								<span className="text-decoration--line-through padding-right--4 font-size--0point8rem color--grayish">${numberFormat(altTotal)}</span>
							)
							: null
					}
					{
						total
							? (
								<span className="font-weight--bolder">${numberFormat(total)}</span>
							)
							: null
					}
				</>
			),
			dataType: UiTableColumnDataType.Integer,
		},
	]);
	return (
		<DataTable
			className={clsName}
			columns={columns}
			rows={rows}
			{...rest}
		/>
	);
}
