import React from 'react';

import {chunk} from '../../util';
import * as datetime from '../../datetime';
import {
	CloudChart,
	IWxCalendarProps,
	partitionChartData,
	PrecipChart,
	WxCalendar,
	WxCalendarDate,
	WxCalendarDateDayOfMonth,
	WxCalendarDateWeekday,
	WxCalendarDay,
	WxCalendarDayPeriod,
	WxCalendarRow,
	WxCalendarSun,
	WxCalendarWx,
	WxCalendarWxConditions,
} from '../../components';

export interface IWxViewProps extends IWxCalendarProps {
	wxData: Array<IWxDay>;
}

export class WxView extends React.Component<Partial<IWxViewProps>, {}> {
	render() {
		const {
			wxData,
		} = this.props;
		const wxDataByRow = chunk(
			(wxData === undefined)
				? []
				: wxData,
			3
		);
		return (
			<WxCalendar>
				{
					wxDataByRow.map((wxDataRow, rowIdx) => {
						return (
							<WxCalendarRow key={rowIdx}>
								{
									wxDataRow.map((wxDataDay, dayIdx) => {
										const d = datetime.date.fromisoformat(wxDataDay.date);
										return (
											<WxCalendarDay key={dayIdx}>
												<WxCalendarDate>
													<WxCalendarDateWeekday>
														{d.weekdayNameAbbr()}
													</WxCalendarDateWeekday>
													<WxCalendarDateDayOfMonth>
														{d.day}
													</WxCalendarDateDayOfMonth>
												</WxCalendarDate>
												<WxCalendarWx>
													<WxCalendarSun/>
													<WxCalendarWxConditions>
														<CloudChart data={partitionChartData(wxDataDay.hours).cloud}/>
													</WxCalendarWxConditions>
													<WxCalendarWxConditions>
														<PrecipChart data={partitionChartData(wxDataDay.hours).precip}/>
													</WxCalendarWxConditions>
												</WxCalendarWx>
											</WxCalendarDay>
										);
									})
								}
								<WxCalendarDayPeriod/>
							</WxCalendarRow>
						);
					})
				}
			</WxCalendar>
		);
	}
}
