import React from 'react';

import {
	makeClassName,
	pixelString,
} from '../util';

export interface INotchedOutlineProps extends React.HTMLAttributes<any> {
}

interface INotchedOutlineState {
	isOpen: boolean;
	width: number | undefined;
}

export class NotchedOutline extends React.Component<INotchedOutlineProps, INotchedOutlineState> {
	private readonly notchRef: React.RefObject<HTMLDivElement>;

	constructor(props: INotchedOutlineProps) {
		super(props);
		this.notchRef = React.createRef();
		this.state = {
			isOpen: false,
			width: undefined,
		};
	}

	close() {
		this.setState({
			isOpen: false,
			width: undefined,
		});
	}

	open(width: number) {
		if (width > 0) {
			width += 8; // Padding
		}
		this.setState({
			isOpen: true,
			width,
		});
	}

	render() {
		const {
			children,
			className,
			...rest
		} = this.props;
		const {
			isOpen,
			width,
		} = this.state;
		const sty: React.CSSProperties = {
			width: (width === undefined)
				? undefined
				: pixelString(width),
		};
		const clsName = makeClassName(
			'mdc-notched-outline',
			isOpen
				? 'mdc-notched-outline--notched'
				: undefined,
			className,
		);
		return (
			<div className={clsName} {...rest}>
				<div className="mdc-notched-outline__leading"/>
				{
					(children === undefined)
						? null
						: (
							<div className="mdc-notched-outline__notch" ref={this.notchRef} style={sty}>
								{children}
							</div>
						)
				}
				<span className="mdc-notched-outline__trailing"/>
			</div>
		);
	}
}
