import React from 'react';

import {ConfirmDelete} from './confirmdelete';
import {
	bind,
	makeClassName,
} from '../../../util';
import {
	TextInput,
	ToolButton,
} from '../../../components';

export interface IPricingProps extends React.HTMLAttributes<any> {
	objs: Array<IPriceGroupProductPrice>;
	onDelete: (index: number) => any;
	onPriceChange: <K extends keyof IPriceGroupProductPrice>(index: number, name: K, value: IPriceGroupProductPrice[K]) => any;
	priceGroupName: (pk: number) => string;
}

interface IPricingState {
	renderConfirmAtIndex: number;
}

export class Pricing extends React.Component<IPricingProps, IPricingState> {
	constructor(props: IPricingProps) {
		super(props);
		this.state = {
			renderConfirmAtIndex: -1,
		};
	}

	@bind
	private deleteClicked(index: number): void {
		this.setState({
			renderConfirmAtIndex: index,
		});
	}

	@bind
	private deleteConfirmResult(res: boolean): void {
		const {onDelete} = this.props;
		const {renderConfirmAtIndex} = this.state;
		if (res) {
			if (renderConfirmAtIndex >= 0) {
				onDelete(renderConfirmAtIndex);
			} else {
				console.log('deleteConfirmResult: Invalid index:', renderConfirmAtIndex);
			}
		}
		this.setState({
			renderConfirmAtIndex: -1,
		});
	}

	@bind
	private priceChanged(index: number, value: string): void {
		this.props.onPriceChange(index, 'price', value);
	}

	render() {
		const {
			className,
			objs,
			priceGroupName,
		} = this.props;
		const {
			renderConfirmAtIndex,
		} = this.state;
		const prices = (objs === undefined)
			? []
			: objs;
		const clsName = makeClassName(
			'pb-item-price-list',
			className,
		);
		return (
			<div className={clsName}>
				{
					prices.map((obj, idx) => {
						if (renderConfirmAtIndex === idx) {
							return (
								<ConfirmDelete onClick={this.deleteConfirmResult} key={idx}>
									{`Delete ${priceGroupName(obj.priceGroupId)}?`}
								</ConfirmDelete>
							);
						} else if (renderConfirmAtIndex >= 0) {
							return null;
						}
						return (
							<Price
								key={idx}
								onChange={this.priceChanged.bind(this, idx)}
								onDeleteClick={this.deleteClicked.bind(this, idx)}
								price={obj.price}
								priceGroupName={priceGroupName(obj.priceGroupId)}
							/>
						);
					})
				}
			</div>
		);
	}
}

interface IPriceProps extends Omit<React.HTMLAttributes<any>, 'onChange'> {
	onChange: (value: string) => any;
	onDeleteClick: () => any;
	price: string;
	priceGroupName: string;
}

interface IPriceState {
}

class Price extends React.Component<Partial<IPriceProps>, IPriceState> {
	render() {
		const {
			className,
			onChange,
			onDeleteClick,
			price,
			priceGroupName,
		} = this.props;
		const clsName = makeClassName(
			'pb-item-price',
			className,
		);
		return (
			<div className={clsName}>
				<div>
					<TextInput
						isMinimal
						value={price}
						label={priceGroupName}
						onChange={onChange}
						type="number"
						step={0.01}
						helpText="e.g.: 55.99"
						min={0}
					/>
				</div>
				<ToolButton className="pb-icon-button--color-danger" onClick={onDeleteClick}>
					delete
				</ToolButton>
			</div>
		);
	}
}
