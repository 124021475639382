import React from 'react';

import {
	bind,
	makeClassName,
} from '../../util';

export interface ILinearProgressProps extends React.HTMLAttributes<HTMLDivElement> {
	buffer: number;
	isDeterminate: boolean;
	isOpen: boolean;
	progress: number;
}

interface ILinearProgressState {
	bufferBarStyles: React.CSSProperties;
	classNames: Set<string>;
	primaryBarStyles: React.CSSProperties;
	rootStyles: React.CSSProperties;
}

export class LinearProgress extends React.Component<Partial<ILinearProgressProps>, ILinearProgressState> {
	private readonly rootRef: React.RefObject<HTMLDivElement>;
	private resizeObs: ResizeObserver;

	constructor(props: Partial<ILinearProgressProps>) {
		super(props);
		this.resizeObs = new ResizeObserver(this.resizeObsCallBack);
		this.rootRef = React.createRef();
		this.state = {
			bufferBarStyles: {
				backgroundColor: 'transparent',
			},
			classNames: new Set(),
			primaryBarStyles: {},
			rootStyles: {},
		};
	}

	componentDidMount() {
		const curr = this.rootRef.current;
		if (curr) {
			this.resizeObs.observe(curr);
			if (!this.props.isDeterminate) {
				this.calculateAndSetDimensions(curr.offsetWidth);
			}
			this.state.classNames.add('mdc-linear-progress--animation-ready');
			this.setState({
				classNames: this.state.classNames,
			});
		} else {
			console.log('componentDidMount: root has no node.');
		}
	}

	componentDidUpdate(prevProps: Readonly<Partial<ILinearProgressProps>>, prevState: Readonly<ILinearProgressState>) {
		const {
			buffer,
			isDeterminate,
			isOpen,
			progress,
		} = this.props;
		const changed = (prevProps.buffer !== buffer)
			|| (prevProps.progress !== progress)
			|| (prevProps.isDeterminate !== isDeterminate)
			|| (prevProps.isOpen !== isOpen);
		if (changed) {
			const buf = (buffer === undefined)
				? 0
				: buffer;
			const prog = (progress === undefined)
				? 0
				: progress;
			const isDeter = (isDeterminate === undefined)
				? false
				: isDeterminate;
			const open = (isOpen == undefined)
				? false
				: isOpen;
			this.setDeterminate(isDeter);
			this.setBuffer(buf);
			this.setProgress(prog);
			this.setOpen(open);
		}
	}

	componentWillUnmount() {
		this.resizeObs.disconnect();
	}

	private setDeterminate(isDeterminate: boolean) {
		const {
			buffer,
			progress,
		} = this.props;
		const {classNames} = this.state;
		let buf = (buffer === undefined)
			? 1
			: buffer;
		let prog = (progress === undefined)
			? 0
			: progress;
		if (!isDeterminate) {
			buf = 1;
			prog = 1;
		}
		const curr = this.rootRef.current;
		if (curr) {
			this.calculateAndSetDimensions(curr.offsetWidth);
		}
		classNames.delete('mdc-linear-progress--indeterminate');
		classNames.add('mdc-linear-progress--indeterminate');
		this.setState(
			{
				classNames,
			},
			() => {
				this.setPrimaryBarProgress(prog);
				this.setBufferBarProgress(buf);
			},
		);
	}

	private setPrimaryBarProgress(progressValue: number) {
		this.setState({
			primaryBarStyles: {
				transform: `scaleX(${progressValue})`,
			},
		});
	}

	private setBuffer(buffer: number) {
		if (this.props.isDeterminate) {
			this.setBufferBarProgress(buffer);
		}
	}

	private setProgress(progress: number) {
		if (this.props.isDeterminate) {
			this.setPrimaryBarProgress(progress);
		}
	}

	private setBufferBarProgress(progressValue: number) {
		this.setState({
			bufferBarStyles: {
				...this.state.bufferBarStyles,
				flexBasis: `${progressValue * 100}%`,
			},
		});
	}

	private calculateAndSetDimensions(width: number) {
		this.setState(
			{
				rootStyles: {
					['--mdc-linear-progress-primary-half' as any]: `${width * .8367142}px`,
					['--mdc-linear-progress-primary-half-neg' as any]: `${-width * .8367142}px`,
					['--mdc-linear-progress-primary-full' as any]: `${width * 2.00611057}px`,
					['--mdc-linear-progress-primary-full-neg' as any]: `${-width * 2.00611057}px`,
					['--mdc-linear-progress-secondary-quarter' as any]: `${width * .37651913}px`,
					['--mdc-linear-progress-secondary-quarter-neg' as any]: `${-width * .37651913}px`,
					['--mdc-linear-progress-secondary-half' as any]: `${width * .84386165}px`,
					['--mdc-linear-progress-secondary-half-neg' as any]: `${-width * .84386165}px`,
					['--mdc-linear-progress-secondary-full' as any]: `${width * 1.60277782}px`,
					['--mdc-linear-progress-secondary-full-neg' as any]: `${-width * 1.60277782}px`,
				},
			},
			() => {
				this.state.classNames.delete('mdc-linear-progress--animation-ready');
				this.setState(
					{
						classNames: this.state.classNames,
					},
					() => {
						this.forceUpdate(() => {
							this.state.classNames.add('mdc-linear-progress--animation-ready');
							this.setState({
								classNames: this.state.classNames,
							});
						});
					},
				);
			},
		);
	}

	@bind
	private resizeObsCallBack(entries: Array<ResizeObserverEntry>) {
		if (!this.props.isDeterminate) {
			for (const entry of entries) {
				this.calculateAndSetDimensions(entry.contentRect.width);
			}
		}
	}

	render() {
		const {
			buffer,
			className,
			isDeterminate,
			isOpen,
			progress,
			...rest
		} = this.props;
		const {
			bufferBarStyles,
			classNames,
			primaryBarStyles,
		} = this.state;
		const clsName = makeClassName(
			'mdc-linear-progress',
			'mdc-linear-progress--indeterminate',
			...classNames,
			className,
		);
		return (
			<div className={clsName} ref={this.rootRef} onTransitionEnd={this.transitionEnd} role="progressbar" {...rest}>
				<div className="mdc-linear-progress__buffer">
					<div className="mdc-linear-progress__buffer-bar" style={bufferBarStyles}/>
					<div className="mdc-linear-progress__buffer-dots"/>
				</div>
				<div className="mdc-linear-progress__bar mdc-linear-progress__primary-bar" style={primaryBarStyles}>
					<div className="mdc-linear-progress__bar-inner"/>
				</div>
				<div className="mdc-linear-progress__bar mdc-linear-progress__secondary-bar">
					<div className="mdc-linear-progress__bar-inner"/>
				</div>
			</div>
		);
	}

	private setOpen(isOpen: boolean) {
		const {classNames} = this.state;
		if (isOpen) {
			classNames.delete('mdc-linear-progress--closed');
			classNames.delete('mdc-linear-progress--closed-animation-off');
		} else {
			classNames.add('mdc-linear-progress--closed');
		}
		this.setState({
			classNames,
		});
	}

	@bind
	private transitionEnd() {
		const {classNames} = this.state;
		if (classNames.has('mdc-linear-progress--closed')) {
			classNames.add('mdc-linear-progress--closed-animation-off');
			this.setState({
				classNames,
			});
		}
	}
}
