import React from 'react';

import {ToolButton} from '../toolbutton';
import {
	bind,
	makeClassName,
} from '../../util';
import {
	Icon,
	IIconProps,
} from '../icon';

export enum CCardToolbarAction {
	Edit,
	More,
	Close,
}

export function CCardHeader(props: React.HTMLAttributes<any>) {
	const {
		children,
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		'pb-cc-header',
		className,
	);
	return (
		<div className={clsName} {...rest}>
			<div className="pb-cc-detail-header__inner pb-cc-header__inner">
				{children}
			</div>
		</div>
	);
}

export interface IEditCardHeaderProps extends React.HTMLAttributes<any> {
	text?: string;
}

export function EditCardHeader(props: IEditCardHeaderProps) {
	const {
		children,
		className,
		text,
		...rest
	} = props;
	const clsName = makeClassName('pb-cc-header', className);
	return (
		<div className={clsName} {...rest}>
			<div className="pb-cc-header__inner pb-cc-edit-header__inner">
				<div className="pb-cc-edit-header__content">
					{
						(text === undefined)
							? null
							: (
								<div className="pb-cc-edit-header__text-content">
									{text}
								</div>
							)
					}
					{children}
				</div>
			</div>
		</div>
	);
}

export function CCardAvatar(props: Partial<IIconProps>) {
	const {
		children,
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		'pb-cc-detail-header__avatar',
		className,
	);
	return (
		<div className="pb-cc-detail-header__avatar-container">
			<Icon className={clsName} {...rest}>
				{children}
			</Icon>
		</div>
	);
}

export function CCardDescribe(props: React.HTMLAttributes<any>) {
	const {
		children,
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		'pb-cc-detail-header__describe',
		className,
	);
	return (
		<div className={clsName} {...rest}>
			{children}
		</div>
	);
}

export function CCardIntro(props: React.HTMLAttributes<any>) {
	const {
		children,
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		'pb-cc-detail-header__intro',
		className,
	);
	return (
		<div className={clsName} {...rest}>
			{children}
		</div>
	);
}

export function CCardNameTitle(props: React.HTMLAttributes<any>) {
	const {
		children,
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		'pb-cc-detail-header__name-title',
		className,
	);
	return (
		<div className={clsName} {...rest}>
			{children}
		</div>
	);
}

export function CCardName(props: React.HTMLAttributes<any>) {
	const {
		children,
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		'pb-cc-detail-header__name',
		className,
	);
	return (
		<div className={clsName} {...rest}>
			{children}
		</div>
	);
}

export function CCardTitle(props: React.HTMLAttributes<any>) {
	const {
		children,
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		'pb-cc-detail-header__title',
		className,
	);
	return (
		<div className={clsName} {...rest}>
			{children}
		</div>
	);
}

export interface ICCardToolbarProps extends React.HTMLAttributes<any> {
	actions: Array<CCardToolbarAction>;
	onAction: (axn: CCardToolbarAction, coords: PlainCoords) => any;
}

const axnMap: Map<CCardToolbarAction, string> = new Map([
	[
		CCardToolbarAction.Edit,
		'create',
	],
	[
		CCardToolbarAction.More,
		'more_vert',
	],
	[
		CCardToolbarAction.Close,
		'close',
	],
]);

export class CCardToolbar extends React.Component<Partial<ICCardToolbarProps>, {}> {
	@bind
	private buttonClicked(axn: CCardToolbarAction, event: React.MouseEvent): void {
		const {onAction} = this.props;
		if (onAction) {
			onAction(
				axn,
				{
					x: event.clientX,
					y: event.clientY,
				},
			);
		}
	}

	render() {
		const {
			actions,
			className,
			onAction,
			...rest
		} = this.props;
		const clsName = makeClassName(
			'pb-cc-detail-header__toolbar',
			className,
		);
		const ac = (actions === undefined)
			? [
				CCardToolbarAction.Edit,
				CCardToolbarAction.More,
				CCardToolbarAction.Close,
			]
			: actions;
		const axns: Array<[CCardToolbarAction, string]> = [];
		for (const axn of ac) {
			const iconName = axnMap.get(axn);
			if (iconName !== undefined) {
				axns.push([
					axn,
					iconName,
				]);
			}
		}
		return (
			<div className={clsName} {...rest}>
				{
					axns.map((axn, i) => {
						return (
							<ToolButton className="pb-toolbar-tool-button pb-cc-tool-button" key={i} onClick={this.buttonClicked.bind(this, axn[0])}>
								{axn[1]}
							</ToolButton>
						);
					})
				}
			</div>
		);
	}
}
