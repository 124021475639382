import React from 'react';

import {bind} from '../../../util';
import {CheckState} from '../../../constants';
import * as datetime from '../../../datetime';
import {
	Button,
	Card,
	CardHeader,
	CardInner,
	Checkbox,
	GridLayout,
	GridLayoutCell,
	GridLayoutInner,
	TextInput,
} from '../../../components';

export interface IProjectReadTempDateTimeChangerThingProps extends Omit<React.HTMLAttributes<any>, 'onSubmit'> {
	event: IEvent;
	projectEvent: IProjectEvent;
	onSubmit: (evt: IEvent, anytime: boolean) => any;
}

interface IDtParts {
	month: string;
	day: string;
	hour: string;
	minute: string;
	duration: string;
	year: string;
}

interface IProjectReadTempDateTimeChangerThingState extends IDtParts {
	anytime: boolean;
}

export class ProjectReadTempDateTimeChangerThing extends React.Component<IProjectReadTempDateTimeChangerThingProps, IProjectReadTempDateTimeChangerThingState> {
	constructor(props: IProjectReadTempDateTimeChangerThingProps) {
		super(props);
		this.state = {
			anytime: false,
			day: '',
			duration: '',
			hour: '',
			minute: '',
			month: '',
			year: '',
		};
	}

	@bind
	anytimeChanged(state: CheckState) {
		this.setState({
			anytime: state === CheckState.Checked,
		});
	}

	componentDidMount() {
		const {
			event,
			projectEvent,
		} = this.props;
		const dt = event.dt;
		if ((dt === null) || (dt.lower === null) || (dt.upper === null)) {
			return;
		}
		const lower = datetime.datetime.fromisoformat(dt.lower);
		const upper = datetime.datetime.fromisoformat(dt.upper);
		this.setState({
			month: String(lower.month),
			day: String(lower.day),
			hour: String(lower.hour),
			minute: String(lower.minute),
			duration: String(upper.sub(lower)),
			year: String(lower.year),
			anytime: projectEvent.flexibleDatetime,
		});
	}

	@bind
	private dtChanged<K extends keyof IDtParts>(key: K, value: IDtParts[K]): void {
		this.setState({
			...this.state,
			[key]: value,
		});
	}

	render() {
		const {
			month,
			day,
			hour,
			minute,
			duration,
			year,
			anytime,
		} = this.state;
		return (
			<Card>
				<CardHeader>
					<div className="display--flex flex-direction--row justify-content--space-between align-items--center">
						<h2 className="mdc-typography--title">Temp Datetime Changer Thing</h2>
						<label className="display--flex align-items--center">
							Anytime
							<Checkbox isChecked={anytime} onChange={this.anytimeChanged}/>
						</label>
					</div>
				</CardHeader>
				<CardInner>
					<form onSubmit={this.submitted}>
						<GridLayout id="id_temp-datetime-changer-thing-form">
							<GridLayoutCell span={12}>
								<GridLayoutInner>
									<GridLayoutCell desktopSpan={2}>
										<TextInput
											isMinimal
											isRequired
											value={month}
											label="Month (1-12)"
											onChange={this.dtChanged.bind(this, 'month')}
											type="number"
											max={12}
											min={1}
										/>
									</GridLayoutCell>
									<GridLayoutCell desktopSpan={2}>
										<TextInput
											isMinimal
											isRequired
											value={day}
											label="Day (1-31)"
											onChange={this.dtChanged.bind(this, 'day')}
											type="number"
											max={31}
											min={1}
										/>
									</GridLayoutCell>
								</GridLayoutInner>
							</GridLayoutCell>
							<GridLayoutCell span={12}>
								<GridLayoutInner>
									<GridLayoutCell desktopSpan={2}>
										<TextInput
											isMinimal
											isRequired
											value={hour}
											label="Start hour (0-23)"
											onChange={this.dtChanged.bind(this, 'hour')}
											type="number"
											max={23}
											min={0}
										/>
									</GridLayoutCell>
									<GridLayoutCell desktopSpan={2}>
										<TextInput
											isMinimal
											isRequired
											value={minute}
											label="Start minute (0-59)"
											onChange={this.dtChanged.bind(this, 'minute')}
											type="number"
											max={59}
											min={0}
										/>
									</GridLayoutCell>
								</GridLayoutInner>
							</GridLayoutCell>
							<GridLayoutCell span={12}>
								<GridLayoutInner>
									<GridLayoutCell desktopSpan={2}>
										<TextInput
											isMinimal
											isRequired
											value={duration}
											label="Duration (HH:MM:SS)"
											onChange={this.dtChanged.bind(this, 'duration')}
										/>
									</GridLayoutCell>
								</GridLayoutInner>
							</GridLayoutCell>
							<GridLayoutCell span={12}>
								<GridLayoutInner>
									<GridLayoutCell desktopSpan={2}>
										<TextInput
											isMinimal
											value={year}
											label="Year (1-9999)"
											onChange={this.dtChanged.bind(this, 'year')}
											type="number"
											max={9999}
											min={1}
										/>
									</GridLayoutCell>
								</GridLayoutInner>
							</GridLayoutCell>
							<GridLayoutCell span={12}>
								<Button className="width--100-percent margin-top--16" raisedFilled type="submit">
									Update
								</Button>
							</GridLayoutCell>
						</GridLayout>
					</form>
				</CardInner>
			</Card>
		);
	}

	@bind
	private submitted(evt: React.FormEvent): void {
		evt.preventDefault();
		const {
			event,
			onSubmit,
		} = this.props;
		const {
			month,
			day,
			hour,
			minute,
			duration,
			year,
			anytime,
		} = this.state;
		const lower = new datetime.datetime(
			Number.parseInt(year),
			Number.parseInt(month),
			Number.parseInt(day),
			Number.parseInt(hour),
			Number.parseInt(minute),
			0,
		);
		// Ensure leading zero in the case where a single digit is given.
		const upper = lower.add(
			datetime.timedelta.fromisoformat(
				duration.replace(/^(\d:)/, '0$1'),
			),
		);
		onSubmit(
			{
				id: event.id,
				dt: {
					lower: lower.isoformat(),
					lowerInc: event.dt === null
						? true
						: event.dt.lowerInc,
					upper: upper.isoformat(),
					upperInc: event.dt === null
						? false
						: event.dt.upperInc,
					isempty: event.dt === null
						? true
						: event.dt.isempty,
				},
			},
			anytime,
		);
	}
}
