import {numberFormat, numberStringCmp} from '.';

type ProductPrice = {price: string; altPrice: string | null;};

export function priceHiLo(item: ProductPrice, children: Array<ProductPrice>): [string, string] {
	let price: string;
	let altPrice: string;
	if (children.length <= 1) {
		const obj = (children.length === 1)
			? children[0]
			: item;
		price = numberFormat(obj.price, '$');
		altPrice = obj.altPrice
			? numberFormat(obj.altPrice, '$')
			: '';
	} else {
		const prices = children.map(obj => obj.price);
		prices.sort(numberStringCmp);
		const lo = prices[0];
		const hi = prices[prices.length - 1];
		if (lo && hi) {
			price = `${numberFormat(lo, '$')} ~ ${numberFormat(hi, '$')}`;
		} else {
			price = '';
		}
		const altPrices = <Array<string>>children.map(obj => obj.altPrice).filter(obj => (typeof obj === 'string'));
		const altValuesKeep: [string, string] = ['', ''];
		if (altPrices.length > 0) {
			altPrices.sort(numberStringCmp);
			const altLo = altPrices[0];
			const altHi = altPrices[altPrices.length - 1];
			if (numberStringCmp(altLo, lo) > 0) {
				// altPrice > price
				altValuesKeep[0] = altLo;
				if (numberStringCmp(altHi, hi) > 0) {
					altValuesKeep[1] = altHi;
				}
			}
		}
		const [avkLo, avkHi] = altValuesKeep;
		if (avkLo) {
			if (avkHi) {
				altPrice = `${numberFormat(avkLo, '$')} ~ ${numberFormat(avkHi, '$')}`;
			} else {
				altPrice = numberFormat(avkLo, '$');
			}
		} else {
			altPrice = '';
		}
	}
	return [price, altPrice];
}
