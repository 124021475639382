import React from 'react';

import {
	bind,
	staticUser,
} from '../../util';
import {
	Button,
	CCard,
	CCardSection,
	CCardHeader,
	CCardContent,
	CCardBottom,
	ICCardProps,
	CCardTitle,
	CCardNameTitle,
	CCardDescribe,
	CCardIntro,
	CCardName,
	CCardAvatar,
	UserCardHeaderChipSet,
} from '../../components';

export interface IUserDeleteProps extends Omit<ICCardProps, 'onChange'> {
	objDeleter: (pk: string) => Promise<any>;
	objGetter: (pk: string) => Promise<IUser>;
	onCancel: () => any;
	onDone: () => any;
	pk: string;
	userClasses: Array<IUserClass>;
}

interface IUserDeleteState {
	obj: IUser;
}

export class UserDelete extends React.Component<IUserDeleteProps, IUserDeleteState> {
	constructor(props: IUserDeleteProps) {
		super(props);
		this.state = {
			obj: staticUser(),
		};
	}

	@bind
	private canceled(): void {
		const {onCancel} = this.props;
		if (onCancel) {
			onCancel();
		}
	}

	async componentDidMount() {
		const {
			objGetter,
			pk,
		} = this.props;
		this.setState({
			obj: await objGetter(pk),
		});
	}

	@bind
	private async deleteObj(pk: string): Promise<void> {
		const {objDeleter} = this.props;
		return await objDeleter(pk);
	}

	render() {
		const {
			objDeleter,
			objGetter,
			onCancel,
			onDone,
			userClasses,
			pk,
			...rest
		} = this.props;
		const {
			obj,
		} = this.state;
		const avName = obj.isAdmin
			? 'admin_panel_settings'
			: 'account_circle';
		const userClassName = this.userClassName(obj.userClassId);
		return (
			<CCard {...rest}>
				<CCardHeader>
					<CCardIntro>
						<CCardAvatar>{avName}</CCardAvatar>
						<CCardDescribe>
							<CCardNameTitle>
								<CCardName>{obj.displayName}</CCardName>
								<CCardTitle>{obj.organizationName}</CCardTitle>
							</CCardNameTitle>
							{
								userClassName
									? <UserCardHeaderChipSet text={userClassName}/>
									: null
							}
						</CCardDescribe>
					</CCardIntro>
				</CCardHeader>
				<CCardContent>
					<CCardSection>
						Delete this client?
					</CCardSection>
				</CCardContent>
				<CCardBottom {...rest}>
					<Button value="cancel" raisedFilled onClick={this.canceled}>Cancel</Button>
					<Button value="delete" onClick={this.submitted}>Delete</Button>
				</CCardBottom>
			</CCard>
		);
	}

	@bind
	private async submitted() {
		const {onDone} = this.props;
		const {obj} = this.state;
		await this.deleteObj(obj.email);
		this.setState(
			{
				obj: staticUser(),
			},
			() => {
				if (onDone) {
					onDone();
				}
			},
		);
	}

	private userClass(pk?: number | null): IUserClass | null {
		if ((pk === undefined) || (pk === null)) {
			return null;
		}
		const {userClasses} = this.props;
		for (const obj of userClasses) {
			if (obj.id === pk) {
				return obj;
			}
		}
		return null;
	}

	private userClassName(pk?: number | null): string | undefined {
		const obj = this.userClass(pk);
		return obj
			? obj.name
			: undefined;
	}
}
