import React from 'react';

import {bind} from '../../../util';
import {SizeInput} from './sizeinput';
import {IconButtonSubheading} from './iconbuttonsubheading';

export interface ISizeProps {
	onChange: (value: number | null, name: 'size') => any;
	size: number | null;
}

interface ISizeState {
	renderTextInput: boolean;
}

export class Size extends React.Component<Partial<ISizeProps>, ISizeState> {
	constructor(props: Partial<ISizeProps>) {
		super(props);
		this.state = {
			renderTextInput: false,
		};
	}

	@bind
	buttonClick(): void {
		const {renderTextInput} = this.state;
		this.setState({renderTextInput: !renderTextInput});
	}

	render(): React.ReactNode {
		const {renderTextInput} = this.state;
		const {
			onChange,
			size
		} = this.props;
		const iconName = renderTextInput
			? 'text_fields'
			: 'linear_scale';
		const title = renderTextInput
			? 'Switch to slider'
			: 'Switch to text input';
		return (
			<>
				<IconButtonSubheading iconName={iconName} onClick={this.buttonClick} title={title}>
					Size
				</IconButtonSubheading>
				<SizeInput
					onChange={onChange}
					renderTextInput={renderTextInput}
					value={size}
				/>
			</>
		);
	}
}
