import React from 'react';

import {
	bind,
	tryInteger,
} from '../../../util';
import {
	CardHeader,
	IProjectCreateViewState,
} from './misc';
import {
	ComboBox,
	GridLayout,
	GridLayoutCell,
	IMenuOptionProps,
	TextInput,
} from '../../../components';

export interface IProjectLocationViewProps extends Omit<React.HTMLAttributes<any>, 'onChange'> {
	onChange: <K extends keyof IProjectCreateViewState>(name: K, value: IProjectCreateViewState[K]) => any;
	propertyName: string;
	serviceAreaPk: number | null;
	serviceAreas: Array<IServiceArea>;
	street: string;
}

interface IProjectLocationViewState {
}

export class ProjectLocationView extends React.Component<Partial<IProjectLocationViewProps>, IProjectLocationViewState> {
	@bind
	private notifyChange<K extends keyof IProjectCreateViewState>(name: K, value: IProjectCreateViewState[K]): void {
		const {onChange} = this.props;
		if (onChange) {
			onChange(name, value);
		}
	}

	render() {
		const {
			propertyName,
			street,
		} = this.props;
		return (
			<>
				<CardHeader stepNumber={1}>
					Location
				</CardHeader>
				<GridLayout>
					<GridLayoutCell span={12}>
						<TextInput
							className="width--100-percent"
							label="Street"
							placeholder="123 Ocean Boulevard"
							value={street}
							onChange={val => this.notifyChange('street', val)}
						/>
					</GridLayoutCell>
					<GridLayoutCell span={12}>
						<ComboBox
							className="width--100-percent"
							isRequired
							options={this.serviceAreaOptions()}
							onChange={this.serviceAreaSelected}
							label="Select a city"
						/>
					</GridLayoutCell>
					<GridLayoutCell span={12}>
						<TextInput
							className="width--100-percent"
							label="Name"
							placeholder="Property name"
							value={propertyName}
							onChange={val => this.notifyChange('propertyName', val)}
						/>
					</GridLayoutCell>
				</GridLayout>
			</>
		);
	}

	private serviceAreaOptions(): Array<IMenuOptionProps> {
		const {
			serviceAreaPk,
			serviceAreas,
		} = this.props;
		if (serviceAreas === undefined) {
			return [];
		}
		return serviceAreas.map(obj => ({
			label: obj.name,
			value: String(obj.id),
			isSelected: (obj.id === serviceAreaPk),
		}));
	}

	@bind
	private serviceAreaSelected(index: number): void {
		const opts = this.serviceAreaOptions();
		if ((index >= 0) && (index < opts.length)) {
			const opt = opts[index];
			const id = tryInteger(opt.value);
			if (id === null) {
				console.log(
					'serviceAreaSelected: Invalid value for option at index',
					index,
				);
			} else {
				this.notifyChange('serviceAreaPk', id);
			}
		} else {
			console.log(
				'serviceAreaSelected: Invalid index',
				index,
			);
		}
	}
}
