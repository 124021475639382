import React from 'react';

import {
	bind,
	makeClassName,
	numberFormat,
} from '../../../util';
import {
	Icon,
	IconButton,
	IIconButtonProps,
	IIconProps,
	IListItemBaseProps,
	ListItemBase,
	ListItemPrimaryText,
	ListItemSecondaryText,
	ListItemText,
} from '../../../components';

export interface IProductListItemProps extends Partial<Omit<IListItemBaseProps, 'onClick'>> {
	priceRepr?: PriceRepr;
	isCompact?: boolean;
	obj: ICatalogProduct;
	onClick: (pk: number) => any;
}

interface IProductListItemState {
	showMoreInfo: boolean;
}

export class ProductListItem extends React.Component<IProductListItemProps, IProductListItemState> {
	constructor(props: IProductListItemProps) {
		super(props);
		this.state = {
			showMoreInfo: false,
		};
	}

	@bind
	private clicked(): void {
		const {
			obj,
			onClick,
		} = this.props;
		onClick(obj.id);
	}

	@bind
	private infoButtonClicked(event: React.MouseEvent): void {
		event.stopPropagation();
		const {showMoreInfo} = this.state;
		this.setState({
			showMoreInfo: !showMoreInfo,
		});
	}

	render() {
		const {
			className,
			priceRepr,
			children,
			isCompact,
			isSelected,
			obj,
			onClick,
			...rest
		} = this.props;
		const {showMoreInfo} = this.state;
		const atSizeComp = (obj.size === null)
			? null
			: (
				<AtSize size={obj.size}/>
			);
		const icon = isSelected
			? 'check_circle'
			: obj.icon;
		const graphicComp = (
			<ProductIcon>
				{icon}
			</ProductIcon>
		);
		const nfoBtnComp = (obj.description.length > 0)
			? (
				<HelpButton onClick={this.infoButtonClicked}>
					{
						showMoreInfo
							? 'help_outline'
							: 'help'
					}
				</HelpButton>
			)
			: null;
		const clsName = makeClassName(
			'pb-catalog-item',
			isSelected
				? 'pb-catalog-item--selected'
				: undefined,
			showMoreInfo
				? 'pb-catalog-item--show-more-info'
				: undefined,
			className,
		);
		let price: string = '';
		let altPrice: string = '';
		if (priceRepr !== undefined) {
			price = priceRepr.price;
			altPrice = priceRepr.altPrice;
		}
		const altPriceComp = (altPrice.length > 0)
			? (
				<AltPrice>{obj.altPrice}</AltPrice>
			)
			: null;
		const metaComp = isCompact
			? null
			: (
				<Price>
					{altPriceComp}
					{price}
				</Price>
			);
		const secTxtComp = isCompact
			? (
				<ListItemSecondaryText>
					<Price>
						{altPriceComp}
						{price}
					</Price>
				</ListItemSecondaryText>
			)
			: null;
		const sty = {
			backgroundColor: (obj.color.length > 0)
				? obj.color
				: undefined,
		};
		const moreNfoComp = showMoreInfo
			? (
				<MoreInfo style={sty}>{obj.description}</MoreInfo>
			)
			: null;
		return (
			<>
				<ListItemBase afterText={nfoBtnComp} className={clsName} graphic={graphicComp} isSelected={isSelected} meta={metaComp} noRipple onClick={this.clicked} style={sty} {...rest}>
					{
						isCompact
							? (
								<ListItemText>
									<ListItemPrimaryText>
										{obj.name}{atSizeComp}
									</ListItemPrimaryText>
									{secTxtComp}
								</ListItemText>
							)
							: (
								<ListItemText>
									{obj.name}{atSizeComp}
								</ListItemText>
							)
					}
				</ListItemBase>
				{moreNfoComp}
			</>
		);
	}
}

interface IAtSizeProps extends React.HTMLAttributes<any> {
	size: number | string;
}

function AtSize(props: IAtSizeProps) {
	const {
		className,
		size,
		...rest
	} = props;
	const clsName = makeClassName(
		'catalog-item-at-size',
		className,
	);
	const str = (size === undefined)
		? ''
		: numberFormat(size);
	return (
		<span className={clsName} {...rest}>@ {str} ft<sup>2</sup></span>
	);
}

interface IHelpButtonProps extends IIconButtonProps {
}

function HelpButton(props: Partial<IHelpButtonProps>) {
	const {
		children,
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		className,
	);
	return (
		<IconButton className={clsName} {...rest}>
			{children}
		</IconButton>
	);
}

interface IMoreInfo extends IListItemBaseProps {
}

function MoreInfo(props: Partial<IMoreInfo>) {
	const {
		children,
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		'pb-catalog-item',
		'pb-catalog-item__more-info',
		className,
	);
	return (
		<ListItemBase className={clsName} {...rest}>
			{children}
		</ListItemBase>
	);
}

interface IProductIconProps extends IIconProps {
}

function ProductIcon(props: Partial<IProductIconProps>) {
	const {
		children,
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		'pb-color--white',
		'pb-margin-right--8',
		className,
	);
	return (
		<Icon className={clsName} {...rest}>
			{children}
		</Icon>
	);
}

interface IAltPriceProps extends React.HTMLAttributes<any> {
}

function AltPrice(props: Partial<IAltPriceProps>) {
	const {
		children,
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		'text-decoration--line-through',
		'padding-right--4',
		'font-size--0point8rem',
		'color--light-grayish',
		className,
	);
	return (
		<span className={clsName} {...rest}>
            {children}
        </span>
	);
}

interface IPriceProps extends React.HTMLAttributes<any> {
}

function Price(props: Partial<IPriceProps>) {
	const {
		children,
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		'pb-catalog-item__secondary',
		className,
	);
	return (
		<span className={clsName} {...rest}>
            {children}
        </span>
	);
}
