import React from 'react';

import {
	CCard,
	CCardAvatar,
	CCardContent,
	CCardDescribe,
	CCardHeader,
	CCardIntro,
	CCardName,
	CCardNameTitle,
	CCardSection,
	CCardTitle,
	CCardToolbar,
	CCardToolbarAction,
	ICCardProps,
} from './ccard';

export interface IPromptProps extends ICCardProps {
	actions?: Array<CCardToolbarAction>;
	iconIsOutlined?: boolean;
	iconName?: string;
	header?: string;
	onAction?: (axn: CCardToolbarAction, coords: PlainCoords) => any;
	subHeader?: string;
}

export interface IPromptState {
}

export class Prompt extends React.Component<IPromptProps, IPromptState> {
	render() {
		const {
			actions,
			children,
			iconIsOutlined,
			iconName,
			header,
			onAction,
			subHeader,
			...rest
		} = this.props;
		const hasChildren = React.Children.toArray(children).length > 0;
		return (
			<CCard {...rest}>
				<CCardHeader>
					<CCardIntro>
						{
							(iconName === undefined)
								? null
								: (
									<CCardAvatar isOutlined={iconIsOutlined}>
										{iconName}
									</CCardAvatar>
								)
						}
						<CCardDescribe>
							<CCardNameTitle>
								{
									(header === undefined)
										? null
										: (
											<CCardName>
												{header}
											</CCardName>
										)
								}
								{
									(subHeader === undefined)
										? null
										: (
											<CCardTitle>
												{subHeader}
											</CCardTitle>
										)
								}
							</CCardNameTitle>
						</CCardDescribe>
					</CCardIntro>
					{
						actions
							? (
								<CCardToolbar
									actions={actions}
									onAction={onAction}
								/>
							)
							: null
					}
				</CCardHeader>
				{
					hasChildren
						? (
							<CCardContent>
								<CCardSection>
									{children}
								</CCardSection>
							</CCardContent>
						)
						: null
				}
			</CCard>
		);
	}
}
