import React from 'react';

import {api} from '../../httpapi';
import {staticUserClass} from '../../util';
import {CCard, CCardAvatar, CCardDescribe, CCardHeader, CCardIntro, CCardName, CCardNameTitle, CCardToolbar, CCardToolbarAction, ICCardProps} from '../../components';

export interface IUserClassReadViewProps extends Partial<ICCardProps> {
	onToolbarAction?: (axn: CCardToolbarAction, coords: PlainCoords) => any;
	pk: number;
}

interface IUserClassReadViewState {
	obj: IUserClass;
}

export class UserClassReadView extends React.Component<IUserClassReadViewProps, IUserClassReadViewState> {
	constructor(props: IUserClassReadViewProps) {
		super(props);
		this.state = {
			obj: staticUserClass(),
		};
	}

	async componentDidMount() {
		const {pk} = this.props;
		this.setState({
			obj: await api.userClassDetail(pk),
		});
	}

	render() {
		const {onToolbarAction} = this.props;
		const {obj} = this.state;
		return (
			<CCard>
				<CCardHeader>
					<CCardIntro>
						<CCardAvatar/>
						<CCardDescribe>
							<CCardNameTitle>
								<CCardName>
									{obj.name}
								</CCardName>
							</CCardNameTitle>
						</CCardDescribe>
					</CCardIntro>
					<CCardToolbar
						onAction={onToolbarAction}
					/>
				</CCardHeader>
			</CCard>
		);
	}
}
