import React from 'react';
import {
	Button,
	Card,
	ContentCard,
	Dialog,
	GridLayout,
	GridLayoutCell,
	Icon,
	Prompt,
	WideContentContainer,
	WideContentHeader,
} from '../../components';
import {
	DialogCode,
	StandardButton,
} from '../../constants';

import {api} from '../../httpapi';
import {
	bind,
	idxOk,
} from '../../util';

export interface IPaymentMethodViewProps extends React.HTMLAttributes<any> {
}

export interface IPaymentMethodViewState {
	activeObjIdx: number;
	dialogIsOpen: boolean;
	paymentMethods: Array<IPaymentMethod>;
}

export class PaymentMethodView extends React.Component<IPaymentMethodViewProps, IPaymentMethodViewState> {
	constructor(props: IPaymentMethodViewProps) {
		super(props);
		this.state = {
			activeObjIdx: -1,
			dialogIsOpen: false,
			paymentMethods: [],
		};
	}

	private activeObj(failSilent: boolean = false): IPaymentMethod | null {
		return this.objAtIndex(this.state.activeObjIdx, failSilent);
	}

	@bind
	private async closeDialog(result: DialogCode) {
		if (result === DialogCode.Accepted) {
			await this.removePaymentMethod(this.state.activeObjIdx);
		}
		this.setState({
			dialogIsOpen: false,
		});
	}

	async componentDidMount() {
		this.setState({
			paymentMethods: await api.paymentMethodList(),
		});
	}

	private objAtIndex(index: number, failSilent: boolean = false): IPaymentMethod | null {
		const {paymentMethods} = this.state;
		if (idxOk(index, paymentMethods.length, 'objAtIndex', failSilent)) {
			return paymentMethods[index];
		}
		return null;
	}

	private objSummary(obj: IPaymentMethod | null): string {
		if (obj === null) {
			return '';
		}
		return `${obj.brand}\u00A0\u2022\u2022\u2022\u2022\u00A0${obj.last4}`;
	}

	private openDialog(activeObjIdx: number) {
		this.setState({
			activeObjIdx,
			dialogIsOpen: true,
		});
	}

	@bind
	private removeClicked(index: number) {
		const {paymentMethods} = this.state;
		if (idxOk(index, paymentMethods.length, 'removeClicked')) {
			this.openDialog(index);
		}
	}

	private async removePaymentMethod(index: number) {
		const obj = this.objAtIndex(index);
		if (obj) {
			await api.deletePaymentMethod(obj.id);
			this.setState({
				paymentMethods: await api.paymentMethodList(),
			});
		}
	}

	render() {
		const {
			dialogIsOpen,
			paymentMethods,
		} = this.state;
		const actObj = this.activeObj(true);
		const promptHeader = 'Remove this payment method?';
		const promptSubheader = this.objSummary(actObj);
		const promptIcon = actObj
			? actObj.icon
			: 'payment';
		const buttons = StandardButton.Yes | StandardButton.No;
		return (
			<>
				<WideContentHeader hasDivider>Payment methods</WideContentHeader>
				<WideContentContainer>
					<ContentCard isWide>
						<GridLayout>
							{
								(paymentMethods.length > 0)
									? paymentMethods.map((obj, idx) => {
										return (
											<GridLayoutCell key={idx} span={6}>
												<PaymentMethod obj={obj} onClick={this.removeClicked.bind(this, idx)}/>
											</GridLayoutCell>
										);
									})
									: (
										<GridLayoutCell span={6}>
											<Card isOutlined style={{height: '165px'}}>
												<div className="display--flex flex-direction--row align-items--center justify-content--center height--100-percent">
													No saved payment methods
												</div>
											</Card>
										</GridLayoutCell>
									)
							}
						</GridLayout>
					</ContentCard>
				</WideContentContainer>
				<Dialog
					buttons={buttons}
					isOpen={dialogIsOpen}
					onFinished={this.closeDialog}>
					<Prompt header={promptHeader} subHeader={promptSubheader} iconName={promptIcon}/>
				</Dialog>
			</>
		);
	}
}

interface IPaymentMethodProps {
	obj: IPaymentMethod;
	onClick: () => any;
}

function PaymentMethod(props: IPaymentMethodProps) {
	const {
		obj,
		onClick,
	} = props;
	return (
		<Card>
			<div className="mdc-card-wrapper__text-section">
				<div className="display--flex flex-direction--row">
					<Icon className="margin-right--24 font-size--48px">{obj.icon}</Icon>
					<div className="display--flex flex-direction--column margin-top--4">
						<span>{obj.brand} &#8226;&#8226;&#8226;&#8226; {obj.last4}</span>
						{
							(obj.expiration === null)
								? null
								: (
									<Expiration obj={obj.expiration}/>
								)
						}
					</div>
				</div>
			</div>
			<div className="display--flex flex-direction--row justify-content--flex-end margin-top--36" style={{
				borderTop: '1px solid #F1F3F4',
				paddingTop: '10px',
				paddingBottom: '10px',
			}}>
				<Button className="mdc-button margin-left--24 margin-right--8" onClick={onClick}>Remove</Button>
			</div>
		</Card>
	);
}

interface IExpirationProps {
	obj: IExpiration;
}

function Expiration(props: IExpirationProps) {
	const {obj} = props;
	const sty: React.CSSProperties = {
		color: 'rgba(0, 0, 0, 0.54)',
		lineHeight: '18px',
	};
	return (
		<span className="font-size--12px font-weight--400" style={sty}>
			Expires {obj.month}/{obj.year}
		</span>
	);
}
