import React from 'react';

import {api} from '../../httpapi';
import * as datetime from '../../datetime';
import {SECONDS_IN_HOUR} from '../../constants';
import {operatingHoursToWeekdayRangeMap} from './create';
import {
	CardHeader,
	IOperatingHoursWithDateTimeTime,
} from './create/misc';
import {
	bind,
	padStart,
	secondsToDuration,
	staticDateTimeRange,
	staticEvent,
	staticProjectEvent,
} from '../../util';
import {
	Button,
	Card,
	GridLayout,
	GridLayoutCell,
	Scheduler,
	Slider,
} from '../../components';

const MAX_TOTAL_SECONDS = SECONDS_IN_HOUR * 9;
const STEP = SECONDS_IN_HOUR / 4;
const TIME_DELTA_MINUTES = 30;
const TIME_SEGMENT_STEP_MINUTES = TIME_DELTA_MINUTES;

export interface IRescheduleViewProps {
	pk: string;
}

interface IRescheduleViewState {
	anytime: boolean;
	date: datetime.date | null;
	dateAvailability: Map<string, Array<IAvailability>>;
	duration: number;
	event: IEvent;
	invoiceLines: Array<IInvoiceLine>;
	projectEvent: IProjectEvent;
	serviceAreaPk: number | null;
	showTimes: boolean;
	time: datetime.time | null;
	visualDate: datetime.date | null;
	weekdayTimeRangeMap: Map<number, IOperatingHoursWithDateTimeTime>;
	wx: Map<number, Array<IWxDay>>;
}

export class RescheduleView extends React.Component<IRescheduleViewProps, IRescheduleViewState> {
	constructor(props: IRescheduleViewProps) {
		super(props);
		this.state = {
			anytime: false,
			date: null,
			dateAvailability: new Map(),
			duration: 0,
			event: staticEvent(),
			invoiceLines: [],
			projectEvent: staticProjectEvent(),
			serviceAreaPk: null,
			showTimes: false,
			time: null,
			visualDate: null,
			weekdayTimeRangeMap: new Map(),
			wx: new Map(),
		};
	}

	@bind
	anyTimeChanged(checked: boolean): void {
		const {
			date,
			time,
			visualDate,
		} = this.state;
		this.setState({
			anytime: checked,
			date: checked
				? visualDate
				: date,
			time,
		});
	}

	@bind
	private backNavClicked() {
		if (this.state.showTimes) {
			this.setState({
				showTimes: false,
				visualDate: null,
			});
		} else {
			this.gotoReadView();
		}
	}

	private clearDateAvailability(isoDate: string): void {
		const {dateAvailability} = this.state;
		dateAvailability.delete(isoDate);
		this.setState({
			dateAvailability: new Map(dateAvailability),
		});
	}

	async componentDidMount() {
		let serviceAreaPk: number | null = this.state.serviceAreaPk;
		const project = await api.projectDetail(this.props.pk);
		if (project.locationId !== null) {
			const loc = await api.locationDetail(project.locationId);
			serviceAreaPk = loc.serviceAreaId;
		}
		const projectEvent = await api.projectEventDetail(this.props.pk);
		const event = await api.eventDetail(projectEvent.eventId);
		let duration: number = this.state.duration;
		let date: datetime.date | null = this.state.date;
		let time: datetime.time | null = this.state.time;
		if (event.dt && (event.dt.lower !== null) && (event.dt.upper !== null)) {
			const lowerDt = datetime.datetime.fromisoformat(event.dt.lower);
			date = lowerDt.date();
			time = lowerDt.time();
			const upperDt = datetime.datetime.fromisoformat(event.dt.upper);
			const td = upperDt.sub(lowerDt);
			duration = td.totalSeconds();
		}
		this.setState(
			{
				anytime: projectEvent.flexibleDatetime,
				duration,
				event,
				invoiceLines: await api.projectInvoiceLineList(this.props.pk),
				projectEvent,
				date,
				time,
				serviceAreaPk,
			},
			async () => {
				if (this.state.serviceAreaPk !== null) {
					await this.setServiceAreaWxDataIfNotAlready(this.state.serviceAreaPk);
				}
				await this.setWeekdayTimeRangeMapIfNotAlready();
			},
		);
	}

	@bind
	private dateAvailability(date: datetime.date): Array<IAvailability> {
		const {dateAvailability} = this.state;
		const d = dateAvailability.get(date.isoformat());
		return (d === undefined)
			? []
			: d;
	}

	@bind
	async dateChanged(dtDate: datetime.date | null) {
		const {
			date,
			showTimes,
			time,
			visualDate,
		} = this.state;
		let newVisDate: datetime.date | null = null;
		let newDate: datetime.date | null = date;
		let newTime: datetime.time | null = time;
		let newShowTimes: boolean = showTimes;
		if (dtDate === null) {
			newDate = null;
			newTime = null;
			if (visualDate !== null) {
				this.clearDateAvailability(visualDate.isoformat());
			}
		} else {
			await this.setDateAvailability(dtDate);
			if (this.dateIsAvailable()) {
				newVisDate = dtDate;
				newShowTimes = true;
			}
		}
		this.setState({
			date: newDate,
			showTimes: newShowTimes,
			time: newTime,
			visualDate: newVisDate,
		});
	}

	@bind
	dateIsAvailable(): boolean {
		return true;
	}

	private dtLower(): string | null {
		const {
			date,
			time,
		} = this.state;
		if ((date !== null) && (time !== null)) {
			return datetime.datetime.combine(date, time).isoformat();
		}
		return null;
	}

	private dtUpper(): string | null {
		const {
			date,
			time,
			duration,
		} = this.state;
		if ((date !== null) && (time !== null)) {
			return datetime.datetime.combine(
				date,
				time,
			).add(
				new datetime.timedelta(undefined, duration),
			).isoformat();
		}
		return null;
	}

	@bind
	private durationChanged(duration: number) {
		this.setState({
			duration,
		});
	}

	@bind
	private formatDurationValue(value: number): string {
		const dur = secondsToDuration(value);
		const pl = (dur.hours === 1)
			? ''
			: 's';
		const hr = `Hour${pl}`;
		return `${dur.hours} ${hr} ${padStart(dur.minutes, 2, ' ')} Minutes`;
	}

	private gotoReadView() {
		window.location.assign(this.readViewUrl());
	}

	private hasChanges(): boolean {
		const {
			anytime,
			event,
			projectEvent,
		} = this.state;
		const oldDt = event.dt
			? new DtThing(event.dt.lower, event.dt.upper)
			: new DtThing(null, null);
		const dtLow = this.dtLower();
		const dtUp = this.dtUpper();
		const newDt = new DtThing(dtLow, dtUp);
		return (projectEvent.flexibleDatetime !== anytime) || oldDt.neq(newDt);
	}

	private readViewUrl(): string {
		const {pk} = this.props;
		return `${window.pbUrls.projectListView}${pk}/`;
	}

	render() {
		const {
			duration,
			showTimes,
			visualDate,
		} = this.state;
		return (
			<GridLayout>
				<GridLayoutCell span={12}>
					<Card>
						<CardHeader onBackNavClick={this.backNavClicked}>
							{
								showTimes
									? 'Pick a Time'
									: 'Pick a Date'
							}
						</CardHeader>
						<GridLayout>
							<GridLayoutCell span={12}>
								<Scheduler
									currentDate={visualDate}
									currentDateAvailability={this.dateAvailability}
									dateIsAvailable={this.dateIsAvailable}
									onAnyTimeChange={this.anyTimeChanged}
									onDateChange={this.dateChanged}
									onTimeChange={this.timeChanged}
									onTimeSelectRequest={this.timeSelectRequested}
									selectedEvent={this.selectedEvent()}
									timeRangeForWeekday={this.timeRangeForWeekday}
									timeSegmentStep={TIME_SEGMENT_STEP_MINUTES}
									weather={this.wx()}
								/>
							</GridLayoutCell>
						</GridLayout>
					</Card>
					<Card>
						<CardHeader>
							Adjust duration
						</CardHeader>
						<GridLayout>
							<GridLayoutCell span={12}>
								<div className="justify-content--center display--flex color--grayish">{this.formatDurationValue(duration)}</div>
								<Slider
									max={MAX_TOTAL_SECONDS}
									min={0}
									onChange={this.durationChanged}
									step={STEP}
									value={duration}
								/>
							</GridLayoutCell>
						</GridLayout>
					</Card>
					<Card>
						<GridLayout>
							<GridLayoutCell span={12}>
								<Button disabled={!this.hasChanges()} onClick={this.saveClicked} raisedFilled style={{width: '100%'}}>
									Save
								</Button>
							</GridLayoutCell>
						</GridLayout>
					</Card>
				</GridLayoutCell>
			</GridLayout>
		);
	}

	@bind
	private async saveClicked() {
		await this.saveEvent();
		this.gotoReadView();

	}

	private async saveEvent() {
		const {pk} = this.props;
		const {
			anytime,
			event,
			projectEvent,
		} = this.state;
		await api.updateProjectEvent(
			pk,
			{
				...projectEvent,
				flexibleDatetime: anytime,
			},
		);
		const dt = event.dt
			? {...event.dt}
			: staticDateTimeRange();
		dt.lower = this.dtLower();
		dt.upper = this.dtUpper();
		dt.isempty = (dt.lower === null) && (dt.upper === null);
		const evt = {
			...event,
			dt,
		};
		await api.updateEvent(evt);
	}

	private selectedEvent(): datetime.ISchedulerSelectedBlock | null {
		const {
			duration,
			anytime,
			date: stateDate,
			time: stateTime,
		} = this.state;
		if (stateDate && (stateTime || anytime)) {
			let iso: string = stateDate.isoformat();
			if (stateTime) {
				iso = `${iso}T${stateTime.isoformat()}`;
			}
			const dt = datetime.datetime.fromisoformat(iso);
			return {
				start: dt,
				duration,
				anyTime: anytime,
			};
		}
		return null;
	}

	private async setDateAvailability(date: datetime.date) {
		const {
			dateAvailability,
			invoiceLines,
			serviceAreaPk,
		} = this.state;
		if (serviceAreaPk === null) {
			console.log('setDateAvailability: Service area is null');
			return;
		}
		const productPks: Array<number> = [];
		for (const obj of invoiceLines) {
			if (obj.productId !== null) {
				productPks.push(obj.productId);
			}
		}
		const timeRange = this.timeRangeForWeekday();
		if (timeRange === null) {
			return;
		}
		const delta = `00:${TIME_DELTA_MINUTES}:00`;
		const startTime = timeRange.start;
		const endTime = timeRange.stop;
		const startDateTime = datetime.datetime.combine(date, startTime);
		const endDateTime = datetime.datetime.combine(date, endTime);
		const data = await api.availabilityList({
			svc: serviceAreaPk,
			start: startDateTime.isoformat(),
			end: endDateTime.isoformat(),
			dx: delta,
			pid: Array.from(productPks),
		});
		dateAvailability.set(
			date.isoformat(),
			data,
		);
		this.setState({
			dateAvailability: new Map(dateAvailability),
		});
	}

	private async setServiceAreaWxDataIfNotAlready(svcPk: number | null) {
		const {wx} = this.state;
		if ((svcPk === null) || wx.has(svcPk)) {
			return;
		}
		wx.set(
			svcPk,
			await api.serviceAreaWxDayList(svcPk),
		);
		this.setState({
			wx: new Map(wx),
		});
	}

	@bind
	private async setWeekdayTimeRangeMapIfNotAlready() {
		const {weekdayTimeRangeMap} = this.state;
		if (weekdayTimeRangeMap.size > 0) {
			return;
		}
		this.setState({
			weekdayTimeRangeMap: operatingHoursToWeekdayRangeMap(await api.operatingHours()),
		});
	}

	@bind
	private timeChanged(time: datetime.time | null): void {
		const {date} = this.state;
		let newDate: datetime.date | null = date;
		let newTime: datetime.time | null = null;
		if (time !== null) {
			const {visualDate} = this.state;
			if (visualDate !== null) {
				newDate = visualDate;
				newTime = time;
			}
		}
		this.setState({
			anytime: false,
			date: newDate,
			time: newTime,
		});
	}

	@bind
	timeRangeForWeekday(): datetime.ITimeRange | null {
		return {
			start: new datetime.time(0),
			stop: new datetime.time(23),
		};
	}

	@bind
	private timeSelectRequested(): boolean {
		return true;
	}

	private wx(): Array<IWxDay> {
		const {
			serviceAreaPk,
			wx,
		} = this.state;
		if (serviceAreaPk === null) {
			return [];
		}
		const rv = wx.get(serviceAreaPk);
		return (rv === undefined)
			? []
			: rv;
	}
}

class DtThing {
	private readonly _lower: datetime.datetime | null;
	private readonly _upper: datetime.datetime | null;

	constructor(lower: datetime.datetime | string | null, upper: datetime.datetime | string | null) {
		this._lower = makeDt(lower);
		this._upper = makeDt(upper);
	}

	get date(): datetime.date | null {
		return this._lower
			? this._lower.date()
			: null;
	}

	get datetime(): datetime.datetime | null {
		return this._lower;
	}

	get duration(): datetime.timedelta {
		if (this._lower && this._upper) {
			return this._upper.sub(this._lower);
		}
		return new datetime.timedelta(0);
	}

	eq(other: DtThing): boolean {
		const aLower = this.lower;
		const bLower = other.lower;
		const lowEq: boolean = (aLower !== null) && (bLower !== null) && aLower.eq(bLower);
		const aUpper = this.upper;
		const bUpper = other.upper;
		const upEq: boolean = (aUpper !== null) && (bUpper !== null) && aUpper.eq(bUpper);
		const dxEq: boolean = this.duration.eq(other.duration);
		return lowEq && upEq && dxEq;
	}

	get lower(): datetime.datetime | null {
		return this._lower;
	}

	neq(other: DtThing): boolean {
		return !this.eq(other);
	}

	get time(): datetime.time | null {
		return this._lower
			? this._lower.time()
			: null;
	}

	get upper(): datetime.datetime | null {
		return this._upper;
	}
}

function makeDt(dt: datetime.datetime | string | null): datetime.datetime | null {
	return (dt instanceof datetime.datetime)
		? dt
		: (typeof dt === 'string')
			? datetime.datetime.fromisoformat(dt)
			: null;
}
