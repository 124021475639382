import React from 'react';

import {Cta, ICtaProps} from './cta';
import {bind, makeClassName} from '../util';
import {IMenuOptionProps, Menu} from './menu';

export interface ICtaMenuProps extends ICtaProps {
	isOpen: boolean;
	menuOptions: Array<IMenuOptionProps>;
	onCloseRequest: () => any;
	onSelection: (index: number) => any;
}

interface ICtaState {
}

export class CtaMenu extends React.Component<Partial<ICtaMenuProps>, ICtaState> {
	@bind
	private closeMenu(): void {
		const {
			onCloseRequest,
		} = this.props;
		if (onCloseRequest) {
			onCloseRequest();
		}
	}

	@bind
	private menuItemSelected(index: number, isNavigation: boolean): void {
		const {onSelection} = this.props;
		if (onSelection) {
			onSelection(index);
		}
		if (!isNavigation) {
			this.closeMenu();
		}
	}

	@bind
	private menuKeyPressed(event: React.KeyboardEvent): void {
		const {
			onCloseRequest,
		} = this.props;
		if (event.key === 'Escape') {
			if (onCloseRequest) {
				event.stopPropagation();
				onCloseRequest();
			}
		}
	}

	@bind
	private rootClicked(): void {
	}

	render() {
		const {
			className,
			children,
			isOpen,
			menuOptions,
			onCloseRequest,
			onSelection,
			...rest
		} = this.props;
		const clsName = makeClassName(
			'mdc-menu-surface--anchor',
			className,
		);
		return (
			<Cta className={clsName} onClick={this.rootClicked} {...rest}>
				{children}
				<Menu
					isCompact
					isOpen={isOpen}
					onClose={this.closeMenu}
					onKeyDown={this.menuKeyPressed}
					onSelection={this.menuItemSelected}
					options={menuOptions}
				/>
			</Cta>
		);
	}
}
