import React from 'react';

import {api} from '../../httpapi';
import {
	PageNavigation,
	SortOrder,
	UiTableTitle,
} from '../../constants';
import {
	bind,
	doDragDropCols,
	idxOk,
	isNumber,
	perPageOpts,
	staticPaginationPage,
} from '../../util';
import {
	DataTable,
	DataTableCellProps,
	DataTableHeaderCellProps,
	IDataTablePageInfo,
	IDataTableProps,
	IMenuOptionProps,
} from '../../components';

export interface IListViewProps<T = any> extends Omit<IDataTableProps, 'columns' | 'rows'> {
	activePriceGroupPk?: number;
	activeSize?: number | null;
	cellHook?: (col: ICol, obj: T) => Partial<DataTableCellProps>;
	cfg: ICfg;
	onCellClick?: (obj: T, row: number, column: number) => any;
	pageGetter: (params?: Partial<IPageParams>) => Promise<IPaginationPage<T>>;
	searchFilters?: Array<ICfgFilter>;
	selectedStatus?: Set<string>;
	tableLabel?: string;
	uiTableTitle: UiTableTitle;
}

interface IListViewState<T = any> {
	busy: boolean;
	page: IPaginationPage<T>;
}

export class ListView<T = any> extends React.Component<IListViewProps, IListViewState<T>> {
	private readonly dataTableRef: React.RefObject<DataTable>;

	constructor(props: IListViewProps) {
		super(props);
		this.dataTableRef = React.createRef();
		this.state = {
			busy: false,
			page: staticPaginationPage(),
		};
	}

	@bind
	private cellClicked(row: number, column: number) {
		const {onCellClick} = this.props;
		const {page} = this.state;
		if (onCellClick) {
			if (idxOk(row, page.objects.length, 'cellClicked')) {
				onCellClick(
					page.objects[row],
					row,
					column,
				);
			}

		}
	}

	@bind
	private async columnDraggedDropped(fromColumnIndex: number, toColumnIndex: number) {
		if (fromColumnIndex === toColumnIndex) {
			return;
		}
		const {uiTableTitle} = this.props;
		const cols = this.visibleColumns();
		const [objs, isOk] = doDragDropCols(
			cols,
			fromColumnIndex,
			toColumnIndex,
		);
		if (isOk) {
			await api.updateCfgTableColumns(
				uiTableTitle,
				objs,
			);
			await this.refreshPage();
		}
	}

	private columnMenuOptions(): Array<IMenuOptionProps> {
		const {page} = this.state;
		const visibleCols = this.visibleColumns();
		const visiblePks = new Set(visibleCols.map(x => x.col));
		const rv = page.fields.map(x => {
			return {
				label: x.label,
				value: String(x.col),
				isSelected: visiblePks.has(x.col),
			};
		});
		const col = new Intl.Collator();
		rv.sort(
			(a, b) => col.compare(a.label, b.label),
		);
		return rv;
	}

	@bind
	private async columnMenuSelectionChanged(index: number) {
		const {
			uiTableTitle,
		} = this.props;
		const {page} = this.state;
		const cols = page.fields;
		const opts = this.columnMenuOptions();
		if (idxOk(index, opts.length, 'columnMenuSelectionChanged')) {
			const opt = opts[index];
			const val = Number.parseInt(opt.value);
			const visibleCols = this.visibleColumns();
			const visiblePks = new Set(visibleCols.map(x => x.col));
			if (isNumber(val)) {
				if (visiblePks.has(val)) {
					visiblePks.delete(val);
				} else {
					visiblePks.add(val);
				}
				await api.updateCfgTableColumns(
					uiTableTitle,
					cols.map(x => {
						return {
							...x,
							isVisible: visiblePks.has(x.col),
						};
					}),
				);
				await this.refreshPage();
			} else {
				console.log('columnPickerMenuItemSelected: Invalid value type:', opt.value);
			}
		}
	}

	async componentDidMount() {
		this.setBusy(true);
		setTimeout(async () => {
			await this.refreshPage(
				undefined,
				() => this.setBusy(false),
			);
		}, 700);
	}

	private dataTableColumns(): Array<DataTableHeaderCellProps> {
		const rv: Array<DataTableHeaderCellProps> = [];
		for (const col of this.visibleColumns()) {
			rv.push({
				children: col.label,
				dataType: col.dataType,
			});
		}
		return rv;
	}

	@bind
	private fallbackTableLabel(): string {
		const {uiTableTitle} = this.props;
		let prefix: string;
		switch (uiTableTitle) {
			case UiTableTitle.ClientOrgList: {
				prefix = 'Organizations';
				break;
			}
			case UiTableTitle.ClientUserList: {
				prefix = 'Clients';
				break;
			}
			case UiTableTitle.ItemList: {
				prefix = 'Products';
				break;
			}
			case UiTableTitle.ProjectList: {
				prefix = 'Projects';
				break;
			}
			case UiTableTitle.TeamMemberList: {
				prefix = 'Team members';
				break;
			}
			case UiTableTitle.ServiceAreaList: {
				prefix = 'Service areas';
				break;
			}
			case UiTableTitle.UserClassList: {
				prefix = 'Service areas';
				break;
			}
			default: {
				return '';
			}
		}
		return `${prefix} per page`;
	}

	@bind
	private async fetchPage(nfo?: Partial<Omit<IPageParams, 'q' | 'status' | 'tbl'>>): Promise<IPaginationPage<T>> {
		const {
			activePriceGroupPk,
			activeSize,
			pageGetter,
			searchFilters,
			selectedStatus,
			uiTableTitle,
		} = this.props;
		const {page} = this.state;
		let n: Partial<IPageParams> = {
			...nfo,
		};
		const fils = (searchFilters === undefined)
			? []
			: searchFilters;
		let q: Array<string> = fils.filter(
			x => x.isEnabled,
		).map(
			x => x.value,
		);
		if (n.q !== undefined) {
			q.push(...n.q);
		}
		n.q = q;
		n.tbl = uiTableTitle;
		n.status = (selectedStatus === undefined)
			? []
			: Array.from(selectedStatus);
		if (n.col === undefined) {
			n.col = this.sortColumnPk();
		}
		if (n.so === undefined) {
			n.so = this.sortOrder();
		}
		if (n.page === undefined) {
			n.page = page.number;
		}
		if (n.perPage === undefined) {
			n.perPage = page.perPage;
		}
		if (n.pgid === undefined) {
			if ((activePriceGroupPk !== undefined) && (activePriceGroupPk > 0)) {
				n.pgid = activePriceGroupPk;
			}
		} else {
			if (n.pgid === 0) {
				n.pgid = undefined;
			}
		}
		if (n.sz === undefined) {
			if (activeSize !== null) {
				n.sz = activeSize;
			}
		}
		return await pageGetter(n);
	}

	@bind
	private async pageNavClicked(nav: PageNavigation) {
		const {page} = this.state;
		let pageNum: number = page.number;
		switch (nav) {
			case PageNavigation.FirstPage: {
				pageNum = 1;
				break;
			}
			case PageNavigation.PrevPage: {
				--pageNum;
				break;
			}
			case PageNavigation.NextPage: {
				++pageNum;
				break;
			}
			case PageNavigation.LastPage: {
				pageNum = page.numPages;
				break;
			}
		}
		if (pageNum !== page.number) {
			await this.refreshPage({
				page: pageNum,
			});
		}
	}

	@bind
	private pageInfo(): IDataTablePageInfo {
		const {page} = this.state;
		return {
			count: page.count,
			endIndex: page.endIndex,
			hasNext: page.hasNext,
			perPageOptions: this.perPageOptions(),
			hasPrevious: page.hasPrevious,
			label: this.tableLabel(),
			nextPageNumber: page.nextPageNumber,
			numPages: page.numPages,
			number: page.number,
			perPage: page.perPage,
			previousPageNumber: page.previousPageNumber,
			startIndex: page.startIndex,
		};
	}

	@bind
	private async perPageChanged(perPageOptIndex: number) {
		const opts = this.perPageOptions();
		if (idxOk(perPageOptIndex, opts.length, 'perPageChanged')) {
			const opt = opts[perPageOptIndex];
			const num = Number.parseInt(opt.value);
			if (isNumber(num)) {
				// NB: The following statement exists to avoid the issue where
				//     the combobox displays the value the user just chose. If
				//     we wait until we've received a new page, the previous
				//     per page option will remain until updating upon
				//     receiving the new value. So, yeah... it may seem
				//     superficial, but I think it's a bit more than that.
				this.state.page.perPage = num;
				await this.refreshPage({perPage: num});
			} else {
				console.log('ListView::perPageChanged: Got invalid option index:', perPageOptIndex);
			}
		}
	}

	private perPageOptions(): Array<IMenuOptionProps> {
		const {page} = this.state;
		const val = String(page.perPage);
		return perPageOpts().map(obj => {
			return {
				...obj,
				isSelected: obj.value === val,
			};
		});
	}

	@bind
	async refreshPage(params?: Partial<IPageParams>, cb?: () => any): Promise<void> {
		this.setBusy(
			true,
			async () => {
				this.setState(
					{
						page: await this.fetchPage(params),
					},
					() => {
						if (cb) {
							cb();
						}
						this.setBusy(false);
					},
				);
			},
		);
	}

	render() {
		const {
			activePriceGroupPk,
			activeSize,
			cellHook,
			cfg,
			onCellClick,
			onRowSelectionChange,
			pageGetter,
			rowsSelectable,
			searchFilters,
			selectedStatus,
			tableLabel,
			uiTableTitle,
			...rest
		} = this.props;
		const {busy} = this.state;
		return (
			<div id="id_pb-list-view-table-container">
				<DataTable
					cellsClickable
					columnMenuOptions={this.columnMenuOptions()}
					columns={this.dataTableColumns()}
					columnSortOrder={this.sortOrder()}
					columnsSortable
					id="id_pb-list-view-table"
					onClick={this.cellClicked}
					onColumnMenuOptionClick={this.columnMenuSelectionChanged}
					onColumnMove={this.columnDraggedDropped}
					onPaginationPageNav={this.pageNavClicked}
					onPaginationPerPageOptionChange={this.perPageChanged}
					onRowSelectionChange={onRowSelectionChange}
					onSortClick={this.sortClicked}
					pageInfo={this.pageInfo()}
					ref={this.dataTableRef}
					rows={this.rows()}
					rowsSelectable={rowsSelectable}
					showProgress={busy}
					sortedColumnIndex={this.sortColumnIndex()}
					{...rest}
				/>
			</div>
		);
	}

	private rows(): Array<Array<DataTableCellProps>> {
		const {cellHook} = this.props;
		const {page} = this.state;
		const rv: Array<Array<DataTableCellProps>> = [];
		const visCols = this.visibleColumns();
		for (const obj of page.objects) {
			const row: Array<DataTableCellProps> = [];
			for (const col of visCols) {
				const r: Partial<DataTableCellProps> = (cellHook === undefined)
					? {}
					: cellHook(col, obj);
				let sty: React.CSSProperties = {};
				if (r.style === undefined) {
					sty = {
						maxWidth: '300px',
					};
				} else {
					sty = {
						maxWidth: '300px',
						...r.style,
					};
					r.style = undefined;
				}
				row.push({
					...r,
					style: sty,
				});
			}
			rv.push(row);
		}
		return rv;
	}

	private setBusy(busy: boolean, cb?: () => any) {
		this.setState(
			{
				busy,
			},
			cb,
		);
	}

	@bind
	private async sortClicked(columnIndex: number) {
		const sortColumnIndex = this.sortColumnIndex();
		const sortOrder = this.sortOrder();
		let nextSortOrder: SortOrder;
		if (columnIndex === sortColumnIndex) {
			switch (sortOrder) {
				case SortOrder.DescendingOrder: {
					nextSortOrder = SortOrder.AscendingOrder;
					break;
				}
				case SortOrder.AscendingOrder: {
					nextSortOrder = SortOrder.DescendingOrder;
					break;
				}
				default: {
					console.log('ListView::sortClicked Got SortOrder.NoOrder as current sort order');
					nextSortOrder = SortOrder.AscendingOrder;
					break;
				}
			}
		} else {
			nextSortOrder = SortOrder.AscendingOrder;
		}
		const colPk = this.visibleColumnPkAtIndex(columnIndex);
		if (colPk !== null) {
			await this.refreshPage(
				{
					so: nextSortOrder,
					col: colPk,
				},
			);
		}
	}

	private sortColumnIndex(): number {
		const pk = this.sortColumnPk();
		if (pk === undefined) {
			return -1;
		}
		const cols = this.visibleColumns();
		for (let i = 0; i < cols.length; ++i) {
			if (cols[i].col === pk) {
				return i;
			}
		}
		return -1;
	}

	private sortColumnPk(): number | undefined {
		const cols = this.visibleColumns();
		for (let i = 0; i < cols.length; ++i) {
			if (cols[i].sortOrder !== SortOrder.NoOrder) {
				return cols[i].col;
			}
		}
		return undefined;
	}

	private sortOrder(): SortOrder {
		const cols = this.visibleColumns();
		for (const col of cols) {
			switch (col.sortOrder) {
				case SortOrder.AscendingOrder:
				case SortOrder.DescendingOrder: {
					return col.sortOrder;
				}
			}
		}
		return SortOrder.NoOrder;
	}

	private tableLabel(): string {
		const {tableLabel} = this.props;
		return (tableLabel === undefined)
			? this.fallbackTableLabel()
			: tableLabel;
	}

	private visibleColumnPkAtIndex(index: number): number | null {
		const cols = this.visibleColumns();
		if (idxOk(index, cols.length, 'visibleColumnPkAtIndex')) {
			return cols[index].col;
		}
		return null;
	}

	private visibleColumns(): Array<ICol> {
		return this.state.page.fields.filter(x => x.isVisible);
	}
}
