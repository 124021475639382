import React from 'react';

import {
	bind,
	isNumber,
} from '../../util';
import {
	CCardControls,
	EditCardSectionRow,
	CCardTextInputControl,
} from '../../components';

export interface IOrgFormProps {
	obj: IOrganization;
	onChange: <K extends keyof IOrganization>(value: IOrganization[K], name: K) => any;
}

export class OrgForm extends React.Component<IOrgFormProps, {}> {
	private changed(value: string, name?: string): void {
		const {onChange} = this.props;
		switch (name) {
			case 'name': {
				onChange(value, 'name');
				break;
			}
			case 'email': {
				onChange(value, 'email');
				break;
			}
		}
	}

	@bind
	private parentChanged(value: string): void {
		let parentId: number | null;
		if (value === '') {
			parentId = null;
		} else {
			const num = Number.parseInt(value);
			if (isNumber(num)) {
				parentId = num;
			} else {
				console.log('parentChanged: Invalid value:', value);
				return;
			}
		}
		this.props.onChange(parentId, 'parentId');
	}

	render() {
		const {obj} = this.props;
		return (
			<>
				<EditCardSectionRow itemIconName="group">
					<CCardControls>
						<CCardTextInputControl
							value={obj.name}
							label="Name"
							onChange={this.changed}
							name="name"
						/>
					</CCardControls>
				</EditCardSectionRow>
				<EditCardSectionRow itemIconName="mail_outline">
					<CCardControls>
						<CCardTextInputControl
							value={obj.email}
							label="Email"
							onChange={this.changed}
							name="email"
						/>
					</CCardControls>
				</EditCardSectionRow>
			</>
		);
	}
}

