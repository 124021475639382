import React from 'react';

import {bind, staticAlternateEmail} from '../../util';
import {AlternateEmailForm} from './form';
import {ICCardProps} from '../../components';

interface IAlternateEmailUpdatePropsBase extends Omit<ICCardProps, 'onChange'> {
	onCancel: () => any;
	onDone: () => any;
}

export interface IAlternateEmailUpdateProps extends Partial<IAlternateEmailUpdatePropsBase> {
	objGetter: (pk: number) => Promise<IAlternateEmailAddress>;
	objUpdater: (obj: IAlternateEmailAddress) => Promise<IAlternateEmailAddress>;
	pk: number;
}

interface IAlternateEmailUpdateState {
	obj: IAlternateEmailAddress;
}

export class AlternateEmailUpdate extends React.Component<IAlternateEmailUpdateProps, IAlternateEmailUpdateState> {
	constructor(props: IAlternateEmailUpdateProps) {
		super(props);
		this.state = {
			obj: staticAlternateEmail(),
		};
	}

	@bind
	private canceled(): void {
		const {onCancel} = this.props;
		this.setState(
			{
				obj: staticAlternateEmail(),
			},
			() => {
				if (onCancel) {
					onCancel();
				}
			}
		);
	}

	@bind
	private changed<K extends keyof IAlternateEmailAddress>(name: K, value: IAlternateEmailAddress[K]): void {
		this.setState({
			obj: {
				...this.state.obj,
				[name]: value,
			}
		});
	}

	async componentDidMount() {
		const {
			objGetter,
			pk,
		} = this.props;
		this.setState({
			obj: await objGetter(pk)

		});
	}

	render() {
		const {
			onCancel,
			onDone,
			objGetter,
			objUpdater,
			pk,
			...rest
		} = this.props;
		const {obj} = this.state;
		return (
			<AlternateEmailForm
				obj={obj}
				onChange={this.changed}
				onSave={this.submitted}
				onCancel={this.canceled}
				headerText="Update email"
				{...rest}
			/>
		);
	}

	@bind
	private async submitted() {
		const {onDone} = this.props;
		const {obj} = this.state;
		const newObj = await this.updateObj(obj);
		this.setState(
			{
				obj: {...newObj},
			},
			() => {
				if (onDone) {
					onDone();
				}
			}
		);
	}

	private async updateObj(obj: IAlternateEmailAddress): Promise<IAlternateEmailAddress> {
		return await this.props.objUpdater(obj);
	}
}

