import React, {PropsWithChildren} from 'react';

import {cssClassName, makeClassName} from '../util';

export interface IGridLayoutProps extends React.HTMLAttributes<any> {
	align: 'left' | 'right';
	noPad: boolean;
}

export function GridLayout(props: PropsWithChildren<Partial<IGridLayoutProps>>) {
	const {
		align,
		children,
		className,
		noPad,
		...rest
	} = props;
	const clsName = makeClassName(
		'mdc-layout-grid',
		align
			? `mdc-layout-grid--align-${align}`
			: undefined,
		noPad
			? 'pb-layout-grid--no-margin-or-something'
			: undefined,
		className,
	);
	return (
		<div className={clsName} {...rest}>
			<GridLayoutInner>
				{children}
			</GridLayoutInner>
		</div>
	);
}

export interface IGridLayoutInnerProps extends React.HTMLAttributes<any> {
}

export function GridLayoutInner(props: PropsWithChildren<IGridLayoutInnerProps>) {
	const {
		children,
		...rest
	} = props;
	return (
		<div className="mdc-layout-grid__inner" {...rest}>
			{children}
		</div>
	);
}

export interface IGridLayoutCellProps extends React.HTMLAttributes<any> {
	alignment: GridCellAlignment;
	desktopSpan: GridCellSpan;
	orderIndex: GridCellSpan;
	phoneSpan: GridCellSpan;
	span: GridCellSpan;
	tabletSpan: GridCellSpan;
}

export function GridLayoutCell(props: PropsWithChildren<Partial<IGridLayoutCellProps>>) {
	const {
		alignment,
		children,
		desktopSpan,
		orderIndex,
		phoneSpan,
		span,
		tabletSpan,
		...rest
	} = props;
	const sp = (!span && !desktopSpan && !phoneSpan && !tabletSpan)
		? 12
		: span;
	const classNames: string[] = [
		'mdc-layout-grid__cell',
	];
	if (sp) {
		classNames.push(`mdc-layout-grid__cell--span-${sp}`);
	}
	if (desktopSpan) {
		classNames.push(`mdc-layout-grid__cell--span-${desktopSpan}-desktop`);
	}
	if (phoneSpan) {
		classNames.push(`mdc-layout-grid__cell--span-${phoneSpan}-phone`);
	}
	if (tabletSpan) {
		classNames.push(`mdc-layout-grid__cell--span-${tabletSpan}-tablet`);
	}
	if (alignment) {
		classNames.push(`mdc-layout-grid__cell--align-${alignment}`);
	}
	if (orderIndex) {
		classNames.push(`mdc-layout-grid__cell--order-${orderIndex}`);
	}
	return (
		<div className={cssClassName(...classNames)} {...rest}>
			{children}
		</div>
	);
}

export interface IGridLayoutDividerProps extends IGridLayoutCellProps {
}

export function GridLayoutDivider(props: PropsWithChildren<Partial<IGridLayoutDividerProps>>) {
	const {
		span,
		...rest
	} = props;
	if (!span || (span === 12)) {
		return (
			<GridLayoutCell
				span={span}
				style={{borderTop: '1px solid #EAEBEB'}}
				{...rest}
			/>
		);
	}
	return (
		<GridLayoutCell>
			<GridLayoutInner>
				<GridLayoutCell
					span={span}
					style={{borderTop: '1px solid #EAEBEB'}}
					{...rest}
				/>
			</GridLayoutInner>
		</GridLayoutCell>
	);
}
