import React from 'react';

import {
	coordStr,
	staticServiceArea,
} from '../../util';
import {
	CCard,
	CCardAvatar,
	CCardDescribe,
	CCardHeader,
	CCardIntro,
	CCardName,
	CCardNameTitle,
	CCardTitle,
	CCardToolbar,
	CCardToolbarAction,
} from '../../components';
import {IUserCardProps} from '../../components/usercard';

export interface IServiceAreaReadProps extends Partial<IUserCardProps> {
	objGetter: (pk: number) => Promise<IServiceArea>;
	onToolbarAction: (axn: CCardToolbarAction) => any;
	pk: number;
}

interface IServiceAreaReadState {
	obj: IServiceArea;
}

export class ServiceAreaRead extends React.Component<IServiceAreaReadProps, IServiceAreaReadState> {
	constructor(props: IServiceAreaReadProps) {
		super(props);
		this.state = {
			obj: staticServiceArea(),
		};
	}

	async componentDidMount() {
		const {
			objGetter,
			pk,
		} = this.props;
		this.setState({
			obj: await objGetter(pk),
		});
	}

	render() {
		const {
			objGetter,
			onToolbarAction,
			serviceAreas,
			...rest
		} = this.props;
		const {obj} = this.state;
		return (
			<CCard {...rest}>
				<CCardHeader>
					<CCardIntro>
						<CCardAvatar>
							pin_drop
						</CCardAvatar>
						<CCardDescribe>
							<CCardNameTitle>
								<CCardName>
									{obj.name}
								</CCardName>
								<CCardTitle>
									{coordStr(obj.latitude, obj.longitude, 4)}
								</CCardTitle>
							</CCardNameTitle>
						</CCardDescribe>
					</CCardIntro>
					<CCardToolbar
						onAction={onToolbarAction}
					/>
				</CCardHeader>
			</CCard>
		);
	}
}
