import React from 'react';

import * as datetime from '../../datetime';
import {
	bind,
	genTimes,
	idxOk,
	isNumber,
	padStart,
	to12Hour,
	toPeriod,
} from '../../util';
import {COLOR_PB_GREEN} from '../../constants';
import {Calendar} from '../calendar';
import {DatePicker} from './datepicker';
import {
	DEFAULT_SEGMENT_STEP,
	TimePicker,
} from './timepicker';
import {
	ISchedulerBlock,
	ISchedulerSelectedBlock,
} from '../../datetime';

type Partition = {start: datetime.datetime; end: datetime.datetime;};

interface ISchedulerProps {
	currentDate: datetime.date | null;
	currentDateAvailability: (date: datetime.date) => Array<IAvailability>;
	dateIsAvailable: (date: datetime.date) => boolean;
	events: Array<ISchedulerBlock>;
	onAnyTimeChange: (isChecked: boolean) => any;
	onDateChange: (date: datetime.date | null) => any;
	onTimeChange: (time: datetime.time | null) => any;
	onTimeSelectRequest: (datetime: datetime.datetime) => boolean;
	selectedEvent: ISchedulerSelectedBlock | null;
	timeRangeForWeekday: (weekday: number) => datetime.ITimeRange | null;
	timeSegmentStep: number | null;
	weather: Array<IWxDay>;
}

interface ISchedulerState {
	miniDatePickerInputValue: datetime.date | null;
	timeSelectAtIsoTime: string;
}

export class Scheduler extends React.Component<Partial<ISchedulerProps>, ISchedulerState> {
	calendarDates: Array<datetime.date>;

	constructor(props: Partial<ISchedulerProps>) {
		super(props);
		const today = datetime.date.today();
		const tomorrow = today.add(new datetime.timedelta(1));
		this.calendarDates = datetime.genDates(9, tomorrow);
		this.state = {
			miniDatePickerInputValue: null,
			timeSelectAtIsoTime: '',
		};
	}

	@bind
	anyTimeChanged(checked: boolean): void {
		const {onAnyTimeChange} = this.props;
		if (onAnyTimeChange) {
			onAnyTimeChange(
				checked,
			);
		}
		this.setState({
			miniDatePickerInputValue: null,
			timeSelectAtIsoTime: '',
		});
	}

	availabilityDates(): Array<IAvailability> {
		const {dateIsAvailable} = this.props;
		const func = dateIsAvailable
			? dateIsAvailable
			: () => true;
		return this.calendarDates.map(d => {
			const iso = d.isoformat();
			return {
				datetime: iso,
				available: func(d),
			};
		});
	}

	@bind
	calendarChanged(index: number | null): void {
		let value: datetime.date | null = null;
		if (isNumber(index) && idxOk(index, this.calendarDates.length, 'calendarChanged')) {
			value = this.calendarDates[index];
		}
		this.currentDateChanged(value);
	}

	calendarOverlay(): {text: string; index: number;} | undefined {
		const timeDisplay = this.calendarTimeDisplay();
		if (timeDisplay) {
			const idx = this.selectedEventCalendarDateIndex();
			if (idx >= 0) {
				return {
					index: idx,
					text: timeDisplay,
				};
			}
		}
		return undefined;
	}

	calendarTimeDisplay(): string {
		const {selectedEvent} = this.props;
		if (selectedEvent) {
			if (selectedEvent.anyTime) {
				return 'Anytime';
			}
			const {start} = selectedEvent;
			return `${to12Hour(start.hour)}:${padStart(start.minute, 2, '0')} ${toPeriod(start.hour)}`;
		}
		return '';
	}

	componentDidMount(): void {
		document.addEventListener('keydown', this.keyDownEvent);
	}

	componentDidUpdate(): void {
		const {selectedEvent} = this.props;
		const {miniDatePickerInputValue} = this.state;
		if (selectedEvent) {
			const isMiniPickerDateTime = this.selectedEventCalendarDateIndex() === -1;
			if (isMiniPickerDateTime && !miniDatePickerInputValue) {
				this.setState({
					miniDatePickerInputValue: selectedEvent.start.date(),
				});
			}
		}
	}

	componentWillUnmount(): void {
		document.removeEventListener('keydown', this.keyDownEvent);
	}

	currentDateChanged(date: datetime.date | null): void {
		const {onDateChange} = this.props;
		if (onDateChange) {
			onDateChange(date);
		}
		this.setState({
			timeSelectAtIsoTime: '',
		});
	}

	currentDateEvents(): Array<ISchedulerBlock> {
		const {currentDate} = this.props;
		const rv: Array<ISchedulerBlock> = [];
		if (currentDate) {
			const selEvt = this.selectedTimelineEvent();
			if (selEvt) {
				rv.push(selEvt);
			}
			rv.push(
				...this.unavailableEvents(),
				...this.dateEvents(currentDate),
			);
		}
		return rv.map(x => ({
			...x,
			interactive: true,
		}));
	}

	currentDateFormatString(): string {
		const {currentDate} = this.props;
		if (currentDate) {
			return `${currentDate.weekdayName()}, ${currentDate.monthName()} ${currentDate.day}`;
		}
		return '';
	}

	currentDateIsAnyTime(): boolean {
		const {selectedEvent} = this.props;
		return ((selectedEvent === undefined) || (selectedEvent === null))
			? false
			: (this.currentDateIsSelectedDate() && selectedEvent.anyTime);
	}

	currentDateIsSelectedDate(): boolean {
		const {
			currentDate,
			selectedEvent,
		} = this.props;
		return ((selectedEvent === undefined) || (selectedEvent === null))
			? false
			: ((currentDate === null) || (currentDate === undefined))
				? false
				: (currentDate.eq(selectedEvent.start.date()));
	}

	currentDateTimes(): Array<datetime.time> {
		const {
			currentDate,
			timeRangeForWeekday,
		} = this.props;
		if (currentDate && timeRangeForWeekday) {
			const timeRange = timeRangeForWeekday(currentDate.isoweekday());
			if (timeRange) {
				return genTimes(
					timeRange.start.hour,
					timeRange.stop.hour,
					true,
				);
			}
		}
		return [];
	}

	dateEvents(date: datetime.date): Array<ISchedulerBlock> {
		const {events} = this.props;
		const evts = (events === undefined)
			? []
			: events;
		return evts.filter(evt => evt.start.date().eq(date));
	}

	@bind
	keyDownEvent(event: KeyboardEvent): void {
		if ((event.key === 'Escape') && this.state.timeSelectAtIsoTime) {
			this.setState({
				timeSelectAtIsoTime: '',
			});
		}
	}

	@bind
	miniDatePickerButtonClicked(): void {
		const {miniDatePickerInputValue} = this.state;
		if (miniDatePickerInputValue) {
			this.currentDateChanged(miniDatePickerInputValue);
		}
	}

	@bind
	miniDatePickerInputValueChanged(value: string): void {
		if ((value.length > 0) || !this.miniDatePickerIsSelected()) {
			this.setState({
				miniDatePickerInputValue: (value.length) > 0
					? datetime.date.fromisoformat(value)
					: null,
			});
		} else {
			this.selectedTimeChanged(null);
			this.currentDateChanged(null);
		}
	}

	miniDatePickerIsSelected(): boolean {
		const {selectedEvent} = this.props;
		return ((selectedEvent === undefined) || (selectedEvent === null))
			? false
			: (this.selectedEventCalendarDateIndex() === -1);
	}

	partitionUnavailable(avail: Array<IAvailability>): Array<Partition> {
		const rv: Array<Partition> = [];
		let start: datetime.datetime | null = null;
		for (let i = 0; i < avail.length; ++i) {
			const av = avail[i];
			if (av.available) {
				if (start) {
					rv.push({
						start,
						end: datetime.datetime.fromisoformat(av.datetime),
					});
					start = null;
				}
			} else {
				if (!start) {
					start = datetime.datetime.fromisoformat(av.datetime);
				} else {
					if (i === (avail.length - 1)) {
						rv.push({
							start,
							end: datetime.datetime.fromisoformat(av.datetime),
						});
					}
				}
			}
		}
		return rv;
	}

	render() {
		const {
			currentDate,
			weather,
		} = this.props;
		const {
			miniDatePickerInputValue,
			timeSelectAtIsoTime,
		} = this.state;
		const anyTime = this.currentDateIsAnyTime();
		const times = this.currentDateTimes();
		const showDatePick = ((currentDate === undefined) || (currentDate === null));
		return showDatePick
			? (
				<div className="display--flex flex-direction--column">
					<Calendar
						dates={this.availabilityDates()}
						onClick={this.calendarChanged}
						overlay={this.calendarOverlay()}
						weather={weather}/>
					<DatePicker
						inputValue={(miniDatePickerInputValue === null)
							? ''
							: miniDatePickerInputValue.isoformat()}
						isSelected={this.miniDatePickerIsSelected()}
						onChange={this.miniDatePickerInputValueChanged}
						onForwardClick={this.miniDatePickerButtonClicked}
					/>
				</div>
			)
			: (
				<TimePicker
					anyTime={anyTime}
					dateString={this.currentDateFormatString()}
					events={this.currentDateEvents()}
					onClick={this.timeClicked}
					onAnyTimeClick={this.anyTimeChanged}
					onSegmentClick={this.selectedTimeChanged}
					segmentStep={this.timeSegmentStep()}
					times={times}
					timeSelectAtIsoTime={timeSelectAtIsoTime}
				/>
			);
	}

	selectedEventCalendarDateIndex(): number {
		const {selectedEvent} = this.props;
		const isoDate = ((selectedEvent === undefined) || (selectedEvent === null))
			? ''
			: selectedEvent.start.date().isoformat();
		return this.calendarDates.findIndex(
			d => d.isoformat() === isoDate,
		);
	}

	@bind
	selectedTimeChanged(t: datetime.time | null): void {
		const {onTimeChange} = this.props;
		if (onTimeChange) {
			onTimeChange(t);
		}
		this.setState({
			miniDatePickerInputValue: null,
			timeSelectAtIsoTime: '',
		});
	}

	selectedTimelineEvent(): ISchedulerBlock | null {
		const {selectedEvent} = this.props;
		if ((selectedEvent === undefined) || (selectedEvent === null)) {
			return null;
		}
		if (this.currentDateIsSelectedDate()) {
			return {
				color: COLOR_PB_GREEN,
				interactive: true,
				zIndex: 1,
				...selectedEvent,
			};
		}
		return null;
	}

	@bind
	timeClicked(isoTime: string): void {
		const {
			currentDate,
			onTimeSelectRequest,
		} = this.props;
		let timeSelectAtIsoTime: string = '';
		if (currentDate) {
			let t: datetime.time | null = null;
			const times = this.currentDateTimes();
			for (let i = 0; i < times.length; ++i) {
				if (times[i].isoformat() === isoTime) {
					t = times[i];
					break;
				}
			}
			if (t) {
				// const dt = {
				// 	date: isoDateToIDate(currentDate),
				// 	time: t,
				// };
				const dt = datetime.datetime.combine(
					currentDate,
					t,
				);
				if ((onTimeSelectRequest === undefined) || onTimeSelectRequest(dt)) {
					timeSelectAtIsoTime = isoTime;
				}
			}
		}
		this.setState({
			timeSelectAtIsoTime,
		});
	}

	timeSegmentStep(): number {
		const {timeSegmentStep} = this.props;
		return (timeSegmentStep === undefined) || (timeSegmentStep === null)
			? DEFAULT_SEGMENT_STEP
			: timeSegmentStep;
	}

	unavailableEvent(start: datetime.datetime, end: datetime.datetime, color?: string): ISchedulerBlock {
		// const startDt = datetime.fromIDateTime(start);
		const delta = end.sub(start);
		return {
			color,
			duration: delta.totalSeconds(),
			start,
		};
	}

	unavailableEvents(): Array<ISchedulerBlock> {
		const {
			currentDate,
			currentDateAvailability,
		} = this.props;
		if ((currentDateAvailability === undefined) || (currentDate === undefined) || (currentDate === null)) {
			return [];
		}
		const rv: Array<ISchedulerBlock> = [];
		const parts = this.partitionUnavailable(
			currentDateAvailability(currentDate),
		);
		if (parts.length > 0) {
			rv.push(
				...parts.map(
					p => this.unavailableEvent(p.start, p.end),
				),
			);
		}
		return rv;
	}
}
