import React from 'react';

import {staticAlternateEmail} from '../../util';
import {IUserCardProps} from '../../components/usercard';
import {
	CCard,
	CCardAvatar,
	CCardDescribe,
	CCardHeader,
	CCardIntro,
	CCardName,
	CCardNameTitle,
	CCardTitle,
	CCardToolbar,
	CCardToolbarAction,
} from '../../components';

export interface IAlternateEmailReadProps extends Partial<IUserCardProps> {
	objGetter: (pk: number) => Promise<IAlternateEmailAddress>;
	onToolbarAction: (axn: CCardToolbarAction) => any;
	pk: number;
}

interface IAlternateEmailReadState {
	obj: IAlternateEmailAddress;
}

export class AlternateEmailRead extends React.Component<IAlternateEmailReadProps, IAlternateEmailReadState> {
	constructor(props: IAlternateEmailReadProps) {
		super(props);
		this.state = {
			obj: staticAlternateEmail(),
		};
	}

	async componentDidMount() {
		const {
			objGetter,
			pk,
		} = this.props;
		this.setState({
			obj: await objGetter(pk),
		});
	}

	render() {
		const {
			objGetter,
			onToolbarAction,
			serviceAreas,
			...rest
		} = this.props;
		const {obj} = this.state;
		return (
			<CCard {...rest}>
				<CCardHeader>
					<CCardIntro>
						<CCardAvatar>
							mail_outline
						</CCardAvatar>
						<CCardDescribe>
							<CCardNameTitle>
								<CCardName>
									{obj.address}
								</CCardName>
								<CCardTitle>
									{obj.label}
								</CCardTitle>
							</CCardNameTitle>
						</CCardDescribe>
					</CCardIntro>
					<CCardToolbar
						onAction={onToolbarAction}
					/>
				</CCardHeader>
			</CCard>
		);
	}
}
