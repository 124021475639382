import React from 'react';
import {DialogCode} from '../constants';
import {Button} from './button';

interface IConfirmProps extends React.HTMLAttributes<any> {
	affirmText?: string;
	denyText?: string;
	onResult: (result: DialogCode) => any;
}

export function Confirm(props: Partial<IConfirmProps>) {
	const {
		affirmText,
		children,
		denyText,
		onResult,
		...rest
	} = props;
	const aTxt = affirmText
		? affirmText
		: 'YES';
	const dTxt = denyText
		? denyText
		: 'NO';
	return (
		<div {...rest}>
			{children}
			<Button raisedFilled onClick={() => onResult && onResult(DialogCode.Rejected)}>
				{dTxt}
			</Button>
			<Button onClick={() => onResult && onResult(DialogCode.Accepted)}>
				{aTxt}
			</Button>
		</div>
	);
}
