import React from 'react';

import {CheckState} from '../constants';
import {bind, makeClassName} from '../util';

export interface ICheckboxProps extends Omit<React.HTMLAttributes<any>, 'onChange'> {
	inputName: string;
	inputValue: string;
	isChecked: boolean;
	onChange: (state: CheckState) => any;
}

export class Checkbox extends React.Component<Partial<ICheckboxProps>, {}> {
	private readonly inputRef: React.RefObject<HTMLInputElement>;

	constructor(props: Partial<ICheckboxProps>) {
		super(props);
		this.inputRef = React.createRef();
	}

	@bind
	private inputChanged(): void {
		const {
			onChange,
		} = this.props;
		if (onChange) {
			const el = this.inputRef.current;
			if (el) {
				onChange(
					el.checked
						? CheckState.Checked
						: el.indeterminate
							? CheckState.PartiallyChecked
							: CheckState.Unchecked
				);
			}
		}
	}

	render() {
		const {
			className,
			isChecked,
			onChange,
			inputName,
			inputValue,
			...rest
		} = this.props;
		const clsName = makeClassName(
			'mdc-checkbox',
			'pb-checkbox',
			className,
		);
		return (
			<div className={clsName} {...rest}>
				<input
					checked={isChecked}
					className="mdc-checkbox__native-control"
					name={inputName}
					onChange={this.inputChanged}
					ref={this.inputRef}
					type="checkbox"
					value={inputValue}
				/>
				<div className="mdc-checkbox__background">
					<svg className="mdc-checkbox__checkmark" viewBox="0 0 24 24">
						<path className="mdc-checkbox__checkmark-path" fill="none" d="M1.73,12.91 8.1,19.28 22.79,4.59"/>
					</svg>
					<div className="mdc-checkbox__mixedmark"/>
				</div>
				<div className="mdc-checkbox__ripple"/>
				<div className="mdc-checkbox__focus-ring"/>
			</div>
		);
	}
}
