import React from 'react';

import {
	bind,
	isNumber,
	numberFormat,
} from '../../../util';
import {
	TextInput,
	Slider,
} from '../../../components';

export interface ISizeInputProps {
	onChange: (value: number | null, name: 'size') => any;
	renderTextInput: boolean;
	value: number | null;
}

export class SizeInput extends React.Component<Partial<ISizeInputProps>, {}> {
	private notify(value: number): void {
		const {onChange} = this.props;
		onChange && onChange(
			isNumber(value)
				? value
				: null,
			'size',
		);
	}

	render() {
		const {
			renderTextInput,
			value,
		} = this.props;
		const val = isNumber(value)
			? value
			: 0;
		if (renderTextInput) {
			return (
				<TextInput
					isMinimal
					helpText="Sq ft"
					label="Size"
					min={0}
					onChange={this.textInputChanged}
					persistHelpText
					step={500}
					type="number"
					value={String(val)}
				/>
			);
		}
		return (
			<>
				<Slider
					min={0}
					max={15000}
					onChange={this.sliderChanged}
					step={500}
					value={val}
				/>
				<div className="display--flex flex-direction--row">
					<div className="pb-catalog-item-form__subsection-input-label">
						<div>
							{numberFormat(val)}
						</div>
						<div>Sq ft</div>
					</div>
				</div>
			</>
		);
	}

	@bind
	sliderChanged(value: number): void {
		this.notify(value);
	}

	@bind
	textInputChanged(value: string): void {
		this.notify(Number.parseInt(value));
	}
}
