import React, {PropsWithChildren} from 'react';

import {makeClassName} from '../../util';
import {CssClassName} from './constants';

export interface IListItemTextProps extends React.HTMLAttributes<any> {
	htmlFor: string;
}

export function ListItemText(props: PropsWithChildren<Partial<IListItemTextProps>>) {
	const {
		children,
		className,
		htmlFor,
		...rest
	} = props;
	const tag: keyof React.ReactHTML = htmlFor
		? 'label'
		: 'span';
	const clsName = makeClassName(
		CssClassName.ListItemText,
		className,
	);
	return React.createElement(
		tag,
		{
			className: clsName,
			htmlFor,
			...rest,
		},
		children,
	);
}

export interface IListItemPrimaryTextProps extends React.HTMLAttributes<any> {
}

export function ListItemPrimaryText(props: PropsWithChildren<IListItemPrimaryTextProps>) {
	const {
		children,
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		CssClassName.ListItemPrimaryText,
		className,
	);
	return (
		<span className={clsName} {...rest}>
			{children}
		</span>
	);
}

export interface IListItemSecondaryTextProps extends React.HTMLAttributes<any> {
}

export function ListItemSecondaryText(props: PropsWithChildren<IListItemSecondaryTextProps>) {
	const {
		children,
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		CssClassName.ListItemSecondaryText,
		className,
	);
	return (
		<span className={clsName} {...rest}>
			{children}
		</span>
	);
}
