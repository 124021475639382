import React from 'react';

import {
	bind,
	numberFormat,
	priceHiLo,
} from '../../../util';
import {ProductListItem} from './listitem';
import {
	CardHeader,
	SubtleLabel,
	IProjectCreateViewState,
} from './misc';
import {
	GridLayout,
	GridLayoutCell,
	GridLayoutDivider,
	GridLayoutInner,
	List,
	Slider,
} from '../../../components';

const TOP_LEVEL_ITEM_COLLAPSE_MAX_WIDTH = 544;
const SECOND_LEVEL_ITEM_COLLAPSE_MAX_WIDTH = 672;

export interface IProjectServiceViewProps extends Omit<React.HTMLAttributes<any>, 'onChange'> {
	onChange: <K extends keyof IProjectCreateViewState>(name: K, value: IProjectCreateViewState[K]) => any;
	productAddonPks: Set<number>;
	productPks: Set<number>;
	products: Array<ICatalogProduct>;
	size: number | null;
}

interface IProjectServiceViewState {
	mqlSecondLevelTriggered: boolean;
	mqlTopLevelTriggered: boolean;
}

export class ProjectServiceView extends React.Component<Partial<IProjectServiceViewProps>, IProjectServiceViewState> {
	private readonly mqlTopLevel: MediaQueryList;
	private readonly mqlSecondLevel: MediaQueryList;

	constructor(props: Partial<IProjectServiceViewProps>) {
		super(props);
		this.mqlTopLevel = window.matchMedia(
			`(max-width: ${TOP_LEVEL_ITEM_COLLAPSE_MAX_WIDTH}px)`,
		);
		this.mqlSecondLevel = window.matchMedia(
			`(max-width: ${SECOND_LEVEL_ITEM_COLLAPSE_MAX_WIDTH}px)`,
		);
		this.state = {
			mqlSecondLevelTriggered: false,
			mqlTopLevelTriggered: false,
		};
	}

	private allAvailableAddons(): Array<ICatalogProduct> {
		const seenAddonPk = new Set<number>();
		const rv: Array<ICatalogProduct> = [];
		for (const pk of this.productPks()) {
			for (const addon of this.productAddons(pk)) {
				if (!seenAddonPk.has(addon.id)) {
					seenAddonPk.add(addon.id);
					rv.push(addon);
				}
			}
		}
		return rv;
	}

	private anySelectedProductsAreSized(): boolean {
		for (const pk of this.productPks()) {
			if (this.productIsSized(pk)) {
				return true;
			}
		}
		return false;
	}

	componentDidMount() {
		this.mqlTopLevel.addEventListener(
			'change',
			this.mediaQueryEvent,
		);
		this.mqlSecondLevel.addEventListener(
			'change',
			this.mediaQueryEvent,
		);
	}

	componentWillUnmount() {
		this.mqlTopLevel.removeEventListener(
			'change',
			this.mediaQueryEvent,
		);
		this.mqlSecondLevel.removeEventListener(
			'change',
			this.mediaQueryEvent,
		);
	}

	@bind
	private formatSizeDisplay(value: number): string {
		return `${numberFormat(value)}\xA0sqft`;
	}

	private maxProductSize(): number {
		let rv: number = 0;
		for (const obj of this.products()) {
			if ((obj.sizeMax !== null) && (obj.sizeMax > rv)) {
				rv = obj.sizeMax;
			}
		}
		return rv;
	}

	@bind
	private mediaQueryEvent(event: MediaQueryListEvent): void {
		if (event.target === this.mqlTopLevel) {
			this.setState({
				mqlTopLevelTriggered: event.matches,
			});
		} else if (event.target === this.mqlSecondLevel) {
			this.setState({
				mqlSecondLevelTriggered: event.matches,
			});
		}
	}

	@bind
	private notifyChange<K extends keyof IProjectCreateViewState>(name: K, value: IProjectCreateViewState[K]): void {
		const {onChange} = this.props;
		if (onChange) {
			onChange(name, value);
		}
	}

	private product(pk: number): ICatalogProduct | null {
		for (const obj of this.products()) {
			if (obj.id === pk) {
				return obj;
			}
		}
		return null;
	}

	@bind
	private productAddonClicked(pk: number): void {
		const productAddonPks = this.productAddonPks();
		if (productAddonPks.has(pk)) {
			productAddonPks.delete(pk);
		} else {
			productAddonPks.add(pk);
		}
		this.notifyChange(
			'productAddonPks',
			productAddonPks,
		);
	}

	private productAddonPks(): Set<number> {
		const {productAddonPks} = this.props;
		return (productAddonPks === undefined)
			? new Set()
			: productAddonPks;
	}

	private productAddons(pk: number): Array<ICatalogProduct> {
		const obj = this.product(pk);
		if (!obj || (obj.addOns.length === 0)) {
			return [];
		}
		const ids = new Set(obj.addOns);
		return this.products().filter(
			x => ids.has(x.id),
		);
	}

	private productChildren(parentId: number | null): Array<ICatalogProduct> {
		if (parentId === null) {
			return [];
		}
		const rv: Array<ICatalogProduct> = [];
		for (const obj of this.products()) {
			if (obj.parentId === parentId) {
				rv.push(obj);
			}
		}
		return rv;
	}

	private productIsMutuallyExclusive(pk: number): boolean {
		const obj = this.product(pk);
		return (obj !== null) && obj.isMutuallyExclusive;
	}

	private productIsSized(pk: number): boolean {
		const obj = this.product(pk);
		return obj
			? (obj.sizeMax !== null) || (obj.size !== null)
			: false;
	}

	@bind
	private productListItemClicked(pk: number): void {
		// Mutually exclusive products:
		// ----------------------------
		// Goal: If this product is mutually exclusive and is being selected,
		//       it must be the only selected product. If this product is NOT
		//       mutually exclusive and is being selected, remove any
		//       currently selected mutually exclusive products.
		//
		// If this product is being selected
		//     If this product is mutually exclusive
		//         - Clear all currently selected product
		//         - Add this product pk to selected
		//     Else if any currently selected products are mutually exclusive
		//         - Clear all currently selected product
		//         - Add this product pk to selected
		const productPks = this.productPks();
		const selecting = !productPks.has(pk);
		if (selecting) {
			if (this.productIsMutuallyExclusive(pk) || this.selectedProductIsMutuallyExclusive()) {
				productPks.clear();
			}
			productPks.add(pk);
		} else {
			productPks.delete(pk);
		}
		this.notifyChange(
			'productPks',
			new Set(productPks),
		);
	}

	private productPks(): Set<number> {
		const {productPks} = this.props;
		return (productPks === undefined)
			? new Set()
			: productPks;
	}

	private productPriceRepr(pk: number): PriceRepr {
		const rv: PriceRepr = {
			price: '',
			altPrice: '',
		};
		const obj = this.product(pk);
		if (obj) {
			const kids = this.productChildren(pk);
			const [price, altPrice] = priceHiLo(obj, kids);
			rv.price = price;
			rv.altPrice = altPrice;
		}
		return rv;
	}

	private products(): Array<ICatalogProduct> {
		const {products} = this.props;
		return (products === undefined)
			? []
			: products;
	}

	render() {
		const {
			mqlTopLevelTriggered,
		} = this.state;
		const addons = this.allAvailableAddons();
		const s = this.size();
		const sz = (s === null)
			? 0
			: s;
		return (
			<>
				<CardHeader stepNumber={2}>
					Select Services
				</CardHeader>
				<GridLayout>
					<GridLayoutCell span={12}>
						<GridLayoutInner>
							<GridLayoutCell span={12}>
								<List isTwoLine={mqlTopLevelTriggered}>
									{
										this.topLevelProducts().map((prod, idx) => {
											return (
												<ProductListItem
													priceRepr={this.productPriceRepr(prod.id)}
													isCompact={mqlTopLevelTriggered}
													isSelected={this.productPks().has(prod.id)}
													key={idx}
													obj={prod}
													onClick={this.productListItemClicked}
												/>
											);
										})
									}
								</List>
							</GridLayoutCell>
						</GridLayoutInner>
					</GridLayoutCell>
				</GridLayout>
				{
					(addons.length > 0)
						? (
							<>
								<GridLayout noPad>
									<GridLayoutDivider span={12}/>
								</GridLayout>
								<GridLayout>
									<GridLayoutCell span={4}>
										<SubtleLabel>
											Add-ons (maybe some more, descriptive text...)
										</SubtleLabel>
									</GridLayoutCell>
									<GridLayoutCell span={12}>
										{
											<List isTwoLine={mqlTopLevelTriggered}>
												{
													addons.map((addon, idx) => {
														const isSelected = this.productAddonPks().has(addon.id);
														return (
															<React.Fragment key={idx}>
																<ProductListItem
																	priceRepr={this.productPriceRepr(addon.id)}
																	isCompact={mqlTopLevelTriggered}
																	isSelected={isSelected}
																	key={idx}
																	obj={addon}
																	onClick={this.productAddonClicked}
																/>
															</React.Fragment>
														);
													})
												}
											</List>
										}
									</GridLayoutCell>
								</GridLayout>
							</>
						)
						: null
				}
				{
					this.anySelectedProductsAreSized()
						? (
							<>
								<GridLayout noPad>
									<GridLayoutDivider span={12}/>
								</GridLayout>
								<GridLayout>
									<GridLayoutCell span={4}>
										<SubtleLabel>
											Estimated size (slide to select)
										</SubtleLabel>
									</GridLayoutCell>
									<GridLayoutCell span={12}>
										<Slider
											isDiscrete
											formatValueIndicator={this.formatSizeDisplay}
											value={sz}
											step={500}
											max={this.maxProductSize()}
											min={0}
											onChange={this.sizeChanged}
										/>
									</GridLayoutCell>
								</GridLayout>
							</>
						)
						: null
				}
			</>
		);
	}

	private selectedProductIsMutuallyExclusive(): boolean {
		const productPks = this.productPks();
		if (productPks.size === 1) {
			const pk = Array.from(productPks)[0];
			return this.productIsMutuallyExclusive(pk);
		}
		return false;
	}

	private size(): number | null {
		const {size} = this.props;
		return (size === undefined)
			? null
			: size;
	}

	@bind
	private sizeChanged(size: number): void {
		this.notifyChange('size', size);
	}

	private topLevelProducts(): Array<ICatalogProduct> {
		return this.products().filter(
			x => (x.parentId === null) && (!x.isAddon),
		);
	}
}
