import React from 'react';

import {api} from '../../httpapi';
import {SnippetFormView} from './snippetform';
import {
	bind,
	staticSnippet,
} from '../../util';
import {
	WideContentCard,
	WideContentContainer,
	WideContentHeader,
} from '../../components';

export interface ISnippetCreateViewProps extends React.HTMLAttributes<any> {
}

export interface ISnippetCreateViewState {
	obj: ISnippet;
}

export class SnippetCreateView extends React.Component<ISnippetCreateViewProps, ISnippetCreateViewState> {
	constructor(props: ISnippetCreateViewProps) {
		super(props);
		this.state = {
			obj: staticSnippet(),
		};
	}

	@bind
	private bodyChanged(body: string) {
		const {obj} = this.state;
		this.setState({
			obj: {
				...obj,
				body,
			},
		});
	}

	@bind
	private async formSubmitted(event: React.FormEvent) {
		event.preventDefault();
		const {obj} = this.state;
		this.setState({
			obj: await api.createSnippet(obj),
		});
	}

	@bind
	private nameChanged(name: string) {
		const {obj} = this.state;
		this.setState({
			obj: {
				...obj,
				name,
			},
		});
	}

	render() {
		const {obj} = this.state;
		return (
			<>
				<WideContentHeader backNavUri={window.pbUrls.snippetListView} backNavTitle="back to snippets" hasDivider>
					Create snippet
				</WideContentHeader>
				<WideContentContainer>
					<WideContentCard>
						<SnippetFormView
							obj={obj}
							onNameChange={this.nameChanged}
							onBodyChange={this.bodyChanged}
							onSubmit={this.formSubmitted}
						/>
					</WideContentCard>
				</WideContentContainer>
			</>
		);
	}
}
