import React from 'react';

import {makeClassName} from '../../util';
import {
	Icon,
	IIconProps,
} from '../icon';
import {
	IToolButtonProps,
	ToolButton,
} from '../toolbutton';

export interface ICCardSectionProps extends React.HTMLAttributes<any> {
	headerText: string;
}

export function CCardSection(props: Partial<ICCardSectionProps>) {
	const {
		children,
		className,
		headerText,
		...rest
	} = props;
	const clsName = makeClassName(
		'pb-cc-section',
		'pb-cc-detail-section',
		className,
	);
	return (
		<div className={clsName} {...rest}>
			{
				(headerText === undefined)
					? null
					: <div className="pb-cc-section__header">{headerText}</div>
			}
			{children}
		</div>
	);
}

export interface IEditCardSectionProps extends React.HTMLAttributes<any> {
	headerText: string;
}

export function EditCardSection(props: Partial<IEditCardSectionProps>) {
	const {
		children,
		className,
		headerText,
		...rest
	} = props;
	const clsName = makeClassName(
		'pb-cc-section',
		'pb-cc-edit-section',
		className,
	);
	return (
		<div className={clsName} {...rest}>
			{
				headerText
					? <div className="pb-cc-section__header pb-cc-edit-section__header">{headerText}</div>
					: null
			}
			<div className="pb-cc-edit-section__inner">
				{children}
			</div>
		</div>
	);
}

export function CCardRow(props: React.HTMLAttributes<any>) {
	const {
		children,
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		'pb-cc-section__row',
		'pb-cc-detail-section__row',
		className,
	);
	return (
		<div className={clsName} {...rest}>
			{children}
		</div>
	);
}

export interface IEditCardSectionRowProps extends React.HTMLAttributes<any> {
	isDisabled: boolean;
	itemIconName: string;
	itemIconIsOutlined: boolean;
	renderAddAction: boolean;
}

export function EditCardSectionRow(props: Partial<IEditCardSectionRowProps>) {
	const {
		children,
		className,
		renderAddAction,
		isDisabled,
		itemIconIsOutlined,
		itemIconName,
		...rest
	} = props;
	const clsName = makeClassName(
		'pb-cc-section__row',
		'pb-cc-edit-section__row',
		renderAddAction
			? 'pb-cc-edit-section__row--with-data'
			: undefined,
		className,
		isDisabled
			? 'pb-cc-edit-section__row--disabled'
			: undefined,
	);
	return (
		<div className={clsName} {...rest}>
			{
				(itemIconName === undefined)
					? null
					: (
						<CCardItemIcon className="pb-cc-edit-section__item-icon" isOutlined={itemIconIsOutlined}>
							{itemIconName}
						</CCardItemIcon>
					)
			}
			{children}
		</div>
	);
}

export interface ICCardRowToolButtonProps extends IToolButtonProps {
}

export function CCardRowToolButton(props: Partial<ICCardRowToolButtonProps>) {
	const {
		className,
		tabIndex,
		...rest
	} = props;
	const clsName = makeClassName(
		'pb-cc-tool-button__row-tool-button',
		className,
	);
	const tabIdx = (tabIndex === undefined)
		? -1
		: tabIndex;
	return (
		<ToolButton
			className={clsName}
			tabIndex={tabIdx}
			{...rest}
		/>
	);
}

export function CCardRowToolBox(props: React.HTMLAttributes<any>) {
	const {
		children,
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		'pb-cc-edit-section__row__tool-box',
		className,
	);
	return (
		<div className={clsName} {...rest}>
			{children}
		</div>
	);
}

interface ICCardRowActionProps extends React.HTMLAttributes<any> {
	onClick: () => any;
}

export function CCardRowAction(props: Partial<ICCardRowActionProps>) {
	const {
		children,
		className,
		onClick,
		...rest
	} = props;
	const clsName = makeClassName(
		'pb-cc-edit-section__row__tool-box--tool',
		className,
	);
	return (
		<div className={clsName} {...rest}>
			<div className="pb-cc-edit-section__row__tool-box__inner--tool">
				<CCardRowToolButton onClick={onClick}>
					{children}
				</CCardRowToolButton>
			</div>
		</div>
	);
}

export interface ICCardRowActionSetProps {
	onAddClick: () => any;
	onDeleteClick: () => any;
}

export function CCardRowActionSet(props: Partial<ICCardRowActionSetProps>) {
	const {
		onAddClick,
		onDeleteClick,
	} = props;
	return (
		<CCardRowToolBox>
			<CCardRowAction onClick={onDeleteClick}>
				close
			</CCardRowAction>
			<CCardRowAction onClick={onAddClick}>
				add_circle_outline
			</CCardRowAction>
		</CCardRowToolBox>
	);
}

export interface ICCardItemIconProps extends IIconProps {
}

export function CCardItemIcon(props: Partial<ICCardItemIconProps>) {
	const {
		children,
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		'pb-cc-detail-section__item-icon',
		className,
	);
	return (
		<Icon className={clsName} {...rest}>
			{children}
		</Icon>
	);
}

export function CCardItemText(props: React.HTMLAttributes<any>) {
	const {
		children,
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		'pb-cc-detail-section__item-text',
		className,
	);
	return (
		<div className={clsName} {...rest}>
			{children}
		</div>
	);
}

export function CCardSubtleLabel(props: React.HTMLAttributes<any>) {
	const {
		children,
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		'pb-cc-subtle-label',
		className,
	);
	return (
		<span className={clsName} {...rest}>
			{children}
		</span>
	);
}

export interface ICCardEmailAddressRowProps extends React.HTMLAttributes<any> {
	address?: string;
	obj?: IEmailAddress;
	onToolButtonClick?: () => any;
	noNotifications?: boolean;
	renderRowIcon?: boolean;
}

export function CCardEmailAddressRow(props: ICCardEmailAddressRowProps) {
	const {
		address,
		obj,
		onToolButtonClick,
		noNotifications,
		renderRowIcon,
		...rest
	} = props;
	let addr: string = '';
	let lbl: string = '';
	let rcvNot: boolean = false;
	if (obj === undefined) {
		if (address !== undefined) {
			addr = address;
		}
	} else {
		addr = obj.address;
		lbl = obj.label;
		rcvNot = obj.receiveNotifications;
	}
	if ((addr.length === 0) && (lbl.length === 0)) {
		return null;
	}
	const toolBtnClsName = makeClassName(
		'pb-cc-tool-button',
		'pb-cc-tool-button__notification_ctrl',
		rcvNot
			? 'pb-cc-tool-button--on'
			: 'pb-cc-tool-button--off',
	);
	return (
		<CCardRow {...rest}>
			<CCardItemIcon>
				{
					renderRowIcon
						? 'mail_outline'
						: null
				}
			</CCardItemIcon>
			<CCardItemText>
				<a href={`mailto:${addr}`}>{addr}</a>
				{
					lbl
						? <CCardSubtleLabel>
							&nbsp;&nbsp;•&nbsp;{lbl}
						</CCardSubtleLabel>
						: null
				}
			</CCardItemText>
			{
				noNotifications
					? null
					: (
						<ToolButton className={toolBtnClsName} onClick={onToolButtonClick}>
							{
								rcvNot
									? 'notifications_active'
									: 'notifications_off'
							}
						</ToolButton>
					)
			}
		</CCardRow>
	);
}
