import React from 'react';

import {api} from '../../httpapi';
import {bind, staticUserClass} from '../../util';
import {UserClassForm} from './form';
import {ICCardProps} from '../../components';

export interface IUserClassUpdateViewProps extends Omit<ICCardProps, 'onChange'> {
	onCancel?: () => any;
	onDone?: () => any;
	pk: number;
}

interface IUserClassUpdateViewState {
	obj: IUserClass;
}

export class UserClassUpdateView extends React.Component<IUserClassUpdateViewProps, IUserClassUpdateViewState> {
	constructor(props: IUserClassUpdateViewProps) {
		super(props);
		this.state = {
			obj: staticUserClass(),
		};
	}

	@bind
	private canceled(): void {
		const {onCancel} = this.props;
		this.setState(
			{
				obj: staticUserClass(),
			},
			() => {
				if (onCancel) {
					onCancel();
				}
			}
		);
	}

	@bind
	private changed<K extends keyof IUserClass>(name: K, value: IUserClass[K]): void {
		this.setState({
			obj: {
				...this.state.obj,
				[name]: value,
			}
		});
	}

	async componentDidMount() {
		this.setState({
			obj: await api.userClassDetail(this.props.pk)
		});
	}

	render() {
		const {
			onCancel,
			onDone,
			...rest
		} = this.props;
		const {obj} = this.state;
		return (
			<UserClassForm
				obj={obj}
				onChange={this.changed}
				onSave={this.submitted}
				onCancel={this.canceled}
				title="Update user class"
				{...rest}
			/>
		);
	}

	@bind
	private async submitted() {
		const {onDone} = this.props;
		const {obj} = this.state;
		const newObj = await this.updateObj(obj);
		this.setState(
			{
				obj: {...newObj},
			},
			() => {
				if (onDone) {
					onDone();
				}
			}
		);
	}

	private async updateObj(obj: IUserClass): Promise<IUserClass> {
		return await api.updateUserClass(obj);
	}
}

