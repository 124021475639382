import React from 'react';

import {
	Card,
	CardHeader,
	CardInner,
	Dialog,
	IMenuOptionProps,
	List,
	ListItem,
	Menu,
	Prompt,
} from '../../../components';
import {
	bind,
	idxOk,
} from '../../../util';
import {
	DialogCode,
	ProjectEmail,
	ProjectRole,
	StandardButton,
} from '../../../constants';
import {IAssignmentState} from '../common';

export enum Axn {
	None,
	MarkPaid,
	UnMarkPaid,
	SendEmail,
	CancelProject,
	AssignEditor,
	AssignShooter,
}

export interface IProjectReadActionsProps extends React.HTMLAttributes<any> {
	projectAssignments: Array<IProjectAssignmentRole>;
	onAction: (axn: Axn, projEmail?: ProjectEmail, assignmentState?: IAssignmentState) => any;
	isMarkedPaid: boolean;
	teamMembers: Array<IUser>;
}

interface IProjectReadActionsState {
	axn: Axn;
	axnMenuCoords: PlainCoords | undefined;
	axnMenuIsOpen: boolean;
	dialogIsOpen: boolean;
	projectEmail: string;
}

export class ProjectReadActions extends React.Component<IProjectReadActionsProps, IProjectReadActionsState> {
	constructor(props: IProjectReadActionsProps) {
		super(props);
		this.state = {
			axn: Axn.None,
			axnMenuCoords: undefined,
			axnMenuIsOpen: false,
			dialogIsOpen: false,
			projectEmail: '',
		};
	}

	private assignmentRoleOptions(roleName: string): Array<IMenuOptionProps> {
		const {teamMembers} = this.props;
		const roleUsers = this.roleUsers(roleName);
		const pks = new Set<string>(roleUsers.map(x => x.email));
		return teamMembers.map(x => {
			return {
				label: x.displayName,
				value: x.email,
				isSelected: pks.has(x.email),
			};
		});
	}

	@bind
	private axnClicked(axn: Axn, event: React.MouseEvent): void {
		this.setState(
			{
				axn,
				axnMenuCoords: {
					x: event.clientX,
					y: event.clientY,
				},
			},
			() => {
				switch (axn) {
					case Axn.AssignEditor:
					case Axn.AssignShooter:
					case Axn.SendEmail: {
						this.openAxnMenu();
						break;
					}
					case Axn.None: {
						console.log('axnClicked: Got "None"');
						break;
					}
					default: {
						this.openDialog();
						break;
					}
				}
			},
		);
	}

	@bind
	private axnMenuOptions(): Array<IMenuOptionProps> {
		const {axn} = this.state;
		switch (axn) {
			case Axn.SendEmail: {
				return projEmailOpts.map(([value, label]) => {
					return {
						value,
						label,
						isSelected: false,
					};
				});
			}
			case Axn.AssignEditor: {
				return this.assignmentRoleOptions(ProjectRole.Editor);
			}
			case Axn.AssignShooter: {
				return this.assignmentRoleOptions(ProjectRole.Shooter);
			}
			default: {
				return [];
			}
		}
	}

	@bind
	private axnMenuSelection(index: number): void {
		this.closeAxnMenu();
		const opts = this.axnMenuOptions();
		if (idxOk(index, opts.length, 'axnMenuSelection')) {
			const opt = opts[index];
			switch (this.state.axn) {
				case Axn.AssignShooter:
				case Axn.AssignEditor: {
					const roleName = this.state.axn === Axn.AssignShooter
						? ProjectRole.Shooter
						: ProjectRole.Editor;
					const opts = this.assignmentRoleOptions(roleName);
					if (idxOk(index, opts.length, 'axnMenuSelection (2)')) {
						const opt = opts[index];
						if (this.props.onAction) {
							const roleUsers = this.roleUsers(roleName);
							const pks = new Set<string>(roleUsers.map(x => x.email));
							this.props.onAction(
								this.state.axn,
								undefined,
								{
									roleName,
									userEmail: opt.value,
									isSelected: !pks.has(opt.value),
								},
							);
						}
					}
					break;
				}
				case Axn.SendEmail: {
					this.setState(
						{
							projectEmail: opt.value,
						},
						this.openDialog,
					);
					break;
				}
			}
		}
	}

	@bind
	private closeAxnMenu(): void {
		this.setState({
			axnMenuIsOpen: false,
		});
	}

	@bind
	private closeDialog(result: DialogCode) {
		const {onAction} = this.props;
		const {axn} = this.state;
		if (result === DialogCode.Accepted) {
			onAction(axn);
		}
		this.setState({
			axn: Axn.None,
			dialogIsOpen: false,
		});
	}

	@bind
	private openAxnMenu() {
		this.setState({
			axnMenuIsOpen: true,
			projectEmail: '',
		});
	}

	@bind
	private openDialog(): void {
		this.setState({
			dialogIsOpen: true,
		});
	}

	projectEmailPromptHeader(val: string): string {
		let projEmail: ProjectEmail | null = null;
		for (const [obj, _] of projEmailOpts) {
			if (val === obj) {
				projEmail = obj;
				break;
			}
		}
		if (projEmail === null) {
			return '';
		}
		return `Send a "${val}" email?`;
	}

	render() {
		const {isMarkedPaid} = this.props;
		const {
			axn,
			axnMenuCoords,
			axnMenuIsOpen,
			dialogIsOpen,
			projectEmail,
		} = this.state;
		let promptHeader: string = '';
		let promptIcon: string = '';
		switch (axn) {
			case Axn.None: {
				break;
			}
			case Axn.SendEmail: {
				promptHeader = this.projectEmailPromptHeader(projectEmail);
				promptIcon = 'send';
				break;
			}
			case Axn.MarkPaid: {
				promptHeader = 'Mark this project paid?';
				promptIcon = 'price_check';
				break;
			}
			case Axn.UnMarkPaid: {
				promptHeader = 'Un-Mark this project paid?';
				promptIcon = 'undo';
				break;
			}
			case Axn.CancelProject: {
				promptHeader = 'Cancel this project?';
				promptIcon = 'cancel_presentation';
				break;
			}
		}
		const buttons = StandardButton.Yes | StandardButton.No;
		const i = isMarkedPaid
			? 'undo'
			: 'price_check';
		const a = isMarkedPaid
			? Axn.UnMarkPaid
			: Axn.MarkPaid;
		return (
			<>
				<Card>
					<CardHeader primaryText="Actions"/>
					<CardInner>
						<List>
							<ListItem isClickable leadingIconName="photo_camera" onClick={this.axnClicked.bind(this, Axn.AssignShooter)}>
								Assign shooter...
							</ListItem>
							<ListItem isClickable leadingIconName="edit" onClick={this.axnClicked.bind(this, Axn.AssignEditor)}>
								Assign editor...
							</ListItem>
							<ListItem isClickable leadingIconName={i} onClick={this.axnClicked.bind(this, a)}>
								{
									isMarkedPaid
										? 'Un-Mark paid'
										: 'Mark paid'
								}
							</ListItem>
							<ListItem isClickable leadingIconName="send" onClick={this.axnClicked.bind(this, Axn.SendEmail)}>
								Send email...
							</ListItem>
							<ListItem isClickable leadingIconName="cancel_presentation" onClick={this.axnClicked.bind(this, Axn.CancelProject)}>
								Cancel project
							</ListItem>
						</List>
					</CardInner>
					<Dialog
						buttons={buttons}
						isOpen={dialogIsOpen}
						onFinished={this.closeDialog}>
						<Prompt header={promptHeader} iconName={promptIcon}/>
					</Dialog>
					<Menu
						absolutePosition={axnMenuCoords}
						anchorToBody
						isOpen={axnMenuIsOpen}
						onSelection={this.axnMenuSelection}
						options={this.axnMenuOptions()}
						onClose={this.closeAxnMenu}
					/>
				</Card>
			</>
		);
	}

	private roleUsers(roleName: string): Array<IUser> {
		const {projectAssignments} = this.props;
		for (const projAss of projectAssignments) {
			if (projAss.role.name === roleName) {
				return projAss.users;
			}
		}
		return [];
	}
}

const projEmailOpts: Array<[ProjectEmail, string]> = [
	[
		ProjectEmail.Created,
		'Send "created" email',
	],
	[
		ProjectEmail.Confirmed,
		'Send "confirmed" email',
	],
	[
		ProjectEmail.Complete,
		'Send "complete" email',
	],
	[
		ProjectEmail.Gallery,
		'Send "gallery" email',
	],
	[
		ProjectEmail.Invoice,
		'Send "invoice" email',
	],
	[
		ProjectEmail.OnHold,
		'Send "on hold" email',
	],
];
