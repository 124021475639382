import React from 'react';

import {CrudOp} from '../../constants';
import {UserClassList} from './list';
import {
	bind,
	staticCfg,
} from '../../util';
import {UserClassReadView} from './read';
import {
	CCardToolbarAction,
	Dialog,
	IMenuOptionProps,
	Menu,
	TubbyButton,
	WideContentContainer,
	WideContentHeader,
} from '../../components';
import {UserClassUpdateView} from './update';
import {UserClassCreateView} from './create';
import {UserClassDeleteView} from './delete';
import {api} from '../../httpapi';

export interface IUserClassViewProps extends React.HTMLAttributes<any> {
}

interface IUserClassViewState {
	activePk: number;
	cfg: ICfg;
	moreMenuCoords: PlainCoords | null;
	moreMenuIsOpen: boolean;
	op: CrudOp;
}

export class UserClassView extends React.Component<Partial<IUserClassViewProps>, IUserClassViewState> {
	private readonly userClassListRef: React.RefObject<UserClassList>;

	constructor(props: Partial<IUserClassViewProps>) {
		super(props);
		this.userClassListRef = React.createRef();
		this.state = {
			cfg: staticCfg(),
			activePk: 0,
			moreMenuCoords: null,
			moreMenuIsOpen: false,
			op: CrudOp.NoOp,
		};
	}

	@bind
	private closeDialog(): void {
		this.setState({
			activePk: 0,
			op: CrudOp.NoOp,
		});
	}

	@bind
	private closeMoreMenu(): void {
		this.setState({
			moreMenuIsOpen: false,
			moreMenuCoords: null,
		});
	}

	async componentDidMount() {
		await this.refreshCfg();
	}

	@bind
	private async fetchCfg(): Promise<ICfg> {
		return await api.cfgDetail();
	}

	@bind
	private formActionCanceled(): void {
		const {activePk} = this.state;
		this.openReadDialog(activePk);
	}

	@bind
	private moreMenuClosed(): void {
		this.closeMoreMenu();
	}

	private moreMenuOptions(): Array<IMenuOptionProps> {
		return [
			{
				isSelected: false,
				label: 'Delete',
				value: 'DELETE',
			},
		];
	}

	@bind
	private moreMenuOptionSelected(index: number): void {
		const {activePk} = this.state;
		const opts = this.moreMenuOptions();
		if ((index >= 0) && (index < opts.length)) {
			this.openDeleteDialog(activePk);
		} else {
			console.log('moreMenuOptionSelected: Invalid option index:', index);
		}
	}

	@bind
	private openCreateDialog() {
		this.setState({
			activePk: 0,
			op: CrudOp.Create,
		});
	}

	@bind
	private openDeleteDialog(activePk: number) {
		this.closeMoreMenu();
		this.setState({
			activePk,
			op: CrudOp.Delete,
		});
	}

	private openMoreMenu(coords: PlainCoords): void {
		this.setState({
			moreMenuIsOpen: true,
			moreMenuCoords: coords,
		});
	}

	@bind
	private openReadDialog(activePk: number) {
		this.setState({
			activePk,
			op: CrudOp.Read,
		});
	}

	@bind
	private openUpdateDialog() {
		this.setState({
			op: CrudOp.Update,
		});
	}

	@bind
	async refreshCfg(cb?: () => any): Promise<void> {
		this.setState(
			{
				cfg: await this.fetchCfg(),
			},
			cb,
		);
	}

	@bind
	async refreshPage(params?: Partial<IPageParams>, cb?: () => any): Promise<void> {
		const curr = this.userClassListRef.current;
		if (curr) {
			await curr.refreshPage(params, cb);
		}
	}

	@bind
	private async userClassCreated() {
		await this.refreshPage();
		this.closeDialog();
	}

	@bind
	private async userClassDeleted() {
		this.closeDialog();
		await this.refreshPage();
	}

	@bind
	private userClassSelected(pk: number): void {
		this.openReadDialog(pk);
	}

	@bind
	private userClassToolbarAction(axn: CCardToolbarAction, coords: PlainCoords) {
		switch (axn) {
			case CCardToolbarAction.Close: {
				this.closeDialog();
				break;
			}
			case CCardToolbarAction.Edit: {
				this.openUpdateDialog();
				break;
			}
			case CCardToolbarAction.More: {
				this.openMoreMenu(coords);
				break;
			}
		}
	}

	@bind
	private async userClassUpdated() {
		const {activePk} = this.state;
		this.openReadDialog(activePk);
		await this.refreshPage();
	}

	render() {
		const {
			activePk,
			cfg,
			op,
			moreMenuIsOpen,
			moreMenuCoords,
		} = this.state;
		let comp: React.ReactNode = null;
		switch (op) {
			case CrudOp.Create: {
				comp = (
					<UserClassCreateView
						onCancel={this.closeDialog}
						onDone={this.userClassCreated}
					/>
				);
				break;
			}
			case CrudOp.Read: {
				comp = (
					<UserClassReadView
						onToolbarAction={this.userClassToolbarAction}
						pk={activePk}
					/>
				);
				break;
			}
			case CrudOp.Update: {
				comp = (
					<UserClassUpdateView
						pk={activePk}
						onCancel={this.formActionCanceled}
						onDone={this.userClassUpdated}
					/>
				);
				break;
			}
			case CrudOp.Delete: {
				comp = (
					<UserClassDeleteView
						pk={activePk}
						onCancel={this.formActionCanceled}
						onDone={this.userClassDeleted}
					/>
				);
				break;
			}
		}
		const dialogOpen = (op !== CrudOp.NoOp);
		return (
			<>
				<WideContentHeader hasDivider>
					User classes
				</WideContentHeader>
				<WideContentContainer>
					<div className="pb-list-view-actions">
						<span/>
						<TubbyButton onClick={this.openCreateDialog}>
							Create user class
						</TubbyButton>
					</div>
					<UserClassList
						cfg={cfg}
						onObjectClick={this.userClassSelected}
						ref={this.userClassListRef}
					/>
				</WideContentContainer>
				<Dialog isOpen={dialogOpen} onFinished={this.closeDialog}>
					{comp}
				</Dialog>
				<Menu
					anchorToBody
					isCompact
					isOpen={moreMenuIsOpen}
					onClose={this.moreMenuClosed}
					onSelection={this.moreMenuOptionSelected}
					options={this.moreMenuOptions()}
					absolutePosition={moreMenuCoords || undefined}
				/>
			</>
		);
	}
}
