import React from 'react';

import {bind} from '../../util';
import {
	CCardContent,
	CCard,
	CCardControls,
	CCardBottomButtons,
	EditCardHeader,
	EditCardSection,
	EditCardSectionRow,
	CCardTextInputControl,
	ICCardProps,
} from '../../components';

export interface IPhoneNumberFormProps extends Omit<ICCardProps, 'onChange'> {
	obj: IPhoneNumber;
	headerText: string;
	onCancel: () => any;
	onChange: <K extends keyof IPhoneNumber>(name: K, value: IPhoneNumber[K]) => any;
	onSave: () => any;
}

interface IPhoneNumberFormState {
}

export class PhoneNumberForm extends React.Component<IPhoneNumberFormProps, IPhoneNumberFormState> {
	private readonly formRef: React.RefObject<HTMLFormElement>;

	constructor(props: IPhoneNumberFormProps) {
		super(props);
		this.formRef = React.createRef();
	}

	private changed<K extends keyof IPhoneNumber>(name: K, value: IPhoneNumber[K]): void {
		const {onChange} = this.props;
		if (onChange) {
			onChange(name, value);
		}
	}

	private isValid(): boolean {
		const curr = this.formRef.current;
		if (curr) {
			return curr.checkValidity();
		}
		return true;
	}

	@bind
	private labelChanged(value: string) {
		this.changed('label', value);
	}

	@bind
	private numberChanged(value: string) {
		this.changed('number', value);
	}

	render() {
		const {
			headerText,
			obj,
			onCancel,
			onSave,
			onChange,
			...rest
		} = this.props;
		return (
			<CCard {...rest}>
				<EditCardHeader text={headerText}/>
				<form action="" onSubmit={this.submitted} ref={this.formRef}>
					<CCardContent>
						<EditCardSection>
							<EditCardSectionRow itemIconIsOutlined itemIconName="phone" {...rest}>
								<CCardControls>
									<CCardTextInputControl
										autoFocus
										isRequired
										value={obj.number}
										label="Phone"
										onChange={this.numberChanged}
									/>
								</CCardControls>
							</EditCardSectionRow>
							<EditCardSectionRow itemIconIsOutlined itemIconName="label">
								<CCardControls>
									<CCardTextInputControl
										label="Label"
										onChange={this.labelChanged}
										value={obj.label}
									/>
								</CCardControls>
							</EditCardSectionRow>
						</EditCardSection>
					</CCardContent>
					<CCardBottomButtons
						onCancel={onCancel}
						saveIsSubmit
					/>
				</form>
			</CCard>
		);
	}

	@bind
	private save() {
		if (this.isValid()) {
			this.props.onSave();
		}
	}

	@bind
	private submitted(event: React.FormEvent) {
		event.preventDefault();
		this.save();
	}
}
