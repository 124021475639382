import React from 'react';

import {Icon} from './icon';
import {makeClassName} from '../util';

export interface IIconButtonProps extends React.ButtonHTMLAttributes<any> {
}

export class IconButton extends React.Component<Partial<IIconButtonProps>, {}> {
	render() {
		const {
			children,
			className,
			...rest
		} = this.props;
		const clsName = makeClassName(
			'material-icons',
			'mdc-icon-button',
			'pb-icon-button',
			className,
		);
		return (
			<button className={clsName} {...rest}>
				<span className="mdc-icon-button__ripple"/>
				<span className="mdc-icon-button__focus-ring"/>
				<Icon>{children}</Icon>
			</button>
		);
	}
}
