import React from 'react';

import {api} from '../../../httpapi';
import * as datetime from '../../../datetime';
import {InvoiceView} from './invoice';
import {ProjectPaymentView} from './payment';
import {ProjectReadTempDateTimeChangerThing} from './tempdatetimechangerthing';
import {
	ProjectEmail,
	ProjectRoleName,
} from '../../../constants';
import {
	Axn,
	ProjectReadActions,
} from './actions';
import {
	Button,
	Card,
	CardHeader,
	CardInner,
	Dialog,
	GridLayout,
	GridLayoutCell,
	IMenuOptionProps,
	LinearProgress,
	Menu,
	Prompt,
} from '../../../components';
import {
	ClientHeader,
	formattedScheduledDateTime,
	Header,
	IAssignmentState,
	ProjectFieldContainer,
	ProjectFieldLabel,
	ProjectFieldValue,
	ProjectSectionContainer,
	ProjectSubSectionContainer,
	ScheduledDateTime,
} from '../common';
import {
	bind,
	capitalize,
	idxOk,
	padStart,
	staticAnnotatedProject,
	staticEvent,
	staticInvoice,
	staticLocation,
	staticProfile,
	staticProject,
	staticProjectEvent,
	to12Hour,
	toPeriod,
} from '../../../util';

export interface IProjectDetailViewProps extends React.HTMLAttributes<any> {
	onBackClick: () => any;
	onUpdateClick: () => any;
	pk: string;  // slug
}

export interface IProjectDetailViewState {
	annotatedProject: IAnnotatedProject;
	assignments: Array<IProjectAssignmentRole>;
	event: IEvent;
	initializing: boolean;
	invoice: IInvoice;
	location: ILocation;
	profile: IProfile;
	project: IProject;
	projectEvent: IProjectEvent;
	projectStatus: Array<IProjectStatus>;
	showPaymentSuccess: boolean;
	statusMenuCoords: PlainCoords;
	statusMenuIsOpen: boolean;
	teamMembers: Array<IUser>;
	transactions: Array<ITransaction>;
}

export class ProjectDetailView extends React.Component<IProjectDetailViewProps, IProjectDetailViewState> {
	private readonly fileUploadInput: React.RefObject<HTMLInputElement>;

	constructor(props: IProjectDetailViewProps) {
		super(props);
		this.fileUploadInput = React.createRef();
		this.state = {
			annotatedProject: staticAnnotatedProject(),
			assignments: [],
			event: staticEvent(),
			initializing: true,
			invoice: staticInvoice(),
			location: staticLocation(),
			profile: staticProfile(),
			project: staticProject(),
			projectEvent: staticProjectEvent(),
			projectStatus: [],
			showPaymentSuccess: false,
			statusMenuCoords: {
				x: 0,
				y: 0,
			},
			statusMenuIsOpen: false,
			teamMembers: [],
			transactions: [],
		};
	}

	@bind
	private async assignmentChanged(obj: IAssignmentState) {
		const {pk} = this.props;
		const ass: IProjectAssignment = {
			roleId: obj.roleName,
			userId: obj.userEmail,
		};
		if (obj.isSelected) {
			await api.createProjectAssignment(pk, ass);
		} else {
			await api.deleteProjectAssignment(pk, ass.roleId, ass.userId);
		}
		this.setState({
			assignments: await api.projectAssignmentRoleList(pk),
		});
	}

	@bind
	private async axnRequest(axn: Axn, projectEmail?: ProjectEmail, assignmentState?: IAssignmentState) {
		const {pk} = this.props;
		switch (axn) {
			case Axn.AssignEditor:
			case Axn.AssignShooter: {
				if (assignmentState === undefined) {
					console.log('axnRequest: Assignment state not provided.');
				} else {
					await this.assignmentChanged(assignmentState);
				}
				break;
			}
			case Axn.MarkPaid: {
				await api.markProjectPaid(pk);
				this.setState({
					annotatedProject: await api.projectDetailAnnotated(pk),
					project: await api.projectDetail(pk),
					invoice: await api.projectInvoiceDetail(pk),
				});
				break;
			}
			case Axn.UnMarkPaid: {
				await api.unmarkProjectPaid(pk);
				this.setState({
					annotatedProject: await api.projectDetailAnnotated(pk),
					project: await api.projectDetail(pk),
					invoice: await api.projectInvoiceDetail(pk),
				});
				break;
			}
			case Axn.CancelProject: {
				await api.cancelProjectPaid(pk);
				break;
			}
			case Axn.SendEmail: {
				switch (projectEmail) {
					case ProjectEmail.Created: {
						break;
					}
					case ProjectEmail.Confirmed: {
						break;
					}
					case ProjectEmail.Complete: {
						break;
					}
					case ProjectEmail.Gallery: {
						break;
					}
					case ProjectEmail.Invoice: {
						break;
					}
					case ProjectEmail.OnHold: {
						break;
					}
				}
				break;
			}
			default: {
				console.log('axnRequest: Invalid action:', axn);
				break;
			}
		}
	}

	private bedsBathsDisplay(): string {
		const {location} = this.state;
		const parts: Array<string> = [];
		if (location.numBeds === null) {
			parts.push('--');
		} else {
			parts.push(String(location.numBeds));
		}
		if (location.numBaths === null) {
			parts.push('--');
		} else {
			parts.push(String(location.numBaths));
		}
		return parts.join(' / ');
	}

	private clientEmail(): string {
		return this.clientUsers()
			.map(x => x.email)
			.join(', ');
	}

	private clientNotes(): string {
		const users = this.clientUsers();
		const notes: Array<INote> = [];
		for (const obj of users) {
			notes.push(...obj.notes);
		}
		return notes
			.map(p => {
				if (p.text.length > 0 && p.label.length > 0) {
					return `${p.text} (${p.label})`;
				}
				return p.text;
			})
			.filter(n => (n.trim().length > 0))
			.join(', ');
	}

	private clientOrganization(): string {
		return this.clientUsers()
			.map(x => x.organizationName)
			.join(', ');
	}

	private clientPhone(): string {
		const users = this.clientUsers();
		const ph: Array<IPhoneNumber> = [];
		for (const obj of users) {
			ph.push(...obj.phoneNumbers);
		}
		return ph
			.map(p => {
				if (p.number.length > 0 && p.label.length > 0) {
					return `${p.number} (${p.label})`;
				}
				return p.number;
			})
			.filter(n => (n.trim().length > 0))
			.join(', ');
	}

	private clientUsers(): Array<IExpandedUser> {
		const {assignments} = this.state;
		const rv: Array<IExpandedUser> = [];
		for (const obj of assignments) {
			if (obj.role.name === ProjectRoleName.Client) {
				rv.push(...obj.users);
			}
		}
		return rv;
	}

	@bind
	private closeStatusMenu() {
		this.setState({
			statusMenuIsOpen: false,
		});
	}

	async componentDidMount() {
		const {pk} = this.props;
		const projectEvent = await api.projectEventDetail(pk);
		const loc = await api.projectLocationDetail(pk);
		const invoice = await api.projectInvoiceDetail(pk);
		this.setState(
			{
				annotatedProject: await api.projectDetailAnnotated(pk),
				assignments: await api.projectAssignmentRoleList(pk),
				event: await api.eventDetail(projectEvent.eventId),
				invoice,
				location: loc || this.state.location,
				profile: await api.profileDetail(),
				project: await api.projectDetail(pk),
				projectStatus: await api.projectStatusList(pk),
				projectEvent,
				teamMembers: await api.allTeamMembers({project: pk}),
				transactions: await api.projectTransactionList(pk),
			},
			() => this.setState({initializing: false}),
		);
	}

	@bind
	private async dateTimeChanged(event: IEvent, anytime: boolean) {
		const {pk} = this.props;
		const {projectEvent} = this.state;
		let projEvt = projectEvent;
		if (anytime !== projectEvent.flexibleDatetime) {
			projEvt = await api.updateProjectEvent(
				pk,
				{
					...projectEvent,
					flexibleDatetime: anytime,
				},
			);
		}
		const evt = await api.updateEvent(event);
		this.setState({
			event: evt,
			projectEvent: projEvt,
		});
		if (evt.dt !== null) {
			this.setDateTimeContainerStrings(evt.dt);
		}
	}

	@bind
	private async deleteInvoiceLine(lineIndex: number) {
		const {pk} = this.props;
		const {invoice} = this.state;
		if (idxOk(lineIndex, invoice.lines.length, 'deleteInvoiceLine')) {
			const obj = invoice.lines[lineIndex];
			if (obj.id > 0) {
				await api.deleteProjectInvoiceLine(
					pk,
					invoice.lines[lineIndex].id,
				);
			}
			this.setState({
				invoice: await api.projectInvoiceDetail(pk),
			});
		}
	}

	@bind
	private async deleteMediaObject(invoiceLinePk: number, mediaObjIdx: number) {
		const {pk} = this.props;
		const {invoice} = this.state;
		let invLine: IInvoiceLine | null = null;
		for (const line of invoice.lines) {
			if (line.id === invoiceLinePk) {
				invLine = line;
				break;
			}
		}
		if (invLine === null) {
			console.log('deleteMediaObject(%s, %s): Invoice line for given ID was not found.', invoiceLinePk, mediaObjIdx);
			return;
		}
		if (idxOk(mediaObjIdx, invLine.mediaObjects.length, 'deleteMediaObject')) {
			const obj = invLine.mediaObjects[mediaObjIdx];
			if (obj.id > 0) {
				await api.deleteMediaObject(
					pk,
					invoiceLinePk,
					obj.id,
				);
				this.setState({
					invoice: await api.projectInvoiceDetail(pk),
				});
			}
		}
	}

	private formattedScheduledDateTime() {
		return formattedScheduledDateTime(
			this.state.event,
		);
	}

	@bind
	private hidePaymentSuccess() {
		this.setState({
			showPaymentSuccess: false,
		});
	}

	@bind
	private openStatusMenu(coords: PlainCoords) {
		this.setState({
			statusMenuIsOpen: true,
			statusMenuCoords: coords,
		});
	}

	@bind
	private async paymentProcessingDone(success: boolean) {
		const {pk} = this.props;
		const updatedInv = await api.projectInvoiceDetail(pk);
		this.setState(
			{
				invoice: updatedInv,
				annotatedProject: await api.projectDetailAnnotated(pk),
				project: await api.projectDetail(pk),
				profile: await api.profileDetail(),
				showPaymentSuccess: success,
			},
		);
	}

	render() {
		const {
			onBackClick,
			onUpdateClick,
		} = this.props;
		const {
			annotatedProject,
			teamMembers,
			assignments,
			event,
			initializing,
			invoice,
			location,
			profile,
			project,
			projectEvent,
			showPaymentSuccess,
			statusMenuCoords,
			statusMenuIsOpen,
		} = this.state;
		if (initializing) {
			return (
				<GridLayout>
					<GridLayoutCell span={12}>
						<div className="display--flex align-items--center justify-content--center">
							<LinearProgress isOpen/>
						</div>
					</GridLayoutCell>
				</GridLayout>
			);
		}
		const assigns = assignments.filter(x => x.role.name !== ProjectRoleName.Client);
		const fmtDt = this.formattedScheduledDateTime();
		const hasClientDue = annotatedProject.clientDueDateIsoformat.length > 0;
		const hasPhotosDue = annotatedProject.photosDueDateIsoformat.length > 0;
		const hasVideoDue = annotatedProject.videoDueDateIsoformat.length > 0;
		const hasBedsBaths = (location.numBaths !== null) || (location.numBeds !== null);
		const stat = this.statusDisplay(project.statusId);
		return (
			<>
				<GridLayout>
					<GridLayoutCell span={12}>
						<Card className="project-container">
							<CardInner>
								<Header onBackClick={onBackClick} onUpdateClick={onUpdateClick}>
									{annotatedProject.services}
								</Header>
								{
									profile.isProducer
										? (
											<>
												<ClientHeader>
													{annotatedProject.client}
												</ClientHeader>
												<ProjectSectionContainer className="darker-blue display--flex flex-direction--column align-items--center">
													<div>
														<div className="display--flex">
															<ProjectFieldLabel>Company</ProjectFieldLabel>
															<span className="truncate">{this.clientOrganization()}</span>
														</div>
														<div className="display--flex">
															<ProjectFieldLabel>Email</ProjectFieldLabel>
															<span className="truncate">{this.clientEmail()}</span>
														</div>
														<div className="display--flex">
															<ProjectFieldLabel>Phone</ProjectFieldLabel>
															<span className="truncate">{this.clientPhone()}</span>
														</div>
														<div className="display--flex">
															<ProjectFieldLabel>Notes</ProjectFieldLabel>
															<span className="truncate">{this.clientNotes()}</span>
														</div>
													</div>
												</ProjectSectionContainer>
											</>
										)
										: null
								}
								<ProjectSectionContainer className="display--flex align-items--center justify-content--center darker-blue">
									{
										profile.isProducer
											? (
												<Button className="pb-color--white" onClick={this.statusButtonClicked} trailingIconName="arrow_drop_down">
													{stat}
												</Button>
											)
											: stat
									}
								</ProjectSectionContainer>
								<ProjectSectionContainer>
									{
										project.description.trim().length > 0
											? (
												<ProjectSubSectionContainer>
													<ProjectFieldContainer>
														<ProjectFieldLabel>
															Description
														</ProjectFieldLabel>
														<ProjectFieldValue className="truncate">
															{project.description}
														</ProjectFieldValue>
													</ProjectFieldContainer>
												</ProjectSubSectionContainer>
											)
											: null
									}
									<ProjectSubSectionContainer>
										<ProjectFieldContainer>
											<ProjectFieldLabel>
												Street
											</ProjectFieldLabel>
											<ProjectFieldValue className="truncate">
												{annotatedProject.locationStreet}
											</ProjectFieldValue>
										</ProjectFieldContainer>
									</ProjectSubSectionContainer>
									<ProjectSubSectionContainer>
										<ProjectFieldContainer>
											<ProjectFieldLabel>
												City
											</ProjectFieldLabel>
											<ProjectFieldValue className="truncate">
												{annotatedProject.locationCity}
											</ProjectFieldValue>
										</ProjectFieldContainer>
									</ProjectSubSectionContainer>
									{
										annotatedProject.locationName.trim().length > 0
											? (
												<ProjectSubSectionContainer>
													<ProjectFieldContainer>
														<ProjectFieldLabel>
															Name
														</ProjectFieldLabel>
														<ProjectFieldValue className="truncate">
															{annotatedProject.locationName}
														</ProjectFieldValue>
													</ProjectFieldContainer>
												</ProjectSubSectionContainer>
											)
											: null
									}
								</ProjectSectionContainer>
								<ScheduledDateTime anytime={projectEvent.flexibleDatetime} date={fmtDt.date} duration={fmtDt.duration} startTime={fmtDt.startTime} endTime={fmtDt.endTime}>
									{
										(profile.isProducer && window.pbUrls.rescheduleProjectUri)
											? (
												<a href={window.pbUrls.rescheduleProjectUri} id="id_temp-project-datetime-change-link">
													{
														projectEvent.flexibleDatetime
															? 'schedule'
															: 'reschedule'
													}
												</a>
											)
											: null
									}
								</ScheduledDateTime>
								<ProjectSubSectionContainer>
									<ProjectFieldContainer>
										<ProjectFieldLabel>
											Access
										</ProjectFieldLabel>
										<ProjectFieldValue className="truncate">
											{annotatedProject.accessOptionLabel}
										</ProjectFieldValue>
									</ProjectFieldContainer>
								</ProjectSubSectionContainer>
								{
									annotatedProject.accessNotes.trim().length > 0
										? (
											<ProjectSectionContainer>
												<ProjectFieldContainer>
													<ProjectFieldLabel>
														Access Notes
													</ProjectFieldLabel>
													<ProjectFieldValue>
														<p>
															{annotatedProject.accessNotes}
														</p>
													</ProjectFieldValue>
												</ProjectFieldContainer>
											</ProjectSectionContainer>
										)
										: null
								}
								<ProjectSubSectionContainer>
									<ProjectFieldContainer>
										<ProjectFieldLabel>
											Occupancy
										</ProjectFieldLabel>
										<ProjectFieldValue className="truncate">
											{annotatedProject.occupancyOptionLabel}
										</ProjectFieldValue>
									</ProjectFieldContainer>
								</ProjectSubSectionContainer>
								{
									project.notes.trim().length > 0
										? (
											<ProjectSectionContainer className="fluid">
												<ProjectFieldContainer>
													<ProjectFieldLabel>
														Notes
													</ProjectFieldLabel>
													<ProjectFieldValue>
														{
															project.notes
																.split('\n')
																.map((note, idx) => <p key={idx}>{note}</p>)
														}
													</ProjectFieldValue>
												</ProjectFieldContainer>
											</ProjectSectionContainer>
										)
										: null
								}
								{
									profile.isProducer && hasPhotosDue
										? (
											<ProjectSubSectionContainer>
												<ProjectFieldContainer>
													<ProjectFieldLabel>
														Project Due
													</ProjectFieldLabel>
													<ProjectFieldValue>
														{
															dueObjFormattedDateTime(
																annotatedProject.photosDueDateIsoformat,
																annotatedProject.photosDueTimeIsoformat,
															)
														}
													</ProjectFieldValue>
												</ProjectFieldContainer>
											</ProjectSubSectionContainer>
										)
										: null
								}
								{
									profile.isProducer && hasVideoDue
										? (
											<ProjectSubSectionContainer>
												<ProjectFieldContainer>
													<ProjectFieldLabel>
														Video Due
													</ProjectFieldLabel>
													<ProjectFieldValue>
														{
															dueObjFormattedDateTime(
																annotatedProject.videoDueDateIsoformat,
																annotatedProject.videoDueTimeIsoformat,
															)
														}
													</ProjectFieldValue>
												</ProjectFieldContainer>
											</ProjectSubSectionContainer>
										)
										: null
								}
								{
									hasClientDue
										? (
											<ProjectSubSectionContainer>
												<ProjectFieldContainer>
													<ProjectFieldLabel>
														Client Due
													</ProjectFieldLabel>
													<ProjectFieldValue>
														{
															dueObjFormattedDateTime(
																annotatedProject.clientDueDateIsoformat,
																annotatedProject.clientDueTimeIsoformat,
															)
														}
													</ProjectFieldValue>
												</ProjectFieldContainer>
											</ProjectSubSectionContainer>
										)
										: null
								}
								{
									hasBedsBaths
										? (
											<ProjectSubSectionContainer>
												<ProjectFieldContainer>
													<ProjectFieldLabel>
														Beds/Baths
													</ProjectFieldLabel>
													<ProjectFieldValue>
														{this.bedsBathsDisplay()}
													</ProjectFieldValue>
												</ProjectFieldContainer>
											</ProjectSubSectionContainer>
										)
										: null
								}
							</CardInner>
						</Card>
						<Card>
							<CardHeader primaryText="Invoice"/>
							<CardInner>
								<InvoiceView
									lines={invoice.lines}
									total={project.total}
									balance={project.balance}
									onLineSave={this.saveInvoiceLine}
									onLineDelete={this.deleteInvoiceLine}
									onMediaObjectSave={this.saveMediaObject}
									onMediaObjectDelete={this.deleteMediaObject}
								/>
							</CardInner>
						</Card>
						{
							(profile.isProducer || (project.paid && !showPaymentSuccess))
								? null
								: (
									<Card>
										<CardHeader primaryText="Payment"/>
										<CardInner>
											{
												showPaymentSuccess
													? (
														<div className="display--flex flex-direction--column justify-content--center align-items--center">
															<h4 className="mdc-typography mdc-typography--headline5 margin-bottom--24">Thank you for your payment!</h4>
															<Button onClick={this.hidePaymentSuccess}>
																Dismiss
															</Button>
														</div>
													)
													: (
														(project.slug.length > 0)
															? (
																<ProjectPaymentView
																	onComplete={this.paymentProcessingDone}
																	slug={project.slug}
																/>
															)
															: null
													)
											}
										</CardInner>
									</Card>
								)
						}
						{
							profile.isProducer
								? (
									<>
										<ProjectReadActions
											isMarkedPaid={annotatedProject.markedPaid}
											onAction={this.axnRequest}
											teamMembers={teamMembers}
											projectAssignments={assigns}
										/>
										<ProjectReadTempDateTimeChangerThing
											projectEvent={projectEvent}
											event={event}
											onSubmit={this.dateTimeChanged}
										/>
									</>
								)
								: null
						}
					</GridLayoutCell>
				</GridLayout>
				<Menu
					absolutePosition={statusMenuCoords}
					anchorToBody
					isOpen={statusMenuIsOpen}
					onClose={this.closeStatusMenu}
					onSelection={this.statusMenuItemSelected}
					options={this.statusMenuOptions()}
				/>
				<Dialog>
					<Prompt/>
				</Dialog>
			</>
		);
	}

	@bind
	private async saveInvoiceLine(line: IInvoiceLine) {
		const {pk} = this.props;
		await api.updateProjectInvoiceLine(pk, line);
		this.setState({
			invoice: await api.projectInvoiceDetail(pk),
		});
	}

	@bind
	private async saveMediaObject(invoiceLinePk: number | string, data: IMediaObject) {
		const {pk} = this.props;
		if (data.id > 0) {
			await api.updateMediaObject(pk, invoiceLinePk, data);
		} else {
			await api.createMediaObject(pk, invoiceLinePk, data);
		}
		this.setState({
			invoice: await api.projectInvoiceDetail(pk),
		});
	}

	@bind
	private setDateTimeContainerStrings(dt: IDateTimeRange) {
		if ((dt.lower === null) || (dt.upper === null)) {
			console.log('setDateTimeContainerStrings: Missing lower/upper date range.');
			return;
		}
		const dateEl = document.getElementById('id_pb-project-detail-date-str');
		const startTimeEl = document.getElementById('id_pb-project-detail-start-time-str');
		const endTimeEl = document.getElementById('id_pb-project-detail-end-time-str');
		if (dateEl === null) {
			console.log('setDateTimeContainerStrings: Date element not found.');
			return;
		}
		if (startTimeEl === null) {
			console.log('setDateTimeContainerStrings: Start time element not found.');
			return;
		}
		if (endTimeEl === null) {
			console.log('setDateTimeContainerStrings: End time element not found.');
			return;
		}
		const lower = datetime.datetime.fromisoformat(dt.lower);
		const upper = datetime.datetime.fromisoformat(dt.upper);
		const dateStr = `${lower.weekdayName()}, ${lower.monthName()} ${lower.day}`;
		const startStr = `${to12Hour(lower.hour)}:${String(lower.minute).replace(/^(\d)$/, '0$1')} ${toPeriod(lower.hour)}`;
		const endStr = `${to12Hour(upper.hour)}:${String(upper.minute).replace(/^(\d)$/, '0$1')} ${toPeriod(upper.hour)}`;
		dateEl.textContent = dateStr;
		startTimeEl.textContent = startStr;
		endTimeEl.textContent = endStr;
	}

	@bind
	private statusButtonClicked(event: React.MouseEvent) {
		this.openStatusMenu({
			x: event.clientX,
			y: event.clientY,
		});
	}

	@bind
	private statusDisplay(statusId: string): string {
		const {projectStatus} = this.state;
		for (const obj of projectStatus) {
			if (obj.name === statusId) {
				return obj.summary;
			}
		}
		return '';
	}

	@bind
	private async statusMenuItemSelected(index: number) {
		this.closeStatusMenu();
		const opts = this.statusMenuOptions();
		if (idxOk(index, opts.length, 'statusMenuItemSelected')) {
			const opt = opts[index];
			await this.updateProject({
				...this.state.project,
				statusId: opt.value,
			});
		}
	}

	private statusMenuOptions(): Array<IMenuOptionProps> {
		const {
			project,
			projectStatus,
		} = this.state;
		const rv: Array<IMenuOptionProps> = [];
		for (const projStatus of projectStatus) {
			rv.push({
				label: capitalize(projStatus.summary),
				value: projStatus.name,
				isSelected: projStatus.name === project.statusId,
			});
		}
		return rv;
	}

	private async updateProject(obj: IProject) {
		const project = await api.updateProject(obj.slug, obj);
		this.setState({
			annotatedProject: await api.projectDetailAnnotated(obj.slug),
			project,
		});
	}
}

function dueObjFormattedDateTime(date: string, time: string): string {
	if (date.length === 0) {
		return '-';
	}
	const d = datetime.date.fromisoformat(date);
	const t = (time.length > 0)
		? datetime.time.fromisoformat(time)
		: null;
	const parts: Array<string> = [
		`${d.monthNameAbbr()}. ${d.day}, ${d.year}`,
	];
	if (t) {
		const s = (t.minute > 0)
			? `${to12Hour(t.hour)}:${padStart(t.minute, 2, '0')}`
			: `${to12Hour(t.hour)}`;
		parts.push(
			`${s} ${toPeriod(t.hour)}`,
		);
	}
	return parts.join(' @ ');
}
