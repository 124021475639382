import React from 'react';

import {api} from '../../httpapi';
import {WidgetForm} from './widgetform';
import {WidgetCard} from './widgetcard';
import {
	DialogCode,
	StandardButton,
} from '../../constants';
import {
	bind,
	capitalize,
	idxOk,
	staticOrgAppWidgetOption,
} from '../../util';
import {
	CCardToolbarAction,
	Dialog,
	List,
	ListItem,
	ListItemPrimaryText,
	ListItemSecondaryText,
	WideContentContainer,
	WideContentHeader,
} from '../../components';

export interface IAppDetailViewProps extends React.HTMLAttributes<any> {
	pk: string;
}

enum State {
	AppWidgetRead,
	DefaultState,
	OrgAppWidgetUpdate,
}

export interface IAppDetailViewState {
	activeAppWidgetIndex: number | null;
	app: IApp;
	dialogIsOpen: boolean;
	orgAppWidgets: Array<IOrgAppWidget>;
	state: State;
	unmodifiedActiveOrgAppWidget: IOrgAppWidget | null;
}

export class AppDetailView extends React.Component<IAppDetailViewProps, IAppDetailViewState> {
	constructor(props: IAppDetailViewProps) {
		super(props);
		this.state = {
			activeAppWidgetIndex: null,
			app: {
				name: '',
				slug: '',
				widgets: [],
			},
			dialogIsOpen: false,
			orgAppWidgets: [],
			state: State.DefaultState,
			unmodifiedActiveOrgAppWidget: null,
		};
	}

	private activeAppWidget(): IAppWidget | null {
		return this.appWidget(this.state.activeAppWidgetIndex);
	}

	private appWidget(index: number | null): IAppWidget | null {
		const {app} = this.state;
		if ((index === null) || !idxOk(index, app.widgets.length, 'appWidget', true)) {
			return null;
		}
		return app.widgets[index];
	}

	@bind
	private addOrgAppWidgetOption() {
		const widg = this.activeAppWidget();
		if (widg === null) {
			console.log('addOrgAppWidgetOption: Active object is null.');
			return;
		}
		const idx = this.orgAppWidgetIndexForAppWidget(widg.id);
		if (idx >= 0) {
			const orgAppWidgets = [
				...this.state.orgAppWidgets,
			];
			const orgAppWidg = orgAppWidgets[idx];
			orgAppWidg.options = [
				...orgAppWidg.options,
				staticOrgAppWidgetOption(),
			];
			orgAppWidgets.splice(idx, 1);
			orgAppWidgets.splice(idx, 0, orgAppWidg);
			this.setState({
				orgAppWidgets,
			});
		} else {
			console.log('addOrgAppWidgetOption: Invalid index.');
		}
	}

	@bind
	private appWidgetListItemClicked(index: number) {
		this.openReadDialog(index);
	}

	private appWidgetTypeDisplay(widg: IAppWidget): string {
		if (widg.widgetInputType === null) {
			return '';
		}
		return widg.widgetInputType.label;
	}

	@bind
	private cancelOrgAppWidgetUpdate() {
		const {
			activeAppWidgetIndex,
			app,
			unmodifiedActiveOrgAppWidget,
		} = this.state;
		if (unmodifiedActiveOrgAppWidget === null) {
			console.log('cancelOrgAppWidgetUpdate: Unmodified object is null.');
			this.closeDialog();
			return;
		}
		if ((activeAppWidgetIndex === null) || !idxOk(activeAppWidgetIndex, app.widgets.length, 'cancelOrgAppWidgetUpdate')) {
			this.closeDialog();
			return;
		}
		const activeAppWidg = app.widgets[activeAppWidgetIndex];
		const orgAppWidgIdx = this.orgAppWidgetIndexForAppWidget(activeAppWidg.id);
		let orgAppWidgets: Array<IOrgAppWidget>;
		if (orgAppWidgIdx >= 0) {
			orgAppWidgets = [
				...this.state.orgAppWidgets,
			];
			orgAppWidgets.splice(orgAppWidgIdx, 1);
			orgAppWidgets.splice(orgAppWidgIdx, 0, unmodifiedActiveOrgAppWidget);
		} else {
			console.log('cancelOrgAppWidgetUpdate: Invalid index for org app widget: %s', orgAppWidgIdx);
			orgAppWidgets = this.state.orgAppWidgets;
		}
		this.setState(
			{
				orgAppWidgets,
			},
			this.closeDialog,
		);
	}

	@bind
	private closeDialog() {
		this.setState({
			activeAppWidgetIndex: null,
			dialogIsOpen: false,
			state: State.DefaultState,
			unmodifiedActiveOrgAppWidget: null,
		});
	}

	async componentDidMount() {
		this.setState({
			app: await api.appDetail(this.props.pk),
			orgAppWidgets: await api.orgAppWidgetList({
				app: this.props.pk,
			}),
		});
	}

	@bind
	private deleteOrgAppWidgetOption(optionIndex: number) {
		const {
			activeAppWidgetIndex,
			app,
		} = this.state;
		if (activeAppWidgetIndex === null) {
			console.log('deleteOrgAppWidgetOption: Active index is null.');
			return;
		}
		if (!idxOk(activeAppWidgetIndex, app.widgets.length, 'deleteOrgAppWidgetOption')) {
			return;
		}
		const activeAppWidg = app.widgets[activeAppWidgetIndex];
		const orgAppWidgIdx = this.orgAppWidgetIndexForAppWidget(activeAppWidg.id);
		if (orgAppWidgIdx === -1) {
			console.log('deleteOrgAppWidgetOption: Active index is -1.');
			return;
		}
		const orgAppWidgets = [
			...this.state.orgAppWidgets,
		];
		const orgAppWidg = orgAppWidgets[orgAppWidgIdx];
		const orgAppWidgetOptions = [
			...orgAppWidg.options,
		];
		orgAppWidgetOptions.splice(optionIndex, 1);
		orgAppWidg.options = orgAppWidgetOptions;
		this.setState({
			orgAppWidgets,
		});
	}

	@bind
	private dialogResult(result: DialogCode) {
		if (result === DialogCode.Rejected) {
			switch (this.state.state) {
				case State.OrgAppWidgetUpdate: {
					this.cancelOrgAppWidgetUpdate();
					return;
				}
			}
		}
		this.closeDialog();
	}

	private openReadDialog(activeAppWidgetIndex: number) {
		this.setState({
			activeAppWidgetIndex,
			dialogIsOpen: true,
			state: State.AppWidgetRead,
		});
	}

	private openUpdateDialog(activeAppWidgetIndex: number) {
		const appWidg = this.appWidget(activeAppWidgetIndex);
		if (appWidg === null) {
			console.log('openUpdateDialog: Active object is null.');
			return;
		}
		const orgAppWidg = this.orgAppWidgetForAppWidget(appWidg.id);
		if (orgAppWidg === null) {
			console.log('openUpdateDialog: Active object (2) is null.');
			return;
		}
		this.setState({
			activeAppWidgetIndex,
			dialogIsOpen: true,
			state: State.OrgAppWidgetUpdate,
			unmodifiedActiveOrgAppWidget: JSON.parse(JSON.stringify(orgAppWidg)),
		});
	}

	@bind
	private orgAppWidgetChanged<K extends keyof IOrgAppWidget>(key: K, value: IOrgAppWidget[K]) {
		const {
			activeAppWidgetIndex,
			app,
		} = this.state;
		if (activeAppWidgetIndex === null) {
			console.log('orgAppWidgetChanged: Active index is null.');
			return;
		}
		if (!idxOk(activeAppWidgetIndex, app.widgets.length, 'orgAppWidgetChanged')) {
			return;
		}
		const activeAppWidg = app.widgets[activeAppWidgetIndex];
		const orgAppWidgIdx = this.orgAppWidgetIndexForAppWidget(activeAppWidg.id);
		if (orgAppWidgIdx === -1) {
			console.log('orgAppWidgetChanged: Active index is -1.');
			return;
		}
		const orgAppWidgets = [
			...this.state.orgAppWidgets,
		];
		orgAppWidgets[orgAppWidgIdx][key] = value;
		this.setState({
			orgAppWidgets,
		});
	}

	private orgAppWidgetForAppWidget(appWidgetPk: number): IOrgAppWidget | null {
		const {orgAppWidgets} = this.state;
		const idx = this.orgAppWidgetIndexForAppWidget(appWidgetPk);
		if (idx >= 0) {
			return orgAppWidgets[idx];
		}
		return null;
	}

	private orgAppWidgetIndexForAppWidget(appWidgetPk: number): number {
		const {orgAppWidgets} = this.state;
		for (let i = 0; i < orgAppWidgets.length; ++i) {
			if (orgAppWidgets[i].appWidgetId === appWidgetPk) {
				return i;
			}
		}
		return -1;
	}

	@bind
	private orgAppWidgetOptionChanged<K extends keyof IOrgAppWidgetOption>(optionIndex: number, key: K, value: IOrgAppWidgetOption[K]) {
		const {
			activeAppWidgetIndex,
			app,
		} = this.state;
		if (activeAppWidgetIndex === null) {
			console.log('orgAppWidgetOptionChanged: Active index is null.');
			return;
		}
		if (!idxOk(activeAppWidgetIndex, app.widgets.length, 'orgAppWidgetOptionChanged')) {
			return;
		}
		const activeAppWidg = app.widgets[activeAppWidgetIndex];
		const orgAppWidgIdx = this.orgAppWidgetIndexForAppWidget(activeAppWidg.id);
		if (orgAppWidgIdx === -1) {
			console.log('orgAppWidgetOptionChanged: Active index is -1.');
			return;
		}
		const orgAppWidgets = [
			...this.state.orgAppWidgets,
		];
		const orgAppWidg = orgAppWidgets[orgAppWidgIdx];
		const orgAppWidgetOptions = [
			...orgAppWidg.options,
		];
		orgAppWidgetOptions[optionIndex][key] = value;
		orgAppWidg.options = orgAppWidgetOptions;
		this.setState({
			orgAppWidgets,
		});
	}

	render() {
		const {
			app,
			dialogIsOpen,
			state,
		} = this.state;
		const activeWidg = this.activeAppWidget();
		const orgAppWidg = activeWidg
			? this.orgAppWidgetForAppWidget(activeWidg.id)
			: null;
		let btns: StandardButton | undefined = undefined;
		let dialogComp: React.ReactNode | null = null;
		switch (state) {
			case State.AppWidgetRead: {
				if (activeWidg && orgAppWidg) {
					dialogComp = (
						<WidgetCard
							onToolbarAction={this.toolbarAction}
							widget={activeWidg}
							orgAppWidgetOptions={orgAppWidg.options}
						/>
					);
				}
				break;
			}
			case State.OrgAppWidgetUpdate: {
				if (orgAppWidg) {
					dialogComp = (
						<WidgetForm
							heading="Update widget"
							onAddOrgAppWidgetOption={this.addOrgAppWidgetOption}
							onCancel={this.cancelOrgAppWidgetUpdate}
							onDeleteOrgAppWidgetOption={this.deleteOrgAppWidgetOption}
							onOrgAppWidgetChange={this.orgAppWidgetChanged}
							onOrgAppWidgetOptionChange={this.orgAppWidgetOptionChanged}
							onSave={this.updateOrgAppWidget}
							onToolbarAction={this.toolbarAction}
							orgAppWidget={orgAppWidg}
						/>
					);
				}
				break;
			}
		}
		return (
			<>
				<WideContentHeader hasDivider>{app.name}</WideContentHeader>
				<WideContentContainer>
					<List isTwoLine>
						{
							app.widgets.map((widg, idx) => {
								return (
									<ListItem isClickable key={idx} onClick={this.appWidgetListItemClicked.bind(this, idx)}>
										<ListItemPrimaryText>
											{capitalize(widg.label)}
										</ListItemPrimaryText>
										<ListItemSecondaryText>
											{this.appWidgetTypeDisplay(widg)}
										</ListItemSecondaryText>
									</ListItem>
								);
							})
						}
					</List>
				</WideContentContainer>
				<Dialog buttons={btns} isOpen={dialogIsOpen} onFinished={this.dialogResult}>
					{dialogComp}
				</Dialog>
			</>
		);
	}

	@bind
	private toolbarAction(axn: CCardToolbarAction) {
		switch (axn) {
			case CCardToolbarAction.More: {
				break;
			}
			case CCardToolbarAction.Edit: {
				if (this.state.activeAppWidgetIndex !== null) {
					this.openUpdateDialog(this.state.activeAppWidgetIndex);
				}
				break;
			}
			case CCardToolbarAction.Close: {
				this.closeDialog();
				break;
			}
		}
	}

	@bind
	private async updateOrgAppWidget() {
		const activeAppWidg = this.activeAppWidget();
		if (activeAppWidg === null) {
			console.log('updateOrgAppWidget: Active object is null.');
			return;
		}
		const orgAppWidg = this.orgAppWidgetForAppWidget(activeAppWidg.id);
		if (orgAppWidg === null) {
			console.log('updateOrgAppWidget: Org app widget for active object is null.');
			return;
		}
		await api.updateOrgAppWidget(orgAppWidg);
		this.setState(
			{
				orgAppWidgets: await api.orgAppWidgetList({
					app: this.props.pk,
				}),
			},
			this.closeDialog,
		);
	}
}
