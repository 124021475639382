import React from 'react';
import {
	MDCTextFieldHelperTextAdapter,
	MDCTextFieldHelperTextFoundation,
} from '@material/textfield/helper-text';
import {makeClassName} from '../util';

export interface IHelperTextProps extends React.HTMLAttributes<any> {
	isPersistent: boolean;
	isValidationText: boolean;
}

interface IHelperTextState {
	classNames: Set<string>;
	textContent: string | null;
}

export class HelperText extends React.Component<Partial<IHelperTextProps>, IHelperTextState> {
	ctrl: MDCTextFieldHelperTextFoundation;
	private readonly rootRef: React.RefObject<HTMLDivElement>;

	constructor(props: Partial<IHelperTextProps>) {
		super(props);
		this.ctrl = new MDCTextFieldHelperTextFoundation();
		this.rootRef = React.createRef();
		this.state = {
			classNames: new Set(),
			textContent: null,
		};
	}

	componentDidMount() {
		this.ctrl.destroy();
		this.ctrl = new MDCTextFieldHelperTextFoundation(
			this.mdcAdapter(),
		);
		this.ctrl.init();
	}

	componentWillUnmount() {
		this.ctrl.destroy();
	}

	private mdcAdapter(): MDCTextFieldHelperTextAdapter {
		return {
			addClass: (className: string) => {
				this.state.classNames.add(className);
				this.setState({
					classNames: new Set(this.state.classNames),
				});
			},
			getAttr: (attrName: string) => {
				const el = this.rootRef.current;
				return el
					? el.getAttribute(attrName)
					: null;
			},
			hasClass: (className: string) => {
				return this.state.classNames.has(className);
			},
			removeAttr: (attrName: string) => {
				const el = this.rootRef.current;
				if (el) {
					el.removeAttribute(attrName);
				}
			},
			removeClass: (className: string) => {
				if (this.state.classNames.has(className)) {
					this.state.classNames.delete(className);
					this.setState({
						classNames: new Set(this.state.classNames),
					});
				}
			},
			setAttr: (attrName: string, value: string) => {
				const el = this.rootRef.current;
				if (el) {
					el.setAttribute(attrName, value);
				}
			},
			setContent: (text: string) => {
				// TODO: Shouldn't this "content" be the text? If so, text is
				//       already being "set" when the element is defined
				//       (`children` in ReactJS).
				console.log(
					'HelperText::setContent: NotImplemented. Got "%s"',
					text,
				);
			},
		};
	}

	render() {
		const {
			children,
			className,
			isPersistent,
			isValidationText,
			...rest
		} = this.props;
		const {classNames} = this.state;
		const clsName = makeClassName(
			'mdc-text-field-helper-line',
			...classNames,
			className,
		);
		const txtClsName = makeClassName(
			'mdc-text-field-helper-text',
			isPersistent
				? 'mdc-text-field-helper-text--persistent'
				: undefined,
			isValidationText
				? 'mdc-text-field-helper-text--validation-msg'
				: undefined,
		);
		return (
			<div className={clsName} ref={this.rootRef} {...rest}>
				<div className={txtClsName} aria-hidden="true">
					{children}
				</div>
			</div>
		);
	}

	setValid(valid: boolean) {
		this.ctrl.setValidity(valid);
	}
}
