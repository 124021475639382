import {RegexPattern, URLPattern, Callable} from './resolvers';

export function path(route: RegExp | string, view: Callable, name?: string): URLPattern {
	const regex = (route instanceof RegExp)
		? route
		: new RegExp(route);
	return new URLPattern(
		new RegexPattern(regex, name),
		view,
		name,
	);
}
