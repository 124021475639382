import React from 'react';

import {bind, staticExpandedUser} from '../../util';
import {CCardToolbarAction} from '../../components';
import {UserCard, IUserCardProps} from '../../components/usercard';

export interface IUserReadProps extends Partial<IUserCardProps> {
	objGetter: (pk: string) => Promise<IExpandedUser>;
	objUpdater: (obj: IExpandedUser) => Promise<IExpandedUser>;
	onToolbarAction: (axn: CCardToolbarAction) => any;
	pk: string;
	userClasses: Array<IUserClass>;
}

interface IUserReadState {
	obj: IExpandedUser;
}

export class UserRead extends React.Component<IUserReadProps, IUserReadState> {
	constructor(props: IUserReadProps) {
		super(props);
		this.state = {
			obj: staticExpandedUser(),
		};
	}

	async componentDidMount() {
		const {
			objGetter,
			pk,
		} = this.props;
		this.setState({
			obj: await objGetter(pk),
		});
	}

	@bind
	private receiveNotificationsClicked(index: number): void {
		if (index === -1) {
			console.log('UserRead::receiveNotificationsClicked: Invalid index. Got: %s', index);
			return;
		}
		const {obj} = this.state;
		if (index === 0) {
			this.setState(
				{
					obj: {
						...obj,
					}
				},
				() => this.updateObj(this.state.obj)
			);
		} else if (index > 0) {
			// Alternate email
			//
			// Given index is the position within the view, not within the
			// local object collection. So, since we need to account for the
			// main account address, we deduct 1 from the index to correctly
			// index into the alternate email collection.
			--index;
			if (index < obj.alternateEmailAddresses.length) {
				const o = obj.alternateEmailAddresses[index];
				o.receiveNotifications = !o.receiveNotifications;
				this.setState(
					{
						obj: {
							...obj,
							alternateEmailAddresses: [
								...obj.alternateEmailAddresses
							],
						}
					},
					() => this.updateObj(this.state.obj)
				);
			} else {
				console.log('UserRead::receiveNotificationsClicked: Invalid alt obj index. Got: %s', index);
			}
		} else {
			console.log('UserRead::receiveNotificationsClicked: Invalid alt obj index. Got: %s', index);
		}
	}

	render() {
		const {
			objGetter,
			objUpdater,
			onToolbarAction,
			serviceAreas,
			userClasses,
			...rest
		} = this.props;
		const {obj} = this.state;
		return (
			<UserCard
				emailAddresses={allEmailAddress(obj)}
				isAdmin={obj.isAdmin}
				onToolbarAction={onToolbarAction}
				onReceiveNotificationsClick={this.receiveNotificationsClicked}
				phoneNumbers={obj.phoneNumbers}
				serviceAreas={this.serviceAreas()}
				subjectName={obj.displayName}
				notes={obj.notes}
				subjectTitle={obj.organizationName}
				userClassName={this.userClassName(obj.userClassId)}
				{...rest}
			/>
		);
	}

	private serviceAreas(): Array<IServiceArea> {
		const {serviceAreas} = this.props;
		if (serviceAreas === undefined) {
			return [];
		}
		const {obj} = this.state;
		const pks = new Set(obj.serviceAreas);
		return serviceAreas.filter(x => pks.has(x.id));
	}

	private async updateObj(obj: IExpandedUser): Promise<IExpandedUser> {
		return await this.props.objUpdater(obj);
	}

	private userClass(pk?: number | null): IUserClass | null {
		if ((pk === undefined) || (pk === null)) {
			return null;
		}
		const {userClasses} = this.props;
		for (const obj of userClasses) {
			if (obj.id === pk) {
				return obj;
			}
		}
		return null;
	}

	private userClassName(pk?: number | null): string | undefined {
		const obj = this.userClass(pk);
		return obj
			? obj.name
			: undefined;
	}
}

function allEmailAddress(obj: IExpandedUser, forCreate: boolean = false): Array<IEmailAddress> {
	return [
		{
			address: obj.email,
			label: forCreate
				? ''
				: 'Account',
			// FIXME: Disgusting.
			id: forCreate
				? -1
				: null,
			receiveNotifications: false,
		},
		...obj.alternateEmailAddresses,
	];
}
