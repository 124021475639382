import React from 'react';

import {api} from '../../httpapi';
import {ListView} from '../list';
import {UiTableTitle} from '../../constants';
import {
	bind,
	staticCfg,
} from '../../util';
import {
	DataTableCellProps,
	TubbyButton,
} from '../../components';

enum OrgColumn {
	Name = 'Name',
	Email = 'Email',
}

export interface IOrgListProps extends React.HTMLAttributes<any> {
	onCreateBtnClick: () => any;
	onObjectClicked: (orgPk: number) => any;
}

interface IOrgListState {
	cfg: ICfg;
}

export class OrgList extends React.Component<IOrgListProps, IOrgListState> {
	private readonly listViewRef: React.RefObject<ListView>;

	constructor(props: IOrgListProps) {
		super(props);
		this.listViewRef = React.createRef();
		this.state = {
			cfg: staticCfg(),
		};
	}

	@bind
	private cellClicked(obj: IOrganization) {
		this.props.onObjectClicked(obj.id);
	}

	@bind
	private cellHook(col: ICol, obj: IOrganization): Partial<DataTableCellProps> {
		let val: string = '';
		switch (col.label) {
			case OrgColumn.Name: {
				val = obj.name;
				break;
			}
			case OrgColumn.Email: {
				val = obj.email;
				break;
			}
		}
		return {
			children: val,
			title: val,
		};
	}

	async componentDidMount() {
		await this.refreshCfg();
	}

	@bind
	private async fetchCfg(): Promise<ICfg> {
		return await api.cfgDetail();
	}

	@bind
	async refreshCfg(cb?: () => any): Promise<void> {
		this.setState(
			{
				cfg: await this.fetchCfg(),
			},
			cb,
		);
	}

	@bind
	async refreshPage(params?: Partial<IPageParams>, cb?: () => any): Promise<void> {
		const curr = this.listViewRef.current;
		if (curr) {
			await curr.refreshPage(params, cb);
		}
	}

	render() {
		const {onCreateBtnClick} = this.props;
		const {cfg} = this.state;
		return (
			<>
				<div className="pb-list-view-actions">
					<span/>
					<TubbyButton onClick={onCreateBtnClick}>
						Create organization
					</TubbyButton>
				</div>
				<ListView
					cellHook={this.cellHook}
					cfg={cfg}
					columnMenuEnabled
					columnsMovable
					ref={this.listViewRef}
					onCellClick={this.cellClicked}
					pageGetter={api.clientOrganizationPage}
					uiTableTitle={UiTableTitle.ClientOrgList}
				/>
			</>
		);
	}
}

