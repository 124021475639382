import React from 'react';
import {
	MDCLineRippleAdapter,
	MDCLineRippleFoundation,
} from '@material/line-ripple';
import {
	EventType,
	SpecificEventListener,
} from '@material/base/types';

import {bind} from '../util';

export interface ILineRippleProps extends React.HTMLAttributes<any> {
}

interface ILineRippleState {
	classNames: Set<string>;
	transitionEndEventHandler: ((evt: TransitionEvent) => any) | null;
}

export class LineRipple extends React.Component<Partial<ILineRippleProps>, ILineRippleState> {
	private ctrl: MDCLineRippleFoundation;
	private readonly rootRef: React.RefObject<HTMLElement>;

	constructor(props: Partial<ILineRippleProps>) {
		super(props);
		this.ctrl = new MDCLineRippleFoundation();
		this.rootRef = React.createRef();
		this.state = {
			classNames: new Set(),
			transitionEndEventHandler: null,
		};
	}

	componentDidMount() {
		this.ctrl.destroy();
		this.ctrl = new MDCLineRippleFoundation(
			this.mdcAdapter()
		);
		this.ctrl.init();
	}

	componentWillUnmount() {
		this.ctrl.destroy();
	}

	private mdcAdapter(): MDCLineRippleAdapter {
		return {
			addClass: (className: string) => {
				this.state.classNames.add(className);
				this.setState({
					classNames: new Set(this.state.classNames),
				});
			},
			deregisterEventHandler: <T extends EventType>(evtType: T) => {
				if (evtType === 'transitionend') {
					this.setState({
						transitionEndEventHandler: null,
					});
				} else {
					console.log(
						'LineRipple::deregisterEventHandler got unknown event type: %s',
						evtType,
					);
				}
			},
			hasClass: (className: string) => {
				return this.state.classNames.has(className);
			},
			registerEventHandler: <T extends EventType>(evtType: T, handler: SpecificEventListener<T>) => {
				if (evtType === 'transitionend') {
					this.setState({
						transitionEndEventHandler: handler as (evt: TransitionEvent) => any,
					});
				} else {
					console.log(
						'LineRipple::registerEventHandler got unknown event type: %s',
						evtType,
					);
				}
			},
			removeClass: (className: string) => {
				this.state.classNames.delete(className);
				this.setState({
					classNames: new Set(this.state.classNames),
				});
			},
			setStyle: (propertyName: string, value: string) => {
				const el = this.rootRef.current;
				if (el) {
					el.style.setProperty(propertyName, value);
				}
			}
		};
	}

	render() {
		const {
			className,
			...rest
		} = this.props;
		const classNames: Array<string> = [
			'mdc-line-ripple',
			...this.state.classNames,
		];
		if (className) {
			classNames.push(className);
		}
		return (
			<span
				className={classNames.join(' ')}
				onTransitionEnd={this.transitionEnded}
				ref={this.rootRef}
				{...rest}/>
		);
	}

	setActive(active: boolean): void {
		if (active) {
			this.ctrl.activate();
		} else {
			this.ctrl.deactivate();
		}
	}

	setRippleCenter(x: number): void {
		this.ctrl.setRippleCenter(x);
	}

	@bind
	private transitionEnded(evt: React.TransitionEvent): void {
		if (this.state.transitionEndEventHandler) {
			evt.persist();
			this.state.transitionEndEventHandler(evt.nativeEvent);
		}
	}
}
