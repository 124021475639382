import React from 'react';

import {api} from '../../httpapi';
import {CheckState} from '../../constants';
import {MessageTemplateFormView} from './messagetemplateform';
import {
	bind,
	idxOk,
	staticMessageTemplate,
} from '../../util';
import {
	WideContentCard,
	WideContentContainer,
	WideContentHeader,
} from '../../components';

export interface IMessageTemplateCreateViewProps extends React.HTMLAttributes<any> {
}

export interface IMessageTemplateCreateViewState {
	media: Array<IMedium>;
	obj: IMessageTemplate;
	projectActions: Array<IProjectAction>;
	userClasses: Array<IUserClass>;
}

export class MessageTemplateCreateView extends React.Component<IMessageTemplateCreateViewProps, IMessageTemplateCreateViewState> {
	constructor(props: IMessageTemplateCreateViewProps) {
		super(props);
		this.state = {
			media: [],
			obj: staticMessageTemplate(),
			projectActions: [],
			userClasses: [],
		};
	}

	@bind
	private actionCheckStateChanged(index: number, state: CheckState) {
		const {
			obj,
			projectActions,
		} = this.state;
		if (idxOk(index, projectActions.length, 'actionCheckStateChanged')) {
			const actionIds = new Set(obj.actionIds);
			if (state === CheckState.Checked) {
				actionIds.add(projectActions[index].name);
			} else {
				actionIds.delete(projectActions[index].name);
			}
			this.setState({
				obj: {
					...obj,
					actionIds: Array.from(actionIds),
				},
			});
		}
	}

	@bind
	private bodyChanged(body: string) {
		const {obj} = this.state;
		this.setState({
			obj: {
				...obj,
				body,
			},
		});
	}

	async componentDidMount() {
		this.setState({
			media: await api.mediumList(),
			projectActions: await api.actionList(),
			userClasses: await api.allUserClasses(),
		});
	}

	@bind
	private footerChanged(footer: string) {
		const {obj} = this.state;
		this.setState({
			obj: {
				...obj,
				footer,
			},
		});
	}

	@bind
	private async formSubmitted(event: React.FormEvent) {
		event.preventDefault();
		const {obj} = this.state;
		const data: IMessageTemplateUpdate = {
			...obj,
		};
		this.setState({
			obj: await api.createMessageTemplate(data),
		});
	}

	@bind
	private isActiveChanged(isActive: boolean) {
		this.setState({
			obj: {
				...this.state.obj,
				isActive,
			},
		});
	}

	@bind
	private mediumCheckStateChanged(index: number, state: CheckState) {
		const {
			media,
			obj,
		} = this.state;
		if (idxOk(index, media.length, 'mediumCheckStateChanged')) {
			const mediumIds = new Set(obj.mediumIds);
			if (state === CheckState.Checked) {
				mediumIds.add(media[index].name);
			} else {
				mediumIds.delete(media[index].name);
			}
			this.setState({
				obj: {
					...obj,
					mediumIds: Array.from(mediumIds),
				},
			});
		}
	}

	@bind
	private nameChanged(name: string) {
		const {obj} = this.state;
		this.setState({
			obj: {
				...obj,
				name,
			},
		});
	}

	render() {
		const {
			media,
			obj,
			projectActions,
			userClasses,
		} = this.state;
		return (
			<>
				<WideContentHeader backNavUri={window.pbUrls.messageListView} backNavTitle="back to messages" hasDivider>
					Create message template
				</WideContentHeader>
				<WideContentContainer>
					<WideContentCard>
						<MessageTemplateFormView
							media={media}
							obj={obj}
							onActionCheckStateChange={this.actionCheckStateChanged}
							onBodyChange={this.bodyChanged}
							onFooterChange={this.footerChanged}
							onIsActiveChange={this.isActiveChanged}
							onMediumCheckStateChange={this.mediumCheckStateChanged}
							onNameChange={this.nameChanged}
							onSubjectChange={this.subjectChanged}
							onSubmit={this.formSubmitted}
							onUserClassCheckStateChange={this.userClassCheckStateChanged}
							projectActions={projectActions}
							userClasses={userClasses}
						/>
					</WideContentCard>
				</WideContentContainer>
			</>
		);
	}

	@bind
	private subjectChanged(subject: string) {
		const {obj} = this.state;
		this.setState({
			obj: {
				...obj,
				subject,
			},
		});
	}

	@bind
	private userClassCheckStateChanged(index: number, state: CheckState) {
		const {
			obj,
			userClasses,
		} = this.state;
		if (idxOk(index, userClasses.length, 'userClassCheckStateChanged')) {
			const userClassIds = new Set(obj.userClassIds);
			if (state === CheckState.Checked) {
				userClassIds.add(userClasses[index].id);
			} else {
				userClassIds.delete(userClasses[index].id);
			}
			this.setState({
				obj: {
					...obj,
					userClassIds: Array.from(userClassIds),
				},
			});
		}
	}
}
