import React from 'react';

import {ProductForm} from './form';
import {
	CheckState,
	ProductUserRole,
} from '../../../constants';

export interface IProductFormViewProps {
	addOns: Array<IProduct>;
	children: Array<IChildObj>;
	obj: IProduct;
	onAddChild: () => any;
	onAddOnClick: (index: number) => any;
	onAddOption: () => any;
	onCancel: () => any;
	onChildClick: (index: number) => any;
	onDeleteOption: (index: number) => any;
	onDeletePrice: (index: number) => any;
	onObjChange: <K extends keyof IProduct>(name: K, value: IProduct[K]) => any;
	onOptionChange: <K extends keyof IProductOption>(index: number, name: K, value: IProductOption[K]) => any;
	onPriceChange: <K extends keyof IPriceGroupProductPrice>(index: number, name: K, value: IPriceGroupProductPrice[K]) => any;
	onPriceGroupSelect: (index: number) => any;
	onQuickBooksItemClick: (index: number) => any;
	onSave: () => any;
	onUserAssignmentChange: (userId: string, roleId: ProductUserRole, state: CheckState) => any;
	onUserClassClick: (index: number) => any;
	options: Array<IProductOption>;
	priceGroups: Array<IPriceGroup>;
	prices: Array<IPriceGroupProductPrice>;
	productUsers: Array<IProductUser>;
	quickBooksItems: Array<IQuickBooksItem>;
	userClasses: Array<IUserClass>;
	users: Array<IUser>;
}

interface IChildObj extends IProduct {
	priceObjs: Array<IPriceGroupProductPrice>;
}

function childPrices(children: Array<IChildObj>, childPk: number): Array<IPriceGroupProductPrice> {
	for (const childObj of children) {
		if (childObj.id === childPk) {
			return childObj.priceObjs;
		}
	}
	return [];
}

export function ProductFormView(props: IProductFormViewProps) {
	const {
		addOns,
		children,
		obj,
		onAddChild,
		onAddOnClick,
		onAddOption,
		onCancel,
		onChildClick,
		onDeleteOption,
		onDeletePrice,
		onObjChange,
		onOptionChange,
		onPriceChange,
		onPriceGroupSelect,
		onQuickBooksItemClick,
		onSave,
		onUserAssignmentChange,
		onUserClassClick,
		options,
		priceGroups,
		prices,
		productUsers,
		quickBooksItems,
		userClasses,
		users,
	} = props;
	return (
		<ProductForm
			addons={addOns}
			childPrices={childPrices.bind(null, children)}
			children={children}
			obj={obj}
			onAddChild={onAddChild}
			onAddonClick={onAddOnClick}
			onAddOption={onAddOption}
			onCancel={onCancel}
			onChange={onObjChange}
			onChildClick={onChildClick}
			onDeleteOption={onDeleteOption}
			onOptionChange={onOptionChange}
			onPriceChange={onPriceChange}
			onPriceDelete={onDeletePrice}
			onPriceGroupSelect={onPriceGroupSelect}
			onQuickBooksItemClick={onQuickBooksItemClick}
			onSave={onSave}
			onUserAssignmentClick={onUserAssignmentChange}
			onUserClassClick={onUserClassClick}
			options={options}
			priceGroups={priceGroups}
			prices={prices}
			productUsers={productUsers}
			quickBooksItems={quickBooksItems}
			userClasses={userClasses}
			users={users}
		/>
	);
}

export interface IChildProductFormViewProps {
	obj: IProduct;
	onCancel: () => any;
	onChange: <K extends keyof IProduct>(name: K, value: IProduct[K]) => any;
	onPriceChange: <K extends keyof IPriceGroupProductPrice>(index: number, name: K, value: IPriceGroupProductPrice[K]) => any;
	onPriceGroupSelect: (index: number) => any;
	onSave: () => any;
	onDeletePrice: (index: number) => any;
	priceGroups: Array<IPriceGroup>;
	prices: Array<IPriceGroupProductPrice>;
}

export function ChildProductFormView(props: IChildProductFormViewProps) {
	const {
		obj,
		onCancel,
		onChange,
		onPriceChange,
		onPriceGroupSelect,
		onSave,
		onDeletePrice,
		priceGroups,
		prices,
	} = props;
	return (
		<ProductForm
			isCompact
			obj={obj}
			onCancel={onCancel}
			onChange={onChange}
			onPriceChange={onPriceChange}
			onPriceDelete={onDeletePrice}
			onPriceGroupSelect={onPriceGroupSelect}
			onSave={onSave}
			priceGroups={priceGroups}
			prices={prices}
		/>
	);
}


