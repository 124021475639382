import React from 'react';

import {makeClassName} from '../util';

export interface IContentCardProps extends React.HTMLAttributes<any> {
	isWide?: boolean;
}

export function ContentCard(props: IContentCardProps) {
	const {
		className,
		isWide,
		...rest
	} = props;
	const clsName = makeClassName(
		'pb-block--card',
		isWide
			? 'pb-block--card--wide'
			: undefined,
		className,
	);
	return (
		<div className={clsName} {...rest}/>
	);
}

export function WideContentCard(props: Omit<IContentCardProps, 'isWide'>) {
	return (
		<ContentCard isWide {...props}/>
	);
}
