import React from 'react';

import {api} from '../../httpapi';
import {bind, staticPriceGroup} from '../../util';
import {PriceGroupForm} from './form';
import {ICCardProps} from '../../components';

export interface IPriceGroupUpdateViewProps extends Omit<ICCardProps, 'onChange'> {
	onCancel?: () => any;
	onDone?: () => any;
	priceGroups: Array<IPriceGroup>;
	pk: number;
}

interface IPriceGroupUpdateViewState {
	obj: IPriceGroup;
}

export class PriceGroupUpdateView extends React.Component<IPriceGroupUpdateViewProps, IPriceGroupUpdateViewState> {
	constructor(props: IPriceGroupUpdateViewProps) {
		super(props);
		this.state = {
			obj: staticPriceGroup(),
		};
	}

	@bind
	private canceled(): void {
		const {onCancel} = this.props;
		this.setState(
			{
				obj: staticPriceGroup(),
			},
			() => {
				if (onCancel) {
					onCancel();
				}
			}
		);
	}

	@bind
	private changed<K extends keyof IPriceGroup>(name: K, value: IPriceGroup[K]): void {
		this.setState({
			obj: {
				...this.state.obj,
				[name]: value,
			}
		});
	}

	async componentDidMount() {
		this.setState({
			obj: await api.priceGroupDetail(this.props.pk)
		});
	}

	private parentPriceGroups(): Array<IPriceGroup> {
		const {priceGroups} = this.props;
		const {obj} = this.state;
		return priceGroups.filter(
			x => ((x.id !== obj.id) && (x.parentId !== obj.id) && (x.level <=obj.level))
		);
	}

	render() {
		const {
			onCancel,
			onDone,
			priceGroups,
			...rest
		} = this.props;
		const {obj} = this.state;
		return (
			<PriceGroupForm
				obj={obj}
				onChange={this.changed}
				onSave={this.submitted}
				onCancel={this.canceled}
				parentPriceGroups={this.parentPriceGroups()}
				title="Update price group"
				{...rest}
			/>
		);
	}

	@bind
	private async submitted() {
		const {onDone} = this.props;
		const {obj} = this.state;
		const newObj = await this.updateObj(obj);
		this.setState(
			{
				obj: {...newObj},
			},
			() => {
				if (onDone) {
					onDone();
				}
			}
		);
	}

	private async updateObj(obj: IPriceGroup): Promise<IPriceGroup> {
		return await api.updatePriceGroup(obj);
	}
}

