import React from 'react';

import {capitalize} from '../../util';
import {CheckState} from '../../constants';
import {
	Button,
	GridLayout,
	GridLayoutCell,
	List,
	ListItem,
	Switch,
	TextInput,
} from '../../components';

export interface IMessageTemplateFormViewProps extends React.FormHTMLAttributes<HTMLFormElement> {
	media: Array<IMedium>;
	obj: IMessageTemplate;
	onBodyChange: (body: string) => any;
	onFooterChange: (footer: string) => any;
	onIsActiveChange: (isActive: boolean) => any;
	onActionCheckStateChange: (index: number, state: CheckState) => any;
	onMediumCheckStateChange: (index: number, state: CheckState) => any;
	onNameChange: (name: string) => any;
	onSubjectChange: (subject: string) => any;
	onUserClassCheckStateChange: (index: number, state: CheckState) => any;
	projectActions: Array<IProjectAction>;
	userClasses: Array<IUserClass>;
}

export interface IMessageTemplateFormViewState {
}

export class MessageTemplateFormView extends React.Component<IMessageTemplateFormViewProps, IMessageTemplateFormViewState> {
	render() {
		const {
			media,
			obj,
			onActionCheckStateChange,
			onBodyChange,
			onFooterChange,
			onIsActiveChange,
			onMediumCheckStateChange,
			onNameChange,
			onSubjectChange,
			onUserClassCheckStateChange,
			projectActions,
			userClasses,
			...rest
		} = this.props;
		return (
			<form action="" method="POST" {...rest}>
				<GridLayout>
					<GridLayoutCell span={12}>
						<label>
							<div>Active</div>
							<Switch
								isSelected={obj.isActive}
								onClick={() => onIsActiveChange(!obj.isActive)}
							/>
						</label>
					</GridLayoutCell>
					<GridLayoutCell span={12}>
						<div>Select which actions will trigger this message:</div>
						<List className="pb-obj-list" isCompact>
							{
								projectActions.map((axn, idx) => {
									return (
										<ListItem onCheckboxChange={state => onActionCheckStateChange(idx, state)} isSelected={obj.actionIds.indexOf(axn.name) >= 0} key={idx} withCheckbox>
											{capitalize(axn.summary)}
										</ListItem>
									);
								})
							}
						</List>
					</GridLayoutCell>
					<GridLayoutCell span={12}>
						<div>Select which media this message will use:</div>
						<List className="pb-obj-list" isCompact>
							{
								media.map((med, idx) => {
									return (
										<ListItem onCheckboxChange={state => onMediumCheckStateChange(idx, state)} isSelected={obj.mediumIds.indexOf(med.name) >= 0} key={idx} withCheckbox>
											{capitalize(med.summary)}
										</ListItem>
									);
								})
							}
						</List>
					</GridLayoutCell>
					<GridLayoutCell span={12}>
						<div>This message is exclusive to:</div>
						<List className="pb-obj-list" isCompact>
							{
								userClasses.map((userClass, idx) => {
									return (
										<ListItem onCheckboxChange={state => onUserClassCheckStateChange(idx, state)} isSelected={obj.userClassIds.indexOf(userClass.id) >= 0} key={idx} withCheckbox>
											{userClass.name}
										</ListItem>
									);
								})
							}
						</List>
					</GridLayoutCell>
					<GridLayoutCell span={12}>
						<TextInput
							isOutlined
							label="Name"
							onChange={onNameChange}
							value={obj.name}
						/>
					</GridLayoutCell>
					<GridLayoutCell span={12}>
						<TextInput
							isOutlined
							label="Subject"
							onChange={onSubjectChange}
							value={obj.subject}
						/>
					</GridLayoutCell>
					<GridLayoutCell span={12}>
						<TextInput
							className="width--100-percent"
							isOutlined
							isResizable
							isTextArea
							value={obj.body}
							onChange={onBodyChange}
							label="Body"
							rows={15}
						/>
					</GridLayoutCell>
					<GridLayoutCell span={12}>
						<TextInput
							className="width--100-percent"
							isOutlined
							isResizable
							isTextArea
							value={obj.footer}
							onChange={onFooterChange}
							label="Footer"
							rows={3}
						/>
					</GridLayoutCell>
					<GridLayoutCell span={12}>
						<div className="display--flex justify-content--flex-end">
							<Button>
								Preview
							</Button>
							<Button className="margin-left--24" raisedFilled type="submit">
								Save
							</Button>
						</div>
					</GridLayoutCell>
				</GridLayout>
			</form>
		);
	}
}
