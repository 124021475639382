import axios from 'axios';

import {bind} from './util';
import * as datetime from './datetime';
import {
	KnownIntegration,
	REQUEST_CONFIG_WELL_KNOWN_REGISTRY_KEY,
} from './constants';

const STANDARD_HEADERS = {
	'Accept': 'application/json',
	'Content-Type': 'application/json',
};
const XSRF_CFG = {
	xsrfCookieName: 'csrftoken',
	xsrfHeaderName: 'X-CSRFToken',
};
type Cfg = Omit<IRequestConfig, 'wellKnown'>;

class HttpApi {
	@bind
	async actionList(): Promise<Array<IProjectAction>> {
		const url = window.pbUrls.actionListApiView;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<Array<IProjectAction>>(cfg);
		return resp.data;
	}

	@bind
	async allClientUsers(params?: Partial<Pick<IPageParams, 'tbl'>>): Promise<Array<IUser>> {
		const rv: Array<IUser> = [];
		let page = 1;
		let obj = await this.clientUserList({
			page,
			perPage: 100,
			...params,
		});
		rv.push(...obj.objects);
		while (obj.hasNext) {
			++page;
			obj = await this.clientUserList({
				page,
				perPage: 100,
				...params,
			});
			rv.push(...obj.objects);
		}
		return rv;
	}

	@bind
	async allProducts(): Promise<Array<IProduct>> {
		const rv: Array<IProduct> = [];
		let page = 1;
		let obj = await this.productList({
			page,
			perPage: 100,
		});
		rv.push(...obj.objects);
		while (obj.hasNext) {
			++page;
			obj = await this.productList({
				page,
				perPage: 100,
			});
			rv.push(...obj.objects);
		}
		return rv;
	}

	@bind
	async allServiceAreas(): Promise<Array<IServiceArea>> {
		const rv: Array<IServiceArea> = [];
		let page = 1;
		let obj = await this.serviceAreaList({
			page,
			perPage: 100,
		});
		rv.push(...obj.objects);
		while (obj.hasNext) {
			++page;
			obj = await this.serviceAreaList({
				page,
				perPage: 100,
			});
			rv.push(...obj.objects);
		}
		return rv;
	}

	@bind
	async allTeamMembers(params?: Partial<IPageParams>): Promise<Array<IUser>> {
		const rv: Array<IUser> = [];
		let page = 1;
		let obj = await this.teamMemberList({
			...params,
			page,
			perPage: 100,
		});
		rv.push(...obj.objects);
		while (obj.hasNext) {
			++page;
			obj = await this.teamMemberList({
				...params,
				page,
				perPage: 100,
			});
			rv.push(...obj.objects);
		}
		return rv;
	}

	@bind
	async allUserClasses(): Promise<Array<IUserClass>> {
		const rv: Array<IUserClass> = [];
		let page = 1;
		let obj = await this.userClassList({
			page,
			perPage: 100,
		});
		rv.push(...obj.objects);
		while (obj.hasNext) {
			++page;
			obj = await this.userClassList({
				page,
				perPage: 100,
			});
			rv.push(...obj.objects);
		}
		return rv;
	}

	@bind
	async alternateEmailDetail(pk: number | string): Promise<IAlternateEmailAddress> {
		const url = `${window.pbUrls.alternateEmailListApiView}${pk}/`;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<IAlternateEmailAddress>(cfg);
		return resp.data;
	}

	@bind
	async availabilityList(params: Pick<IPageParams, 'start' | 'end' | 'dx' | 'svc'> & Partial<Pick<IPageParams, 'pid'>>): Promise<Array<IAvailability>> {
		const url = window.pbUrls.availabilityListApiView;
		const cfg: Cfg = {
			method: 'GET',
			url,
			params,
		};
		const resp = await this.request<Array<IAvailability>>(cfg);
		return resp.data;
	}

	@bind
	async alternateEmailList(): Promise<Array<IAlternateEmailAddress>> {
		const url = window.pbUrls.alternateEmailListApiView;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<Array<IAlternateEmailAddress>>(cfg);
		return resp.data;
	}

	@bind
	async appDetail(pk: string): Promise<IApp> {
		const url = `${window.pbUrls.appListApiView}${pk}/`;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<IApp>(cfg);
		return resp.data;
	}

	@bind
	async appList(): Promise<Array<IApp>> {
		const url = window.pbUrls.appListApiView;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<Array<IApp>>(cfg);
		return resp.data;
	}

	@bind
	async batchPaymentInfo(data: {ids: Array<number | string>; transactionId?: number | string | null;}): Promise<IPaymentInfo> {
		const url = window.pbUrls.batchPaymentApiView;
		const cfg: Cfg = {
			method: 'GET',
			params: {
				ids: data.ids.join(','),
				transaction_id: data.transactionId === null
					? undefined
					: data.transactionId,
			},
			url,
		};
		const resp = await this.request<IPaymentInfo>(cfg);
		return resp.data;
	}

	@bind
	async cancelProjectPaid(pk: string): Promise<void> {
		const url = `${window.pbUrls.projectListApiView}${pk}/`;
		const cfg: Cfg = {
			method: 'DELETE',
			url,
		};
		const resp = await this.request<void>(cfg);
		return resp.data;
	}

	@bind
	async cfgDetail(): Promise<ICfg> {
		const url = window.pbUrls.cfgDetailApiView;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<ICfg>(cfg);
		return resp.data;
	}

	@bind
	async clientOrganizationDetail(pk: number | string): Promise<IOrganization> {
		const url = `${window.pbUrls.clientOrganizationListApiView}${pk}/`;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<IOrganization>(cfg);
		return resp.data;
	}

	@bind
	async clientOrganizationList(params?: Partial<IPageParams>): Promise<Array<IOrganization>> {
		// FIXME: Haaaaaaaaack
		return (await this.clientOrganizationPage(params)).objects;
	}

	@bind
	async clientOrganizationPage(params?: Partial<IPageParams>): Promise<PaginatedOrganizationPage> {
		const url = window.pbUrls.clientOrganizationListApiView;
		const cfg: Cfg = {
			method: 'GET',
			url,
			params,
		};
		const resp = await this.request<PaginatedOrganizationPage>(cfg);
		return resp.data;
	}

	@bind
	async clientUserDetail(pk: string): Promise<IExpandedUser> {
		const url = `${window.pbUrls.clientUserListApiView}${pk}/`;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<IExpandedUser>(cfg);
		return resp.data;
	}

	@bind
	async clientUserList(params?: Partial<IPageParams>): Promise<PaginatedUserPage> {
		const url = window.pbUrls.clientUserListApiView;
		const cfg: Cfg = {
			method: 'GET',
			url,
			params,
		};
		const resp = await this.request<PaginatedUserPage>(cfg);
		return resp.data;
	}

	@bind
	async createAlternateEmail(data: Omit<IAlternateEmailAddress, 'id'>): Promise<IAlternateEmailAddress> {
		const url = window.pbUrls.alternateEmailListApiView;
		const cfg: Cfg = {
			data,
			method: 'POST',
			url,
		};
		const resp = await this.request<IAlternateEmailAddress>(cfg);
		return resp.data;
	}

	@bind
	async createBatchPayment(data: IPaymentInfo): Promise<IPaymentInfo> {
		const url = window.pbUrls.batchPaymentApiView;
		const cfg: Cfg = {
			method: 'POST',
			data,
			url,
		};
		try {
			const resp = await this.request<IPaymentInfo>(cfg);
			return resp.data;
		} catch (err) {
			if (isHttpError<IPaymentInfo>(err) && err.response) {
				return err.response.data;
			}
			throw err;
		}
	}

	@bind
	async createCfgTableFilter(data: ICfgFilter): Promise<ICfgFilter> {
		const url = `${window.pbUrls.cfgDetailApiView}tables/${data.tableId}/filters/`;
		const cfg: Cfg = {
			method: 'POST',
			data,
			url,
		};
		const resp = await this.request<ICfgFilter>(cfg);
		return resp.data;
	}

	@bind
	async createClientOrganization(data: IOrganization): Promise<IOrganization> {
		const url = window.pbUrls.clientOrganizationListApiView;
		const cfg: Cfg = {
			method: 'POST',
			url,
			data,
		};
		const resp = await this.request<IOrganization>(cfg);
		return resp.data;
	}

	@bind
	async createClientUser(data: INewUser): Promise<IExpandedUser> {
		const url = window.pbUrls.clientUserListApiView;
		const cfg: Cfg = {
			method: 'POST',
			url,
			data,
		};
		const resp = await this.request<IExpandedUser>(cfg);
		return resp.data;
	}

	@bind
	async createMessageTemplate(data: IMessageTemplateUpdate): Promise<IMessageTemplate> {
		const url = window.pbUrls.messageTemplateListApiView;
		const cfg: Cfg = {
			method: 'POST',
			data,
			url,
		};
		const resp = await this.request<IMessageTemplate>(cfg);
		return resp.data;
	}

	@bind
	async createPhoneNumber(data: Omit<IPhoneNumber, 'id'>): Promise<IPhoneNumber> {
		const url = window.pbUrls.phoneNumberListApiView;
		const cfg: Cfg = {
			data,
			method: 'POST',
			url,
		};
		const resp = await this.request<IPhoneNumber>(cfg);
		return resp.data;
	}

	@bind
	async createPriceGroup(data: IPriceGroup): Promise<IPriceGroup> {
		const url = window.pbUrls.priceGroupListApiView;
		const cfg: Cfg = {
			method: 'POST',
			data,
			url,
		};
		const resp = await this.request<IPriceGroup>(cfg);
		return resp.data;
	}

	@bind
	async createProduct(data: IProductUpdate): Promise<IProduct> {
		const url = window.pbUrls.productListApiView;
		const cfg: Cfg = {
			data,
			method: 'POST',
			url,
		};
		const resp = await this.request<IProduct>(cfg);
		return resp.data;
	}

	@bind
	async createProject(data: INewProject): Promise<IProject> {
		const url = window.pbUrls.projectListApiView;
		const cfg: Cfg = {
			method: 'POST',
			data,
			url,
		};
		const resp = await this.request<IProject>(cfg);
		return resp.data;
	}

	@bind
	async createProjectAssignment(slug: string, data: IProjectAssignment): Promise<IProjectAssignment> {
		const url = `${window.pbUrls.projectListApiView}${slug}/assignments/`;
		const cfg: Cfg = {
			method: 'POST',
			data,
			url,
		};
		const resp = await this.request<IProjectAssignment>(cfg);
		return resp.data;
	}

	@bind
	async createProjectInvoiceLine(pk: string, data: IInvoiceLine): Promise<IInvoiceLine> {
		const url = `${window.pbUrls.projectListApiView}${pk}/invoice/lines/`;
		const cfg: Cfg = {
			method: 'POST',
			data,
			url,
		};
		const resp = await this.request<IInvoiceLine>(cfg);
		return resp.data;
	}

	@bind
	async createProjectPayment(slug: string, data: IPaymentInfo): Promise<IPaymentInfo> {
		const url = `${window.pbUrls.projectListApiView}${slug}/payment/`;
		const cfg: Cfg = {
			method: 'POST',
			data,
			url,
		};
		try {
			const resp = await this.request<IPaymentInfo>(cfg);
			return resp.data;
		} catch (err) {
			if (isHttpError<IPaymentInfo>(err) && err.response) {
				return err.response.data;
			}
			throw err;
		}
	}

	@bind
	async createServiceArea(data: IServiceArea): Promise<IServiceArea> {
		const url = window.pbUrls.serviceAreaListApiView;
		const cfg: Cfg = {
			method: 'POST',
			url,
			data,
		};
		const resp = await this.request<IServiceArea>(cfg);
		return resp.data;
	}

	@bind
	async createSnippet(data: ISnippet): Promise<ISnippet> {
		const url = window.pbUrls.snippetListApiView;
		const cfg: Cfg = {
			method: 'POST',
			data,
			url,
		};
		const resp = await this.request<ISnippet>(cfg);
		return resp.data;
	}

	@bind
	async createTeamMember(data: INewUser): Promise<IExpandedUser> {
		const url = window.pbUrls.teamMemberListApiView;
		const cfg: Cfg = {
			method: 'POST',
			url,
			data,
		};
		const resp = await this.request<IExpandedUser>(cfg);
		return resp.data;
	}

	@bind
	async createUserClass(data: IUserClass): Promise<IUserClass> {
		const url = window.pbUrls.userClassListApiView;
		const cfg: Cfg = {
			method: 'POST',
			url,
			data,
		};
		const resp = await this.request<IUserClass>(cfg);
		return resp.data;
	}

	@bind
	async deleteAlternateEmail(pk: number | string): Promise<void> {
		const url = `${window.pbUrls.alternateEmailListApiView}${pk}/`;
		const cfg: Cfg = {
			method: 'DELETE',
			url,
		};
		const resp = await this.request<void>(cfg);
		return resp.data;
	}

	@bind
	async deleteCfgTableFilter(tablePk: string, filterPk: number | string): Promise<void> {
		const url = `${window.pbUrls.cfgDetailApiView}tables/${tablePk}/filters/${filterPk}/`;
		const cfg: Cfg = {
			method: 'DELETE',
			url,
		};
		const resp = await this.request<void>(cfg);
		return resp.data;
	}

	@bind
	async deleteClientUser(pk: string): Promise<void> {
		const url = `${window.pbUrls.clientUserListApiView}${pk}/`;
		const cfg: Cfg = {
			method: 'DELETE',
			url,
		};
		const resp = await this.request<void>(cfg);
		return resp.data;
	}

	@bind
	async deleteMessageTemplate(pk: number | string): Promise<void> {
		const url = `${window.pbUrls.messageTemplateListApiView}${pk}/`;
		const cfg: Cfg = {
			method: 'DELETE',
			url,
		};
		const resp = await this.request<void>(cfg);
		return resp.data;
	}

	@bind
	async deleteOrganization(pk: number | string): Promise<void> {
		const url = `${window.pbUrls.clientOrganizationListApiView}${pk}/`;
		const cfg: Cfg = {
			method: 'DELETE',
			url,
		};
		const resp = await this.request<void>(cfg);
		return resp.data;
	}

	@bind
	async deletePaymentMethod(pk: string): Promise<void> {
		const url = `${window.pbUrls.paymentMethodListApiView}${pk}/`;
		const cfg: Cfg = {
			method: 'DELETE',
			url,
		};
		const resp = await this.request<void>(cfg);
		return resp.data;
	}

	@bind
	async deletePhoneNumber(pk: number | string): Promise<void> {
		const url = `${window.pbUrls.phoneNumberListApiView}${pk}/`;
		const cfg: Cfg = {
			method: 'DELETE',
			url,
		};
		const resp = await this.request<void>(cfg);
		return resp.data;
	}

	@bind
	async deletePriceGroup(pk: number | string): Promise<void> {
		const url = `${window.pbUrls.priceGroupListApiView}${pk}/`;
		const cfg: Cfg = {
			method: 'DELETE',
			url,
		};
		const resp = await this.request<void>(cfg);
		return resp.data;
	}

	@bind
	async deleteProduct(productId: number | string): Promise<void> {
		const url = `${window.pbUrls.productListApiView}${productId}/`;
		const cfg: Cfg = {
			method: 'DELETE',
			url,
		};
		const resp = await this.request<void>(cfg);
		return resp.data;
	}

	@bind
	async deleteProjectAssignment(slug: string, roleId: string, userId: string): Promise<void> {
		const url = `${window.pbUrls.projectListApiView}${slug}/assignments/${roleId}/users/${userId}/`;
		const cfg: Cfg = {
			method: 'DELETE',
			url,
		};
		const resp = await this.request<void>(cfg);
		return resp.data;
	}

	@bind
	async deleteProjectInvoiceLine(pk: string, linePk: number | string): Promise<void> {
		const url = `${window.pbUrls.projectListApiView}${pk}/invoice/lines/${linePk}/`;
		const cfg: Cfg = {
			method: 'DELETE',
			url,
		};
		const resp = await this.request<void>(cfg);
		return resp.data;
	}

	@bind
	async deleteServiceArea(pk: number | string): Promise<void> {
		const url = `${window.pbUrls.serviceAreaListApiView}${pk}/`;
		const cfg: Cfg = {
			method: 'DELETE',
			url,
		};
		const resp = await this.request<void>(cfg);
		return resp.data;
	}

	@bind
	async deleteSnippet(pk: number | string): Promise<void> {
		const url = `${window.pbUrls.snippetListApiView}${pk}/`;
		const cfg: Cfg = {
			method: 'DELETE',
			url,
		};
		const resp = await this.request<void>(cfg);
		return resp.data;
	}

	@bind
	async deleteUserClass(pk: number | string): Promise<void> {
		const url = `${window.pbUrls.userClassListApiView}${pk}/`;
		const cfg: Cfg = {
			method: 'DELETE',
			url,
		};
		const resp = await this.request<void>(cfg);
		return resp.data;
	}

	@bind
	async durationDetail(params: Pick<IPageParams, 'pid'> & Partial<Pick<IPageParams, 'aid' | 'cid' | 'sz'>>): Promise<number> {
		const url = window.pbUrls.durationDetailApiView;
		const cfg: Cfg = {
			method: 'GET',
			url,
			params,
		};
		const resp = await this.request<number>(cfg);
		return resp.data;
	}

	@bind
	async eventDetail(pk: number): Promise<IEvent> {
		const url = `${window.pbUrls.eventListApiView}${pk}/`;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<IEvent>(cfg);
		return resp.data;
	}

	@bind
	async orgIntegDetail(integName: string): Promise<IOrgInteg> {
		const url = `${window.pbUrls.orgIntegListApiView}${integName}/`;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<IOrgInteg>(cfg);
		return resp.data;
	}

	@bind
	async orgIntegList(): Promise<Array<IOrgInteg>> {
		const url = window.pbUrls.orgIntegListApiView;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<Array<IOrgInteg>>(cfg);
		return resp.data;
	}

	@bind
	async locationDetail(pk: number | string): Promise<ILocation> {
		const url = `${window.pbUrls.locationListApiView}${pk}/`;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<ILocation>(cfg);
		return resp.data;
	}

	@bind
	async markProjectPaid(pk: string): Promise<boolean> {
		const url = `${window.pbUrls.projectListApiView}${pk}/paid/`;
		const cfg: Cfg = {
			method: 'POST',
			url,
		};
		const resp = await this.request<boolean>(cfg);
		return resp.data;
	}

	@bind
	async createMediaObject(slug: string, invoiceLinePk: number | string, data: IMediaObject): Promise<IMediaObject> {
		const url = `${window.pbUrls.projectListApiView}${slug}/invoice/lines/${invoiceLinePk}/media/`;
		const cfg: Cfg = {
			method: 'POST',
			data,
			url,
		};
		const resp = await this.request<IMediaObject>(cfg);
		return resp.data;
	}

	@bind
	async deleteMediaObject(slug: string, invoiceLinePk: number | string, mediaObjectPk: number | string): Promise<void> {
		const url = `${window.pbUrls.projectListApiView}${slug}/invoice/lines/${invoiceLinePk}/media/${mediaObjectPk}/`;
		const cfg: Cfg = {
			method: 'DELETE',
			url,
		};
		const resp = await this.request<void>(cfg);
		return resp.data;
	}

	@bind
	async mediaObjectDetail(slug: string, invoiceLinePk: number | string, mediaObjectPk: number | string): Promise<IMediaObject> {
		const url = `${window.pbUrls.projectListApiView}${slug}/invoice/lines/${invoiceLinePk}/media/${mediaObjectPk}/`;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<IMediaObject>(cfg);
		return resp.data;
	}

	@bind
	async updateMediaObject(slug: string, invoiceLinePk: number | string, data: IMediaObject): Promise<IMediaObject> {
		const url = `${window.pbUrls.projectListApiView}${slug}/invoice/lines/${invoiceLinePk}/media/${data.id}/`;
		const cfg: Cfg = {
			method: 'PUT',
			data,
			url,
		};
		const resp = await this.request<IMediaObject>(cfg);
		return resp.data;
	}

	@bind
	async mediaObjectList(slug: string, invoiceLinePk: number | string): Promise<Array<IMediaObject>> {
		const url = `${window.pbUrls.projectListApiView}${slug}/invoice/lines/${invoiceLinePk}/media/`;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<Array<IMediaObject>>(cfg);
		return resp.data;
	}

	@bind
	async mediumList(): Promise<Array<IMedium>> {
		const url = window.pbUrls.mediumListApiView;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<Array<IMedium>>(cfg);
		return resp.data;
	}

	@bind
	async messageTemplateDetail(pk: number | string): Promise<IMessageTemplate> {
		const url = `${window.pbUrls.messageTemplateListApiView}${pk}/`;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<IMessageTemplate>(cfg);
		return resp.data;
	}

	@bind
	async operatingHours(): Promise<Array<IOperatingHours>> {
		const url = window.pbUrls.operatingHoursListApiView;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<Array<IOperatingHours>>(cfg);
		return resp.data;
	}

	@bind
	async orgAppWidgetDetail(pk: number): Promise<IOrgAppWidget> {
		const url = `${window.pbUrls.orgAppWidgetList}${pk}/`;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<IOrgAppWidget>(cfg);
		return resp.data;
	}

	@bind
	async orgAppWidgetList(params?: Partial<IPageParams>): Promise<Array<IOrgAppWidget>> {
		const url = window.pbUrls.orgAppWidgetList;
		const cfg: Cfg = {
			method: 'GET',
			params,
			url,
		};
		const resp = await this.request<Array<IOrgAppWidget>>(cfg);
		return resp.data;
	}

	@bind
	async orgAppWidgetOptionDetail(pk: number, optionPk: number): Promise<IOrgAppWidgetOption> {
		const url = `${window.pbUrls.orgAppWidgetList}${pk}/options/${optionPk}/`;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<IOrgAppWidgetOption>(cfg);
		return resp.data;
	}

	@bind
	async orgAppWidgetOptionList(pk: number): Promise<Array<IOrgAppWidgetOption>> {
		const url = `${window.pbUrls.orgAppWidgetList}${pk}/options/`;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<Array<IOrgAppWidgetOption>>(cfg);
		return resp.data;
	}

	@bind
	async paymentMethodList(): Promise<Array<IPaymentMethod>> {
		const url = window.pbUrls.paymentMethodListApiView;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<Array<IPaymentMethod>>(cfg);
		return resp.data;
	}

	@bind
	async phoneNumberDetail(pk: number | string): Promise<IPhoneNumber> {
		const url = `${window.pbUrls.phoneNumberListApiView}${pk}/`;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<IPhoneNumber>(cfg);
		return resp.data;
	}

	@bind
	async phoneNumberList(): Promise<Array<IPhoneNumber>> {
		const url = window.pbUrls.phoneNumberListApiView;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<Array<IPhoneNumber>>(cfg);
		return resp.data;
	}

	@bind
	async policyList(params?: Partial<IPageParams>): Promise<Array<IPolicy>> {
		const url = window.pbUrls.policyListApiView;
		const cfg: Cfg = {
			method: 'GET',
			params,
			url,
		};
		const resp = await this.request<Array<IPolicy>>(cfg);
		return resp.data;
	}

	prepareRequest(cfg: Cfg): IRequestConfig {
		const headers: any = cfg.headers
			? {...STANDARD_HEADERS, ...cfg.headers}
			: {...STANDARD_HEADERS};
		let rv: IRequestConfig = {
			data: cfg.data,
			headers: headers,
			method: cfg.method,
			onUploadProgress: cfg.onUploadProgress,
			params: cfg.params,
			responseType: cfg.responseType
				? cfg.responseType
				: 'json',
			timeout: cfg.timeout,
			url: cfg.url,
			wellKnown: Symbol.for(REQUEST_CONFIG_WELL_KNOWN_REGISTRY_KEY),
		};
		switch (cfg.method) {
			case 'DELETE':
			case 'PATCH':
			case 'POST':
			case 'PUT': {
				rv = {
					...rv,
					...XSRF_CFG,
				};
				break;
			}
		}
		return rv;
	}

	@bind
	async priceGroupDetail(pk: number | string): Promise<IPriceGroup> {
		const url = `${window.pbUrls.priceGroupListApiView}${pk}/`;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<IPriceGroup>(cfg);
		return resp.data;
	}

	@bind
	async priceGroupList(): Promise<Array<IPriceGroup>> {
		const url = window.pbUrls.priceGroupListApiView;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<Array<IPriceGroup>>(cfg);
		return resp.data;
	}

	@bind
	async productPriceGroupProductPriceList(pk: number | string): Promise<Array<IPriceGroupProductPrice>> {
		const url = `${window.pbUrls.productListApiView}${pk}/pricing/`;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<Array<IPriceGroupProductPrice>>(cfg);
		return resp.data;
	}

	@bind
	async productAddOnList(): Promise<Array<IProduct>> {
		const url = window.pbUrls.productAddOnListApiView;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<Array<IProduct>>(cfg);
		return resp.data;
	}

	@bind
	async productCatalog(params?: Partial<IPageParams>): Promise<IProductCatalog> {
		const url = window.pbUrls.productCatalogApiView;
		const cfg: Cfg = {
			method: 'GET',
			url,
			params,
		};
		const resp = await this.request<IProductCatalog>(cfg);
		return resp.data;
	}

	@bind
	async productDetail(pk: number | string): Promise<IProduct> {
		const url = `${window.pbUrls.productListApiView}${pk}/`;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<IProduct>(cfg);
		return resp.data;
	}

	@bind
	async productList(params?: Partial<IPageParams>): Promise<PaginatedProductPage> {
		const url = window.pbUrls.productListApiView;
		const cfg: Cfg = {
			method: 'GET',
			url,
			params,
		};
		const resp = await this.request<PaginatedProductPage>(cfg);
		return resp.data;
	}

	@bind
	async productChildList(parentPk: number | string): Promise<Array<IProduct>> {
		const url = `${window.pbUrls.productListApiView}${parentPk}/children/`;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<Array<IProduct>>(cfg);
		return resp.data;
	}

	@bind
	async productOptionList(productId: number | string): Promise<Array<IProductOption>> {
		const url = `${window.pbUrls.productListApiView}${productId}/options/`;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<Array<IProductOption>>(cfg);
		return resp.data;
	}

	@bind
	async productUserList(productId: number | string): Promise<Array<IProductUser>> {
		const url = `${window.pbUrls.productListApiView}${productId}/users/`;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<Array<IProductUser>>(cfg);
		return resp.data;
	}

	@bind
	async profileDetail(): Promise<IProfile> {
		const url = window.pbUrls.accountDetailView;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<IProfile>(cfg);
		return resp.data;
	}

	@bind
	async projectAssignmentRoleList(slug: string): Promise<Array<IProjectAssignmentRole>> {
		const url = `${window.pbUrls.projectListApiView}${slug}/assignments/`;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<Array<IProjectAssignmentRole>>(cfg);
		return resp.data;
	}

	@bind
	async projectDetail(pk: string): Promise<IProject> {
		const url = `${window.pbUrls.projectListApiView}${pk}/`;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<IProject>(cfg);
		return resp.data;
	}

	@bind
	async projectDetailAnnotated(pk: string): Promise<IAnnotatedProject> {
		const url = `${window.pbUrls.projectListApiView}${pk}/annotated/`;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<IAnnotatedProject>(cfg);
		return resp.data;
	}

	@bind
	async projectEventDetail(pk: string): Promise<IProjectEvent> {
		const url = `${window.pbUrls.projectListApiView}${pk}/event/`;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<IProjectEvent>(cfg);
		return resp.data;
	}

	@bind
	async projectInvoiceDetail(pk: string): Promise<IInvoice> {
		const url = `${window.pbUrls.projectListApiView}${pk}/invoice/`;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<IInvoice>(cfg);
		return resp.data;
	}

	@bind
	async projectInvoiceLineList(pk: string): Promise<Array<IInvoiceLine>> {
		const url = `${window.pbUrls.projectListApiView}${pk}/invoice/lines/`;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<Array<IInvoiceLine>>(cfg);
		return resp.data;
	}

	@bind
	async projectList(params?: Partial<IPageParams>): Promise<PaginatedProjectPage> {
		const url = window.pbUrls.projectListApiView;
		const cfg: Cfg = {
			method: 'GET',
			params,
			url,
		};
		const resp = await this.request<PaginatedProjectPage>(cfg);
		return resp.data;
	}

	@bind
	async projectLocationDetail(pk: string): Promise<ILocation | null> {
		const url = `${window.pbUrls.projectListApiView}${pk}/location/`;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<ILocation | null>(cfg);
		return resp.data;
	}

	@bind
	async projectMessageInfoList(slug: string): Promise<Array<IProjectMessageInfo>> {
		const url = `${window.pbUrls.projectListApiView}${slug}/message-info/`;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<Array<IProjectMessageInfo>>(cfg);
		return resp.data;
	}

	@bind
	async projectPaymentDetail(slug: string): Promise<IPaymentInfo> {
		const url = `${window.pbUrls.projectListApiView}${slug}/payment/`;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<IPaymentInfo>(cfg);
		return resp.data;
	}

	@bind
	async projectStatusList(slug: string): Promise<Array<IProjectStatus>> {
		const url = `${window.pbUrls.projectListApiView}${slug}/status/`;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<Array<IProjectStatus>>(cfg);
		return resp.data;
	}

	@bind
	async projectTransactionList(slug: string): Promise<Array<ITransaction>> {
		const url = `${window.pbUrls.projectListApiView}${slug}/transactions/`;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<Array<ITransaction>>(cfg);
		return resp.data;
	}

	@bind
	async quickBooksItemList(): Promise<Array<IQuickBooksItem>> {
		const url = `${window.pbUrls.orgIntegListApiView}${KnownIntegration.QuickBooks}/objects/`;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<Array<IQuickBooksItem>>(cfg);
		return resp.data;
	}

	@bind
	async request<T>(cfg: Cfg): Promise<IResponse<T>> {
		const prepped = this.prepareRequest(cfg);
		const rv = await axios.request<T>(prepped);
		rv.config = prepped;
		return <IResponse<T>>rv;
	}

	@bind
	async serviceAreaDetail(pk: number | string): Promise<IServiceArea> {
		const url = `${window.pbUrls.serviceAreaListApiView}${pk}/`;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<IServiceArea>(cfg);
		return resp.data;
	}

	@bind
	async serviceAreaList(params?: Partial<IPageParams>): Promise<PaginatedServiceAreaPage> {
		const url = window.pbUrls.serviceAreaListApiView;
		const cfg: Cfg = {
			method: 'GET',
			url,
			params,
		};
		const resp = await this.request<PaginatedServiceAreaPage>(cfg);
		return resp.data;
	}

	@bind
	async serviceAreaWxDayList(svcPk: number): Promise<Array<IWxDay>> {
		const url = window.pbUrls.wxListApiView;
		const cfg: Cfg = {
			method: 'GET',
			url,
			params: {svc: svcPk},
		};
		const resp = await this.request<Array<IWxDay>>(cfg);
		return resp.data;
	}

	@bind
	async snippetDetail(pk: number | string): Promise<ISnippet> {
		const url = `${window.pbUrls.snippetListApiView}${pk}/`;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<ISnippet>(cfg);
		return resp.data;
	}

	@bind
	async snippetList(): Promise<Array<ISnippet>> {
		const url = window.pbUrls.snippetListApiView;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<Array<ISnippet>>(cfg);
		return resp.data;
	}

	@bind
	async summaryDetail(params: Pick<IPageParams, 'dt' | 'pid'> & Partial<Pick<IPageParams, 'aid' | 'cid' | 'sz'>>): Promise<datetime.ISummaryData> {
		const url = window.pbUrls.summaryDetailApiView;
		const cfg: Cfg = {
			method: 'GET',
			url,
			params,
		};
		const resp = await this.request<datetime.IApiSummaryData>(cfg);
		return {
			...resp.data,
			startDatetime: datetime.datetime.fromisoformat(resp.data.startDatetime),
			endDatetime: datetime.datetime.fromisoformat(resp.data.endDatetime),
		};
	}

	@bind
	async teamMemberDetail(pk: string): Promise<IExpandedUser> {
		const url = `${window.pbUrls.teamMemberListApiView}${pk}/`;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<IExpandedUser>(cfg);
		return resp.data;
	}

	@bind
	async teamMemberList(params?: Partial<IPageParams>): Promise<PaginatedUserPage> {
		const url = window.pbUrls.teamMemberListApiView;
		const cfg: Cfg = {
			method: 'GET',
			url,
			params,
		};
		const resp = await this.request<PaginatedUserPage>(cfg);
		return resp.data;
	}

	@bind
	async teamMemberProductUserList(pk: string): Promise<Array<IProductUser>> {
		const url = `${window.pbUrls.teamMemberListApiView}${pk}/products/`;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<Array<IProductUser>>(cfg);
		return resp.data;
	}

	@bind
	async tosAgreement(tosPk: number | string): Promise<Array<ITermsOfService>> {
		const url = `${window.pbUrls.policyListApiView}${tosPk}/`;
		const cfg: Cfg = {
			method: 'POST',
			url,
		};
		const resp = await this.request<Array<ITermsOfService>>(cfg);
		return resp.data;
	}

	@bind
	async unmarkProjectPaid(pk: string): Promise<void> {
		const url = `${window.pbUrls.projectListApiView}${pk}/paid/`;
		const cfg: Cfg = {
			method: 'DELETE',
			url,
		};
		const resp = await this.request<void>(cfg);
		return resp.data;
	}

	@bind
	async updateAlternateEmail(data: IAlternateEmailAddress): Promise<IAlternateEmailAddress> {
		const url = `${window.pbUrls.alternateEmailListApiView}${data.id}/`;
		const cfg: Cfg = {
			method: 'PUT',
			url,
			data,
		};
		const resp = await this.request<IAlternateEmailAddress>(cfg);
		return resp.data;
	}

	@bind
	async updateBatchPayment(data: IPaymentInfo): Promise<IPaymentInfo> {
		const url = window.pbUrls.batchPaymentApiView;
		const cfg: Cfg = {
			method: 'PUT',
			data,
			url,
		};
		const resp = await this.request<IPaymentInfo>(cfg);
		return resp.data;
	}

	@bind
	async updateCfgTableColumns(tableId: string, data: Array<ICol>): Promise<Array<ICfgTableColumn>> {
		const url = `${window.pbUrls.cfgDetailApiView}tables/${tableId}/columns/`;
		const cfg: Cfg = {
			method: 'PUT',
			data,
			url,
		};
		const resp = await this.request<Array<ICfgTableColumn>>(cfg);
		return resp.data;
	}

	@bind
	async updateCfgTableFilter(data: ICfgFilter): Promise<ICfgFilter> {
		const url = `${window.pbUrls.cfgDetailApiView}tables/${data.tableId}/filters/${data.id}/`;
		const cfg: Cfg = {
			method: 'PUT',
			data,
			url,
		};
		const resp = await this.request<ICfgFilter>(cfg);
		return resp.data;
	}

	@bind
	async updateClientOrganization(data: IOrganization): Promise<IOrganization> {
		const url = `${window.pbUrls.clientOrganizationListApiView}${data.id}/`;
		const cfg: Cfg = {
			method: 'PUT',
			url,
			data,
		};
		const resp = await this.request<IOrganization>(cfg);
		return resp.data;
	}

	@bind
	async updateClientUser(data: IExpandedUser): Promise<IExpandedUser> {
		const url = `${window.pbUrls.clientUserListApiView}${data.email}/`;
		const cfg: Cfg = {
			method: 'PUT',
			url,
			data,
		};
		const resp = await this.request<IExpandedUser>(cfg);
		return resp.data;
	}

	@bind
	async updateEvent(data: IEvent): Promise<IEvent> {
		const url = `${window.pbUrls.eventListApiView}${data.id}/`;
		const cfg: Cfg = {
			method: 'PUT',
			data,
			url,
		};
		const resp = await this.request<IEvent>(cfg);
		return resp.data;
	}

	@bind
	async updateOrgAppWidget(data: IOrgAppWidget): Promise<IOrgAppWidget> {
		const url = `${window.pbUrls.orgAppWidgetList}${data.id}/`;
		const cfg: Cfg = {
			method: 'PUT',
			data,
			url,
		};
		const resp = await this.request<IOrgAppWidget>(cfg);
		return resp.data;
	}

	@bind
	async updatePhoneNumber(data: IPhoneNumber): Promise<IPhoneNumber> {
		const url = `${window.pbUrls.phoneNumberListApiView}${data.id}/`;
		const cfg: Cfg = {
			method: 'PUT',
			url,
			data,
		};
		const resp = await this.request<IPhoneNumber>(cfg);
		return resp.data;
	}

	@bind
	async updatePriceGroup(data: IPriceGroup): Promise<IPriceGroup> {
		const url = `${window.pbUrls.priceGroupListApiView}${data.id}/`;
		const cfg: Cfg = {
			method: 'PUT',
			url,
			data,
		};
		const resp = await this.request<IPriceGroup>(cfg);
		return resp.data;
	}

	@bind
	async updateProduct(data: IProductUpdate): Promise<IProduct> {
		const url = `${window.pbUrls.productListApiView}${data.id}/`;
		const cfg: Cfg = {
			method: 'PUT',
			url,
			data,
		};
		const resp = await this.request<IProduct>(cfg);
		return resp.data;
	}

	@bind
	async updateProductUsersForProduct(productId: number | string, data: Array<IProductUser>): Promise<Array<IProductUser>> {
		const url = `${window.pbUrls.productListApiView}${productId}/users/`;
		const cfg: Cfg = {
			method: 'PUT',
			data,
			url,
		};
		const resp = await this.request<Array<IProductUser>>(cfg);
		return resp.data;
	}

	@bind
	async updateProductUsersForTeamMember(teamMemberPk: string, data: Array<IProductUser>): Promise<Array<IProductUser>> {
		const url = `${window.pbUrls.teamMemberListApiView}${teamMemberPk}/products/`;
		const cfg: Cfg = {
			method: 'PUT',
			data,
			url,
		};
		const resp = await this.request<Array<IProductUser>>(cfg);
		return resp.data;
	}

	@bind
	async updateProfile(data: IProfile): Promise<IProfile> {
		const url = window.pbUrls.accountDetailView;
		const cfg: Cfg = {
			data,
			method: 'PUT',
			url,
		};
		const resp = await this.request<IProfile>(cfg);
		return resp.data;
	}

	@bind
	async updateProject(slug: string, data: Partial<IProjectUpdate>): Promise<IProject> {
		const url = `${window.pbUrls.projectListApiView}${slug}/`;
		const cfg: Cfg = {
			method: 'PUT',
			data,
			url,
		};
		const resp = await this.request<IProject>(cfg);
		return resp.data;
	}

	@bind
	async updateProjectEvent(pk: string, data: IProjectEvent): Promise<IProjectEvent> {
		const url = `${window.pbUrls.projectListApiView}${pk}/event/`;
		const cfg: Cfg = {
			method: 'PUT',
			data,
			url,
		};
		const resp = await this.request<IProjectEvent>(cfg);
		return resp.data;
	}

	@bind
	async updateProjectInvoiceLine(slug: string, data: IInvoiceLine): Promise<IInvoiceLine> {
		const url = `${window.pbUrls.projectListApiView}${slug}/invoice/lines/${data.id}/`;
		const cfg: Cfg = {
			method: 'PUT',
			data,
			url,
		};
		const resp = await this.request<IInvoiceLine>(cfg);
		return resp.data;
	}

	@bind
	async updateProjectPayment(slug: string, data: IPaymentInfo): Promise<IPaymentInfo> {
		const url = `${window.pbUrls.projectListApiView}${slug}/payment/`;
		const cfg: Cfg = {
			method: 'PUT',
			data,
			url,
		};
		const resp = await this.request<IPaymentInfo>(cfg);
		return resp.data;
	}

	@bind
	async updateServiceArea(data: IServiceArea): Promise<IServiceArea> {
		const url = `${window.pbUrls.serviceAreaListApiView}${data.id}/`;
		const cfg: Cfg = {
			method: 'PUT',
			url,
			data,
		};
		const resp = await this.request<IServiceArea>(cfg);
		return resp.data;
	}

	@bind
	async updateSnippet(data: ISnippet): Promise<ISnippet> {
		const url = `${window.pbUrls.snippetListApiView}${data.id}/`;
		const cfg: Cfg = {
			method: 'PUT',
			data,
			url,
		};
		const resp = await this.request<ISnippet>(cfg);
		return resp.data;
	}

	@bind
	async updateTeamMember(data: IExpandedUser): Promise<IExpandedUser> {
		const url = `${window.pbUrls.teamMemberListApiView}${data.email}/`;
		const cfg: Cfg = {
			method: 'PUT',
			url,
			data,
		};
		const resp = await this.request<IExpandedUser>(cfg);
		return resp.data;
	}

	@bind
	async updateMessageTemplate(pk: number | string, data: IMessageTemplateUpdate): Promise<IMessageTemplate> {
		const url = `${window.pbUrls.messageTemplateListApiView}${pk}/`;
		const cfg: Cfg = {
			method: 'PUT',
			data,
			url,
		};
		const resp = await this.request<IMessageTemplate>(cfg);
		return resp.data;
	}

	@bind
	async updateUserClass(data: IUserClass): Promise<IUserClass> {
		const url = `${window.pbUrls.userClassListApiView}${data.id}/`;
		const cfg: Cfg = {
			method: 'PUT',
			data,
			url,
		};
		const resp = await this.request<IUserClass>(cfg);
		return resp.data;
	}

	@bind
	async uploadFile(data: File): Promise<void> {
		const url = window.pbUrls.tmpFileUpload;
		const cfg: Cfg = {
			headers: {
				'Content-Disposition': `attachment; filename="${data.name}"`,
				'Content-Type': data.type,
			},
			method: 'POST',
			data,
			url,
		};
		const resp = await this.request<void>(cfg);
		return resp.data;
	}

	@bind
	async userClassDetail(pk: number | string): Promise<IUserClass> {
		const url = `${window.pbUrls.userClassListApiView}${pk}/`;
		const cfg: Cfg = {
			method: 'GET',
			url,
		};
		const resp = await this.request<IUserClass>(cfg);
		return resp.data;
	}

	@bind
	async userClassList(params?: Partial<IPageParams>): Promise<PaginatedUserClassPage> {
		const url = window.pbUrls.userClassListApiView;
		const cfg: Cfg = {
			method: 'GET',
			url,
			params,
		};
		const resp = await this.request<PaginatedUserClassPage>(cfg);
		return resp.data;
	}
}

function isHttpError<T>(obj: any): obj is HttpError<T> {
	try {
		return (obj instanceof Error)
			&& ('config' in obj)
			&& ('wellKnown' in (<any>obj).config)
			&& ((<any>obj).config.wellKnown === Symbol.for(REQUEST_CONFIG_WELL_KNOWN_REGISTRY_KEY));
	} catch {
	}
	return false;
}

export const api = new HttpApi();

