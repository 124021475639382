import React from 'react';

import {bind} from '../../util';
import {
	Button,
	Cta,
	IListProps,
	List,
	ListItem,
	ListItemPrimaryText,
	ListItemSecondaryText,
} from '../../components';

export interface IAlternateEmailListProps extends Partial<IListProps> {
	objGetter: () => Promise<Array<IAlternateEmailAddress>>;
	onCreateBtnClick: () => any;
	onObjectClick: (pk: number) => any;
	onRefresh?: () => any;
	pendingRefresh?: boolean;
}

interface IAlternateEmailListState {
	objs: Array<IAlternateEmailAddress>;
}

export class AlternateEmailList extends React.Component<IAlternateEmailListProps, IAlternateEmailListState> {
	constructor(props: IAlternateEmailListProps) {
		super(props);
		this.state = {
			objs: [],
		};
	}

	async componentDidMount(): Promise<void> {
		await this.refreshPage(
			() => {
				if (this.state.objs.length === 0) {
					this.props.onCreateBtnClick();
				}
			},
		);
	}

	async componentDidUpdate(prevProps: Readonly<IAlternateEmailListProps>, prevState: Readonly<IAlternateEmailListState>) {
		const {
			onRefresh,
			pendingRefresh,
		} = this.props;
		if ((prevProps.pendingRefresh !== pendingRefresh) && pendingRefresh) {
			await this.refreshPage();
			if (onRefresh) {
				onRefresh();
			}
		}
	}

	@bind
	private itemClicked(index: number) {
		const {onObjectClick} = this.props;
		const {objs} = this.state;
		if (((index >= 0) && (index < objs.length))) {
			onObjectClick(objs[index].id);
		}
	}

	@bind
	private async refreshPage(cb?: () => any): Promise<void> {
		const {objGetter} = this.props;
		this.setState(
			{
				objs: await objGetter(),
			},
			cb,
		);
	}

	render() {
		const {
			onCreateBtnClick,
			objGetter,
			onObjectClick,
			pendingRefresh,
			onRefresh,
			...rest
		} = this.props;
		const {objs} = this.state;
		const empty = objs.length === 0;
		return (
			<>
				{
					empty
						? null
						: (
							<List isTwoLine {...rest}>
								{
									objs.map((obj, idx) => {
										return (
											<ListItem isClickable key={idx} onClick={this.itemClicked.bind(this, idx)}>
												<ListItemPrimaryText>
													{obj.address}
												</ListItemPrimaryText>
												<ListItemSecondaryText>
													{obj.label}
												</ListItemSecondaryText>
											</ListItem>
										);
									})
								}
							</List>
						)
				}
				<Cta>
					<Button onClick={onCreateBtnClick}>Add alternate email</Button>
				</Cta>
			</>
		);
	}
}
