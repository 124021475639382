import React from 'react';
import {
	Dialog,
	LinearProgress,
	Prompt,
} from '../../../components';

import {api} from '../../../httpapi';
import {
	CheckState,
	StandardButton,
} from '../../../constants';
import {PaymentFormView} from '../paymentform';
import {
	bind,
	staticPaymentInfo,
} from '../../../util';

export interface IProjectPaymentViewProps extends React.HTMLAttributes<HTMLFormElement> {
	onComplete: (success: boolean) => any;
	slug: string;
}

interface IProjectPaymentViewState {
	amount: string;
	initializing: boolean;
	paymentInfo: IPaymentInfo;
	paymentMethods: Array<IPaymentMethod>;
	promptDialogIsOpen: boolean;
	showProgress: boolean;
}

interface IPromptInfo {
	body?: string;
	header: string;
	iconName: string;
	subHeader?: string;
}

export class ProjectPaymentView extends React.Component<IProjectPaymentViewProps, IProjectPaymentViewState> {
	private readonly pmtMethInputContainerRef: React.RefObject<HTMLDivElement>;

	constructor(props: IProjectPaymentViewProps) {
		super(props);
		this.pmtMethInputContainerRef = React.createRef();
		this.state = {
			amount: '',
			initializing: true,
			paymentInfo: staticPaymentInfo(),
			paymentMethods: [],
			promptDialogIsOpen: false,
			showProgress: true,
		};
	}

	@bind
	private amountChanged(amount: string) {
		this.setState({
			amount,
		});
	}

	@bind
	private closePromptDialog() {
		this.setState({
			promptDialogIsOpen: false,
		});
	}

	async componentDidMount() {
		const {slug} = this.props;
		const paymentInfo = await api.projectPaymentDetail(slug);
		this.setState(
			{
				amount: paymentInfo.amount || '',
				paymentInfo,
				paymentMethods: await api.paymentMethodList(),
			},
			() => this.setState({initializing: false}),
		);
	}

	@bind
	private doneProcessing(nfo: IPaymentInfo) {
		const {onComplete} = this.props;
		this.setState(
			{
				paymentInfo: nfo,
			},
			async () => {
				if (nfo.status !== 'succeeded') {
					this.openPromptDialog();
				}
				onComplete(nfo.status === 'succeeded');
			},
		);
	}

	@bind
	private formInitialized() {
		this.setState({
			showProgress: false,
		});
	}

	@bind
	private openPromptDialog() {
		this.setState({
			promptDialogIsOpen: true,
		});
	}

	@bind
	private async processPayment(nfo: IPaymentInfo): Promise<IPaymentInfo> {
		return await api.createProjectPayment(
			this.props.slug,
			nfo,
		);
	}

	@bind
	private promptInfo(): IPromptInfo {
		const {paymentInfo} = this.state;
		let body: string;
		let header: string;
		let iconName: string;
		let subHeader: string | undefined = undefined;
		if (paymentInfo.status === 'succeeded') {
			iconName = 'done';
			header = 'All set!';
			body = 'Thank you for your payment!';
		} else {
			iconName = 'error';
			header = 'An error occurred';
			subHeader = 'There was a problem processing your transaction.';
			body = paymentInfo.errorMessage;
		}
		return {
			iconName,
			header,
			subHeader,
			body,
		};
	}

	render() {
		const {
			onComplete,
			slug,
			...rest
		} = this.props;
		const {
			amount,
			initializing,
			paymentInfo,
			paymentMethods,
			promptDialogIsOpen,
			showProgress,
		} = this.state;
		if (initializing) {
			return null;
		}
		const nfo = this.promptInfo();
		return (
			<>
				<LinearProgress isOpen={initializing || showProgress}/>
				{
					initializing
						? null
						: (
							<>
								<PaymentFormView
									onDoneProcessing={this.doneProcessing}
									onInitialized={this.formInitialized}
									processPayment={this.processPayment}
									updatePaymentInfo={this.updatePaymentInfo}
									amount={amount}
									onAmountChange={this.amountChanged}
									onSavePaymentInfoCheckStateChange={this.savePaymentInfoChanged}
									paymentInfo={paymentInfo}
									paymentMethods={paymentMethods}
									{...rest}
								/>
								<Dialog buttons={StandardButton.Close} isOpen={promptDialogIsOpen} onFinished={this.closePromptDialog}>
									<Prompt iconName={nfo.iconName} header={nfo.header} subHeader={nfo.subHeader}>
										{nfo.body}
									</Prompt>
								</Dialog>
							</>
						)
				}
			</>
		);
	}

	@bind
	private savePaymentInfoChanged(state: CheckState) {
		this.setState({
			paymentInfo: {
				...this.state.paymentInfo,
				savePaymentMethod: state === CheckState.Checked,
			},
		});
	}

	@bind
	private async updatePaymentInfo(nfo: IPaymentInfo): Promise<IPaymentInfo> {
		return await api.updateProjectPayment(
			this.props.slug,
			nfo,
		);
	}
}
