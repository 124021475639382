import React from 'react';

import {api} from '../../httpapi';
import {staticPriceGroup} from '../../util';
import {
	CCard,
	CCardAvatar,
	CCardDescribe,
	CCardHeader,
	CCardIntro,
	CCardName,
	CCardNameTitle,
	CCardToolbar,
	CCardToolbarAction,
	ICCardProps,
} from '../../components';

export interface IPriceGroupReadViewProps extends Partial<ICCardProps> {
	onToolbarAction?: (axn: CCardToolbarAction, coords: PlainCoords) => any;
	pk: number;
}

interface IPriceGroupReadViewState {
	obj: IPriceGroup;
}

export class PriceGroupReadView extends React.Component<IPriceGroupReadViewProps, IPriceGroupReadViewState> {
	constructor(props: IPriceGroupReadViewProps) {
		super(props);
		this.state = {
			obj: staticPriceGroup(),
		};
	}

	async componentDidMount() {
		const {pk} = this.props;
		this.setState({
			obj: await api.priceGroupDetail(pk),
		});
	}

	render() {
		const {onToolbarAction} = this.props;
		const {obj} = this.state;
		const av = (obj.icon.length > 0)
			? obj.icon
			: 'attach_money';
		return (
			<CCard>
				<CCardHeader>
					<CCardIntro>
						<CCardAvatar>
							{av}
						</CCardAvatar>
						<CCardDescribe>
							<CCardNameTitle>
								<CCardName>
									{obj.name}
								</CCardName>
							</CCardNameTitle>
						</CCardDescribe>
					</CCardIntro>
					<CCardToolbar
						onAction={onToolbarAction}
					/>
				</CCardHeader>
			</CCard>
		);
	}
}
