import React from 'react';
import {makeClassName} from '../util';

export type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;

export interface IHeadingProps extends React.HTMLAttributes<any> {
	level: HeadingLevel;
}

export function Heading(props: IHeadingProps) {
	const {
		children,
		className,
		level,
		...rest
	} = props;
	const tag = `h${level}`;
	const clsName = makeClassName(
		`mdc-typography--headline${level}`,
		className,
	);
	return React.createElement(
		tag,
		{
			className: clsName,
			...rest,
		},
		children,
	);
}
