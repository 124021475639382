import React from 'react';

import {CheckState} from '../constants';
import {FloatingLabel} from './floatinglabel';
import {HelperText} from './helpertext';
import {Icon} from './icon';
import {LineRipple} from './lineripple';
import {NotchedOutline} from './notchedoutline';
import {
	bind,
	makeClassName,
} from '../util';

const ALWAYS_FLOAT_TYPES = new Set<string>([
	'color',
	'date',
	'datetime-local',
	'month',
	'range',
	'time',
	'week',
]);
type InputProps = Pick<React.InputHTMLAttributes<HTMLInputElement>,
	| 'autoFocus'
	| 'form'
	| 'formAction'
	| 'formEncType'
	| 'formMethod'
	| 'formNoValidate'
	| 'formTarget'
	| 'max'
	| 'maxLength'
	| 'min'
	| 'minLength'
	| 'name'
	| 'pattern'
	| 'placeholder'
	| 'step'
	| 'type'
	| 'value'>
type ExtProps = Omit<React.HTMLAttributes<any>, 'onChange'> & InputProps;

export interface ITextInputProps extends ExtProps {
	checkState?: CheckState;
	cols?: number;
	helpText?: string;
	inputElementClassName?: string;
	inputElementId?: string;
	isChecked?: boolean;
	isDense?: boolean;
	isDisabled?: boolean;
	isMinimal?: boolean;
	isOutlined?: boolean;
	isReadOnly?: boolean;
	isRequired?: boolean;
	isResizable?: boolean;
	isTextArea?: boolean;
	isTransparent?: boolean;
	label?: string;
	leadingIcon?: string;
	onChange?: (value: string, inputElName?: string) => any;
	onLeadingIconClick?: (event: React.MouseEvent) => any;
	onTrailingIconClick?: (event: React.MouseEvent) => any;
	persistHelpText?: boolean;
	prefix?: string;
	rows?: number;
	suffix?: string;
	trailingIcon?: string;
}

export interface ITextInputState {
	labelIsFloating: boolean;
}

export class TextInput extends React.Component<ITextInputProps, ITextInputState> {
	private readonly floatingLabelRef: React.RefObject<FloatingLabel>;
	private readonly helperTextRef: React.RefObject<HelperText>;
	private readonly inputElementRef: React.RefObject<HTMLInputElement>;
	private readonly lineRippleRef: React.RefObject<LineRipple>;
	private readonly notchedOutlineRef: React.RefObject<NotchedOutline>;
	private readonly rootRef: React.RefObject<HTMLLabelElement>;
	private readonly textAreaElementRef: React.RefObject<HTMLTextAreaElement>;

	constructor(props: ITextInputProps) {
		super(props);
		this.floatingLabelRef = React.createRef();
		this.helperTextRef = React.createRef();
		this.inputElementRef = React.createRef();
		this.lineRippleRef = React.createRef();
		this.notchedOutlineRef = React.createRef();
		this.rootRef = React.createRef();
		this.textAreaElementRef = React.createRef();
		this.state = {
			labelIsFloating: false,
		};
	}

	private changed() {
		const float = this.labelShouldFloat();
		this.setState(
			{
				labelIsFloating: float,
			},
			() => {
				this.setFloatingLabelFloating(float);
				this.setFloatingLabelShaking(this.labelShouldShake());
				setTimeout(
					() => this.setOutlineOpen(float),
					1,
				);
			},
		);
	}

	private closeOutline() {
		const outline = this.notchedOutlineRef.current;
		if (outline) {
			outline.close();
		}
	}

	componentDidMount() {
		if (this.hasFocus()) {
			this.gotFocus();
		} else if (this.labelShouldFloat()) {
			this.setState(
				{
					labelIsFloating: true,
				},
				() => {
					this.setFloatingLabelFloating(true);
					this.setFloatingLabelShaking(this.labelShouldShake());
					setTimeout(
						() => this.setOutlineOpen(true),
						1,
					);
				},
			);
		}
	}

	componentDidUpdate(prevProps: Readonly<ITextInputProps>) {
		const {value} = this.props;
		if (prevProps.value !== value) {
			this.changed();
		}
	}

	@bind
	focus(): void {
		const curr = this.inputElement();
		if (curr !== null) {
			curr.focus();
		}
	}

	@bind
	private gotFocus() {
		this.setLineRippleActive(true);
		this.changed();
	}

	@bind
	private hasFocus(): boolean {
		const curr = this.rootRef.current;
		if (curr) {
			return (curr === document.activeElement) || (curr.contains(document.activeElement));
		}
		return false;
	}

	private hasValue(): boolean {
		const {value} = this.props;
		if (value === undefined) {
			return false;
		}
		if ((typeof value === 'string') || Array.isArray(value)) {
			return value.length > 0;
		}
		return true;
	}

	private inputElement(): HTMLInputElement | HTMLTextAreaElement | null {
		const {isTextArea} = this.props;
		return isTextArea
			? this.textAreaElementRef.current
			: this.inputElementRef.current;
	}

	@bind
	private inputGotFocused() {
		this.gotFocus();
	}

	@bind
	private inputInput(event: React.FormEvent<HTMLInputElement>) {
		const {onChange} = this.props;
		if (onChange) {
			const el = event.target as HTMLInputElement;
			onChange(el.value, el.name);
		}
	}

	@bind
	private inputInvalid() {
		this.setFloatingLabelShaking(true);
	}

	@bind
	private inputLostFocus() {
		this.lostFocus();
	}

	private isValid(): boolean {
		const curr = this.inputElement();
		if (curr) {
			return curr.validity.valid;
		}
		return true;
	}

	private labelShouldAlwaysFloat(): boolean {
		const {type} = this.props;
		return (type !== undefined) && ALWAYS_FLOAT_TYPES.has(type);
	}

	private labelShouldFloat(): boolean {
		return this.labelShouldAlwaysFloat()
			|| this.hasFocus()
			|| this.hasValue()
			|| !this.isValid();
	}

	private labelShouldShake(): boolean {
		return !this.hasFocus() && !this.isValid() && this.hasValue();
	}

	private lostFocus() {
		this.syncValid(this.isValid());
		this.setLineRippleActive(false);
		this.changed();
	}

	private openOutline() {
		const outline = this.notchedOutlineRef.current;
		const lbl = this.floatingLabelRef.current;
		if (outline && lbl) {
			// const labelWidth = lbl.getWidth() * 0.75;
			const labelWidth = lbl.width();
			outline.open(labelWidth);
		}
	}

	render() {
		const {
			autoFocus,
			checkState,
			className,
			cols,
			form,
			formAction,
			formEncType,
			formMethod,
			formNoValidate,
			formTarget,
			helpText,
			inputElementClassName,
			inputElementId,
			isChecked,
			isDense,
			isDisabled,
			isMinimal,
			isOutlined,
			isReadOnly,
			isRequired,
			isResizable,
			isTextArea,
			isTransparent,
			label,
			leadingIcon,
			max,
			maxLength,
			min,
			minLength,
			name,
			onChange,
			onLeadingIconClick,
			onTrailingIconClick,
			pattern,
			persistHelpText,
			placeholder,
			prefix,
			rows,
			step,
			suffix,
			trailingIcon,
			type,
			value,
			...rest
		} = this.props;
		const {labelIsFloating} = this.state;
		const clsName = makeClassName(
			'mdc-text-field',
			'pb-text-input',
			isTextArea
				? 'mdc-text-field--textarea pb-text-input--textarea'
				: undefined,
			isMinimal
				? 'pb-text-input--minimal'
				: undefined,
			isOutlined
				? 'mdc-text-field--outlined'
				: 'mdc-text-field--filled',
			isMinimal
				? isOutlined
					? 'pb-text-input--minimal--outlined'
					: 'pb-text-input--minimal--filled'
				: undefined,
			isDense
				? 'pb-text-field--filled-dense'
				: undefined,
			isTransparent
				? 'pb-text-field--filled-transparent'
				: undefined,
			isDisabled
				? 'mdc-text-field--disabled'
				: undefined,
			(label === undefined)
				? 'mdc-text-field--no-label'
				: undefined,
			(leadingIcon === undefined)
				? undefined
				: 'mdc-text-field--with-leading-icon',
			(trailingIcon === undefined)
				? undefined
				: 'mdc-text-field--with-trailing-icon',
			this.hasFocus()
				? 'mdc-text-field--focused'
				: undefined,
			labelIsFloating
				? 'mdc-text-field--label-floating'
				: undefined,
			(isDisabled || this.isValid())
				? undefined
				: 'mdc-text-field--invalid',
			className,
		);
		const inpClsName = makeClassName(
			'mdc-text-field__input',
			inputElementClassName,
		);
		const leadingIconClickHandler = (onLeadingIconClick && (leadingIcon !== undefined))
			? onLeadingIconClick
			: undefined;
		const leadingIconTabIdx = ((leadingIcon === undefined) || (onLeadingIconClick === undefined))
			? undefined
			: isDisabled
				? -1
				: 0;
		const leadingIconRole = (isDisabled || (leadingIcon === undefined) || (onLeadingIconClick === undefined))
			? undefined
			: 'button';
		const iconLeading = (isTextArea || (leadingIcon === undefined))
			? null
			: (
				<Icon className="mdc-text-field__icon mdc-text-field__icon--leading" onClick={leadingIconClickHandler} role={leadingIconRole} tabIndex={leadingIconTabIdx}>
					{leadingIcon}
				</Icon>
			);
		const trailingIconClickHandler = (onTrailingIconClick && (trailingIcon !== undefined))
			? onTrailingIconClick
			: undefined;
		const trailingIconTabIdx = ((trailingIcon === undefined) || (onTrailingIconClick === undefined))
			? undefined
			: isDisabled
				? -1
				: 0;
		const trailingIconRole = (isDisabled || (trailingIcon === undefined) || (onTrailingIconClick === undefined))
			? undefined
			: 'button';
		const iconTrailing = (isTextArea || (trailingIcon === undefined))
			? null
			: (
				<Icon className="mdc-text-field__icon mdc-text-field__icon--trailing" onClick={trailingIconClickHandler} role={trailingIconRole} tabIndex={trailingIconTabIdx}>
					{trailingIcon}
				</Icon>
			);
		const lbl = (label === undefined)
			? null
			: (
				<FloatingLabel isRequired={isRequired} ref={this.floatingLabelRef}>{label}</FloatingLabel>
			);
		const pFix = (isTextArea || (prefix === undefined))
			? null
			: (
				<span className="mdc-text-field__affix mdc-text-field__affix--prefix">
					{prefix}
				</span>
			);
		const sFix = (isTextArea || (suffix === undefined))
			? null
			: (
				<span className="mdc-text-field__affix mdc-text-field__affix--suffix">
					{suffix}
				</span>
			);
		const commonProps = {
			autoFocus,
			className: inpClsName,
			disabled: isDisabled,
			form,
			id: inputElementId,
			maxLength,
			minLength,
			name,
			onBlur: this.inputLostFocus,
			onFocus: this.inputGotFocused,
			onInput: this.inputInput,
			onInvalid: this.inputInvalid,
			onMouseDown: this.setPointerXOffset,
			onTouchStart: this.setPointerXOffset,
			placeholder,
			readOnly: isReadOnly,
			required: isRequired,
			value,
		};
		const inputElementProps = {
			formAction,
			formEncType,
			formMethod,
			formNoValidate,
			formTarget,
			max,
			min,
			pattern,
			ref: this.inputElementRef,
			step,
			type,
		};
		const textAreaElementProps = {
			cols,
			ref: this.textAreaElementRef,
			rows,
		};
		const inpComp = React.createElement(
			isTextArea
				? 'textarea'
				: 'input',
			{
				...commonProps,
				...(isTextArea
					? textAreaElementProps
					: inputElementProps),
			},
		);
		return (
			<>
				<label className={clsName} ref={this.rootRef} {...rest}>
					{
						isOutlined
							? (
								<NotchedOutline ref={this.notchedOutlineRef}>{lbl}</NotchedOutline>
							)
							: (
								<>
									<span className="mdc-text-field__ripple"/>
									{lbl}
								</>
							)
					}
					{iconLeading}
					{pFix}
					{
						(isTextArea && isResizable)
							? (
								<div className="mdc-text-field__resizer">
									{inpComp}
								</div>
							)
							: inpComp
					}
					{sFix}
					{iconTrailing}
					{
						isOutlined
							? null
							: (
								<LineRipple
									ref={this.lineRippleRef}
								/>
							)
					}
				</label>
				{
					(helpText === undefined)
						? null
						: (
							<HelperText isPersistent={persistHelpText} ref={this.helperTextRef}>
								{helpText}
							</HelperText>
						)
				}
			</>
		);
	}

	@bind
	private setFloatingLabelFloating(isFloating: boolean) {
		const curr = this.floatingLabelRef.current;
		if (curr) {
			curr.setFloating(isFloating);
		}
	}

	@bind
	private setFloatingLabelShaking(isShaking: boolean) {
		const curr = this.floatingLabelRef.current;
		if (curr) {
			curr.setShaking(isShaking);
		}
	}

	private setHelperTextValid(valid: boolean) {
		const curr = this.helperTextRef.current;
		if (curr) {
			curr.setValid(valid);
		}
	}

	private setLineRippleActive(active: boolean) {
		const lineRip = this.lineRippleRef.current;
		if (lineRip) {
			lineRip.setActive(active);
		}
	}

	private setLineRippleCenter(x: number) {
		const curr = this.lineRippleRef.current;
		if (curr) {
			curr.setRippleCenter(x);
		}
	}

	private setOutlineOpen(open: boolean) {
		if (open) {
			this.openOutline();
		} else {
			this.closeOutline();
		}
	}

	@bind
	private setPointerXOffset(event: React.MouseEvent | React.TouchEvent) {
		const {
			isDisabled,
			isOutlined,
		} = this.props;
		if (isDisabled || isOutlined) {
			return;
		}
		let tgt: EventTarget | null = null;
		let x: number = Number.NaN;
		if (event.type === 'mousedown') {
			const evt = event as React.MouseEvent;
			tgt = evt.target;
			x = evt.clientX;
		} else {
			const evt = event as React.TouchEvent;
			if (evt.touches.length > 0) {
				tgt = evt.touches[0].target;
				x = evt.touches[0].clientX;
			}
		}
		if (!Number.isNaN(x) && tgt && (tgt instanceof Element)) {
			const rect = tgt.getBoundingClientRect();
			this.setLineRippleCenter(x - rect.left);
		}
	}

	@bind
	private syncValid(isValid: boolean) {
		this.setHelperTextValid(isValid);
	}
}
