import React from 'react';

import {bind} from '../../../util';
import {TextInput} from '../../../components';

export interface IIconInputProps {
	onChange: (value: string) => any;
	value: string;
}

export class IconInput extends React.Component<IIconInputProps, {}> {
	@bind
	changeEvent(value: string): void {
		const {onChange} = this.props;
		onChange && onChange(value);
	}

	render() {
		const {value} = this.props;
		return <TextInput
			className="width--100-percent"
			helpText="e.g.: photo_camera"
			label="Icon"
			onChange={this.changeEvent}
			type="text"
			value={value}
		/>;
	}
}
