import React from 'react';
import {ActionNavigationEvent, ActionInteractionEvent, MDCChipActionFocusBehavior, MDCChipActionInteractionEventDetail, MDCChipActionNavigationEventDetail, MDCChipActionType, MDCChipAdapter, MDCChipAnimation, MDCChipAnimationEventDetail, MDCChipAttributes, MDCChipCssClasses, MDCChipEvents, MDCChipFoundation, MDCChipInteractionEventDetail, MDCChipNavigationEventDetail,} from '@material/chips';

import {Action, ActionEvent, IGoogleSucksAtSoftwareActionEventData} from './action';
import {bind, makeClassName} from '../../util';

export enum ChipEvent {
	Animation,
	Interaction,
	Navigation,
}

export interface IGoogleSucksAtSoftwareChipEventData {
	actionId: string;
	addedAnnouncement: string;
	animation: MDCChipAnimation;
	chipId: string;
	clickCoords: PlainCoords;
	isComplete: boolean;
	isRTL: boolean;
	isSelectable: boolean;
	isSelected: boolean;
	key: string;
	removedAnnouncement: string;
	shouldRemove: boolean;
	source: MDCChipActionType;
}

export interface IChip {
	isDeletable: boolean;
	isDisabled: boolean;
	isFilter: boolean;
	isInput: boolean;
	isSelectable: boolean;
	isSelected: boolean;
	leadingIconName: string;
	onlyPrimaryActionIsNavigable: boolean;
}

export interface IChipProps extends React.HTMLAttributes<any>, IChip {
	onEvent: (evt: ChipEvent, data: Partial<IGoogleSucksAtSoftwareChipEventData>) => any;
}

interface IChipState {
	classNames: Set<string>;
	lastClickCoords: PlainCoords;
}

export class Chip extends React.Component<Partial<IChipProps>, IChipState> {
	private ctrl: MDCChipFoundation;
	private readonly primaryAxnRef: React.RefObject<Action>;
	private readonly rootRef: React.RefObject<HTMLDivElement>;
	private readonly trailingAxnRef: React.RefObject<Action>;

	constructor(props: Partial<IChipProps>) {
		super(props);
		this.ctrl = new MDCChipFoundation();
		this.primaryAxnRef = React.createRef();
		this.rootRef = React.createRef();
		this.trailingAxnRef = React.createRef();
		this.state = {
			classNames: new Set(['mdc-evolution-chip', 'pb-chip', 'pb-project-search-chip']),
			lastClickCoords: {
				x: 0,
				y: 0,
			},
		};
	}

	@bind
	private actionEvent(axn: ActionEvent, data: Partial<IGoogleSucksAtSoftwareActionEventData>) {
		if (axn === ActionEvent.Interaction) {
			if ((data.actionId === undefined) || (data.source === undefined) || (data.trigger === undefined)) {
				console.log('Chip::actionEvent: Missing data for event type. Got:', axn, data);
				return;
			}
			const d: MDCChipActionInteractionEventDetail = {
				actionID: data.actionId,
				source: data.source,
				trigger: data.trigger,
			};
			this.setState(
				{
					lastClickCoords: (data.clickCoords === undefined)
						? this.state.lastClickCoords
						: data.clickCoords,
				},
				() => this.ctrl.handleActionInteraction({detail: d} as ActionInteractionEvent)
			);
		} else {
			if ((data.source === undefined) || (data.key === undefined)) {
				console.log('Chip::actionEvent: Missing data for event type. Got:', axn, data);
				return;
			}
			const d: MDCChipActionNavigationEventDetail = {
				source: data.source,
				key: data.key,
			};
			this.setState(
				{
					lastClickCoords: (data.clickCoords === undefined)
						? this.state.lastClickCoords
						: data.clickCoords,
				},
				() => this.ctrl.handleActionNavigation({detail: d} as ActionNavigationEvent)
			);
		}
	}

	private actionObjForActionType(typ: MDCChipActionType): Action | null {
		for (const obj of this.actionObjs()) {
			if (obj.actionType() === typ) {
				return obj;
			}
		}
		return null;
	}

	actionObjs(): Array<Action> {
		const rv: Array<Action> = [];
		if (this.primaryAxnRef.current) {
			rv.push(this.primaryAxnRef.current);
		}
		if (this.trailingAxnRef.current) {
			rv.push(this.trailingAxnRef.current);
		}
		return rv;
	}

	actionsTypes(): Array<MDCChipActionType> {
		const rv: Array<MDCChipActionType> = [];
		for (const obj of this.actionObjs()) {
			rv.push(obj.actionType());
		}
		return rv;
	}

	componentDidMount() {
		this.ctrl.destroy();
		this.ctrl = new MDCChipFoundation(
			this.mdcAdapter(),
		);
		this.ctrl.init();
	}

	componentWillUnmount() {
		this.ctrl.destroy();
	}

	id(): string {
		const root = this.rootRef.current;
		if (root) {
			return root.id;
		}
		return '';
	}

	isActionFocusable(actionType: MDCChipActionType): boolean {
		const obj = this.actionObjForActionType(actionType);
		return obj
			? obj.isFocusable()
			: false;
	}

	isActionSelectable(actionType: MDCChipActionType): boolean {
		const obj = this.actionObjForActionType(actionType);
		return obj
			? obj.isSelectable()
			: false;
	}

	isActionSelected(actionType: MDCChipActionType): boolean {
		return this.ctrl.isActionSelected(actionType);
	}

	private mdcAdapter(): MDCChipAdapter {
		return {
			addClass: (className: MDCChipCssClasses) => {
				const {classNames} = this.state;
				classNames.add(className);
				this.setState({
					classNames: new Set(classNames),
				});
			},
			emitEvent: <D extends object>(eventName: MDCChipEvents, eventDetail: D) => {
				const {onEvent} = this.props;
				if (!onEvent) {
					return;
				}
				let cEvt: ChipEvent;
				switch (eventName) {
					case MDCChipEvents.ANIMATION: {
						cEvt = ChipEvent.Animation;
						break;
					}
					case MDCChipEvents.INTERACTION: {
						cEvt = ChipEvent.Interaction;
						break;
					}
					case MDCChipEvents.NAVIGATION: {
						cEvt = ChipEvent.Navigation;
						break;
					}
				}
				const data: Partial<IGoogleSucksAtSoftwareChipEventData> = {};
				if (objIsAnimEvtDetail(eventDetail)) {
					data.chipId = eventDetail.chipID;
					data.animation = eventDetail.animation;
					data.isComplete = eventDetail.isComplete;
					data.addedAnnouncement = eventDetail.addedAnnouncement;
					data.removedAnnouncement = eventDetail.removedAnnouncement;
				} else if (objIsInterEvtDetail(eventDetail)) {
					data.actionId = eventDetail.actionID;
					data.chipId = eventDetail.chipID;
					data.source = eventDetail.source;
					data.shouldRemove = eventDetail.shouldRemove;
					data.isSelectable = eventDetail.isSelectable;
					data.isSelected = eventDetail.isSelected;
				} else if (objIsNavEvtDetail(eventDetail)) {
					data.chipId = eventDetail.chipID;
					data.source = eventDetail.source;
					data.key = eventDetail.key;
					data.isRTL = eventDetail.isRTL;
				} else {
					console.log('Chip::emitEvent: Got invalid detail:', eventDetail);
					return;
				}
				const {lastClickCoords} = this.state;
				data.clickCoords = {
					...lastClickCoords,
				};
				onEvent(cEvt, data);
			},
			getActions: () => {
				return this.actionsTypes();
			},
			getAttribute: (attrName: MDCChipAttributes) => {
				const root = this.rootRef.current;
				if (root) {
					return root.getAttribute(attrName);
				}
				return null;
			},
			getElementID: () => {
				return this.id();
			},
			getOffsetWidth: () => {
				const root = this.rootRef.current;
				if (root) {
					return root.offsetWidth;
				}
				return 0;
			},
			hasClass: (className: MDCChipCssClasses) => {
				return this.state.classNames.has(className);
			},
			isActionDisabled: () => {
				return this.props.isDisabled === true;
			},
			isActionFocusable: (action: MDCChipActionType) => {
				let axn: Action | null;
				switch (action) {
					case MDCChipActionType.PRIMARY: {
						axn = this.primaryAxnRef.current;
						break;
					}
					case MDCChipActionType.TRAILING: {
						axn = this.trailingAxnRef.current;
						break;
					}
					default: {
						return false;
					}
				}
				if (axn) {
					return axn.isFocusable();
				}
				return false;
			},
			isActionSelectable: () => {
				return this.props.isSelectable === true;
			},
			isActionSelected: (action: MDCChipActionType) => {
				return (action === MDCChipActionType.PRIMARY) && (this.props.isSelected === true);
			},
			isRTL: () => {
				return false;
			},
			removeClass: (className: MDCChipCssClasses) => {
				const {classNames} = this.state;
				if (classNames.delete(className)) {
					this.setState({
						classNames: new Set(classNames),
					});
				}
			},
			setActionDisabled: (action: MDCChipActionType, isDisabled: boolean) => {
				console.log('Chip::setActionDisabled: NOT IMPLEMENTED. Got:', action, isDisabled);
			},
			setActionFocus: (action: MDCChipActionType, behavior: MDCChipActionFocusBehavior) => {
				let axn: Action | null = null;
				switch (action) {
					case MDCChipActionType.PRIMARY: {
						axn = this.primaryAxnRef.current;
						break;
					}
					case MDCChipActionType.TRAILING: {
						axn = this.trailingAxnRef.current;
						break;
					}
				}
				if (axn) {
					return axn.setFocus(behavior);
				}
			},
			setActionSelected: (action: MDCChipActionType, isSelected: boolean) => {
				console.log('Chip::setActionSelected: NOT IMPLEMENTED. Got:', action, isSelected);
			},
			setStyleProperty: (property: string, value: string) => {
				const root = this.rootRef.current;
				if (root) {
					root.style.setProperty(property, value);
				}
			},
		};
	}

	render() {
		const {
			children,
			className,
			isDeletable,
			isDisabled,
			isFilter,
			isInput,
			isSelected,
			isSelectable,
			leadingIconName,
			onEvent,
			onlyPrimaryActionIsNavigable,
			tabIndex,
			...rest
		} = this.props;
		const {
			classNames,
		} = this.state;
		const renderTrailingAxn = isInput;
		const clsName = makeClassName(
			...classNames,
			isSelectable
				? 'mdc-evolution-chip--selectable'
				: undefined,
			isSelected
				? 'mdc-evolution-chip--selected pb-chip--selected'
				: undefined,
			isDisabled
				? 'mdc-evolution-chip--disabled'
				: undefined,
			(isFilter || isSelectable || leadingIconName)
				? 'mdc-evolution-chip--with-primary-graphic'
				: undefined,
			leadingIconName
				? 'mdc-evolution-chip--with-primary-icon'
				: undefined,
			renderTrailingAxn
				? 'mdc-evolution-chip--with-trailing-action'
				: undefined,
			isFilter
				? 'mdc-evolution-chip--filter'
				: undefined,
			className,
		);
		const role = isFilter
			? 'presentation'
			: 'row';
		const ariaSel = isSelected
			? 'true'
			: (isSelected === undefined)
				? undefined
				: 'false';
		return (
			<span className={clsName} ref={this.rootRef} role={role} {...rest}>
				<span aria-selected={ariaSel} className="mdc-evolution-chip__cell mdc-evolution-chip__cell--primary" role="gridcell">
					<Action
						isDeletable={isDeletable}
						isDisabled={isDisabled}
						isFilter={isFilter}
						isSelectable={isSelectable}
						isSelected={isSelected}
						isPrimary
						leadingIconName={leadingIconName}
						onEvent={this.actionEvent}
						ref={this.primaryAxnRef}>{children}</Action>
				</span>
				{
					renderTrailingAxn
						? (
							onlyPrimaryActionIsNavigable
								? (
									<Action
										isDeletable
										isDisabled={isDisabled}
										isFilter={isFilter}
										isSelectable={isSelectable}
										isSelected={isSelected}
										leadingIconName={leadingIconName}
										onEvent={this.actionEvent}
										ref={this.trailingAxnRef}
										isPrimary={false}
										tabIndex={-1}
									/>
								)
								: (
									<span className="mdc-evolution-chip__cell mdc-evolution-chip__cell--trailing" role="gridcell">
										<Action
											isDeletable={isDeletable}
											isDisabled={isDisabled}
											isFilter={isFilter}
											isSelectable={isSelectable}
											isSelected={isSelected}
											leadingIconName={leadingIconName}
											onEvent={this.actionEvent}
											ref={this.trailingAxnRef}
											isPrimary={false}
										/>
									</span>
								)
						)
						: null
				}
			</span>
		);
	}

	setActionFocus(actionType: MDCChipActionType, focus: MDCChipActionFocusBehavior): void {
		this.ctrl.setActionFocus(actionType, focus);
	}

	startAnimation(animation: MDCChipAnimation): void {
		this.ctrl.startAnimation(animation);
	}
}

function objIsAnimEvtDetail(obj: any): obj is MDCChipAnimationEventDetail {
	try {
		return 'animation' in obj;
	} catch {
		return false;
	}
}

function objIsInterEvtDetail(obj: any): obj is MDCChipInteractionEventDetail {
	try {
		return 'actionID' in obj;
	} catch {
		return false;
	}
}

function objIsNavEvtDetail(obj: any): obj is MDCChipNavigationEventDetail {
	try {
		return 'key' in obj;
	} catch {
		return false;
	}
}
