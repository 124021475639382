import React from 'react';

import {GridLayout, GridLayoutCell, IGridLayoutProps} from '../../../components';
import {makeClassName} from '../../../util';

export interface IProductFormBaseProps extends IGridLayoutProps {
	addOnComp: React.ReactNode;
	assignableUserComp: React.ReactNode;
	availableComp: React.ReactNode;
	colorComp: React.ReactNode;
	descriptionComp: React.ReactNode;
	durationComp: React.ReactNode;
	exclusiveToComp: React.ReactNode;
	iconComp: React.ReactNode;
	mutexComp: React.ReactNode;
	nameComp: React.ReactNode;
	optionComp: React.ReactNode;
	postDurationComp: React.ReactNode;
	pricingComp: React.ReactNode;
	quickBooksComp: React.ReactNode;
	sizeComp: React.ReactNode;
	summaryComp: React.ReactNode;
	toolBarComp: React.ReactNode;
	variantComp: React.ReactNode;
}

export function ProductFormBase(props: Partial<IProductFormBaseProps>) {
	const {
		addOnComp,
		assignableUserComp,
		availableComp,
		children,
		className,
		colorComp,
		descriptionComp,
		durationComp,
		exclusiveToComp,
		iconComp,
		mutexComp,
		nameComp,
		optionComp,
		postDurationComp,
		pricingComp,
		quickBooksComp,
		sizeComp,
		summaryComp,
		toolBarComp,
		variantComp,
		...rest
	} = props;
	const tbComp = isNil(toolBarComp)
		? null
		: (
			<GridLayoutCell span={12}>
				{toolBarComp}
			</GridLayoutCell>
		);
	const availComp = isNil(availableComp)
		? null
		: (
			<GridLayoutCell span={12}>
				{availableComp}
			</GridLayoutCell>
		);
	const mutex = isNil(mutexComp)
		? null
		: (
			<GridLayoutCell span={12}>
				{mutexComp}
			</GridLayoutCell>
		);
	const exclComp = isNil(exclusiveToComp)
		? null
		: (
			<GridLayoutCell span={12}>
				{exclusiveToComp}
			</GridLayoutCell>
		);
	const nmComp = isNil(nameComp)
		? null
		: (
			<GridLayoutCell span={12}>
				{nameComp}
			</GridLayoutCell>
		);
	const smComp = isNil(summaryComp)
		? null
		: (
			<GridLayoutCell span={12}>
				{summaryComp}
			</GridLayoutCell>
		);
	const descComp = isNil(descriptionComp)
		? null
		: (
			<GridLayoutCell span={12}>
				{descriptionComp}
			</GridLayoutCell>
		);
	const drComp = isNil(durationComp)
		? null
		: (
			<GridLayoutCell desktopSpan={6}>
				{durationComp}
			</GridLayoutCell>
		);
	const pdrComp = isNil(postDurationComp)
		? null
		: (
			<GridLayoutCell desktopSpan={6}>
				{postDurationComp}
			</GridLayoutCell>
		);
	const szComp = isNil(sizeComp)
		? null
		: (
			<GridLayoutCell span={12}>
				{sizeComp}
			</GridLayoutCell>
		);
	const icComp = isNil(iconComp)
		? null
		: (
			<GridLayoutCell desktopSpan={6}>
				{iconComp}
			</GridLayoutCell>
		);
	const clComp = isNil(colorComp)
		? null
		: (
			<GridLayoutCell desktopSpan={6}>
				{colorComp}
			</GridLayoutCell>
		);
	const prComp = isNil(pricingComp)
		? null
		: (
			<GridLayoutCell span={12}>
				{pricingComp}
			</GridLayoutCell>
		);
	const vrComp = isNil(variantComp)
		? null
		: (
			<GridLayoutCell span={12}>
				{variantComp}
			</GridLayoutCell>
		);
	const optComp = isNil(optionComp)
		? null
		: (
			<GridLayoutCell span={12}>
				{optionComp}
			</GridLayoutCell>
		);
	const addComp = isNil(addOnComp)
		? null
		: (
			<GridLayoutCell span={12}>
				{addOnComp}
			</GridLayoutCell>
		);
	const assComp = isNil(assignableUserComp)
		? null
		: (
			<GridLayoutCell span={12}>
				{assignableUserComp}
			</GridLayoutCell>
		);
	const qbComp = isNil(quickBooksComp)
		? null
		: (
			<GridLayoutCell span={12}>
				{quickBooksComp}
			</GridLayoutCell>
		);
	const clsName = makeClassName(
		'pb-grid-layout-pad-it',
		'pb-product-form',
		className,
	);
	return (
		<GridLayout className={clsName} {...rest}>
			{tbComp}
			{availComp}
			{mutex}
			{exclComp}
			{nmComp}
			{smComp}
			{descComp}
			{drComp}
			{pdrComp}
			{szComp}
			{icComp}
			{clComp}
			{prComp}
			{vrComp}
			{optComp}
			{addComp}
			{assComp}
			{qbComp}
			<GridLayoutCell span={12}>
				{children}
			</GridLayoutCell>
		</GridLayout>
	);
}

function isNil(obj?: React.ReactNode | null | undefined): boolean {
	return (obj === undefined) || (obj === null);

}
