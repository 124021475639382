import React from 'react';

import {makeClassName} from '../util';

export interface ICtaProps extends React.HTMLAttributes<any> {
}

export function Cta(props: Partial<ICtaProps>) {
	const {
		children,
		className,
		...rest
	} = props;
	const clsName = makeClassName(
		'pb-cta',
		className,
	);
	return (
		<div className={clsName} {...rest}>
			{children}
		</div>
	);
}
