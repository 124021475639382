import React from 'react';

import {
	Button,
	CCard,
	CCardBottomButtons,
	CCardContent,
	CCardControls,
	CCardRowActionSet,
	CCardSwitchControl,
	CCardTextInputControl,
	CCardToolbarAction,
	EditCardHeader,
	EditCardSection,
	EditCardSectionRow,
	ICCardProps,
} from '../../components';

export interface IWidgetFormProps extends ICCardProps {
	heading: string;
	onAddOrgAppWidgetOption: () => any;
	onCancel: () => any;
	onDeleteOrgAppWidgetOption: (optionIndex: number) => any;
	onOrgAppWidgetChange: <K extends keyof IOrgAppWidget>(key: K, value: IOrgAppWidget[K]) => any;
	onOrgAppWidgetOptionChange: <K extends keyof IOrgAppWidgetOption>(optionIndex: number, key: K, value: IOrgAppWidgetOption[K]) => any;
	onSave: () => any;
	onToolbarAction: (axn: CCardToolbarAction, clientX?: number, clientY?: number) => any;
	orgAppWidget: IOrgAppWidget;
}

export function WidgetForm(props: IWidgetFormProps) {
	const {
		heading,
		onAddOrgAppWidgetOption,
		onCancel,
		onDeleteOrgAppWidgetOption,
		onOrgAppWidgetChange,
		onOrgAppWidgetOptionChange,
		onSave,
		orgAppWidget,
		onToolbarAction,
		...rest
	} = props;
	return (
		<CCard {...rest}>
			<EditCardHeader text={heading}/>
			<CCardContent>
				<EditCardSection>
					<EditCardSectionRow itemIconName="widgets">
						<CCardControls>
							<label className="display--flex align-items--center flex--1">
								<span style={{flexBasis: '96px'}}>Enabled</span>
								<CCardSwitchControl
									onClick={() => onOrgAppWidgetChange('isEnabled', !orgAppWidget.isEnabled)}
									isSelected={orgAppWidget.isEnabled}
								/>
							</label>
						</CCardControls>
					</EditCardSectionRow>
					<EditCardSectionRow itemIconName="">
						<CCardControls>
							<label className="display--flex align-items--center flex--1">
								<span style={{flexBasis: '96px'}}>Required</span>
								<CCardSwitchControl
									onClick={() => onOrgAppWidgetChange('isRequired', !orgAppWidget.isRequired)}
									isSelected={orgAppWidget.isRequired}
								/>
							</label>
						</CCardControls>
					</EditCardSectionRow>
					{
						orgAppWidget.options.length > 0
							? orgAppWidget.options.map((opt, idx) => {
								const renderAxn = (idx === orgAppWidget.options.length - 1) && (opt.label.length > 0);
								return (
									<EditCardSectionRow itemIconName="" key={idx} renderAddAction={renderAxn}>
										<CCardControls>
											<CCardTextInputControl
												value={opt.label}
												label="Label"
												onChange={val => onOrgAppWidgetOptionChange(idx, 'label', val)}
											/>
										</CCardControls>
										<CCardRowActionSet
											onAddClick={onAddOrgAppWidgetOption}
											onDeleteClick={() => onDeleteOrgAppWidgetOption(idx)}
										/>
									</EditCardSectionRow>
								);
							})
							: (
								<EditCardSectionRow className="justify-content--center" itemIconName="">
									<Button leadingIconName="add" onClick={onAddOrgAppWidgetOption}>Add Option</Button>
								</EditCardSectionRow>
							)
					}
				</EditCardSection>
			</CCardContent>
			<CCardBottomButtons
				onCancel={onCancel}
				onSave={onSave}
			/>
		</CCard>
	);
}
