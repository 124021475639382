import React from 'react';

import {
	bind,
	makeClassName,
} from '../../util';
import {
	ButtonSegment,
	ChipSet,
	ChipSetEventType,
	IChipSetChip,
	IGoogleSucksAtSoftwareChipSetEventData,
	SegmentedButton,
	TextInput,
} from '../../components';

export interface IProjectSearchProps extends React.HTMLAttributes<any> {
	onProjectFilterClick: (index: number) => any;
	onSearchFilterClick: (index: number) => any;
	onSearchFilterDelete: (index: number) => any;
	onSearchInputChange: (value: string) => any;
	onSearchInputTrailingIconClick: (event: React.MouseEvent) => any;
	onSearchInputKeyPress: (key: string) => any;
	projectFilters: Array<{label: string; isSelected: boolean;}>;
	searchFilters: Array<Partial<IChipSetChip>>;
	searchInput: string;
}

export class ProjectSearch extends React.Component<Partial<IProjectSearchProps>, any> {
	@bind
	private inputKeyPressed(event: React.KeyboardEvent): void {
		const {onSearchInputKeyPress} = this.props;
		if (onSearchInputKeyPress) {
			onSearchInputKeyPress(event.key);
		}
	}

	@bind
	private projectFilterClicked(index: number): void {
		const {
			onProjectFilterClick,
		} = this.props;
		if (onProjectFilterClick) {
			onProjectFilterClick(index);
		}
	}

	render() {
		const {
			className,
			onProjectFilterClick,
			onSearchFilterClick,
			onSearchFilterDelete,
			onSearchInputChange,
			onSearchInputKeyPress,
			onSearchInputTrailingIconClick,
			projectFilters,
			searchFilters,
			searchInput,
			...rest
		} = this.props;
		const filters = (projectFilters === undefined)
			? []
			: projectFilters;
		const clsName = makeClassName(
			'project-search-and-status',
			className,
		);
		return (
			<>
				<div className={clsName} {...rest}>
					<div className="project-search">
						<TextInput
							id="id_project-search"
							placeholder="Type here to search..."
							trailingIcon="search"
							onChange={onSearchInputChange}
							value={searchInput}
							onKeyDown={this.inputKeyPressed}
							onTrailingIconClick={onSearchInputTrailingIconClick}
						/>
					</div>
					<SegmentedButton className="project-status-segmented-button">
						{
							filters.map((projectFilter, i) => {
								return (
									<ButtonSegment
										iconName={
											projectFilter.isSelected
												? 'check_circle_outline'
												: 'radio_button_unchecked'
										}
										isSelected={projectFilter.isSelected}
										key={i}
										onClick={() => this.projectFilterClicked(i)}>
										{projectFilter.label}
									</ButtonSegment>
								);
							})
						}
					</SegmentedButton>
				</div>
				<ChipSet
					chips={this.searchFilterChips()}
					onEvent={this.searchFilterEvent}
					style={{padding: '8px'}}
				/>
			</>
		);
	}

	@bind
	private searchFilterChips(): Array<Partial<IChipSetChip>> {
		const {searchFilters} = this.props;
		if (searchFilters === undefined) {
			return [];
		}
		return searchFilters.map((x, idx) => {
			return {
				id: `id_${idx}`,
				isDeletable: true,
				isFilter: false,
				isInput: true,
				isSelectable: true,
				onlyPrimaryActionIsNavigable: false,
				...x,
			};
		});
	}

	@bind
	private searchFilterEvent(typ: ChipSetEventType, data: Partial<IGoogleSucksAtSoftwareChipSetEventData>) {
		const {
			onSearchFilterClick,
			onSearchFilterDelete,
		} = this.props;
		switch (typ) {
			case ChipSetEventType.Selection: {
				if (onSearchFilterClick === undefined) {
					return;
				}
				if (data.chipIndex === undefined) {
					console.log('searchFilterEvent: obj index missing');
				} else {
					onSearchFilterClick(data.chipIndex);
				}
				break;
			}
			case ChipSetEventType.Removal: {
				if (onSearchFilterDelete === undefined) {
					return;
				}
				if (data.chipIndex === undefined) {
					console.log('searchFilterEvent: obj index missing');
				} else {
					onSearchFilterDelete(data.chipIndex);
				}
				break;
			}
		}
	}
}
