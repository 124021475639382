import React from 'react';

import {Button} from '../../components';
import {bind, idxOk} from '../../util';

interface IPriceGroupWithChildren extends IPriceGroup {
	children: Array<IPriceGroupWithChildren>;
}

export interface IPriceGroupListViewProps {
	onPriceGroupClick: (pk: number) => any;
	priceGroups: Array<IPriceGroup>;
}

interface IPriceGroupListViewState {
}

export class PriceGroupListView extends React.Component<IPriceGroupListViewProps, IPriceGroupListViewState> {
	@bind
	private itemClicked(priceGroupPk: number) {
		const {
			onPriceGroupClick,
			priceGroups,
		} = this.props;
		const idx = priceGroups.findIndex(
			x => (x.id === priceGroupPk)
		);
		if (idxOk(idx, priceGroups.length, 'itemClicked')) {
			onPriceGroupClick(priceGroups[idx].id);
		}
	}

	render() {
		const wChildren = makeWithChildren(this.props.priceGroups);
		makeTree(wChildren);
		const roots = wChildren.filter(x => (x.level === 0));
		return (
			<ul className="pb-price-group-list">
				{
					roots.map((node, idx) => {
						return (
							<ParentWithChildren key={idx} onClick={this.itemClicked} parent={node}/>
						);
					})
				}
			</ul>
		);
	}
}

function makeWithChildren(objs: Array<IPriceGroup>): Array<IPriceGroupWithChildren> {
	return objs.map(x => ({
		...x,
		children: [],
	}));
}

function makeTree(objs: Array<IPriceGroupWithChildren>) {
	for (const obj of objs) {
		makeBranch(
			obj,
			objs,
		);
	}
}

function makeBranch(parent: IPriceGroupWithChildren, objs: Array<IPriceGroupWithChildren>): void {
	for (const obj of objs) {
		if (obj.parentId === parent.id) {
			parent.children.push({
				...obj,
			});
		}
	}
}

interface IParentWithChildrenProps extends Omit<React.HTMLAttributes<HTMLLIElement>, 'onClick'> {
	// onClick: React.EventHandler<React.MouseEvent<HTMLButtonElement>>;
	onClick: (priceGroupPk: number) => any;
	parent: IPriceGroupWithChildren;
}

function ParentWithChildren(props: IParentWithChildrenProps) {
	const {
		onClick,
		parent,
		...rest
	} = props;
	return (
		<li className="pb-price-group-list-item" {...rest}>
			<Button onClick={() => onClick(parent.id)}>
				{parent.name}
			</Button>
			<ul className="pb-price-group-list">
				{
					parent.children.map((child, idx) => {
						return (
							<ParentWithChildren key={idx} onClick={onClick} parent={child}/>
						);
					})
				}
			</ul>
		</li>
	);
}
