import React from 'react';

import {api} from '../../httpapi';
import {
	bind,
	staticUserClass,
} from '../../util';
import {
	Button,
	CCard,
	CCardAvatar,
	CCardDescribe,
	CCardSection,
	CCardHeader,
	CCardIntro,
	CCardName,
	CCardNameTitle,
	CCardContent,
	CCardBottom,
	ICCardProps,
} from '../../components';

export interface IUserClassDeleteViewProps extends Omit<ICCardProps, 'onChange'> {
	onCancel?: () => any;
	onDone?: () => any;
	pk: number;
}

interface IUserClassDeleteViewState {
	obj: IUserClass;
}

export class UserClassDeleteView extends React.Component<IUserClassDeleteViewProps, IUserClassDeleteViewState> {
	constructor(props: IUserClassDeleteViewProps) {
		super(props);
		this.state = {
			obj: staticUserClass(),
		};
	}

	@bind
	private canceled(): void {
		const {onCancel} = this.props;
		if (onCancel) {
			onCancel();
		}
	}

	@bind
	private changed<K extends keyof IUserClass>(name: K, value: IUserClass[K]): void {
		this.setState({
			obj: {
				...this.state.obj,
				[name]: value,
			},
		});
	}

	async componentDidMount() {
		this.setState({
			obj: await api.userClassDetail(this.props.pk),
		});
	}

	@bind
	private async deleteObj(pk: number | string): Promise<void> {
		return await api.deleteUserClass(pk);
	}

	render() {
		const {
			onCancel,
			onDone,
			...rest
		} = this.props;
		const {obj} = this.state;
		return (
			<CCard {...rest}>
				<CCardHeader>
					<CCardIntro>
						<CCardAvatar/>
						<CCardDescribe>
							<CCardNameTitle>
								<CCardName>
									{obj.name}
								</CCardName>
							</CCardNameTitle>
						</CCardDescribe>
					</CCardIntro>
				</CCardHeader>
				<CCardContent>
					<CCardSection>
						Delete this user class?
					</CCardSection>
				</CCardContent>
				<CCardBottom {...rest}>
					<Button value="cancel" raisedFilled onClick={this.canceled}>Cancel</Button>
					<Button value="delete" onClick={this.submitted}>Delete</Button>
				</CCardBottom>
			</CCard>
		);
	}

	@bind
	private async submitted() {
		const {onDone} = this.props;
		const {obj} = this.state;
		await this.deleteObj(obj.id);
		this.setState(
			{
				obj: staticUserClass(),
			},
			() => {
				if (onDone) {
					onDone();
				}
			},
		);
	}
}

