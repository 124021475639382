import React from 'react';

import {
	CssClassName,
} from './constants';
import {
	makeClassName,
} from '../../util';

export interface IListProps extends React.HTMLAttributes<HTMLUListElement> {
	hasNarrowMargin: boolean;
	isAvatarList: boolean;
	isCompact: boolean;
	isTwoLine: boolean;
}

interface IListState {
}

export class List extends React.Component<Partial<IListProps>, IListState> {
	private readonly rootRef: React.RefObject<HTMLUListElement>;

	constructor(props: Partial<IListProps>) {
		super(props);
		this.rootRef = React.createRef();
	}

	focus(): void {
		const root = this.rootRef.current;
		if (root) {
			root.focus();
		}
	}

	focusListItem(index: number): void {
		const root = this.rootRef.current;
		if (root) {
			const objs = root.querySelectorAll<HTMLLIElement>(`.${CssClassName.ListItem}`);
			if ((index >= 0) && (index < objs.length)) {
				objs[index].focus();
			}
		}
	}

	render() {
		const {
			children,
			className,
			hasNarrowMargin,
			isAvatarList,
			isCompact,
			isTwoLine,
			...rest
		} = this.props;
		const clsName = makeClassName(
			CssClassName.List,
			isTwoLine
				? CssClassName.ListTwoLine
				: undefined,
			isCompact
				? CssClassName.ListCompact
				: undefined,
			isAvatarList
				? 'mdc-deprecated-list--avatar-list'
				: undefined,
			hasNarrowMargin
				? 'pb-list--narrow-margin'
				: undefined,
			className,
		);
		return (
			<ul className={clsName} ref={this.rootRef} {...rest}>
				{children}
			</ul>
		);
	}
}
