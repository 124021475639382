import React, {PropsWithChildren} from 'react';

import * as datetime from '../datetime';
import {
	bind,
	chunk,
	cssClassName,
	makeClassName,
} from '../util';
import {
	CloudChart,
	PrecipChart,
} from './charts';

interface Overlay {
	index: number;
	text: string;
}

interface IProps {
	// dates?: Array<IDate>;
	dates?: Array<IAvailability>;
	onClick?: (index: number | null) => any;
	overlay?: Overlay;
	weather?: Array<IWxDay>;
}

type Props = IProps;

function columnIndex(linearIndex: number, rowIndex: number): number {
	return linearIndex - Calendar.ROW_LENGTH * rowIndex;
}

function linearIndex(rowIndex: number, columnIndex: number): number {
	return columnIndex + Calendar.ROW_LENGTH * rowIndex;
}

export function partitionChartData(wx?: Array<IWxHour>): {cloud: {cloud_cov: number;}[]; precip: {prob_precip: number;}[]} {
	if (wx === undefined) {
		return {
			cloud: [],
			precip: [],
		};
	}
	const cloud: Array<{cloud_cov: number;}> = [];
	const precip: Array<{prob_precip: number;}> = [];
	for (let i = 0; i < wx.length; ++i) {
		const w = wx[i];
		cloud.push({cloud_cov: w.cloudCov});
		precip.push({prob_precip: w.probPrecip});
	}
	return {
		cloud,
		precip,
	};
}

function validRowIndexForLinearIndex(linearIndex: number, rowIndex: number): boolean {
	const colIdx = columnIndex(linearIndex, rowIndex);
	return (colIdx >= 0) && (colIdx < Calendar.ROW_LENGTH);
}

export class Calendar extends React.Component<Props, {}> {
	static ROW_LENGTH: number = 3;

	// availabilityDates(): DateAvailability[] {
	// 	const {dates} = this.props;
	// 	if (dates) {
	// 		if (isIDateArray(dates)) {
	// 			return dates.map(d => ({
	// 				date: d,
	// 				available: true
	// 			}));
	// 		}
	// 		return dates;
	// 	}
	// 	return [];
	// }

	@bind
	dayClicked(index: number): void {
		const {onClick} = this.props;
		if (onClick) {
			onClick(index);
		}
	}

	dayClickHandler(rowIndex: number): ((idx: number) => any) | undefined {
		return this.props.onClick
			?
			(idx: number) => this.dayClicked(linearIndex(rowIndex, idx))
			:
			undefined;
	}

	dayOverlay(rowIndex: number): Overlay | undefined {
		const {overlay} = this.props;
		if (overlay && validRowIndexForLinearIndex(overlay.index, rowIndex)) {
			return {
				index: columnIndex(overlay.index, rowIndex),
				text: overlay.text,
			};
		}
		return undefined;
	}

	// dateRows(): DateAvailability[][] {
	// 	return chunk(this.availabilityDates(), Calendar.ROW_LENGTH);
	// }

	dateRows(): Array<Array<IAvailability>> {
		const {dates} = this.props;
		const d = (dates === undefined)
			? []
			: dates;
		return chunk(
			d,
			Calendar.ROW_LENGTH,
		);
	}

	render() {
		return (
			<div className="pb-calendar">
				{this.dateRows().map((row, idx) =>
					<Row
						dates={row}
						key={idx}
						onClick={this.dayClickHandler(idx)}
						overlay={this.dayOverlay(idx)}
						wx={this.weatherForDate}
					/>)}
			</div>
		);
	}

	@bind
	weatherForDate(isoDate: string): Array<IWxHour> | undefined {
		const {weather} = this.props;
		if (weather === undefined) {
			return undefined;
		}
		for (let i = 0; i < weather.length; ++i) {
			const d = weather[i];
			if (d.date === isoDate) {
				return d.hours;
			}
		}
		return undefined;
	}
}

interface DayProps extends React.HTMLAttributes<any> {
	className?: string;
	interactive?: boolean;
	onClick?: () => any;
	selected?: boolean;
	unavailable?: boolean;
}

function Day(props: DayProps) {
	const {
		children,
		className,
		interactive,
		onClick,
		selected,
		unavailable,
		...rest
	} = props;
	const clsName = makeClassName(
		'pb-calendar__day',
		interactive
			? 'pb-calendar__day--interactive'
			: undefined,
		selected
			? 'pb-calendar__day--selected'
			: undefined,
		unavailable
			? 'pb-calendar__day--unavailable'
			: undefined,
		className,
	);
	return (
		<div className={clsName} onClick={onClick} {...rest}>
			{children}
		</div>
	);
}

function Date({
	              day,
	              name,
              }: {day?: number; name?: string;}) {
	return (
		<div className="pb-calendar__date">
			<span className="pb-calendar__date__weekday">{name}</span>
			<span className="pb-calendar__date__day-of-month">{day}</span>
		</div>
	);
}

function Wx({
	            cloud,
	            children,
	            precip,
            }: PropsWithChildren<{cloud: {cloud_cov: number;}[]; precip: {prob_precip: number;}[]}>) {
	return (
		<div className="pb-calendar__wx">
			<div className="pb-calendar__sun"/>
			<div className="pb-calendar__wx-conditions">
				<CloudChart data={cloud}/>
			</div>
			<div className="pb-calendar__wx-conditions">
				<PrecipChart data={precip}/>
			</div>
			{children}
		</div>
	);
}

function DayPeriod() {
	return (
		<div className="pb-calendar__day-period">
			<span>AM</span>
			<span>NOON</span>
			<span>PM</span>
		</div>
	);
}

interface IRowProps {
	// dates: DateAvailability[];
	dates: Array<IAvailability>;
	onClick?: (idx: number) => any;
	overlay?: Overlay;
	wx?: (isoFormat: string) => (Array<IWxHour> | undefined);
}

function Row(props: IRowProps) {
	const {
		dates,
		onClick,
		overlay,
		wx,
	} = props;
	return (
		<div className="pb-calendar__row">
			{
				dates.map((avail, idx) => {
					const dt = datetime.datetime.fromisoformat(avail.datetime);
					return (
						<Day interactive={Boolean(avail.available && onClick)} key={idx} onClick={onClick
							? () => onClick(idx)
							: undefined} selected={Boolean(overlay && (overlay.index === idx))} unavailable={!avail.available}>
							<Date day={dt.day} name={dt.weekdayNameAbbr()}/>
							<Wx {...partitionChartData(wx
								? wx(dt.date().isoformat())
								: undefined)}>
								{
									avail.available
										? null
										: (
											<DayLabel>
												UNAVAILABLE
											</DayLabel>
										)

								}
								{
									(overlay && (overlay.index === idx))
										? (
											<SelectedDayLabel>
												{overlay.text}
											</SelectedDayLabel>
										)
										: null
								}
							</Wx>
						</Day>
					);
				})
			}
			<DayPeriod/>
		</div>
	);
}

function DayLabel({children}: PropsWithChildren<{}>) {
	return (
		<div className="pb-calendar__day-label">
			{children}
		</div>
	);
}

function SelectedDayLabel({children}: PropsWithChildren<{}>) {
	return (
		<DayLabel>
			<span>{children}</span>
			<i className="material-icons pb-calendar__day-label__icon">check</i>
		</DayLabel>
	);
}
