import React from 'react';
import type {
	PaymentIntentResult,
	Stripe,
	StripeElements,
} from '@stripe/stripe-js';

import {
	Button,
	CCard,
	CCardAvatar,
	CCardContent,
	CCardDescribe,
	CCardHeader,
	CCardIntro,
	CCardName,
	CCardNameTitle,
	CCardSection,
	Checkbox,
	CircularProgress,
	Dialog,
	GridLayout,
	GridLayoutCell,
	GridLayoutInner,
	ICCardProps,
	List,
	ListItem,
	ListItemPrimaryText,
	ListItemSecondaryText,
	Switch,
	TextInput,
} from '../../components';
import {
	CheckState,
	StandardButton,
} from '../../constants';
import {
	bind,
	idxOk,
	numberFormat,
} from '../../util';

export interface IPaymentFormViewProps extends React.FormHTMLAttributes<HTMLFormElement> {
	amount: string;
	onAmountChange?: (amount: string) => any;
	onDoneProcessing: (nfo: IPaymentInfo) => any;
	onInitialized?: () => any;
	onSavePaymentInfoCheckStateChange: (state: CheckState) => any;
	paymentInfo: IPaymentInfo;
	paymentMethods: Array<IPaymentMethod>;
	processPayment: (nfo: IPaymentInfo) => Promise<IPaymentInfo>;
	updatePaymentInfo: (data: IPaymentInfo) => Promise<IPaymentInfo>;
}

export interface IPaymentFormViewState {
	isProcessing: boolean;
	paymentMethodDialogIsOpen: boolean;
	selectedPaymentMethodId: string;
	useSavedPaymentMethod: boolean;
}

let settingUpPaymentVendor: boolean = false;
let stripeObj: Stripe | null = null;
let stripeEls: StripeElements | null = null;

export class PaymentFormView extends React.Component<IPaymentFormViewProps, IPaymentFormViewState> {
	private readonly pmtMethInputContainerRef: React.RefObject<HTMLDivElement>;

	constructor(props: IPaymentFormViewProps) {
		super(props);
		this.pmtMethInputContainerRef = React.createRef();
		this.state = {
			isProcessing: false,
			paymentMethodDialogIsOpen: false,
			selectedPaymentMethodId: '',
			useSavedPaymentMethod: false,
		};
	}

	@bind
	private changePaymentMethodClicked() {
		if (!this.state.paymentMethodDialogIsOpen) {
			this.setState({
				paymentMethodDialogIsOpen: true,
			});
		}
	}

	@bind
	private closePaymentMethodDialog() {
		if (this.state.paymentMethodDialogIsOpen) {
			this.setState({
				paymentMethodDialogIsOpen: false,
			});
		}
	}

	componentDidMount() {
		const {paymentMethods} = this.props;
		const selectedPaymentMethodId = (paymentMethods.length > 0)
			? paymentMethods[paymentMethods.length - 1].id
			: '';
		this.setState(
			{
				selectedPaymentMethodId,
				useSavedPaymentMethod: selectedPaymentMethodId.length > 0,
			},
			() => this.setupPaymentVendor(),
		);
	}

	componentWillUnmount() {
		if (stripeEls) {
			const el = stripeEls.getElement('payment');
			if (el) {
				el.unmount();
			}
		}
	}

	@bind
	private elReady() {
		const {onInitialized} = this.props;
		if (onInitialized) {
			onInitialized();
		}
	}

	private paymentMethodExpirationDisplay(obj: IPaymentMethod | null): string | null {
		if (obj && obj.expiration) {
			return `Expires ${obj.expiration.month}/${obj.expiration.year}`;
		}
		return null;
	}

	@bind
	private paymentMethodItemClicked(index: number) {
		const {paymentMethods} = this.props;
		this.closePaymentMethodDialog();
		if (idxOk(index, paymentMethods.length, 'paymentMethodItemClicked')) {
			const obj = paymentMethods[index];
			this.setState({
				selectedPaymentMethodId: obj.id,
			});
		}
	}

	@bind
	private async processPayment() {
		const {
			amount,
			processPayment,
			updatePaymentInfo,
			onDoneProcessing,
		} = this.props;
		const {
			selectedPaymentMethodId,
			useSavedPaymentMethod,
		} = this.state;
		let paymentInfo: IPaymentInfo = {
			...this.props.paymentInfo,
			amount,
		};
		if (useSavedPaymentMethod && selectedPaymentMethodId.length > 0) {
			paymentInfo.paymentMethodId = selectedPaymentMethodId;
		}
		paymentInfo = await updatePaymentInfo(paymentInfo);
		let status: PaymentInfoStatus = paymentInfo.status;
		let loc: PaymentIntentResult | null = null;
		if (paymentInfo.paymentMethodId.length === 0) {
			if ((stripeObj === null) || (stripeEls === null)) {
				console.log('submitted: Payment vendor data not set.');
				return;
			}
			loc = await stripeObj.confirmPayment({
				elements: stripeEls,
				confirmParams: {
					return_url: window.location.href,
				},
				redirect: 'if_required',
			});
			if (loc.paymentIntent === undefined) {
				status = 'failed';
				if (loc.error.code !== undefined) {
					paymentInfo.errorCode = loc.error.code;
				}
				if (loc.error.message !== undefined) {
					paymentInfo.errorMessage = loc.error.message;
				}
				if (loc.error.type !== undefined) {
					paymentInfo.errorType = loc.error.type;
				}
			} else {
				switch (loc.paymentIntent.status) {
					case 'succeeded': {
						status = 'succeeded';
						break;
					}
					case 'canceled': {
						status = 'canceled';
						break;
					}
					default: {
						console.log('Txn status not handled: %s', loc.paymentIntent.status);
						break;
					}
				}
			}
			paymentInfo.status = status;
		}
		onDoneProcessing(
			await processPayment(paymentInfo),
		);
	}

	render() {
		const {
			amount,
			onAmountChange,
			onSavePaymentInfoCheckStateChange,
			paymentInfo,
			paymentMethods,
			onInitialized,
			processPayment,
			updatePaymentInfo,
			onDoneProcessing,
			...rest
		} = this.props;
		const {
			isProcessing,
			paymentMethodDialogIsOpen,
			useSavedPaymentMethod,
		} = this.state;
		const pmtMeth = this.selectedPaymentMethod();
		const vendorSty = (useSavedPaymentMethod && (pmtMeth !== null))
			? {display: 'none'}
			: undefined;
		return (
			<form id="payment-form" onSubmit={this.submitted} {...rest}>
				<GridLayout>
					{
						(pmtMeth === null)
							? null
							: (
								<GridLayoutCell span={12}>
									<label className="display--flex align-items--center">
										<Switch onClick={this.useSavedCardClicked} isSelected={useSavedPaymentMethod}/>
										<span style={{paddingLeft: '16px'}}>Use saved card</span>
									</label>
								</GridLayoutCell>
							)
					}
					{
						((pmtMeth === null) || !useSavedPaymentMethod)
							? null
							: (
								<>
									<GridLayoutCell span={12}>
										<List isAvatarList isCompact isTwoLine>
											<ListItem noRipple leadingIconName={pmtMeth.icon}>
												<ListItemPrimaryText style={{fontSize: '1rem'}}>
													{pmtMeth.brand} &#8226;&#8226;&#8226;&#8226; {pmtMeth.last4}
												</ListItemPrimaryText>
												<ListItemSecondaryText style={{fontSize: '.812rem'}}>
													{
														paymentMethods.length > 1
															? (
																<>
																	{this.paymentMethodExpirationDisplay(pmtMeth)} (<Button onClick={this.changePaymentMethodClicked} density={-3}>change</Button>)
																</>
															)
															: (
																<>
																	{this.paymentMethodExpirationDisplay(pmtMeth)}
																</>
															)
													}
												</ListItemSecondaryText>
											</ListItem>
										</List>
									</GridLayoutCell>
								</>
							)
					}
					<GridLayoutCell span={12}>
						<span style={{paddingRight: '4px'}}>$</span>
						<TextInput
							isMinimal
							isDisabled={onAmountChange === undefined}
							label="Amount to pay"
							isRequired
							value={amount}
							onChange={onAmountChange}
						/>
					</GridLayoutCell>
					<GridLayoutCell span={12} style={vendorSty}>
						<GridLayoutInner>
							<GridLayoutCell span={4}>
								<div ref={this.pmtMethInputContainerRef}/>
							</GridLayoutCell>
						</GridLayoutInner>
					</GridLayoutCell>
					<GridLayoutCell span={12}>
						<div className="display--flex align-items--center">
							<Button disabled={isProcessing} raisedFilled type="submit">Pay {numberFormat(amount, '$')}</Button>
							{
								isProcessing
									? <CircularProgress className="margin-left--16" isOpen={isProcessing} size={0}/>
									: useSavedPaymentMethod
										? null
										: (
											<label className="display--flex align-items--center" style={{paddingLeft: '16px'}}>
												<Checkbox onChange={onSavePaymentInfoCheckStateChange} isChecked={paymentInfo.savePaymentMethod}/>
												<span>Save payment information</span>
											</label>
										)
							}
						</div>
					</GridLayoutCell>
				</GridLayout>
				<Dialog
					isOpen={paymentMethodDialogIsOpen}
					onFinished={this.closePaymentMethodDialog}
					buttons={StandardButton.Cancel}>
					<PaymentMethodPrompt>
						<List isAvatarList isCompact isTwoLine>
							{
								paymentMethods.map((obj, idx) => {
									return (
										<ListItem onClick={this.paymentMethodItemClicked.bind(this, idx)} key={idx} isClickable leadingIconName={obj.icon}>
											<ListItemPrimaryText style={{fontSize: '1rem'}}>
												{obj.brand} &#8226;&#8226;&#8226;&#8226; {obj.last4}
											</ListItemPrimaryText>
											<ListItemSecondaryText style={{fontSize: '.812rem'}}>
												{this.paymentMethodExpirationDisplay(pmtMeth)}
											</ListItemSecondaryText>
										</ListItem>
									);
								})
							}
						</List>
					</PaymentMethodPrompt>
				</Dialog>
			</form>
		);
	}

	private selectedPaymentMethod(): IPaymentMethod | null {
		const {paymentMethods} = this.props;
		const {selectedPaymentMethodId} = this.state;
		if (paymentMethods.length > 0) {
			for (const obj of paymentMethods) {
				if (obj.id === selectedPaymentMethodId) {
					return obj;
				}
			}
			return paymentMethods[0];
		}
		return null;
	}

	private async setupPaymentVendor() {
		if (settingUpPaymentVendor) {
			console.log('setupPaymentVendor: Already setup.');
			return;
		}
		const {paymentInfo} = this.props;
		const mod = await import('@stripe/stripe-js');
		settingUpPaymentVendor = true;
		stripeObj = await mod.loadStripe(paymentInfo.publicKey);
		stripeEls = null;
		if (stripeObj) {
			stripeEls = stripeObj.elements({clientSecret: paymentInfo.vendorKey});
			const el = stripeEls.create('payment');
			el.on('ready', this.elReady);
			if (this.pmtMethInputContainerRef.current) {
				el.mount(this.pmtMethInputContainerRef.current);
			}
		}
		settingUpPaymentVendor = false;
	}

	@bind
	private async submitted(event: React.FormEvent) {
		event.preventDefault();
		if (this.state.isProcessing) {
			return;
		}
		this.setState(
			{
				isProcessing: true,
			},
			async () => {
				await this.processPayment();
				this.setState({
					isProcessing: false,
				});
			},
		);
	}

	@bind
	private useSavedCardClicked() {
		this.setState({
			useSavedPaymentMethod: !this.state.useSavedPaymentMethod,
		});
	}
}

interface IPaymentMethodPromptProps extends Partial<ICCardProps> {
}

interface IPaymentMethodPromptState {
}

class PaymentMethodPrompt extends React.Component<IPaymentMethodPromptProps, IPaymentMethodPromptState> {
	render() {
		const {
			children,
			...rest
		} = this.props;
		return (
			<CCard {...rest}>
				<CCardHeader>
					<CCardIntro>
						<CCardAvatar>
							payment
						</CCardAvatar>
						<CCardDescribe>
							<CCardNameTitle>
								<CCardName>
									Choose a payment method
								</CCardName>
							</CCardNameTitle>
						</CCardDescribe>
					</CCardIntro>
				</CCardHeader>
				<CCardContent>
					<CCardSection>
						{children}
					</CCardSection>
				</CCardContent>
			</CCard>
		);
	}
}
