import React from 'react';

import {
	bind,
	isNumber,
} from '../../util';
import {
	CCardContent,
	CCard,
	CCardComboBoxControl,
	CCardControls,
	CCardBottomButtons,
	EditCardHeader,
	EditCardSection,
	EditCardSectionRow,
	CCardRowActionSet,
	CCardTextInputControl,
	ICCardProps,
	IMenuOptionProps,
} from '../../components';

export interface IPriceGroupFormProps extends Omit<Partial<ICCardProps>, 'onChange'> {
	obj: IPriceGroup;
	onCancel: () => any;
	onChange: <K extends keyof IPriceGroup>(name: K, value: IPriceGroup[K]) => any;
	onSave: () => any;
	parentPriceGroups: Array<IPriceGroup>;
	title: string;
}

export class PriceGroupForm extends React.Component<IPriceGroupFormProps, {}> {
	@bind
	private changed<K extends keyof IPriceGroup>(name: K, value: IPriceGroup[K]): void {
		this.props.onChange(name, value);
	}

	@bind
	private clearColor(): void {
		this.colorChanged('');
	}

	@bind
	private colorChanged(value: string): void {
		this.changed('color', value);
	}

	@bind
	private iconChanged(value: string): void {
		this.changed('icon', value);
	}

	@bind
	private nameChanged(value: string): void {
		this.changed('name', value);
	}

	private parentComboOpts(): Array<IMenuOptionProps> {
		const {
			obj,
			parentPriceGroups,
		} = this.props;
		const rv: Array<IMenuOptionProps> = [];
		if (parentPriceGroups.length > 0) {
			rv.push({
				label: '',
				value: '',
				isSelected: obj.parentId === null,
			});
		}
		for (const parentObj of parentPriceGroups) {
			rv.push({
				label: parentObj.name,
				value: String(parentObj.id),
				isSelected: obj.parentId === parentObj.id,
			});
		}
		return rv;
	}

	@bind
	private parentIdChanged(value: string): void {
		let parentId: number | null;
		if (value === '') {
			parentId = null;
		} else {
			const num = Number.parseInt(value);
			if (isNumber(num)) {
				parentId = num;
			} else {
				console.log('parentIdChanged: Invalid value:', value);
				return;
			}
		}
		this.changed('parentId', parentId);
	}

	@bind
	private parentOptionChanged(index: number): void {
		const opts = this.parentComboOpts();
		if ((index >= 0) && (index < opts.length)) {
			this.parentIdChanged(opts[index].value);
		} else {
			console.log('parentOptionChanged: Invalid index:', index);
		}
	}

	render() {
		const {
			obj,
			onCancel,
			onSave,
			onChange,
			parentPriceGroups,
			title,
			...rest
		} = this.props;
		const parentComboOpts = this.parentComboOpts();
		return (
			<CCard {...rest}>
				<EditCardHeader text={title}/>
				<CCardContent>
					<EditCardSection>
						<EditCardSectionRow>
							<CCardControls>
								<CCardTextInputControl
									value={obj.name}
									label="Name"
									onChange={this.nameChanged}
								/>
							</CCardControls>
						</EditCardSectionRow>
						<EditCardSectionRow>
							<CCardControls>
								<CCardTextInputControl
									value={obj.icon}
									label="Icon"
									onChange={this.iconChanged}
								/>
								<CCardTextInputControl
									type="color"
									value={obj.color}
									label="Color"
									onChange={this.colorChanged}
								/>
							</CCardControls>
							<CCardRowActionSet
								onDeleteClick={this.clearColor}
							/>
						</EditCardSectionRow>
						{
							parentComboOpts.length > 0
								? (
									<EditCardSectionRow>
										<CCardControls>
											<CCardComboBoxControl
												// anchorMenuToBody
												label="Parent"
												options={parentComboOpts}
												onChange={this.parentOptionChanged}
											/>
										</CCardControls>
									</EditCardSectionRow>
								)
								: null
						}
					</EditCardSection>
				</CCardContent>
				<CCardBottomButtons
					onCancel={onCancel}
					onSave={onSave}
				/>
			</CCard>
		);
	}
}

