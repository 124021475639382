import React from 'react';

import {
	Button,
	GridLayout,
	GridLayoutCell,
	TextInput,
} from '../../components';

export interface ISnippetFormViewProps extends React.FormHTMLAttributes<HTMLFormElement> {
	obj: ISnippet;
	onBodyChange: (body: string) => any;
	onNameChange: (name: string) => any;
}

export interface ISnippetFormViewState {
}

export class SnippetFormView extends React.Component<ISnippetFormViewProps, ISnippetFormViewState> {
	render() {
		const {
			obj,
			onBodyChange,
			onNameChange,
			...rest
		} = this.props;
		return (
			<form action="" method="POST" {...rest}>
				<GridLayout>
					<GridLayoutCell span={12}>
						<TextInput
							isOutlined
							label="Name"
							onChange={onNameChange}
							value={obj.name}
						/>
					</GridLayoutCell>
					<GridLayoutCell span={12}>
						<TextInput
							className="width--100-percent"
							isOutlined
							isResizable
							isTextArea
							value={obj.body}
							onChange={onBodyChange}
							label="Snippet"
							rows={15}
						/>
					</GridLayoutCell>
					<GridLayoutCell span={12}>
						<div className="display--flex justify-content--flex-end">
							<Button>
								Preview
							</Button>
							<Button className="margin-left--24" raisedFilled type="submit">
								Save
							</Button>
						</div>
					</GridLayoutCell>
				</GridLayout>
			</form>
		);
	}
}
