import 'reflect-metadata';
import React from 'react';
import {createRoot} from 'react-dom/client';

import {basicConfig} from './logging';

// NB: Before importing local modules
basicConfig();

import './global';
import {Pb} from './views';

const el = document.querySelector('#wat-wat');
if (el) {
	createRoot(
		el,
	).render(
		<Pb/>,
	);
}
