import React from 'react';
import {
	bind,
	idxOk,
} from '../util';
import {CCardToolbarAction} from './ccard';

import {
	Dialog,
	IDialogProps,
	Modality,
} from './dialog';
import {
	IMenuOptionProps,
	Menu,
} from './menu';
import {Prompt} from './prompt';

interface IconInfo {
	name: string;
	isOutlined: boolean;
}

export enum Severity {
	Information = 1,
	Warning = 2,
	Critical = 3,
	Question = 4,
}

export interface IMessageBoxProps extends IDialogProps {
	detailedText?: string;
	iconName?: string;
	informativeText?: string;
	severity?: Severity;
	text?: string;
}

interface IMessageBoxState {
	moreMenuIsOpen: boolean;
	moreMenuCoords: PlainCoords;
}

export class MessageBox extends React.Component<IMessageBoxProps, IMessageBoxState> {
	static Severity: typeof Severity = Severity;

	constructor(props: IMessageBoxProps) {
		super(props);
		this.state = {
			moreMenuCoords: {
				x: 0,
				y: 0,
			},
			moreMenuIsOpen: false,
		};
	}

	@bind
	private actionTriggered(axn: CCardToolbarAction, coords: PlainCoords) {
		switch (axn) {
			case CCardToolbarAction.Close: {
				break;
			}
			case CCardToolbarAction.Edit: {
				break;
			}
			case CCardToolbarAction.More: {
				this.openMoreMenu(coords);
				break;
			}
		}
	}

	@bind
	private closeMoreMenu() {
		this.setState({moreMenuIsOpen: false});
	}

	private iconInfo(): IconInfo {
		const {iconName} = this.props;
		const rv = this.iconNameForSeverity();
		const name = (rv === undefined)
			? (iconName === undefined)
				? ''
				: iconName
			: rv;
		return {
			name,
			isOutlined: rv !== undefined,
		};
	}

	private iconNameForSeverity(): string | undefined {
		const {severity} = this.props;
		if (severity === undefined) {
			return undefined;
		}
		switch (severity) {
			case Severity.Question: {
				return 'help_outline';
			}
			case Severity.Information: {
				return 'info';
			}
			case Severity.Warning: {
				return 'report_problem';
			}
			case Severity.Critical: {
				return 'dangerous';
			}
		}
	}

	@bind
	private moreMenuOptions(): Array<IMenuOptionProps> {
		return [
			{
				value: 'delete',
				label: 'Delete',
				isSelected: false,
			},
		];
	}

	@bind
	private moreMenuOptionSelected(index: number) {
		const opts = this.moreMenuOptions();
		if (idxOk(index, opts.length, 'moreMenuOptionSelected')) {
			const opt = opts[index];
			if (opt.value === 'delete') {
			} else {
				console.log('moreMenuOptionSelected: Got invalid option value at index %s', index);
			}
		}
	}

	@bind
	private openMoreMenu(coords: PlainCoords) {
		this.setState({
			moreMenuIsOpen: true,
			moreMenuCoords: coords,
		});
	}

	render() {
		const {
			children,
			detailedText,
			iconName,
			informativeText,
			modality,
			severity,
			text,
			...rest
		} = this.props;
		const {
			moreMenuCoords,
			moreMenuIsOpen,
		} = this.state;
		const icoNfo = this.iconInfo();
		const axns = (modality === Modality.Modeless)
			? [
				CCardToolbarAction.Edit,
				CCardToolbarAction.More,
				CCardToolbarAction.Close,
			]
			: undefined;
		return (
			<Dialog modality={modality} {...rest}>
				<Prompt onAction={this.actionTriggered} actions={axns} header={text} subHeader={informativeText} iconName={icoNfo.name} iconIsOutlined={icoNfo.isOutlined}>
					{children}
					{
						detailedText
							? (
								<details>
									<summary>Show details...</summary>
									{detailedText}
								</details>
							)
							: null
					}
				</Prompt>
				<Menu
					isOpen={moreMenuIsOpen}
					absolutePosition={moreMenuCoords}
					anchorToBody
					onClose={this.closeMoreMenu}
					isCompact
					options={this.moreMenuOptions()}
					onSelection={this.moreMenuOptionSelected}
				/>
			</Dialog>
		);
	}
}
