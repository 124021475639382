import React from 'react';

import {makeClassName} from '../util';
import {Icon} from './icon';

export interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	download?: any;
	flatFilled?: boolean;
	href?: string;
	hrefLang?: string;
	isAnchor?: boolean;
	leadingIconName?: string;
	media?: string;
	mimeType?: string;
	density?: -1 | -2 | -3;
	outlined?: boolean;
	ping?: string;
	raisedFilled?: boolean;
	referrerPolicy?: React.HTMLAttributeReferrerPolicy;
	rel?: string;
	target?: React.HTMLAttributeAnchorTarget;
	trailingIconName?: string;
}

export class Button extends React.Component<IButtonProps, {}> {
	rootAnchorRef: React.RefObject<HTMLAnchorElement>;
	rootButtonRef: React.RefObject<HTMLButtonElement>;

	constructor(props: IButtonProps) {
		super(props);
		this.rootAnchorRef = React.createRef();
		this.rootButtonRef = React.createRef();
	}

	render() {
		const {
			children,
			className,
			density,
			flatFilled,
			isAnchor,
			leadingIconName,
			outlined,
			raisedFilled,
			trailingIconName,
			type,
			...rest
		} = this.props;
		const btnType = (!isAnchor && (type === undefined))
			? 'button'
			: type;
		const clsName = makeClassName(
			'mdc-button',
			outlined
				? 'mdc-button--outlined'
				: flatFilled
					? 'mdc-button--unelevated'
					: raisedFilled
						? 'mdc-button--raised'
						: undefined,
			(density === undefined)
				? undefined
				: `pb-button--density${density}`,
			leadingIconName
				? 'mdc-button--icon-leading'
				: undefined,
			trailingIconName
				? 'mdc-button--icon-trailing'
				: undefined,
			className,
		);
		const ch = (
			<>
				<span className="mdc-button__ripple"></span>
				<span className="mdc-button__focus-ring"></span>
				{
					leadingIconName
						? (
							<i className="material-icons mdc-button__icon" aria-hidden="true">
								{leadingIconName}
							</i>
						)
						: null
				}
				<span className="mdc-button__label">
					{children}
				</span>
				{
					trailingIconName
						? (
							<i className="material-icons mdc-button__icon" aria-hidden="true">
								{trailingIconName}
							</i>
						)
						: null
				}
			</>
		);
		return React.createElement(
			isAnchor
				? 'a'
				: 'button',
			{
				className: clsName,
				ref: isAnchor
					? this.rootAnchorRef
					: this.rootButtonRef,
				type: btnType,
				...rest,
			},
			ch,
		);
	}
}

interface IFloatingActionButtonBasePropsBase extends React.ButtonHTMLAttributes<HTMLButtonElement> {
}

interface IFloatingActionButtonBaseProps extends IFloatingActionButtonBasePropsBase {
	isExtended: boolean;
	isMini: boolean;
	iconName?: string;  // Only when isExtended is true b/c this element's children will be the label
}

function FloatingActionButtonBase(props: IFloatingActionButtonBaseProps) {
	const {
		children,
		className,
		iconName,
		isExtended,
		isMini,
		...rest
	} = props;
	if (isExtended && (iconName === undefined)) {
		console.log('FloatingActionButtonBase: Must provide icon name if setting isExtended.');
	}
	const isExtendedForReal = isExtended && (iconName !== undefined);
	const clsName = makeClassName(
		'mdc-fab',
		isMini
			? 'mdc-fab--mini'
			: undefined,
		isExtendedForReal
			? 'mdc-fab--extended'
			: undefined,
		className,
	);
	const ico = isExtendedForReal
		? iconName
		: children;
	return (
		<button className={clsName} {...rest}>
			<span className="mdc-fab__ripple"/>
			<Icon className="mdc-fab__icon">{ico}</Icon>
			{
				isExtendedForReal
					? (
						<span className="mdc-fab__label">{children}</span>
					)
					: null
			}
		</button>
	);
}

export interface IFloatingActionButtonProps extends IFloatingActionButtonBasePropsBase {
}

export function FloatingActionButton(props: IFloatingActionButtonProps) {
	return (
		<FloatingActionButtonBase
			isExtended={false}
			isMini={false}
			{...props}
		/>
	);
}

export interface IMiniFloatingActionButtonProps extends IFloatingActionButtonBasePropsBase {
}

export function MiniFloatingActionButton(props: IMiniFloatingActionButtonProps) {
	return (
		<FloatingActionButtonBase
			isExtended={false}
			isMini={true}
			{...props}
		/>
	);
}

export interface IExtendedFloatingActionButtonProps extends IFloatingActionButtonBasePropsBase {
	iconName: string;
}

export function ExtendedFloatingActionButton(props: IExtendedFloatingActionButtonProps) {
	return (
		<FloatingActionButtonBase
			isExtended={true}
			isMini={false}
			{...props}
		/>
	);
}

export interface ITubbyButtonProps extends IButtonProps {
}

export class TubbyButton extends React.Component<Partial<ITubbyButtonProps>, {}> {
	render() {
		const {
			className,
			...rest
		} = this.props;
		const clsName = makeClassName(
			'pb-tubby-button',
			className,
		);
		return (
			<Button
				raisedFilled
				className={clsName}
				leadingIconName="add"
				{...rest}
			/>
		);
	}
}
