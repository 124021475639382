import React from 'react';

import {makeClassName} from '../util';
import {IconButton} from './iconbutton';
import {
	Heading,
	HeadingLevel,
} from './typography';

export interface IContentHeaderBaseProps extends React.HTMLAttributes<any> {
	backNavTitle?: string;
	backNavUri?: string;
	hasDivider?: boolean;
	isWide?: boolean;
	level?: HeadingLevel;
	trailingNode?: React.ReactNode;
}

function ContentHeaderBase(props: IContentHeaderBaseProps) {
	const {
		backNavTitle,
		backNavUri,
		children,
		className,
		hasDivider,
		isWide,
		level,
		trailingNode,
		...rest
	} = props;
	const clsName = makeClassName(
		'pb-content-header',
		isWide
			? 'pb-content-header--content--wide'
			: undefined,
		className,
	);
	const contentClsName = makeClassName(
		'pb-content-header--content',
		isWide
			? 'pb-content-header--content--wide'
			: undefined,
	);
	const lvl = (level === undefined)
		? 5
		: level;
	return (
		<div className={clsName} {...rest}>
			<div className={contentClsName}>
				{
					backNavUri
						? (
							<a href={backNavUri} title={backNavTitle}><IconButton>arrow_back</IconButton></a>
						)
						: null
				}
				<Heading level={lvl}>
					{children}
				</Heading>
				{trailingNode}
			</div>
			{
				hasDivider
					? (
						<div className="pb-content-header--divider"/>
					)
					: null
			}
		</div>
	);
}

export interface IContentHeaderProps extends Omit<IContentHeaderBaseProps, 'isWide'> {
}

export function ContentHeader(props: IContentHeaderProps) {
	const {
		children,
		...rest
	} = props;
	return (
		<ContentHeaderBase {...rest}>
			{children}
		</ContentHeaderBase>
	);
}

export interface IContentHeaderProps extends Omit<IContentHeaderBaseProps, 'isWide'> {
}

export function WideContentHeader(props: IContentHeaderProps) {
	const {
		children,
		...rest
	} = props;
	return (
		<ContentHeaderBase isWide {...rest}>
			{children}
		</ContentHeaderBase>
	);
}
