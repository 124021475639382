import React from 'react';
import {ISchedulerSelectedBlock} from '../../../datetime';

import * as datetime from '../../../datetime';
import {
	CircularProgress,
	GridLayout,
	GridLayoutCell,
	Scheduler,
} from '../../../components';
import {CardHeader} from './misc';

export interface IDateTimeProps extends React.HTMLAttributes<any> {
	currentDate: datetime.date | null;
	currentDateAvailability: (date: datetime.date) => Array<IAvailability>;
	dateIsAvailable: (date: datetime.date) => boolean;
	onAnyTimeChange: (isChecked: boolean) => any;
	onBackNav: () => any;
	onDateChange: (date: datetime.date | null) => any;
	onTimeChange: (time: datetime.time | null) => any;
	onTimeSelectRequest: (datetime: datetime.datetime) => boolean;
	selectedEvent: ISchedulerSelectedBlock | null;
	timeRangeForWeekday: (weekday: number) => datetime.ITimeRange | null;
	timeSegmentStep: number | null;
	weather: Array<IWxDay>;
}

export function DateTime(props: IDateTimeProps) {
	const {
		currentDate,
		currentDateAvailability,
		dateIsAvailable,
		onAnyTimeChange,
		onBackNav,
		onDateChange,
		onTimeChange,
		onTimeSelectRequest,
		selectedEvent,
		timeRangeForWeekday,
		timeSegmentStep,
		weather,
	} = props;
	return (
		<>
			<CardHeader onBackNavClick={onBackNav} stepNumber={4}>
				Pick a Date
			</CardHeader>
			<GridLayout>
				<GridLayoutCell span={12}>
					<Scheduler
						currentDate={currentDate}
						currentDateAvailability={currentDateAvailability}
						dateIsAvailable={dateIsAvailable}
						onAnyTimeChange={onAnyTimeChange}
						onDateChange={onDateChange}
						onTimeChange={onTimeChange}
						onTimeSelectRequest={onTimeSelectRequest}
						selectedEvent={selectedEvent}
						timeRangeForWeekday={timeRangeForWeekday}
						timeSegmentStep={timeSegmentStep}
						weather={weather}
					/>
				</GridLayoutCell>
			</GridLayout>
		</>
	);
}
